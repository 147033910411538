import { ErrorResponse, PaginationType } from '../../typeDef/common.model';
import {
  FutureTrailRequest,
  FutureTrailSummaryCol,
  GetFutureTrialsRequest,
  GetFutureTrialsResponse,
} from '../../typeDef/grain.model';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import TableHeading, {
  SortDirection,
} from '../shared/tableHeading/tableHeading';
import CustomButton from '../shared/customButton/customButton';
import CustomModal from '../shared/customModal/customModal';
import FutureMissingView from './futureMissingView';
import Lable from '../shared/lable/lable';
import PaginationPages from '../shared/pagination/paginationPages';
import { getFutureTrails } from '../../services/grain.service';

import styles from './dashboard.module.scss';

export interface FutureTrialSummaryHandle {
  handleSearch: () => void;
}

export const FutureTrialSummary = forwardRef<
  FutureTrialSummaryHandle,
  { search: string; setIsLoading: (value: boolean) => void }
>(({ search, setIsLoading }, ref) => {
  const [futureTrailData, setFutureTrailData] = useState<
    GetFutureTrialsResponse[]
  >([]);
  const [paginationData, setPaginationData] = useState<PaginationType>();
  const sortField = useRef(FutureTrailRequest.FTR_FUTURE_TRAIL);
  const sortOrder = useRef(SortDirection.ASC);
  const [pageSize, setPageSize] = useState(10);
  const [activePage, setActivePage] = useState<number>(1);
  const [openMissingModal, setOpenMissingModal] = useState<boolean>(false);
  const [searchErrorMessage, setSearchErrorMessage] = useState('');
  const [selectedFutureTrailName, setSelectedFutureTrialName] =
    useState<string>('');
  const [selectedKeepCullComplete, setSelectedKeepCullComplete] =
    useState<number>();

  const clickPrevious = () => {
    if (paginationData?.hasPreviousPage) {
      setActivePage(activePage - 1);
    }
  };

  /* click next */
  const clickNext = () => {
    if (paginationData?.hasNextPage) {
      setActivePage(activePage + 1);
    }
  };

  const getAllFutureTrails = useCallback(async () => {
    setIsLoading(true);
    try {
      const query: Partial<GetFutureTrialsRequest> = {
        pageNumber: activePage,
        pageSize: pageSize,
        sortOrder: sortOrder.current ?? SortDirection.ASC,
        sortField: sortField.current ?? FutureTrailRequest.FTR_FUTURE_TRAIL,
        ...(search && {
          futureTrialName: search.toLowerCase().trim(),
        }),
      };
      const response = await getFutureTrails(query);
      setSearchErrorMessage('');
      setFutureTrailData(response.data.items);
      setPaginationData(response.data.meta);
      setIsLoading(false);
    } catch (e) {
      const errorResponse = e as ErrorResponse;
      setIsLoading(false);
      if (errorResponse.response.data.message === 'No items found') {
        setSearchErrorMessage('No matching results...');
        setFutureTrailData([]);
      }
    }
  }, [pageSize, activePage, search, sortOrder, sortField]);

  useEffect(() => {
    (async () => {
      await getAllFutureTrails();
    })();
  }, [pageSize, activePage]);

  const handleOnSort = async (sortDirection: SortDirection, title: string) => {
    const sortBy =
      title === FutureTrailSummaryCol.FUTURE_TRAIL
        ? FutureTrailRequest.FTR_FUTURE_TRAIL
        : title === FutureTrailSummaryCol.FT_MISSING
        ? FutureTrailRequest.FTR_KC_MISSING
        : FutureTrailRequest.FTR_KC_COMPLETE;

    sortField.current = sortBy;
    sortOrder.current = sortDirection;
    await getAllFutureTrails();
  };

  useImperativeHandle(ref, () => ({
    handleSearch: async () => {
      if (search === '' || activePage === 1) {
        await getAllFutureTrails();
      }
      setActivePage(1);
    },
  }));
  return (
    <div>
      <table className="table table-striped table-hover mt-5">
        <thead className="table-primary">
          <tr>
            <th scope="col">
              <TableHeading
                field={FutureTrailSummaryCol.FUTURE_TRAIL}
                sortable={true}
                onSort={(sortDirection, title) =>
                  handleOnSort(sortDirection, title)
                }
              />
            </th>
            <th scope="col">
              <TableHeading
                field={FutureTrailSummaryCol.FT_MISSING}
                sortable={true}
                onSort={(sortDirection, title) =>
                  handleOnSort(sortDirection, title)
                }
              />
            </th>
            <th scope="col">
              <TableHeading
                field={FutureTrailSummaryCol.FT_COMPLETE}
                sortable={true}
                onSort={(sortDirection, title) =>
                  handleOnSort(sortDirection, title)
                }
              />
            </th>
            <th scope="col">
              <TableHeading
                field={FutureTrailSummaryCol.FT_MISSING_ACTION}
                sortable={false}
                onSort={(sortDirection, title) =>
                  handleOnSort(sortDirection, title)
                }
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {futureTrailData?.map(
            (item: GetFutureTrialsResponse, index: number) => {
              return (
                <tr key={index}>
                  <td>
                    <p className={styles.rowText}>{item.futureTrialName}</p>
                  </td>
                  <td>
                    {item.keepCullMissing === 0 ? (
                      <p className={styles.rowText}>
                        <Lable
                          labelSuccess={true}
                          labelText={`${item.keepCullMissing}`}
                        />
                      </p>
                    ) : (
                      <p className={styles.rowTextWithoutLabel}>
                        {item.keepCullMissing}
                      </p>
                    )}
                  </td>
                  <td>
                    <p className={styles.rowText}>
                      <Lable
                        labelSuccess={
                          item.keepCullComplete * 100 === 100 ? true : false
                        }
                        labelText={`${
                          Math.floor(item.keepCullComplete * 100 * 10) / 10
                        }%`}
                      />
                    </p>
                  </td>
                  <td>
                    <div className={styles.rowText}>
                      {item.keepCullMissing !== 0 && (
                        <CustomButton
                          text={'View Missing'}
                          className={'fit-content'}
                          type={'button'}
                          onClick={() => {
                            setSelectedFutureTrialName(item.futureTrialName);
                            setSelectedKeepCullComplete(
                              item.keepCullComplete * 100
                            );
                            setOpenMissingModal(true);
                          }}
                        />
                      )}
                    </div>
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>

      {searchErrorMessage && (
        <div>
          <h4 className={'mt-5 text-danger text-center'}>
            {searchErrorMessage}
          </h4>
        </div>
      )}

      <div className={'mt-5'}>
        <div className={'d-flex justify-content-between'}>
          <div className={'d-flex align-items-center'}>
            <h6 className={`me-3 ${styles.rowTest}`}>Rows per page </h6>
            <select
              id="framework"
              className={` font-size-default ${styles.selectBox}`}
              value={pageSize}
              onChange={(e) => {
                setPageSize(e.target.value as unknown as number);
                setActivePage(1);
              }}
            >
              <option
                value="05"
                className={'font-size-default primary-color-text'}
              >
                05
              </option>
              <option
                value="10"
                selected={true}
                className={'font-size-default primary-color-text'}
              >
                10
              </option>
              <option
                value="15"
                className={'font-size-default primary-color-text'}
              >
                15
              </option>
              <option
                value="20"
                className={'font-size-default primary-color-text'}
              >
                20
              </option>
              <option
                value="100"
                className={'font-size-default primary-color-text'}
              >
                100
              </option>
              <option
                value="200"
                className={'font-size-default primary-color-text'}
              >
                200
              </option>
            </select>
          </div>
          <PaginationPages
            hasPreviousPage={paginationData?.hasPreviousPage}
            hasNextPage={paginationData?.hasNextPage}
            clickPrevious={clickPrevious}
            clickNext={clickNext}
            setActivePage={(value) => setActivePage(value as unknown as number)}
            currentPage={activePage}
            totalCount={paginationData?.itemCount ?? 10}
            pageSize={pageSize}
          />
        </div>
      </div>
      {openMissingModal && (
        <CustomModal
          open={openMissingModal}
          onCloseModal={() => setOpenMissingModal(false)}
        >
          <FutureMissingView
            keepCullCompleteStatus={
              Math.floor((selectedKeepCullComplete ?? 0) * 10) / 10
            }
            futureTrialName={selectedFutureTrailName}
            onCloseModal={(value) => {
              setOpenMissingModal(value);
            }}
          />
        </CustomModal>
      )}
    </div>
  );
});
FutureTrialSummary.displayName = 'FutureTrialSummary';
