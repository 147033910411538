import Auth, { AgtUser } from './auth.context';
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

type RootContextType = {
  children: ReactNode;
};

function RootContext({ children }: RootContextType) {
  const [user, setUser] = useState<AgtUser | undefined>();

  async function handleStorage<T>(
    path: string,
    u: T | undefined,
    noRemove = false
  ) {
    if (u) {
      try {
        await localStorage.setItem(path, JSON.stringify(u));
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    } else {
      if (!noRemove) await localStorage.removeItem(path);
    }
  }

  function createCustomSetStateFn<T>(
    path: string,
    origFn: Dispatch<SetStateAction<T | undefined>>,
    noRemove = false
  ): (u?: SetStateAction<T | undefined>) => void {
    return (u?: SetStateAction<T | undefined>) => {
      if (u instanceof Function) {
        origFn((cu) => {
          const r = u(cu);
          handleStorage<T>(path, r, noRemove);
          return r;
        });
      } else {
        origFn(u);
        handleStorage<T>(path, u, noRemove);
      }
    };
  }

  function loadLocalStorage<T>(
    path: string,
    setStateFn: Dispatch<SetStateAction<T>>
  ) {
    const jsonStr = localStorage.getItem(path);
    if (jsonStr) {
      const data = JSON.parse(jsonStr);
      setStateFn(data);
    }
  }

  useEffect(() => {
    loadLocalStorage('auth', setUser);
  }, []);

  return (
    <Auth.Provider
      value={{
        user,
        setUser: createCustomSetStateFn('auth', setUser),
      }}
    >
      {children}
    </Auth.Provider>
  );
}

export default RootContext;
