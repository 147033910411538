import * as React from 'react';
interface Props {
  size?: number;
  color?: string;
}
const CameraIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 32}
      height={size ?? 26}
      fill="none"
      viewBox={'0 0 32 26'}
    >
      <path
        fill={color ?? '#003359'}
        d="M20.179 15.229c0 2.31-1.87 4.179-4.18 4.179-2.309 0-4.178-1.87-4.178-4.18 0-2.309 1.87-4.178 4.179-4.178 2.31 0 4.179 1.87 4.179 4.179Zm8.553-10.474a3.02 3.02 0 0 1 3.02 3.021V22.41a3.222 3.222 0 0 1-3.221 3.221H3.469A3.222 3.222 0 0 1 .247 22.41V7.776a3.02 3.02 0 0 1 3.021-3.021h6.043l.284-1.203A4.118 4.118 0 0 1 13.606.369H18.4a4.118 4.118 0 0 1 4.011 3.183l.278 1.203h6.043ZM6.547 9.115a1.517 1.517 0 0 0-3.034 0c0 .835.68 1.514 1.514 1.514a1.509 1.509 0 0 0 1.52-1.514Zm16.904 6.114A7.455 7.455 0 0 0 16 7.776a7.455 7.455 0 0 0-7.453 7.453A7.455 7.455 0 0 0 16 22.68a7.454 7.454 0 0 0 7.453-7.452Z"
      />
    </svg>
  );
};
export default CameraIcon;
