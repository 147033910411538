import React from 'react';

interface Props {
  size?: number;
  color?: string;
}

const EmailPlusIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 17}
      height={size ?? 16}
      fill="none"
      viewBox={'0 0 17 16'}
    >
      <g clipPath="url(#a)">
        <path
          fill={color ?? '#003359'}
          d="M16.5 6.667v6A3.337 3.337 0 0 1 13.167 16H3.833A3.337 3.337 0 0 1 .5 12.667V5.333A3.337 3.337 0 0 1 3.833 2h5.334a.667.667 0 0 1 0 1.333H3.833A1.993 1.993 0 0 0 2.074 4.4l5.011 5.012a2.005 2.005 0 0 0 2.83 0l2.547-2.545a.667.667 0 0 1 .943.942l-2.548 2.548a3.339 3.339 0 0 1-4.714 0l-4.31-4.31v6.62a2 2 0 0 0 2 2h9.334a2 2 0 0 0 2-2v-6a.667.667 0 1 1 1.333 0Zm-4.667-3.334h1.334v1.334a.667.667 0 1 0 1.333 0V3.333h1.333a.667.667 0 1 0 0-1.333H14.5V.667a.666.666 0 1 0-1.333 0V2h-1.334a.667.667 0 0 0 0 1.333Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.5 0h16v16H.5z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmailPlusIcon;
