import {
  Answer,
  Sample,
  ScanContext,
  WeightTrait,
  WeightUnit,
} from '../../typeDef/grain.model';
import React, { useEffect, useState } from 'react';
import {
  getScannedKeepsCount,
  getScannedSamplesCount,
  getTrialSize,
  getTrialSizeOfKeep,
  scanBarcode,
  weighSample,
} from '../../services/grain.service';
import CameraModal from '../../components/cameraModal/cameraModal';
import CommonLayout from '../../components/layout/commonLayout';
import CrackedGrainIcon from '../../icons/crackedGrain.icon';
import CrackedGrainPortionModal from './crackedGrainPortionModal';
import CustomButton from '../../components/shared/customButton/customButton';
import CustomModal from '../../components/shared/customModal/customModal';
import LoadingSpinner from '../../components/shared/loadingSpinner/loadingSpinner';
import MessageModal from '../../components/modal/messageModal';
import PencilIcon from '../../icons/pencil.icon';
import SaveWeightInput from '../../components/saveWeightInput/saveWeightInput';
//import SaveWeightModal from '../../components/saveWeight/saveWeight';
//import ScanCode from '../../components/scanCode/scanCode';
import ScanCodeContainer from '../../components/scanCodeContainer/scanCodeContainer';
import ViewImagesModal from './viewImagesModal';
import WebCam from './webCam';
import WeightIcon from '../../icons/weight.icon';
import WeightNote from './weightNote';
import { WeightTraitInputTypes } from './dashboardWeightSection';
import loadingStyles from '../../components/shared/loadingSpinner/loadingSpinner.module.scss';
import { logger } from '../../logger';
import { playBeepSound } from '../../common/playSounds';
import styles from './dashboardWeightSection.module.scss';
import { useLocation } from 'react-router-dom';

export enum WeightingMood {
  INITIAL = 'Initial',
  YIELD = 'Yield',
  SCREENING_ABOVE = 'Screening Above',
  SCREENING_BELOW = 'Screening Below',
  HLW = 'HLW',
  HLW_SCREENING_ABOVE = 'HLW Screening Above',
  HLW_SCREENING_BELOW = 'HLW Screening Below',
  HLW_SCREENING_HLW = 'HLW Screening HLW',
  BARCODE = 'Barcode',
  IMAGING = 'Imaging',
  IMAGING_CAMERA = 'Imaging Camera',
  IMAGING_HLW = 'Imaging HLW',
  IMAGING_HLW_CAMERA = 'Imaging HLW Camera',
  IMAGING_HLW_WEIGH_HLW = 'Imaging HLW WEIGH HLW',
  IMAGING_WEIGHT = 'Imaging Weight',
  IMAGING_WEIGHT_CAMERA = 'Imaging Weight Camera',
  IMAGING_WEIGHT_SAMPLE_BAG = 'Imaging Weight Sample Bag',
  IMAGING_SCREENINGS_ABOVE = 'Imaging Screening Above',
  IMAGING_SCREENINGS_BELOW = 'Imaging Screening Below',
  IMAGING_SCREENINGS_SAMPLE = 'Imaging Screening Sample',
  IMAGING_SCREENINGS_CAMERA = 'Imaging Screening Camera',
  IMAGING_HLW_SCREENINGS_ABOVE = 'Imaging HLW Screening Above',
  IMAGING_HLW_SCREENINGS_BELOW = 'Imaging HLW Screening Below',
  IMAGING_HLW_SCREENINGS_SAMPLE = 'Imaging HLW Screening Sample',
  IMAGING_HLW_SCREENINGS_CAMERA = 'Imaging HLW Screening Camera',
  IMAGING_HLW_SCREENINGS_HLW = 'Imaging HLW Screening HLW',
  CRACKED_GRAIN_SCREENS = 'Cracked Grain Screens',
  CRACKED_GRAIN_HLW_SCREENS = 'Cracked Grain HLW Screens',
  CRACKED_GRAIN_SCREENS_IMAGING = 'Cracked Grain Screens Imaging',
  CRACKED_GRAIN_IMAGING_HLW_SCREENS = 'Cracked Grain Imaging HLW Screens',
}

export const DashboardWeightProcessingSection = () => {
  const location = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, seIsError] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [firstRound, setFirstRound] = useState(true);
  const [weightTraitInputs, setWeightTraitInputs] =
    useState<WeightTraitInputTypes>(location.state?.weightTraitInputs);
  const [selectedWeightTrait] = useState<WeightTrait>(
    location.state?.selectedWeightTrait
  );
  const [selectedWeightingMood, setSelectedWeightingMood] =
    useState<WeightingMood>(WeightingMood.INITIAL);
  const [selectedTrialFile, setCurrentSample] = useState<Sample>();
  const [weightUnits] = useState<WeightUnit>(location.state?.weightUnits);
  // const [isScanCodeModalVisible, setScanCodeModalVisible] = useState(false);
  // const [isSaveWeightModalVisible, setSaveWeightModalVisible] = useState(
  //   location.state.weightingCrackedGrains === Answer.YES ? false : true
  // );
  //const [isSaveWeightModalVisible, setSaveWeightModalVisible] = useState(false);
  const [isHlwWeightLimitErrorVisible, setHlwWeightLimitErrorVisible] =
    useState(false);
  const [isSampleWeightLimitErrorVisible, setSampleWeightLimitErrorVisible] =
    useState(false);

  const [saveWeightInput, setSaveWeightInput] = useState(false);
  const [saveWeightScreeningAboveInput, setSaveWeightScreeningAboveInput] =
    useState(false);
  const [saveWeightScreeningBelowInput, setSaveWeightScreeningBelowInput] =
    useState(false);
  const [saveWeightHLWInput, setSaveWeightHLWInput] = useState(false);
  const [saveWeightSampleBagInput, setSaveWeightSampleBagInput] =
    useState(false);

  const [isCameraModal, setIsCameraModal] = useState(false);
  const [enter, setEnter] = useState(false);
  const [weightNoteVisible, setWeightNoteVisible] = useState(false);
  const [note, setNote] = useState(false);
  const [noteSave, setNoteSave] = useState(false);
  const [viewImages, setViewImages] = useState(false);
  const [isCurrentCaptureNoteEditable, setCurrentCaptureNoteEditable] =
    useState(false);
  const [isCurrentCapture, setCurrentCapture] = useState(true);

  const [isCurrentBarcodeNoteEditable, setCurrentBarcodeNoteEditable] =
    useState(false);
  const [isCurrentBarcode, setCurrentBarcode] = useState(true);

  const [crackedProportion, setCrackedProportion] = useState<string>('');
  const [crackedNote, setCrackedNote] = useState<string>('');
  // const [crackedGrainPortionModal, setCrackedGrainPortionModal] = useState(
  //   location.state.weightingCrackedGrains === Answer.YES ? true : false
  // );
  const [crackedGrainPortionModal, setCrackedGrainPortionModal] =
    useState(false);
  const [saveInfo, setSaveInfo] = useState(false);

  const [weightFromScale, setWeight] = useState<number>(0);
  const [weightScreeningAbove, setWeightScreeningAbove] = useState<number>(0);
  const [weightScreeningBelow, setWeightScreeningBelow] = useState<number>(0);
  const [weightHLW, setWeightHLW] = useState<number>(0);
  const [weightSampleBag, setWeightSampleBag] = useState<number>(0);

  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [warningModal, setWarningModal] = useState(false);

  // Captured image is a base64 string
  const [capturedImage, setCapturedImage] = useState<string | undefined>(
    undefined
  );
  const [imageNote, setImageNote] = useState<string>('');
  const [weightNote, setWeightNote] = useState<string>('');

  const [allTrailFileRecordsCount, setAllTrailFileRecordsCount] =
    useState<number>();
  const [allTrailFileRecordsOfKeepCount, setAllTrailFileRecordsOfKeepCount] =
    useState<number>();
  const [allWeightScannedCount, setAllWeightScannedCount] = useState<number>();
  const [allScannedKeepsCount, setAllScannedKeepsCount] = useState<number>();

  const [disable, setDisable] = useState(false);

  // const titleMap: { [key in WeightingMood]?: string } = {
  //   [WeightingMood.YIELD]: WeightingMood.YIELD,
  //   [WeightingMood.SCREENING_ABOVE]: WeightingMood.SCREENING_ABOVE,
  //   [WeightingMood.HLW_SCREENING_ABOVE]: WeightingMood.SCREENING_ABOVE,
  //   [WeightingMood.IMAGING_SCREENINGS_ABOVE]: WeightingMood.SCREENING_ABOVE,
  //   [WeightingMood.IMAGING_HLW_SCREENINGS_ABOVE]: WeightingMood.SCREENING_ABOVE,
  //   [WeightingMood.SCREENING_BELOW]: WeightingMood.SCREENING_BELOW,
  //   [WeightingMood.HLW_SCREENING_BELOW]: WeightingMood.SCREENING_BELOW,
  //   [WeightingMood.IMAGING_SCREENINGS_BELOW]: WeightingMood.SCREENING_BELOW,
  //   [WeightingMood.IMAGING_HLW_SCREENINGS_BELOW]: WeightingMood.SCREENING_BELOW,
  //   [WeightingMood.HLW]: WeightingMood.HLW,
  //   [WeightingMood.HLW_SCREENING_HLW]: WeightingMood.HLW,
  //   [WeightingMood.IMAGING_HLW_WEIGH_HLW]: WeightingMood.HLW,
  //   [WeightingMood.IMAGING_HLW_SCREENINGS_HLW]: WeightingMood.HLW,
  //   [WeightingMood.IMAGING]: 'Sample',
  //   [WeightingMood.IMAGING_HLW]: 'Sample',
  //   [WeightingMood.IMAGING_WEIGHT]: 'Sample',
  //   [WeightingMood.IMAGING_SCREENINGS_SAMPLE]: 'Sample',
  //   [WeightingMood.IMAGING_HLW_SCREENINGS_SAMPLE]: 'Sample',
  //   [WeightingMood.IMAGING_WEIGHT_SAMPLE_BAG]: 'Sample Bag',
  // };

  const goToFirstRound = async (barcode: string) => {
    setLoading(true);
    try {
      // Get the sample details from the barcode
      const sample = await scanBarcode(barcode, ScanContext.WEIGHT);

      // If no sample found, show error message
      if (!sample) {
        setLoading(false);
        setIsSuccess(false);
        seIsError(true);
        playBeepSound();
        setMessageText(
          navigator.onLine
            ? `No data was found`
            : `
              No data was found.
              The device is offline, so the barcode may not have been synced yet.
            `
        );
        setOpenModal(true);
        return;
      }

      // Get the trial information
      const trialSize = await getTrialSize(sample.trialName);
      const trialSizeOfKeep = await getTrialSizeOfKeep(sample.trialName);

      const scannedSamplesCount = await getScannedSamplesCount(
        sample.trialName,
        ScanContext.WEIGHT
      );
      const scannedKeepsCount = await getScannedKeepsCount(
        sample.trialName,
        ScanContext.WEIGHT
      );

      setWeightTraitInputs({ ...weightTraitInputs, barcode: barcode });
      setCurrentSample(sample);
      if (trialSize) {
        setAllTrailFileRecordsCount(trialSize);
      }
      if (trialSizeOfKeep) {
        setAllTrailFileRecordsOfKeepCount(trialSizeOfKeep);
      }
      if (scannedSamplesCount) {
        setAllWeightScannedCount(scannedSamplesCount);
      }
      if (scannedKeepsCount) {
        setAllScannedKeepsCount(scannedKeepsCount);
      }
      setLoading(false);
      setDisable(true);

      if (selectedWeightTrait === WeightTrait.YIELD) {
        setSaveWeightInput(true);
      } else if (selectedWeightTrait === WeightTrait.SCREENS) {
        if (location.state.weightingCrackedGrains === Answer.YES) {
          setCrackedGrainPortionModal(true);
        } else {
          setSaveWeightScreeningAboveInput(true);
        }
      } else if (selectedWeightTrait === WeightTrait.HLW) {
        setSaveWeightHLWInput(true);
      } else if (selectedWeightTrait === WeightTrait.HLW_SCREENS) {
        if (location.state.weightingCrackedGrains === Answer.YES) {
          setCrackedGrainPortionModal(true);
        } else {
          setSaveWeightScreeningAboveInput(true);
        }
      } else if (selectedWeightTrait === WeightTrait.IMAGING) {
        setSaveWeightInput(true);
      } else if (selectedWeightTrait === WeightTrait.IMAGING_HLW) {
        setSaveWeightInput(true);
      } else if (selectedWeightTrait === WeightTrait.IMAGING_WEIGHT) {
        setSaveWeightInput(true);
      } else if (selectedWeightTrait === WeightTrait.SCREENINGS_IMAGINGS) {
        if (location.state.weightingCrackedGrains === Answer.YES) {
          setCrackedGrainPortionModal(true);
        } else {
          setSaveWeightScreeningAboveInput(true);
        }
      } else if (selectedWeightTrait === WeightTrait.IMAGING_HLW_SCREENINGS) {
        if (location.state.weightingCrackedGrains === Answer.YES) {
          setCrackedGrainPortionModal(true);
        } else {
          setSaveWeightScreeningAboveInput(true);
        }
      }

      setFirstRound(false);
      // navigate('/weight/processing', {
      //   state: {
      //     weightTraitInputs: weightTraitInputs,
      //     selectedWeightTrait: selectedWeightTrait,
      //     weightUnits: weightUnits,
      //     trailFileDetails: sample,
      //     workStation: workstation,
      //     weightingCrackedGrains: weightingCrackedGrains,
      //     allTrailFileRecordsCount: trialSize,
      //     allTrailFileRecordsOfKeepCount: trialSizeOfKeep,
      //     allWeightScannedCount: scannedSamplesCount,
      //     allScannedKeepsCount: scannedKeepsCount,
      //   },
      // });
    } catch (e) {
      // If any error occurs, show error message
      logger.log(e);
      playBeepSound();
      setMessageText('An unknown error occured');
      setLoading(false);
      setIsSuccess(false);
      seIsError(true);
      setOpenModal(true);
    }
  };

  useEffect(() => {
    if (selectedWeightTrait === WeightTrait.YIELD) {
      setSelectedWeightingMood(WeightingMood.YIELD);
    } else if (selectedWeightTrait === WeightTrait.SCREENS) {
      if (location.state.weightingCrackedGrains === Answer.YES) {
        setSelectedWeightingMood(WeightingMood.CRACKED_GRAIN_SCREENS);
      } else {
        setSelectedWeightingMood(WeightingMood.SCREENING_ABOVE);
      }
    } else if (selectedWeightTrait === WeightTrait.HLW) {
      setSelectedWeightingMood(WeightingMood.HLW);
    } else if (selectedWeightTrait === WeightTrait.HLW_SCREENS) {
      if (location.state.weightingCrackedGrains === Answer.YES) {
        setSelectedWeightingMood(WeightingMood.CRACKED_GRAIN_HLW_SCREENS);
      } else {
        setSelectedWeightingMood(WeightingMood.HLW_SCREENING_ABOVE);
      }
    } else if (selectedWeightTrait === WeightTrait.IMAGING) {
      setSelectedWeightingMood(WeightingMood.IMAGING);
    } else if (selectedWeightTrait === WeightTrait.IMAGING_HLW) {
      setSelectedWeightingMood(WeightingMood.IMAGING_HLW);
    } else if (selectedWeightTrait === WeightTrait.IMAGING_WEIGHT) {
      setSelectedWeightingMood(WeightingMood.IMAGING_WEIGHT);
    } else if (selectedWeightTrait === WeightTrait.SCREENINGS_IMAGINGS) {
      if (location.state.weightingCrackedGrains === Answer.YES) {
        setSelectedWeightingMood(WeightingMood.CRACKED_GRAIN_SCREENS_IMAGING);
      } else {
        setSelectedWeightingMood(WeightingMood.IMAGING_SCREENINGS_ABOVE);
      }
    } else if (selectedWeightTrait === WeightTrait.IMAGING_HLW_SCREENINGS) {
      if (location.state.weightingCrackedGrains === Answer.YES) {
        setSelectedWeightingMood(
          WeightingMood.CRACKED_GRAIN_IMAGING_HLW_SCREENS
        );
      } else {
        setSelectedWeightingMood(WeightingMood.IMAGING_HLW_SCREENINGS_ABOVE);
      }
    }
  }, [selectedWeightTrait]);

  useEffect(() => {
    if (enter) {
      setIsCameraModal(false);
    }
  }, [enter]);

  const saveWeights = async (): Promise<void> => {
    setDataLoading(true);
    try {
      await weighSample({
        trait: selectedWeightTrait,
        unit: weightUnits,
        plotArea: weightTraitInputs?.plotArea,
        hectolitrePlotArea: weightTraitInputs?.hectolitrePlotArea,
        sampleId: selectedTrialFile ? selectedTrialFile.id : '',
        sampleWeight: weightFromScale,
        aboveWeight: weightScreeningAbove,
        belowWeight: weightScreeningBelow,
        hectolitreWeight: weightHLW,
        bagWeight: weightSampleBag,
        crackedProportion: Number(crackedProportion) / 100,
        crackedNote: crackedNote,
        image: capturedImage,
        imageNote: imageNote,
        weightNote: weightNote,
      });
      setDataLoading(false);
    } catch (e) {
      logger.error(e);
      setIsSuccess(false);
      seIsError(true);
      setMessageText('Something went wrong');
      setOpenModal(true);
      setDataLoading(false);
    }
  };

  // useEffect(() => {
  //   if (capturedImage) {
  //     if (
  //       selectedWeightTrait === WeightTrait.IMAGING ||
  //       selectedWeightTrait === WeightTrait.SCREENINGS_IMAGINGS
  //     )
  //       (async () => {
  //         await saveWeights();
  //       })();
  //   }
  // }, [capturedImage]);

  const goToNextRound = async (barcode: string): Promise<boolean> => {
    setLoading(true);

    // First, save the weights from the previous round.

    // if (
    //   selectedWeightTrait === WeightTrait.YIELD ||
    //   selectedWeightTrait === WeightTrait.SCREENS ||
    //   selectedWeightTrait === WeightTrait.HLW ||
    //   selectedWeightTrait === WeightTrait.HLW_SCREENS
    // ) {
    //   await saveWeights();
    // }
    await saveWeights();

    try {
      // Get the sample details from the barcode
      const sample = await scanBarcode(barcode, ScanContext.WEIGHT);

      // If no sample found, show error message
      if (!sample) {
        setLoading(false);
        setIsSuccess(false);
        seIsError(true);
        playBeepSound();
        setMessageText(
          navigator.onLine
            ? `No data was founding matching the barcode ${barcode}`
            : `No local data was found matching the barcode ${barcode} and no connection to the server could be made to search the database`
        );
        setOpenModal(true);
        return false;
      }

      // Get the trial size, scanned samples count and scanned keeps count
      const trialSize = await getTrialSize(sample.trialName);
      const trialSizeOfKeep = await getTrialSizeOfKeep(sample.trialName);

      const scannedSamplesCount = await getScannedSamplesCount(
        sample.trialName,
        ScanContext.WEIGHT
      );
      const scannedKeepsCount = await getScannedKeepsCount(
        sample.trialName,
        ScanContext.WEIGHT
      );

      setAllTrailFileRecordsCount(trialSize ?? NaN);
      setAllTrailFileRecordsOfKeepCount(trialSizeOfKeep ?? NaN);
      setAllWeightScannedCount(scannedSamplesCount ?? NaN);
      setAllScannedKeepsCount(scannedKeepsCount ?? NaN);

      setCurrentSample(sample);
      setWeightTraitInputs({ ...weightTraitInputs, barcode: barcode });
      setLoading(false);
      //setScanCodeModalVisible(false);
      setDisable(true);

      if (selectedWeightTrait === WeightTrait.YIELD) {
        setSaveWeightInput(true);
      } else if (selectedWeightTrait === WeightTrait.SCREENS) {
        if (location.state.weightingCrackedGrains === Answer.YES) {
          setCrackedGrainPortionModal(true);
        } else {
          setSaveWeightScreeningAboveInput(true);
        }
      } else if (selectedWeightTrait === WeightTrait.HLW) {
        setSaveWeightHLWInput(true);
      } else if (selectedWeightTrait === WeightTrait.HLW_SCREENS) {
        if (location.state.weightingCrackedGrains === Answer.YES) {
          setCrackedGrainPortionModal(true);
        } else {
          setSaveWeightScreeningAboveInput(true);
        }
      } else if (selectedWeightTrait === WeightTrait.IMAGING) {
        setSaveWeightInput(true);
      } else if (selectedWeightTrait === WeightTrait.IMAGING_HLW) {
        setSaveWeightInput(true);
      } else if (selectedWeightTrait === WeightTrait.IMAGING_WEIGHT) {
        setSaveWeightInput(true);
      } else if (selectedWeightTrait === WeightTrait.SCREENINGS_IMAGINGS) {
        if (location.state.weightingCrackedGrains === Answer.YES) {
          setCrackedGrainPortionModal(true);
        } else {
          setSaveWeightScreeningAboveInput(true);
        }
      } else if (selectedWeightTrait === WeightTrait.IMAGING_HLW_SCREENINGS) {
        if (location.state.weightingCrackedGrains === Answer.YES) {
          setCrackedGrainPortionModal(true);
        } else {
          setSaveWeightScreeningAboveInput(true);
        }
      }

      return true;
    } catch (e) {
      // If error, show error message
      logger.log(e);
      playBeepSound();
      setMessageText('An unknown error occured');
      setLoading(false);
      setIsSuccess(false);
      seIsError(true);
      setOpenModal(true);
      return false;
    }
  };

  const goToNextMoodAfterSavingWeight = () => {
    //setSaveWeightModalVisible(false);

    if (selectedWeightingMood === WeightingMood.YIELD) {
      setSelectedWeightingMood(WeightingMood.BARCODE);
      //setScanCodeModalVisible(true);
      setWeightNoteVisible(true);
      setCurrentBarcode(true);
      setDisable(false);
    } else if (selectedWeightingMood === WeightingMood.SCREENING_ABOVE) {
      setSelectedWeightingMood(WeightingMood.SCREENING_BELOW);
      //setSaveWeightModalVisible(true);
      setSaveWeightScreeningAboveInput(false);
      setSaveWeightScreeningBelowInput(true);
    } else if (selectedWeightingMood === WeightingMood.SCREENING_BELOW) {
      setSelectedWeightingMood(WeightingMood.BARCODE);
      //setScanCodeModalVisible(true);
      setWeightNoteVisible(true);
      setCurrentBarcode(true);
      setDisable(false);
      setSaveWeightScreeningBelowInput(false); // cracked
    } else if (selectedWeightingMood === WeightingMood.HLW) {
      setSelectedWeightingMood(WeightingMood.BARCODE);
      //setScanCodeModalVisible(true);
      setWeightNoteVisible(true);
      setCurrentBarcode(true);
      setDisable(false);
    } else if (selectedWeightingMood === WeightingMood.HLW_SCREENING_ABOVE) {
      setSelectedWeightingMood(WeightingMood.HLW_SCREENING_BELOW);
      //setSaveWeightModalVisible(true);
      setSaveWeightScreeningAboveInput(false);
      setSaveWeightScreeningBelowInput(true);
    } else if (selectedWeightingMood === WeightingMood.HLW_SCREENING_BELOW) {
      setSelectedWeightingMood(WeightingMood.HLW_SCREENING_HLW);
      //setSaveWeightModalVisible(true);
      setSaveWeightScreeningBelowInput(false);
      setSaveWeightHLWInput(true);
    } else if (selectedWeightingMood === WeightingMood.HLW_SCREENING_HLW) {
      setSelectedWeightingMood(WeightingMood.BARCODE);
      //setScanCodeModalVisible(true);
      setWeightNoteVisible(true);
      setCurrentBarcode(true);
      setDisable(false);
      setSaveWeightHLWInput(false); // cracked
    } else if (selectedWeightingMood === WeightingMood.IMAGING) {
      setSelectedWeightingMood(WeightingMood.IMAGING_CAMERA);
      setEnter(false);
      setIsCameraModal(true);
    } else if (selectedWeightingMood === WeightingMood.IMAGING_CAMERA) {
      setSelectedWeightingMood(WeightingMood.BARCODE);
      //setScanCodeModalVisible(true);
      setDisable(false);
    } else if (selectedWeightingMood === WeightingMood.IMAGING_HLW) {
      setSelectedWeightingMood(WeightingMood.IMAGING_HLW_CAMERA);
      setEnter(false);
      setIsCameraModal(true);
    } else if (selectedWeightingMood === WeightingMood.IMAGING_HLW_CAMERA) {
      setSelectedWeightingMood(WeightingMood.IMAGING_HLW_WEIGH_HLW);
      //setSaveWeightModalVisible(true);
      setSaveWeightInput(false);
      setSaveWeightHLWInput(true);
    } else if (selectedWeightingMood === WeightingMood.IMAGING_HLW_WEIGH_HLW) {
      setSelectedWeightingMood(WeightingMood.BARCODE);
      //setScanCodeModalVisible(true);
      setDisable(false);
    } else if (selectedWeightingMood === WeightingMood.IMAGING_WEIGHT) {
      setSelectedWeightingMood(WeightingMood.IMAGING_WEIGHT_CAMERA);
      setEnter(false);
      setIsCameraModal(true);
    } else if (selectedWeightingMood === WeightingMood.IMAGING_WEIGHT_CAMERA) {
      setSelectedWeightingMood(WeightingMood.IMAGING_WEIGHT_SAMPLE_BAG);
      //setSaveWeightModalVisible(true);
      setSaveWeightInput(false);
      setSaveWeightSampleBagInput(true);
    } else if (
      selectedWeightingMood === WeightingMood.IMAGING_WEIGHT_SAMPLE_BAG
    ) {
      setSelectedWeightingMood(WeightingMood.BARCODE);
      //setScanCodeModalVisible(true);
      setDisable(false);
    } else if (
      selectedWeightingMood === WeightingMood.IMAGING_SCREENINGS_ABOVE
    ) {
      setSelectedWeightingMood(WeightingMood.IMAGING_SCREENINGS_BELOW);
      //setSaveWeightModalVisible(true);
      setSaveWeightScreeningAboveInput(false);
      setSaveWeightScreeningBelowInput(true);
    } else if (
      selectedWeightingMood === WeightingMood.IMAGING_SCREENINGS_BELOW
    ) {
      setSelectedWeightingMood(WeightingMood.IMAGING_SCREENINGS_SAMPLE);
      //setSaveWeightModalVisible(true);
      setSaveWeightScreeningBelowInput(false);
      setSaveWeightInput(true);
    } else if (
      selectedWeightingMood === WeightingMood.IMAGING_SCREENINGS_SAMPLE
    ) {
      setSelectedWeightingMood(WeightingMood.IMAGING_SCREENINGS_CAMERA);
      setEnter(false);
      setIsCameraModal(true);
      setSaveWeightInput(false);
    } else if (
      selectedWeightingMood === WeightingMood.IMAGING_SCREENINGS_CAMERA
    ) {
      setSelectedWeightingMood(WeightingMood.BARCODE);
      //setScanCodeModalVisible(true);
      setDisable(false);
    } else if (
      selectedWeightingMood === WeightingMood.IMAGING_HLW_SCREENINGS_ABOVE
    ) {
      setSelectedWeightingMood(WeightingMood.IMAGING_HLW_SCREENINGS_BELOW);
      //setSaveWeightModalVisible(true);
      setSaveWeightScreeningAboveInput(false);
      setSaveWeightScreeningBelowInput(true);
    } else if (
      selectedWeightingMood === WeightingMood.IMAGING_HLW_SCREENINGS_BELOW
    ) {
      setSelectedWeightingMood(WeightingMood.IMAGING_HLW_SCREENINGS_SAMPLE);
      //setSaveWeightModalVisible(true);
      setSaveWeightScreeningBelowInput(false);
      setSaveWeightInput(true);
    } else if (
      selectedWeightingMood === WeightingMood.IMAGING_HLW_SCREENINGS_SAMPLE
    ) {
      setSelectedWeightingMood(WeightingMood.IMAGING_HLW_SCREENINGS_CAMERA);
      setEnter(false);
      setIsCameraModal(true);
      setSaveWeightInput(false);
    } else if (
      selectedWeightingMood === WeightingMood.IMAGING_HLW_SCREENINGS_CAMERA
    ) {
      setSelectedWeightingMood(WeightingMood.IMAGING_HLW_SCREENINGS_HLW);
      //setSaveWeightModalVisible(true);
      setSaveWeightInput(false);
      setSaveWeightHLWInput(true);
    } else if (
      selectedWeightingMood === WeightingMood.IMAGING_HLW_SCREENINGS_HLW
    ) {
      setSelectedWeightingMood(WeightingMood.BARCODE);
      //setScanCodeModalVisible(true);
      setDisable(false);
      setSaveWeightHLWInput(false); //cracked
    } else if (selectedWeightingMood === WeightingMood.CRACKED_GRAIN_SCREENS) {
      setSelectedWeightingMood(WeightingMood.SCREENING_ABOVE);
      //setSaveWeightModalVisible(true);
      setSaveWeightScreeningAboveInput(true);
    } else if (
      selectedWeightingMood === WeightingMood.CRACKED_GRAIN_HLW_SCREENS
    ) {
      setSelectedWeightingMood(WeightingMood.HLW_SCREENING_ABOVE);
      //setSaveWeightModalVisible(true);
      setSaveWeightScreeningAboveInput(true);
    } else if (
      selectedWeightingMood === WeightingMood.CRACKED_GRAIN_SCREENS_IMAGING
    ) {
      setSelectedWeightingMood(WeightingMood.IMAGING_SCREENINGS_ABOVE);
      //setSaveWeightModalVisible(true);
      setSaveWeightScreeningAboveInput(true);
    } else if (
      selectedWeightingMood === WeightingMood.CRACKED_GRAIN_IMAGING_HLW_SCREENS
    ) {
      setSelectedWeightingMood(WeightingMood.IMAGING_HLW_SCREENINGS_ABOVE);
      //setSaveWeightModalVisible(true);
      setSaveWeightScreeningAboveInput(true);
    } else {
      setSelectedWeightingMood(WeightingMood.BARCODE);
      //setScanCodeModalVisible(true);
      setDisable(false);
    }
  };

  const saveWeightOnWeightReceived = async (weight?: number) => {
    if (
      selectedWeightingMood === WeightingMood.YIELD ||
      selectedWeightingMood === WeightingMood.IMAGING ||
      selectedWeightingMood === WeightingMood.IMAGING_HLW ||
      selectedWeightingMood === WeightingMood.IMAGING_WEIGHT ||
      selectedWeightingMood === WeightingMood.IMAGING_SCREENINGS_SAMPLE ||
      selectedWeightingMood === WeightingMood.IMAGING_HLW_SCREENINGS_SAMPLE
    ) {
      setWeight(weight ?? 0);
    } else if (
      selectedWeightingMood === WeightingMood.SCREENING_ABOVE ||
      selectedWeightingMood === WeightingMood.HLW_SCREENING_ABOVE ||
      selectedWeightingMood === WeightingMood.IMAGING_SCREENINGS_ABOVE ||
      selectedWeightingMood === WeightingMood.IMAGING_HLW_SCREENINGS_ABOVE
    ) {
      setWeightScreeningAbove(weight ?? 0);
    } else if (
      selectedWeightingMood === WeightingMood.SCREENING_BELOW ||
      selectedWeightingMood === WeightingMood.HLW_SCREENING_BELOW ||
      selectedWeightingMood === WeightingMood.IMAGING_SCREENINGS_BELOW ||
      selectedWeightingMood === WeightingMood.IMAGING_HLW_SCREENINGS_BELOW
    ) {
      setWeightScreeningBelow(weight ?? 0);
    } else if (
      selectedWeightingMood === WeightingMood.HLW ||
      selectedWeightingMood === WeightingMood.HLW_SCREENING_HLW ||
      selectedWeightingMood === WeightingMood.IMAGING_HLW_WEIGH_HLW ||
      selectedWeightingMood === WeightingMood.IMAGING_HLW_SCREENINGS_HLW
    ) {
      setWeightHLW(weight ?? 0);
    } else if (
      selectedWeightingMood === WeightingMood.IMAGING_WEIGHT_SAMPLE_BAG
    ) {
      setWeightSampleBag(weight ?? 0);
    }
  };

  const sendWeightsAfterSaved = async (skipErrorPopups?: boolean) => {
    if (
      !skipErrorPopups &&
      (selectedWeightingMood === WeightingMood.HLW ||
        selectedWeightingMood === WeightingMood.HLW_SCREENING_HLW ||
        selectedWeightingMood === WeightingMood.IMAGING_HLW_WEIGH_HLW ||
        selectedWeightingMood === WeightingMood.IMAGING_HLW_SCREENINGS_HLW) &&
      (weightHLW > Number(weightTraitInputs?.hlwUpperLimit || 0) ||
        weightHLW < Number(weightTraitInputs?.hlwLowerLimit || 0))
    ) {
      playBeepSound();
      setHlwWeightLimitErrorVisible(true);
      return;
    }

    if (
      !skipErrorPopups &&
      (selectedWeightingMood === WeightingMood.IMAGING ||
        selectedWeightingMood === WeightingMood.IMAGING_HLW ||
        selectedWeightingMood === WeightingMood.IMAGING_WEIGHT ||
        selectedWeightingMood === WeightingMood.IMAGING_SCREENINGS_SAMPLE ||
        selectedWeightingMood ===
          WeightingMood.IMAGING_HLW_SCREENINGS_SAMPLE) &&
      (weightFromScale > Number(weightTraitInputs?.sampleUpperLimit || 0) ||
        weightFromScale < Number(weightTraitInputs?.sampleLowerLimit || 0))
    ) {
      playBeepSound();
      setSampleWeightLimitErrorVisible(true);
      return;
    }

    //setSaveWeightModalVisible(false);
    if (selectedWeightTrait === WeightTrait.YIELD) {
      setSaveWeightInput(false);
    } else if (selectedWeightTrait === WeightTrait.SCREENS) {
      if (location.state.weightingCrackedGrains === Answer.YES) {
        // add
      } else {
        setSaveWeightScreeningBelowInput(false);
      }
    } else if (selectedWeightTrait === WeightTrait.HLW) {
      setSaveWeightHLWInput(false);
    } else if (selectedWeightTrait === WeightTrait.HLW_SCREENS) {
      if (location.state.weightingCrackedGrains === Answer.YES) {
        // add
      } else {
        setSaveWeightHLWInput(false);
      }
    } else if (selectedWeightTrait === WeightTrait.IMAGING) {
      setSaveWeightInput(false);
    } else if (selectedWeightTrait === WeightTrait.IMAGING_HLW) {
      setSaveWeightHLWInput(false);
    } else if (selectedWeightTrait === WeightTrait.IMAGING_WEIGHT) {
      setSaveWeightSampleBagInput(false);
    } else if (selectedWeightTrait === WeightTrait.SCREENINGS_IMAGINGS) {
      if (location.state.weightingCrackedGrains === Answer.YES) {
        // add
      } else {
        setSaveWeightHLWInput(false);
      }
    } else if (selectedWeightTrait === WeightTrait.IMAGING_HLW_SCREENINGS) {
      if (location.state.weightingCrackedGrains === Answer.YES) {
        // add
      } else {
        setSaveWeightHLWInput(false);
      }
    }

    goToNextMoodAfterSavingWeight();
  };

  useEffect(() => {
    if (
      ((selectedWeightingMood === WeightingMood.YIELD ||
        selectedWeightingMood === WeightingMood.IMAGING ||
        selectedWeightingMood === WeightingMood.IMAGING_HLW ||
        selectedWeightingMood === WeightingMood.IMAGING_WEIGHT ||
        selectedWeightingMood === WeightingMood.IMAGING_SCREENINGS_SAMPLE ||
        selectedWeightingMood ===
          WeightingMood.IMAGING_HLW_SCREENINGS_SAMPLE) &&
        weightFromScale !== 0) ||
      ((selectedWeightingMood === WeightingMood.SCREENING_ABOVE ||
        selectedWeightingMood === WeightingMood.HLW_SCREENING_ABOVE ||
        selectedWeightingMood === WeightingMood.IMAGING_SCREENINGS_ABOVE ||
        selectedWeightingMood === WeightingMood.IMAGING_HLW_SCREENINGS_ABOVE) &&
        weightScreeningAbove !== 0) ||
      ((selectedWeightingMood === WeightingMood.SCREENING_BELOW ||
        selectedWeightingMood === WeightingMood.HLW_SCREENING_BELOW ||
        selectedWeightingMood === WeightingMood.IMAGING_SCREENINGS_BELOW ||
        selectedWeightingMood === WeightingMood.IMAGING_HLW_SCREENINGS_BELOW) &&
        weightScreeningBelow !== 0) ||
      ((selectedWeightingMood === WeightingMood.HLW ||
        selectedWeightingMood === WeightingMood.HLW_SCREENING_HLW ||
        selectedWeightingMood === WeightingMood.IMAGING_HLW_WEIGH_HLW ||
        selectedWeightingMood === WeightingMood.IMAGING_HLW_SCREENINGS_HLW) &&
        weightHLW !== 0) ||
      (selectedWeightingMood === WeightingMood.IMAGING_WEIGHT_SAMPLE_BAG &&
        weightSampleBag !== 0)
    ) {
      sendWeightsAfterSaved();
    }
  }, [
    weightFromScale,
    weightScreeningAbove,
    weightScreeningBelow,
    weightHLW,
    weightSampleBag,
  ]);

  useEffect(() => {
    if (crackedGrainPortionModal) {
      setSaveInfo(false);
      setCrackedProportion('');
      setCrackedNote('');
    }
  }, [crackedGrainPortionModal]);

  useEffect(() => {
    if (isCurrentCapture) {
      setImageNote('');
      setNote(false);
      setNoteSave(false);
    }
  }, [isCurrentCapture]);

  useEffect(() => {
    if (isCurrentBarcode) {
      setWeightNote('');
      setNote(false);
      setNoteSave(false);
    }
  }, [isCurrentBarcode]);

  return (
    <div className={`${dataLoading && loadingStyles.app_while_loading}`}>
      {dataLoading && <LoadingSpinner />}
      <CommonLayout admin title={'Weight'} selectedPage={'weight'}>
        <div className="container">
          <div className="row">
            <div className={'col-lg-8'}>
              <div>
                <ScanCodeContainer
                  source={'/weight'}
                  onOk={async (barcode: string) => {
                    if (firstRound) {
                      await goToFirstRound(barcode);
                    } else {
                      const isGoingNextRound = await goToNextRound(barcode);
                      if (!isGoingNextRound) return;

                      if (selectedWeightTrait === WeightTrait.YIELD) {
                        setWeight(0);
                        setSelectedWeightingMood(WeightingMood.YIELD);
                      } else if (selectedWeightTrait === WeightTrait.SCREENS) {
                        setWeightScreeningAbove(0);
                        setWeightScreeningBelow(0);
                        if (
                          location.state.weightingCrackedGrains === Answer.YES
                        ) {
                          setSelectedWeightingMood(
                            WeightingMood.CRACKED_GRAIN_SCREENS
                          );
                          setSaveInfo(false);
                          setCrackedGrainPortionModal(true);
                        } else {
                          setSelectedWeightingMood(
                            WeightingMood.SCREENING_ABOVE
                          );
                        }
                      } else if (selectedWeightTrait === WeightTrait.HLW) {
                        setWeightHLW(0);
                        setSelectedWeightingMood(WeightingMood.HLW);
                      } else if (
                        selectedWeightTrait === WeightTrait.HLW_SCREENS
                      ) {
                        setWeightHLW(0);
                        setWeightScreeningAbove(0);
                        setWeightScreeningBelow(0);
                        if (
                          location.state.weightingCrackedGrains === Answer.YES
                        ) {
                          setSelectedWeightingMood(
                            WeightingMood.CRACKED_GRAIN_HLW_SCREENS
                          );
                          setCrackedGrainPortionModal(true);
                          setSaveInfo(false);
                        } else {
                          setSelectedWeightingMood(
                            WeightingMood.HLW_SCREENING_ABOVE
                          );
                        }
                      } else if (selectedWeightTrait === WeightTrait.IMAGING) {
                        setWeight(0);
                        setSelectedWeightingMood(WeightingMood.IMAGING);
                      } else if (
                        selectedWeightTrait === WeightTrait.IMAGING_HLW
                      ) {
                        setWeight(0);
                        setWeightHLW(0);
                        setSelectedWeightingMood(WeightingMood.IMAGING_HLW);
                      } else if (
                        selectedWeightTrait === WeightTrait.IMAGING_WEIGHT
                      ) {
                        setWeight(0);
                        setWeightSampleBag(0);
                        setSelectedWeightingMood(WeightingMood.IMAGING_WEIGHT);
                      } else if (
                        selectedWeightTrait === WeightTrait.SCREENINGS_IMAGINGS
                      ) {
                        setWeight(0);
                        setWeightScreeningAbove(0);
                        setWeightScreeningBelow(0);
                        if (
                          location.state.weightingCrackedGrains === Answer.YES
                        ) {
                          setSelectedWeightingMood(
                            WeightingMood.CRACKED_GRAIN_SCREENS_IMAGING
                          );
                          setCrackedGrainPortionModal(true);
                          setSaveInfo(false);
                        } else {
                          setSelectedWeightingMood(
                            WeightingMood.IMAGING_SCREENINGS_ABOVE
                          );
                        }
                      } else if (
                        selectedWeightTrait ===
                        WeightTrait.IMAGING_HLW_SCREENINGS
                      ) {
                        setWeight(0);
                        setWeightHLW(0);
                        setWeightScreeningAbove(0);
                        setWeightScreeningBelow(0);
                        if (
                          location.state.weightingCrackedGrains === Answer.YES
                        ) {
                          setSelectedWeightingMood(
                            WeightingMood.CRACKED_GRAIN_IMAGING_HLW_SCREENS
                          );
                          setCrackedGrainPortionModal(true);
                          setSaveInfo(false);
                        } else {
                          setSelectedWeightingMood(
                            WeightingMood.IMAGING_HLW_SCREENINGS_ABOVE
                          );
                        }
                      }

                      setCurrentCapture(false);
                      setCurrentBarcode(false);
                    }
                  }}
                  onEndSession={saveWeights}
                  onSetNote={(value) => {
                    setNote(value);
                    setCurrentCaptureNoteEditable(true);
                    setCurrentBarcodeNoteEditable(true);
                  }}
                  onSetImages={async (value) => {
                    await saveWeights();
                    setViewImages(value);
                  }}
                  noteButtonVisible={enter}
                  weightNoteButtonVisible={weightNoteVisible}
                  disable={disable}
                  dataLoading={dataLoading}
                  isLoading={loading}
                  setWarningModal={(value) => {
                    setWarningModal(value);
                  }}
                />
              </div>
              <div className={'row mt-4'}>
                <div className="col-lg-6">
                  <div className={`card ${styles.card}`}>
                    <div className={styles.customCardBody2}>
                      <div className="row">
                        <div className={`mt-2 col-5 ${styles.infoTitle}`}>
                          Experiment Name <span>:</span>
                        </div>
                        <div className={`mt-2 col-7 ${styles.infoDetailsText}`}>
                          {selectedTrialFile
                            ? selectedTrialFile?.barcode.split('-')[1]
                            : 'N/A'}
                        </div>
                        <div className={`mt-2 col-5 ${styles.infoTitle}`}>
                          Range <span>:</span>
                        </div>
                        <div className={`mt-2 col-7 ${styles.infoDetailsText}`}>
                          {selectedTrialFile ? selectedTrialFile?.range : 'N/A'}
                        </div>
                        <div className={`mt-2 col-5 ${styles.infoTitle}`}>
                          Row <span>:</span>
                        </div>
                        <div className={`mt-2 col-7 ${styles.infoDetailsText}`}>
                          {selectedTrialFile ? selectedTrialFile?.row : 'N/A'}
                        </div>
                        <div className={`mt-2 col-5 ${styles.infoTitle}`}>
                          Name <span>:</span>
                        </div>
                        <div className={`mt-2 col-7 ${styles.infoDetailsText}`}>
                          {selectedTrialFile ? selectedTrialFile?.name : 'N/A'}
                        </div>
                        <div className={`mt-2 col-5 ${styles.infoTitle}`}>
                          Scanned <span>:</span>
                        </div>
                        <div className={`mt-2 col-7 ${styles.infoDetailsText}`}>
                          {selectedTrialFile
                            ? selectedTrialFile?.barcode
                            : 'N/A'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mt-4 mt-lg-0">
                  <div className={`card ${styles.card}`}>
                    <div className={styles.customCardBody2}>
                      <div className={styles.progressHeaderWrapper}>
                        <div className={styles.progressHeaderSuccess}>
                          {allScannedKeepsCount ?? 0} /
                          {allTrailFileRecordsOfKeepCount ?? 0}
                        </div>
                        <div className={styles.progressHeaderTitle}>
                          Scanned Keeps
                        </div>
                      </div>
                      <div className={`progress ${styles.customProgress}`}>
                        <div
                          className={`progress-bar bg-success ${styles.customProgressBarSuccess}`}
                          role="progressbar"
                          style={{
                            width: `${
                              ((allScannedKeepsCount || 0) /
                                (allTrailFileRecordsOfKeepCount || 1)) *
                              100
                            }%`,
                          }}
                          aria-valuenow={allScannedKeepsCount}
                          aria-valuemin={0}
                          aria-valuemax={allTrailFileRecordsOfKeepCount}
                        ></div>
                      </div>

                      <div className={styles.progressHeaderWrapper}>
                        <div className={styles.progressHeaderWarning}>
                          {allWeightScannedCount ?? 0} /
                          {allTrailFileRecordsCount ?? 0}
                        </div>
                        <div className={styles.progressHeaderTitle}>
                          Scanned Samples
                        </div>
                      </div>
                      <div className={`progress ${styles.customProgress}`}>
                        <div
                          className={`progress-bar bg-warning ${styles.customProgressBarWarning}`}
                          role="progressbar"
                          style={{
                            width: `${
                              ((allWeightScannedCount || 0) /
                                (allTrailFileRecordsCount || 1)) *
                              100
                            }%`,
                          }}
                          aria-valuenow={allWeightScannedCount}
                          aria-valuemin={0}
                          aria-valuemax={allTrailFileRecordsCount}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 mt-4">
                {location.state?.weightingCrackedGrains === Answer.YES && (
                  <div className={`card ${styles.card}`}>
                    <div className={styles.customCardBody}>
                      <h6
                        className={`card-title mb-2 ${styles.customCardTitle}`}
                      >
                        <CrackedGrainIcon />
                        <span className={`${styles.ml2}`}>
                          Cracked Grains Proportion
                        </span>
                      </h6>
                      <div>
                        {`${saveInfo ? `${crackedProportion} %` : '----'}`}
                      </div>
                      <h6
                        className={`card-title mt-5 mb-2 ${styles.customCardTitle}`}
                      >
                        <PencilIcon />
                        <span className={`${styles.ml2}`}>
                          Note on Cracked Grains
                        </span>
                      </h6>
                      <div>{`${saveInfo ? `${crackedNote}` : '----'}`}</div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-lg-12 mt-4">
                <div className={`card ${styles.card}`}>
                  {selectedWeightTrait === WeightTrait.YIELD && (
                    <div className={styles.customCardBody}>
                      <h6
                        className={`card-title mb-2 ${styles.customCardTitle}`}
                      >
                        <WeightIcon size={18} />
                        <span className={`${styles.ml2}`}>Yield Weight</span>
                      </h6>
                      <div>
                        {!saveWeightInput && (weightFromScale || '----')}{' '}
                        {!saveWeightInput && (
                          <span className={`${styles.amountUnit}`}>
                            {weightUnits}
                          </span>
                        )}
                        {saveWeightInput && (
                          <SaveWeightInput
                            onOk={saveWeightOnWeightReceived}
                            weightUnits={weightUnits}
                          />
                        )}
                      </div>
                      {weightFromScale !== 0 && !saveWeightInput && (
                        <button
                          className={`btn mt-5 btn-success btn-lg ${styles.savedWeightButton}`}
                        >
                          Weight Saved
                        </button>
                      )}
                    </div>
                  )}

                  {selectedWeightTrait === WeightTrait.IMAGING && (
                    <div className={styles.customCardBody}>
                      <h6
                        className={`card-title mb-2 ${styles.customCardTitle}`}
                      >
                        <WeightIcon size={18} />
                        <span className={`${styles.ml2}`}>Sample Weight</span>
                      </h6>
                      <div>
                        {!saveWeightInput && (weightFromScale || '----')}{' '}
                        {!saveWeightInput && (
                          <span className={`${styles.amountUnit}`}>
                            {weightUnits}
                          </span>
                        )}
                        {saveWeightInput && (
                          <SaveWeightInput
                            onOk={saveWeightOnWeightReceived}
                            weightUnits={weightUnits}
                          />
                        )}
                      </div>
                      {weightFromScale !== 0 && !saveWeightInput && (
                        <button
                          className={`btn mt-5 btn-success btn-lg ${styles.savedWeightButton}`}
                        >
                          Weight Saved
                        </button>
                      )}
                    </div>
                  )}

                  {selectedWeightTrait === WeightTrait.IMAGING_HLW && (
                    <div className={styles.customCardBody}>
                      <h6
                        className={`card-title mb-2 ${styles.customCardTitle}`}
                      >
                        <WeightIcon size={18} />
                        <span className={`${styles.ml2}`}>Sample Weight</span>
                      </h6>
                      <div>
                        {!saveWeightInput && (weightFromScale || '----')}{' '}
                        {!saveWeightInput && (
                          <span className={`${styles.amountUnit}`}>
                            {weightUnits}
                          </span>
                        )}
                        {saveWeightInput && (
                          <SaveWeightInput
                            onOk={saveWeightOnWeightReceived}
                            weightUnits={weightUnits}
                          />
                        )}
                      </div>
                      <h6
                        className={`card-title mt-5 mb-2 ${styles.customCardTitle}`}
                      >
                        <WeightIcon size={18} />
                        <span className={`${styles.ml2}`}>HLW Weight</span>
                      </h6>
                      <div>
                        {!saveWeightHLWInput && (weightHLW || '----')}{' '}
                        {!saveWeightHLWInput && (
                          <span className={`${styles.amountUnit}`}>
                            {weightUnits}
                          </span>
                        )}
                        {saveWeightHLWInput && (
                          <SaveWeightInput
                            onOk={saveWeightOnWeightReceived}
                            weightUnits={weightUnits}
                          />
                        )}
                      </div>
                      {weightFromScale !== 0 &&
                        weightHLW !== 0 &&
                        !saveWeightInput &&
                        !saveWeightHLWInput && (
                          <button
                            className={`btn mt-5 btn-success btn-lg ${styles.savedWeightButton}`}
                          >
                            Weight Saved
                          </button>
                        )}
                    </div>
                  )}

                  {selectedWeightTrait === WeightTrait.IMAGING_WEIGHT && (
                    <div className={styles.customCardBody}>
                      <h6
                        className={`card-title mb-2 ${styles.customCardTitle}`}
                      >
                        <WeightIcon size={18} />
                        <span className={`${styles.ml2}`}>Sample Weight</span>
                      </h6>
                      <div>
                        {!saveWeightInput && (weightFromScale || '----')}{' '}
                        {!saveWeightInput && (
                          <span className={`${styles.amountUnit}`}>
                            {weightUnits}
                          </span>
                        )}
                        {saveWeightInput && (
                          <SaveWeightInput
                            onOk={saveWeightOnWeightReceived}
                            weightUnits={weightUnits}
                          />
                        )}
                      </div>
                      <h6
                        className={`card-title mt-5 mb-2 ${styles.customCardTitle}`}
                      >
                        <WeightIcon size={18} />
                        <span className={`${styles.ml2}`}>
                          Sample Bag Weight
                        </span>
                      </h6>
                      <div>
                        {!saveWeightSampleBagInput &&
                          (weightSampleBag || '----')}{' '}
                        {!saveWeightSampleBagInput && (
                          <span className={`${styles.amountUnit}`}>
                            {weightUnits}
                          </span>
                        )}
                        {saveWeightSampleBagInput && (
                          <SaveWeightInput
                            onOk={saveWeightOnWeightReceived}
                            weightUnits={weightUnits}
                          />
                        )}
                      </div>
                      {weightFromScale !== 0 &&
                        weightSampleBag !== 0 &&
                        !saveWeightInput &&
                        !saveWeightSampleBagInput && (
                          <button
                            className={`btn mt-5 btn-success btn-lg ${styles.savedWeightButton}`}
                          >
                            Weight Saved
                          </button>
                        )}
                    </div>
                  )}

                  {selectedWeightTrait === WeightTrait.SCREENINGS_IMAGINGS && (
                    <div className={styles.customCardBody}>
                      <h6
                        className={`card-title mb-2 ${styles.customCardTitle}`}
                      >
                        <WeightIcon size={18} />
                        <span className={`${styles.ml2}`}>Above Weight</span>
                      </h6>
                      <div>
                        {!saveWeightScreeningAboveInput &&
                          (weightScreeningAbove || '----')}{' '}
                        {!saveWeightScreeningAboveInput && (
                          <span className={`${styles.amountUnit}`}>
                            {weightUnits}
                          </span>
                        )}
                        {saveWeightScreeningAboveInput && (
                          <SaveWeightInput
                            onOk={saveWeightOnWeightReceived}
                            weightUnits={weightUnits}
                          />
                        )}
                      </div>
                      <h6
                        className={`mt-5 card-title mb-2 ${styles.customCardTitle}`}
                      >
                        <WeightIcon size={18} />
                        <span className={`${styles.ml2}`}>Below Weight</span>
                      </h6>
                      <div>
                        {!saveWeightScreeningBelowInput &&
                          (weightScreeningBelow || '----')}{' '}
                        {!saveWeightScreeningBelowInput && (
                          <span className={`${styles.amountUnit}`}>
                            {weightUnits}
                          </span>
                        )}
                        {saveWeightScreeningBelowInput && (
                          <SaveWeightInput
                            onOk={saveWeightOnWeightReceived}
                            weightUnits={weightUnits}
                          />
                        )}
                      </div>
                      <h6
                        className={`mt-5 card-title mb-2 ${styles.customCardTitle}`}
                      >
                        <WeightIcon size={18} />
                        <span className={`${styles.ml2}`}>Sample Weight</span>
                      </h6>
                      <div>
                        {!saveWeightInput && (weightFromScale || '----')}{' '}
                        {!saveWeightInput && (
                          <span className={`${styles.amountUnit}`}>
                            {weightUnits}
                          </span>
                        )}
                        {saveWeightInput && (
                          <SaveWeightInput
                            onOk={saveWeightOnWeightReceived}
                            weightUnits={weightUnits}
                          />
                        )}
                      </div>
                      {weightFromScale !== 0 &&
                        weightScreeningAbove !== 0 &&
                        weightScreeningBelow !== 0 &&
                        !saveWeightScreeningAboveInput &&
                        !saveWeightScreeningBelowInput &&
                        !saveWeightInput && (
                          <button
                            className={`btn mt-5 btn-success btn-lg ${styles.savedWeightButton}`}
                          >
                            Weight Saved
                          </button>
                        )}
                    </div>
                  )}

                  {selectedWeightTrait ===
                    WeightTrait.IMAGING_HLW_SCREENINGS && (
                    <div className={styles.customCardBody}>
                      <h6
                        className={`card-title mb-2 ${styles.customCardTitle}`}
                      >
                        <WeightIcon size={18} />
                        <span className={`${styles.ml2}`}>Above Weight</span>
                      </h6>
                      <div>
                        {!saveWeightScreeningAboveInput &&
                          (weightScreeningAbove || '----')}{' '}
                        {!saveWeightScreeningAboveInput && (
                          <span className={`${styles.amountUnit}`}>
                            {weightUnits}
                          </span>
                        )}
                        {saveWeightScreeningAboveInput && (
                          <SaveWeightInput
                            onOk={saveWeightOnWeightReceived}
                            weightUnits={weightUnits}
                          />
                        )}
                      </div>
                      <h6
                        className={`mt-5 card-title mb-2 ${styles.customCardTitle}`}
                      >
                        <WeightIcon size={18} />
                        <span className={`${styles.ml2}`}>Below Weight</span>
                      </h6>
                      <div>
                        {!saveWeightScreeningBelowInput &&
                          (weightScreeningBelow || '----')}{' '}
                        {!saveWeightScreeningBelowInput && (
                          <span className={`${styles.amountUnit}`}>
                            {weightUnits}
                          </span>
                        )}
                        {saveWeightScreeningBelowInput && (
                          <SaveWeightInput
                            onOk={saveWeightOnWeightReceived}
                            weightUnits={weightUnits}
                          />
                        )}
                      </div>
                      <h6
                        className={`mt-5 card-title mb-2 ${styles.customCardTitle}`}
                      >
                        <WeightIcon size={18} />
                        <span className={`${styles.ml2}`}>Sample Weight</span>
                      </h6>
                      <div>
                        {!saveWeightInput && (weightFromScale || '----')}{' '}
                        {!saveWeightInput && (
                          <span className={`${styles.amountUnit}`}>
                            {weightUnits}
                          </span>
                        )}
                        {saveWeightInput && (
                          <SaveWeightInput
                            onOk={saveWeightOnWeightReceived}
                            weightUnits={weightUnits}
                          />
                        )}
                      </div>
                      <h6
                        className={`card-title mt-5 mb-2 ${styles.customCardTitle}`}
                      >
                        <WeightIcon size={18} />
                        <span className={`${styles.ml2}`}>HLW Weight</span>
                      </h6>
                      <div>
                        {!saveWeightHLWInput && (weightHLW || '----')}{' '}
                        {!saveWeightHLWInput && (
                          <span className={`${styles.amountUnit}`}>
                            {weightUnits}
                          </span>
                        )}
                        {saveWeightHLWInput && (
                          <SaveWeightInput
                            onOk={saveWeightOnWeightReceived}
                            weightUnits={weightUnits}
                          />
                        )}
                      </div>
                      {weightFromScale !== 0 &&
                        weightScreeningAbove !== 0 &&
                        weightScreeningBelow !== 0 &&
                        weightHLW !== 0 &&
                        !saveWeightScreeningAboveInput &&
                        !saveWeightScreeningBelowInput &&
                        !saveWeightInput &&
                        !saveWeightHLWInput && (
                          <button
                            className={`btn mt-5 btn-success btn-lg ${styles.savedWeightButton}`}
                          >
                            Weight Saved
                          </button>
                        )}
                    </div>
                  )}

                  {selectedWeightTrait === WeightTrait.SCREENS && (
                    <div className={styles.customCardBody}>
                      <h6
                        className={`card-title mb-2 ${styles.customCardTitle}`}
                      >
                        <WeightIcon size={18} />
                        <span className={`${styles.ml2}`}>Above Weight</span>
                      </h6>
                      <div>
                        {!saveWeightScreeningAboveInput &&
                          (weightScreeningAbove || '----')}{' '}
                        {!saveWeightScreeningAboveInput && (
                          <span className={`${styles.amountUnit}`}>
                            {weightUnits}
                          </span>
                        )}
                        {saveWeightScreeningAboveInput && (
                          <SaveWeightInput
                            onOk={saveWeightOnWeightReceived}
                            weightUnits={weightUnits}
                          />
                        )}
                      </div>
                      <h6
                        className={`mt-5 card-title mb-2 ${styles.customCardTitle}`}
                      >
                        <WeightIcon size={18} />
                        <span className={`${styles.ml2}`}>Below Weight</span>
                      </h6>
                      <div>
                        {!saveWeightScreeningBelowInput &&
                          (weightScreeningBelow || '----')}{' '}
                        {!saveWeightScreeningBelowInput && (
                          <span className={`${styles.amountUnit}`}>
                            {weightUnits}
                          </span>
                        )}
                        {saveWeightScreeningBelowInput && (
                          <SaveWeightInput
                            onOk={saveWeightOnWeightReceived}
                            weightUnits={weightUnits}
                          />
                        )}
                      </div>
                      {weightScreeningAbove !== 0 &&
                        weightScreeningBelow !== 0 &&
                        !saveWeightScreeningAboveInput &&
                        !saveWeightScreeningBelowInput && (
                          <button
                            className={`btn mt-5 btn-success btn-lg ${styles.savedWeightButton}`}
                          >
                            Weight Saved
                          </button>
                        )}
                    </div>
                  )}

                  {selectedWeightTrait === WeightTrait.HLW && (
                    <div className={styles.customCardBody}>
                      <h6
                        className={`card-title mb-2 ${styles.customCardTitle}`}
                      >
                        <WeightIcon size={18} />
                        <span className={`${styles.ml2}`}>HLW Weight</span>
                      </h6>
                      <div>
                        {!saveWeightHLWInput && (weightHLW || '----')}{' '}
                        {!saveWeightHLWInput && (
                          <span className={`${styles.amountUnit}`}>
                            {weightUnits}
                          </span>
                        )}
                        {saveWeightHLWInput && (
                          <SaveWeightInput
                            onOk={saveWeightOnWeightReceived}
                            weightUnits={weightUnits}
                          />
                        )}
                      </div>
                      {weightHLW !== 0 && !saveWeightHLWInput && (
                        <button
                          className={`btn mt-5 btn-success btn-lg ${styles.savedWeightButton}`}
                        >
                          Weight Saved
                        </button>
                      )}
                    </div>
                  )}

                  {selectedWeightTrait === WeightTrait.HLW_SCREENS && (
                    <div className={styles.customCardBody}>
                      <h6
                        className={`card-title mb-2 ${styles.customCardTitle}`}
                      >
                        <WeightIcon size={18} />
                        <span className={`${styles.ml2}`}>Above Weight</span>
                      </h6>
                      <div>
                        {!saveWeightScreeningAboveInput &&
                          (weightScreeningAbove || '----')}{' '}
                        {!saveWeightScreeningAboveInput && (
                          <span className={`${styles.amountUnit}`}>
                            {weightUnits}
                          </span>
                        )}
                        {saveWeightScreeningAboveInput && (
                          <SaveWeightInput
                            onOk={saveWeightOnWeightReceived}
                            weightUnits={weightUnits}
                          />
                        )}
                      </div>
                      <h6
                        className={`mt-5 card-title mb-2 ${styles.customCardTitle}`}
                      >
                        <WeightIcon size={18} />
                        <span className={`${styles.ml2}`}>Below Weight</span>
                      </h6>
                      <div>
                        {!saveWeightScreeningBelowInput &&
                          (weightScreeningBelow || '----')}{' '}
                        {!saveWeightScreeningBelowInput && (
                          <span className={`${styles.amountUnit}`}>
                            {weightUnits}
                          </span>
                        )}
                        {saveWeightScreeningBelowInput && (
                          <SaveWeightInput
                            onOk={saveWeightOnWeightReceived}
                            weightUnits={weightUnits}
                          />
                        )}
                      </div>
                      <h6
                        className={`mt-5 card-title mb-2 ${styles.customCardTitle}`}
                      >
                        <WeightIcon size={18} />
                        <span className={`${styles.ml2}`}>HLW Weight</span>
                      </h6>
                      <div>
                        {!saveWeightHLWInput && (weightHLW || '----')}{' '}
                        {!saveWeightHLWInput && (
                          <span className={`${styles.amountUnit}`}>
                            {weightUnits}
                          </span>
                        )}
                        {saveWeightHLWInput && (
                          <SaveWeightInput
                            onOk={saveWeightOnWeightReceived}
                            weightUnits={weightUnits}
                          />
                        )}
                      </div>
                      {weightScreeningAbove !== 0 &&
                        weightScreeningBelow !== 0 &&
                        weightHLW !== 0 &&
                        !saveWeightScreeningAboveInput &&
                        !saveWeightScreeningBelowInput &&
                        !saveWeightHLWInput && (
                          <button
                            className={`btn mt-5 btn-success btn-lg ${styles.savedWeightButton}`}
                          >
                            Weight Saved
                          </button>
                        )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {(selectedWeightTrait === WeightTrait.IMAGING ||
              selectedWeightTrait === WeightTrait.IMAGING_HLW ||
              selectedWeightTrait === WeightTrait.IMAGING_WEIGHT ||
              selectedWeightTrait === WeightTrait.SCREENINGS_IMAGINGS ||
              selectedWeightTrait === WeightTrait.IMAGING_HLW_SCREENINGS) && (
              <div className={'col-lg-4 mt-4 mt-lg-0'}>
                <WebCam
                  isEnterKey={enter}
                  isNoteVisible={note}
                  save={noteSave}
                  isCurrentCaptureNoteEditable={isCurrentCaptureNoteEditable}
                  isCurrentCapture={isCurrentCapture}
                  barcode={selectedTrialFile?.barcode}
                  uploadGrainImage={(image) => {
                    setCurrentCapture(true);
                    setCapturedImage(image);
                    goToNextMoodAfterSavingWeight();
                  }}
                  note={(text) => {
                    setImageNote(text);
                    goToNextMoodAfterSavingWeight();
                    //setNote(false);
                    setNoteSave(true);
                  }}
                  continueSession={() => {
                    //setScanCodeModalVisible(true);
                    setDisable(false);
                    setNote(false);
                  }}
                />
              </div>
            )}

            {(selectedWeightTrait === WeightTrait.YIELD ||
              selectedWeightTrait === WeightTrait.SCREENS ||
              selectedWeightTrait === WeightTrait.HLW ||
              selectedWeightTrait === WeightTrait.HLW_SCREENS) &&
              note && (
                <div className={'col-lg-4'}>
                  <WeightNote
                    save={noteSave}
                    isCurrentBarcode={isCurrentBarcode}
                    isCurrentBarcodeNoteEditable={isCurrentBarcodeNoteEditable}
                    note={(text) => {
                      setWeightNote(text);
                      goToNextMoodAfterSavingWeight();
                      setNoteSave(true);
                    }}
                    continueSession={() => {
                      setDisable(false);
                      setNote(false);
                    }}
                  />
                </div>
              )}
          </div>
        </div>

        {/*<ScanCode*/}
        {/*  source={'/weight'}*/}
        {/*  onSetNote={(value) => {*/}
        {/*    setNote(value);*/}
        {/*    setCurrentCaptureNoteEditable(true);*/}
        {/*  }}*/}
        {/*  onSetImages={async (value) => {*/}
        {/*    await saveWeights();*/}
        {/*    setViewImages(value);*/}
        {/*  }}*/}
        {/*  show={false}*/}
        {/*  noteButtonVisible={enter}*/}
        {/*  onHide={() => setScanCodeModalVisible(false)}*/}
        {/*  onEndSession={saveWeights}*/}
        {/*  onOk={async (barcode: string) => {*/}
        {/*    const isGoingNextRound = await goToNextRound(barcode);*/}
        {/*    if (!isGoingNextRound) return;*/}

        {/*    if (selectedWeightTrait === WeightTrait.YIELD) {*/}
        {/*      setWeight(0);*/}
        {/*      setSelectedWeightingMood(WeightingMood.YIELD);*/}
        {/*    } else if (selectedWeightTrait === WeightTrait.SCREENS) {*/}
        {/*      setWeightScreeningAbove(0);*/}
        {/*      setWeightScreeningBelow(0);*/}
        {/*      if (location.state.weightingCrackedGrains === Answer.YES) {*/}
        {/*        setSelectedWeightingMood(WeightingMood.CRACKED_GRAIN_SCREENS);*/}
        {/*        setSaveInfo(false);*/}
        {/*        setCrackedGrainPortionModal(true);*/}
        {/*      } else {*/}
        {/*        setSelectedWeightingMood(WeightingMood.SCREENING_ABOVE);*/}
        {/*      }*/}
        {/*    } else if (selectedWeightTrait === WeightTrait.HLW) {*/}
        {/*      setWeightHLW(0);*/}
        {/*      setSelectedWeightingMood(WeightingMood.HLW);*/}
        {/*    } else if (selectedWeightTrait === WeightTrait.HLW_SCREENS) {*/}
        {/*      setWeightHLW(0);*/}
        {/*      setWeightScreeningAbove(0);*/}
        {/*      setWeightScreeningBelow(0);*/}
        {/*      if (location.state.weightingCrackedGrains === Answer.YES) {*/}
        {/*        setSelectedWeightingMood(*/}
        {/*          WeightingMood.CRACKED_GRAIN_HLW_SCREENS*/}
        {/*        );*/}
        {/*        setCrackedGrainPortionModal(true);*/}
        {/*        setSaveInfo(false);*/}
        {/*      } else {*/}
        {/*        setSelectedWeightingMood(WeightingMood.HLW_SCREENING_ABOVE);*/}
        {/*      }*/}
        {/*    } else if (selectedWeightTrait === WeightTrait.IMAGING) {*/}
        {/*      setWeight(0);*/}
        {/*      setSelectedWeightingMood(WeightingMood.IMAGING);*/}
        {/*    } else if (selectedWeightTrait === WeightTrait.IMAGING_HLW) {*/}
        {/*      setWeight(0);*/}
        {/*      setWeightHLW(0);*/}
        {/*      setSelectedWeightingMood(WeightingMood.IMAGING_HLW);*/}
        {/*    } else if (selectedWeightTrait === WeightTrait.IMAGING_WEIGHT) {*/}
        {/*      setWeight(0);*/}
        {/*      setWeightSampleBag(0);*/}
        {/*      setSelectedWeightingMood(WeightingMood.IMAGING_WEIGHT);*/}
        {/*    } else if (*/}
        {/*      selectedWeightTrait === WeightTrait.SCREENINGS_IMAGINGS*/}
        {/*    ) {*/}
        {/*      setWeight(0);*/}
        {/*      setWeightScreeningAbove(0);*/}
        {/*      setWeightScreeningBelow(0);*/}
        {/*      if (location.state.weightingCrackedGrains === Answer.YES) {*/}
        {/*        setSelectedWeightingMood(*/}
        {/*          WeightingMood.CRACKED_GRAIN_SCREENS_IMAGING*/}
        {/*        );*/}
        {/*        setCrackedGrainPortionModal(true);*/}
        {/*        setSaveInfo(false);*/}
        {/*      } else {*/}
        {/*        setSelectedWeightingMood(*/}
        {/*          WeightingMood.IMAGING_SCREENINGS_ABOVE*/}
        {/*        );*/}
        {/*      }*/}
        {/*    } else if (*/}
        {/*      selectedWeightTrait === WeightTrait.IMAGING_HLW_SCREENINGS*/}
        {/*    ) {*/}
        {/*      setWeight(0);*/}
        {/*      setWeightHLW(0);*/}
        {/*      setWeightScreeningAbove(0);*/}
        {/*      setWeightScreeningBelow(0);*/}
        {/*      if (location.state.weightingCrackedGrains === Answer.YES) {*/}
        {/*        setSelectedWeightingMood(*/}
        {/*          WeightingMood.CRACKED_GRAIN_IMAGING_HLW_SCREENS*/}
        {/*        );*/}
        {/*        setCrackedGrainPortionModal(true);*/}
        {/*        setSaveInfo(false);*/}
        {/*      } else {*/}
        {/*        setSelectedWeightingMood(*/}
        {/*          WeightingMood.IMAGING_HLW_SCREENINGS_ABOVE*/}
        {/*        );*/}
        {/*      }*/}
        {/*    }*/}

        {/*    setCurrentCapture(false);*/}
        {/*    if (location.state.weightingCrackedGrains === Answer.YES) {*/}
        {/*      setSaveWeightModalVisible(false);*/}
        {/*    } else {*/}
        {/*      setSaveWeightModalVisible(true);*/}
        {/*    }*/}
        {/*  }}*/}
        {/*  dataLoading={dataLoading}*/}
        {/*  isLoading={loading}*/}
        {/*  setWarningModal={(value) => {*/}
        {/*    setWarningModal(value);*/}
        {/*  }}*/}
        {/*/>*/}

        {/*<SaveWeightModal*/}
        {/*  show={isSaveWeightModalVisible}*/}
        {/*  onHide={() => {*/}
        {/*    // function*/}
        {/*  }}*/}
        {/*  onOk={saveWeightOnWeightReceived}*/}
        {/*  weightUnits={weightUnits}*/}
        {/*  title={titleMap[selectedWeightingMood] ?? ''}*/}
        {/*/>*/}

        <CrackedGrainPortionModal
          show={crackedGrainPortionModal}
          onCloseModal={() => {
            setCrackedGrainPortionModal(false);
          }}
          savePortion={async () => {
            setSaveInfo(true);
            setCrackedGrainPortionModal(false);
            goToNextMoodAfterSavingWeight();
          }}
          setEnterCrackedProportion={(value) => setCrackedProportion(value)}
          setEnterCrackedNote={(value) => setCrackedNote(value)}
        />

        <CameraModal
          onEnterPress={() => {
            setEnter(true);
          }}
          scanModalOpen={() => {
            /* function */
          }}
          show={isCameraModal}
          onHide={() => {
            /* function */
          }}
        />
        <MessageModal
          title="Weight Error!"
          isSuccess={false}
          isError={true}
          onCloseModal={() => {
            setHlwWeightLimitErrorVisible(false);
          }}
          openModal={isHlwWeightLimitErrorVisible}
          messageText={`The weight goes beyond the weight limit; it must be <${
            weightTraitInputs?.hlwUpperLimit || 0
          } and >${
            weightTraitInputs?.hlwLowerLimit || 0
          }. Please weigh again or continue with this weight anyway.`}
          primaryButtonText="Weigh Again"
          secondaryButtonText="Continue with the Weight"
          onPrimaryButtonClick={() => {
            setWeightHLW(0);
          }}
          onSecondaryButtonClick={() => sendWeightsAfterSaved(true)}
        />
        <MessageModal
          title="Weight Error!"
          isSuccess={false}
          isError={true}
          onCloseModal={() => {
            setSampleWeightLimitErrorVisible(false);
          }}
          openModal={isSampleWeightLimitErrorVisible}
          messageText={`The weight goes beyond the weight limit; it must be <${
            weightTraitInputs?.sampleLowerLimit || 0
          } and >${
            weightTraitInputs?.sampleUpperLimit || 0
          }. Please weigh again or continue with this weight anyway.`}
          primaryButtonText="Weigh Again"
          secondaryButtonText="Continue with the Weight"
          onPrimaryButtonClick={() => {
            setWeight(0);
          }}
          onSecondaryButtonClick={() => sendWeightsAfterSaved(true)}
        />
        {openModal && (
          <MessageModal
            title={isSuccess ? 'Success' : 'Error'}
            isSuccess={isSuccess}
            isError={isError}
            onCloseModal={(value) => setOpenModal(value)}
            openModal={openModal}
            messageText={messageText}
          />
        )}
        <ViewImagesModal
          onContinue={() => {
            /* function */
          }}
          showModal={viewImages}
          trialName={
            selectedTrialFile
              ? selectedTrialFile.barcode.split('-')[1].trim()
              : ''
          }
          onHide={() => setViewImages(false)}
        />
        {warningModal && (
          <CustomModal
            open={warningModal}
            title={'Warning'}
            warning
            onCloseModal={(val) => setWarningModal(val)}
          >
            <div>
              <h6 className={'light-text text-normal'}>
                You are currently offline. Please check your internet
                connection.
              </h6>
              <div
                className={
                  'd-flex justify-content-end align-items-center mt-5 w-100'
                }
              >
                <CustomButton
                  text={'OK'}
                  onClick={() => {
                    setWarningModal(false);
                    //setScanCodeModalVisible(true);
                    setDisable(false);
                  }}
                  className={styles.buttonWidth}
                />
              </div>
            </div>
          </CustomModal>
        )}
      </CommonLayout>
    </div>
  );
};
