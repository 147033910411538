import * as React from 'react';
interface Props {
  size?: number;
  color?: string;
}
const NoteIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 14}
      height={size ?? 14}
      fill="none"
      viewBox={'0 0 14 14'}
    >
      <g clipPath="url(#a)">
        <path
          fill={color ?? '#003359'}
          d="M13.33.67a2.115 2.115 0 0 0-2.988 0L.855 10.158A2.898 2.898 0 0 0 0 12.22v1.197a.583.583 0 0 0 .583.584h1.198a2.898 2.898 0 0 0 2.062-.854l9.488-9.488a2.115 2.115 0 0 0 0-2.989ZM3.019 12.322c-.329.326-.773.51-1.237.512h-.614v-.614a1.738 1.738 0 0 1 .512-1.238l7.2-7.2 1.342 1.342-7.203 7.198Zm9.488-9.488-1.462 1.462-1.342-1.339 1.462-1.462a.948.948 0 0 1 1.34 1.342l.002-.003Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h14v14H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default NoteIcon;
