import React from 'react';

interface Props {
  size?: number;
}

const WhiteCameraIcon: React.FC<Props> = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 12}
      height={size ?? 12}
      fill="none"
      viewBox={'0 0 12 12'}
    >
      <g fill="#fff" clipPath="url(#a)">
        <path d="M8.961 1.965h-.233l-1-1.298A1.386 1.386 0 0 0 6.637.13h-1.77a1.385 1.385 0 0 0-1.09.536l-1 1.298h-.233A2.294 2.294 0 0 0 .253 4.256V8.84a2.294 2.294 0 0 0 2.292 2.291H8.96a2.294 2.294 0 0 0 2.292-2.291V4.256A2.294 2.294 0 0 0 8.96 1.965Zm-4.457-.738a.461.461 0 0 1 .363-.179h1.771a.462.462 0 0 1 .363.179l.57.738H3.935l.57-.738Zm5.832 7.613a1.375 1.375 0 0 1-1.375 1.375H2.545A1.375 1.375 0 0 1 1.17 8.84V4.256a1.375 1.375 0 0 1 1.375-1.375H8.96a1.375 1.375 0 0 1 1.375 1.375V8.84Z" />
        <path d="M5.753 3.798a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5Zm0 4.583a1.833 1.833 0 1 1 0-3.666 1.833 1.833 0 0 1 0 3.666Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.253.131h11v11h-11z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WhiteCameraIcon;
