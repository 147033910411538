import * as React from 'react';
interface Props {
  size?: number;
  color?: string;
}
const keepTikIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 34}
      height={size ?? 24}
      fill="none"
      viewBox={'0 0 34 24'}
    >
      <path
        fill={color ?? '#fff'}
        d="m11.666 19.84-7.838-7.838A2 2 0 0 0 1 14.83l7.838 7.838a4 4 0 0 0 5.658 0L33 4.163a2 2 0 0 0-2.828-2.828L11.666 19.84Z"
      />
    </svg>
  );
};
export default keepTikIcon;
