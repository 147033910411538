import * as React from 'react';
interface Props {
  size?: number;
  color?: string;
}
const UsernameIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 16}
      height={size ?? 16}
      fill="none"
    >
      <g clipPath="url(#a)">
        <path
          fill={color ?? '#003359'}
          d="M9.667 8.667a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm4.9-3.151A6.65 6.65 0 0 0 8 0C3.4 0 2.083 3.116 1.213 5.178a4.248 4.248 0 0 1-.88 1.58.667.667 0 0 0 .253 1.237 10.537 10.537 0 0 0 4.838-.734 1 1 0 1 0 1.15-.56 10.06 10.06 0 0 0 3.303-2.978.667.667 0 1 0-1.084-.774A8.904 8.904 0 0 1 2 6.685c.151-.302.292-.638.441-.989.82-1.944 1.841-4.363 5.56-4.363A5.319 5.319 0 0 1 13.302 6.1a.667.667 0 0 0 .544.585 1 1 0 0 1-.18 1.982 1.07 1.07 0 0 1-.168-.018.663.663 0 0 0-.745.434c-.61 1.731-2.666 3.584-4.754 3.584-1.933 0-3.733-1.522-4.51-3.026a.667.667 0 0 0-1.185.613 7.51 7.51 0 0 0 3.028 3.048v2.031a.667.667 0 0 0 1.334 0v-1.508a5.14 5.14 0 0 0 2.666 0v1.508a.667.667 0 0 0 1.334 0v-2.03a7.358 7.358 0 0 0 3.155-3.308 2.333 2.333 0 0 0 .746-4.479Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UsernameIcon;
