import * as React from 'react';

interface Props {
  size?: number;
  color?: string;
}
const CloudDownloadIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 23}
      height={size ?? 22}
      fill="none"
      viewBox={'0 0 23 22'}
    >
      <path
        fill={color ?? '#003359'}
        d="M17.662 18.624a.922.922 0 0 1-.075 1.302l-1.46 1.302a2.672 2.672 0 0 1-1.9.772 2.774 2.774 0 0 1-1.967-.812l-1.422-1.265a.922.922 0 0 1 1.225-1.376l1.229 1.1v-6.855a.92.92 0 1 1 1.841 0v6.855l1.23-1.097a.921.921 0 0 1 1.3.074Zm-.359-11.946a1.002 1.002 0 0 1-.665-.676 7.367 7.367 0 0 0-14.263.775 7.051 7.051 0 0 0 .737 4.77 5.017 5.017 0 0 0-2.66 5.204 5.38 5.38 0 0 0 5.181 4.328h2.134a.92.92 0 1 0 0-1.841H5.633a3.509 3.509 0 0 1-3.357-2.746 3.194 3.194 0 0 1 1.689-3.315 1.835 1.835 0 0 0 .73-2.473A5.525 5.525 0 1 1 14.879 6.55a2.855 2.855 0 0 0 1.883 1.89 5.498 5.498 0 0 1 3.879 5.717 4.951 4.951 0 0 1-1.023 2.68.92.92 0 1 0 1.46 1.122 6.875 6.875 0 0 0 1.4-3.658 7.33 7.33 0 0 0-5.175-7.622Z"
      />
    </svg>
  );
};
export default CloudDownloadIcon;
