import 'react-datepicker/dist/react-datepicker.css';
import * as Yup from 'yup';
import {
  CreateLocalUserRequest,
  UserRole,
  UserRoleDisplayFormat,
  UserType,
} from '../../typeDef/user.model';
import { Form, Formik } from 'formik';
import {
  MasterDataCategory,
  MasterDataRequest,
} from '../../typeDef/masterData.model';
import React, {
  ForwardedRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ButtonGroup from '../shared/radioButtonWithCheckbox/buttonGroup';
import CalendarIcon from '../../icons/calender.icon';
import CustomButton from '../shared/customButton/customButton';
import CustomDropdown from '../shared/customDropdown/customDropdown';
import CustomInputFiled from '../shared/customInputField/customInputFiled';
import DatePicker from 'react-datepicker';
import EmailPlusIcon from '../../icons/plusEmail.icon';
import { ErrorResponse } from '../../typeDef/common.model';
import LocationIcon from '../../icons/location.icon';
import LockIcon from '../../icons/lock.icon';
import UserPlusIcon from '../../icons/userPlus.icon';
import UsernameIcon from '../../icons/username.icon';
import { capitalizeFirstLetter } from '../../common/users';
import { createUser } from '../../services/user.service';
import { format } from 'date-fns';
import { getMasterData } from '../../services/masterData.service';
import styles from '../table/userList/userListTable.module.scss';

type UserCreateProps = {
  onCloseModal: (value: boolean) => void;
  status: (value: string) => void;
  message?: (value: string) => void;
};

type UserCreateDataProps = {
  firstName: string;
  lastName: string;
  email: string | null;
  role: UserRole;
  type: UserType;
  location: string;
  password: string;
  username: string;
  disableDate: Date | null;
};

type DropDownType = {
  text: string;
  value: string;
};

interface CustomInputProps {
  value: string;
  onClick: () => void;
}

const UserCreateModal = ({
  onCloseModal,
  status, //message,
}: UserCreateProps) => {
  const [selected, setSelected] = useState<string | null>(
    UserRoleDisplayFormat.GUEST
  );
  const [loading, setLoading] = useState(false);
  const [locationValue, setLocationValue] = useState<string>('');
  const [locationDropdownOptions, setLocationDropdownOptions] = useState<
    DropDownType[]
  >([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [locationError, setLocationError] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(null);

  const initialVal: UserCreateDataProps = useMemo(() => {
    return {
      firstName: '',
      lastName: '',
      email: '',
      role: UserRole.ADMIN,
      type: UserType.CASUAL,
      location: '',
      password: '',
      username: '',
      disableDate: null,
    };
  }, []);

  const getCharacterValidationError = (str: string) => {
    return `Password must have at least 1 ${str} character`;
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Please enter first name.'),
    lastName: Yup.string().required('Please enter last name.'),
    email: Yup.string().email('Please enter a valid email address.'),
    password: Yup.string()
      .required('Please enter password')
      .min(8, 'Password must have at least 8 characters')
      .matches(/[0-9]/, getCharacterValidationError('digit'))
      .matches(/[!@#$%^&*]/, getCharacterValidationError('special'))
      .matches(/[a-z]/, getCharacterValidationError('lowercase'))
      .matches(/[A-Z]/, getCharacterValidationError('uppercase')),
    username: Yup.string().required('Please enter username'),
  });

  const getLocationList = useCallback(async () => {
    try {
      const query: Partial<MasterDataRequest> = {
        category: MasterDataCategory.LOCATION,
      };
      const response = await getMasterData(query);
      const updatedOptions = response.data.map((da) => ({
        text: da.value,
        value: da.category,
      }));
      setLocationDropdownOptions(updatedOptions);
    } catch {
      status('Something went wrong');
      onCloseModal(false);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getLocationList();
    })();
  }, []);

  const handleSubmit = async (values: UserCreateDataProps) => {
    if (locationValue) {
      setLoading(true);
      try {
        const user: Partial<CreateLocalUserRequest> = {};
        user.firstName = capitalizeFirstLetter(values.firstName);
        user.lastName = capitalizeFirstLetter(values.lastName);
        user.email = values.email !== '' ? values.email : null;
        user.role =
          selected === UserRoleDisplayFormat.GUEST
            ? UserRole.USER
            : UserRole.ADMIN;
        user.type = UserType.CASUAL;
        user.location = locationValue;
        user.password = values.password;
        user.username = values.username;
        user.disableDate = startDate ? format(startDate, 'yyyy-MM-dd') : null;

        await createUser(user);
        setLoading(false);
        status('success');
        onCloseModal(false);
      } catch (err) {
        const errorResponse = err as ErrorResponse;
        setLoading(false);
        //status('error');
        // if (message) {
        //   if (errorResponse.response.data.message === 'Email is not valid') {
        //     message(
        //       `This ${errorResponse.response.data.message} for admin users`
        //     );
        //   } else if (
        //     errorResponse.response.data.message === 'Email is already taken'
        //   ) {
        //     message(
        //       `This ${errorResponse.response.data.message}. Please use another email`
        //     );
        //   } else if (
        //     errorResponse.response.data.message.includes('Duplicate entry ')
        //   ) {
        //     message('This Username is already taken');
        //   } else {
        //     message('Something went wrong');
        //   }
        // }
        //onCloseModal(false);

        if (errorResponse.response.data.message === 'Email is not valid') {
          setErrorMessage(
            `This ${errorResponse.response.data.message} for admin users`
          );
        } else if (
          errorResponse.response.data.message === 'Email is already taken'
        ) {
          setErrorMessage(
            `This ${errorResponse.response.data.message}. Please use another email`
          );
        } else if (
          errorResponse.response.data.message.includes('Duplicate entry ')
        ) {
          setErrorMessage(
            'This Username is already taken. Please use another username'
          );
        } else {
          setErrorMessage('Something went wrong');
        }
      }
    } else {
      setLocationError('Please select location.');
    }
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const adjustedDate = new Date(date.setHours(0, 0, 0, 0));
      setStartDate(adjustedDate);
    } else {
      setStartDate(null);
    }
  };

  const CustomInput = React.forwardRef<HTMLInputElement, CustomInputProps>(
    ({ value, onClick }, ref: ForwardedRef<HTMLInputElement>) => (
      <div className="custom-date-input">
        <input type="text" value={value} onClick={onClick} ref={ref} readOnly />
        <span className="custom-icon" onClick={onClick}>
          <CalendarIcon />
        </span>
      </div>
    )
  );

  CustomInput.displayName = 'CustomInput';

  return (
    <div className={styles.modalWidth}>
      <Formik
        initialValues={initialVal}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        autoComplete="off"
      >
        {({ setFieldValue }) => (
          <Form className={styles.viewUser}>
            <div className={'d-flex'}>
              <div>
                <p className={'dark-text text-semi-bold mb-2'}>
                  First Name <span className="text-danger">*</span>
                </p>
                <CustomInputFiled
                  name={'firstName'}
                  icon={<UserPlusIcon />}
                  className={styles.inputWidth}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('firstName', e.target.value)
                  }
                />
              </div>
              <div className={'mx-3'}></div>
              <div>
                <p className={'dark-text text-semi-bold mb-2'}>
                  Last Name <span className="text-danger">*</span>
                </p>
                <CustomInputFiled
                  autoFocus={false}
                  name={'lastName'}
                  icon={<UserPlusIcon />}
                  className={styles.inputWidth}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('lastName', e.target.value)
                  }
                />
              </div>
            </div>

            <div className={'mt-4 d-flex'}>
              <div>
                <p className={'dark-text text-semi-bold mb-2'}>Email ID</p>
                <CustomInputFiled
                  autoFocus={false}
                  name={'email'}
                  icon={<EmailPlusIcon />}
                  className={styles.inputWidth}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('email', e.target.value)
                  }
                />
              </div>
              <div className={'mx-3'}></div>
              <div>
                <p className={'dark-text text-semi-bold mb-2'}>
                  Location <span className="text-danger">*</span>
                </p>
                <div className={'d-flex align-items-center'}>
                  <div className={styles.inputWidth}>
                    <CustomDropdown
                      icon={<LocationIcon />}
                      placeHolder={'Select Location'}
                      dataList={locationDropdownOptions}
                      setFieldValue={(value) => setLocationValue(value)}
                    />
                  </div>
                </div>
                {locationError && (
                  <div>
                    <p className={'mt-1 text-danger'}>
                      <small>{locationError}</small>
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className={'mt-4 d-flex'}>
              <div>
                <p className={'dark-text text-semi-bold mb-2'}>
                  Username <span className="text-danger">*</span>
                </p>
                <CustomInputFiled
                  autoFocus={false}
                  name={'username'}
                  icon={<UsernameIcon />}
                  className={styles.inputWidth}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('username', e.target.value)
                  }
                  autoComplete="new-username"
                />
              </div>
              <div className={'mx-3'}></div>
              <div>
                <p className={'dark-text text-semi-bold mb-2'}>
                  Password <span className="text-danger">*</span>
                </p>
                <CustomInputFiled
                  autoFocus={false}
                  name={'password'}
                  icon={<LockIcon />}
                  type={'password'}
                  className={styles.inputWidth}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('password', e.target.value)
                  }
                  autoComplete="new-password"
                />
              </div>
            </div>

            <div className={'mt-4'}>
              <p className={'text-dark text-semi-bold mb-2'}>Disable Date</p>
              <DatePicker
                selected={startDate}
                minDate={new Date()}
                onChange={handleDateChange}
                customInput={
                  <CustomInput
                    value={startDate ? format(startDate, 'yyyy-MM-dd') : ''}
                    onClick={() => {
                      /* function */
                    }}
                  />
                }
              />
            </div>

            <div className={'mt-4'}>
              <p className={'text-dark text-semi-bold mb-2'}>User Type</p>
              <ButtonGroup
                options={[
                  UserRoleDisplayFormat.GUEST,
                  UserRoleDisplayFormat.ADMIN,
                ]}
                selectedOption={selected}
                onSelect={(option: string) => {
                  setSelected(option);
                }}
              />
            </div>

            {errorMessage && (
              <div>
                <p className={'mt-2 text-danger text-center'}>
                  <small>{errorMessage}</small>
                </p>
              </div>
            )}

            <div className={'mt-5'}>
              <CustomButton
                text={'Create User'}
                type={'submit'}
                loading={loading}
              />
            </div>
            <div className={'mt-3'}>
              <CustomButton
                variant={'transparent'}
                text={'Cancel'}
                onClick={() => onCloseModal(false)}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default UserCreateModal;
