//import * as Yup from 'yup';
//import { Form, Formik } from 'formik';
import AuthContext, { AgtUser } from '../../context/auth.context';
import {
  FutureTrialSummary,
  FutureTrialSummaryHandle,
} from '../../components/dashboardOverview/futureTrialSummary';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  WeightsAndKeepCullSummary,
  WeightsAndKeepCullSummaryHandle,
} from '../../components/dashboardOverview/weightsAndKeepCullSummary';
import CommonLayout from '../../components/layout/commonLayout';
import CustomButton from '../../components/shared/customButton/customButton';
//import CustomInputFiled from '../../components/shared/customInputField/customInputFiled';
import CustomTabSwitch from '../../components/shared/customTabSwitch/customTabSwtch';

import LoadingSpinner from '../../components/shared/loadingSpinner/loadingSpinner';
import SearchIcon from '../../icons/search.icon';
import { TabSwitch } from '../../typeDef/grain.model';
import excelIcon from '../../images/excelIcon.png';
import { getCurrentUserProfile } from '../../services/profile.services';
import loadingStyles from '../../components/shared/loadingSpinner/loadingSpinner.module.scss';
import styles from './dashboardOverview.module.scss';
import { useNavigate } from 'react-router-dom';

export const DashboardOverview = () => {
  const [selectedTab, setSelectedTab] = useState<string>(
    TabSwitch.WEIGH_AND_KC_SUMMARY
  );
  const [searchValue, setSearchValue] = useState<string>('');
  const [isPageLoading, setIsPageLoading] = useState(false);
  const navigate = useNavigate();
  const { setUser } = useContext(AuthContext);
  const accessToken = localStorage.getItem('token') as string;

  const checkCurrentUser = useCallback(async () => {
    try {
      const response = await getCurrentUserProfile();

      setUser(new AgtUser({ jwtToken: accessToken, ...response.data }));
    } catch (e) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    (async () => {
      await checkCurrentUser();
    })();
  }, []);

  const handleTabChange = (selected: string) => {
    setSearchValue('');
    setSelectedTab(selected);
  };

  const weightsAndKeepCullSummaryRef =
    useRef<WeightsAndKeepCullSummaryHandle | null>(null);

  const futureTrialSummaryRef = useRef<FutureTrialSummaryHandle | null>(null);

  const handleExportClick = () => {
    weightsAndKeepCullSummaryRef.current?.handleExport();
  };

  const handleChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearchValue(e.target.value);
  };

  const handleSearch = async () => {
    weightsAndKeepCullSummaryRef.current?.handleSearch();
    futureTrialSummaryRef.current?.handleSearch();
  };
  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const pressedKey = event.key;

    if (pressedKey === 'Enter') {
      event.preventDefault();

      await handleSearch();
    }
  };

  return (
    <CommonLayout
      admin
      title={'Welcome to Dashboard'}
      selectedPage={'overview'}
    >
      <div className={`${isPageLoading && loadingStyles.app_while_loading}`}>
        {isPageLoading && <LoadingSpinner />}
        <div className={'w-100'}>
          <div className={`card ${styles.customCard}`}>
            <div className={`${styles.customCardBody}`}>
              <CustomTabSwitch
                tabs={[
                  TabSwitch.WEIGH_AND_KC_SUMMARY,
                  TabSwitch.FUTURE_TRAIL_SUMMARY,
                ]}
                selectedTab={selectedTab}
                onTabChange={handleTabChange}
              />
              {/*<div className={`mt-5 ${styles.searchBarWrapper}`}>*/}
              {/*  <Formik*/}
              {/*    initialValues={{ search: '' }}*/}
              {/*    validationSchema={Yup.object({*/}
              {/*      search: Yup.string(),*/}
              {/*    })}*/}
              {/*    onSubmit={(values) => {*/}
              {/*      setSearchValue(values.search);*/}
              {/*    }}*/}
              {/*    enableReinitialize={true}*/}
              {/*  >*/}
              {/*    {({ setFieldValue }) => (*/}
              {/*      <Form>*/}
              {/*        <div className={`${styles.search}`}>*/}
              {/*          <CustomInputFiled*/}
              {/*            name="search"*/}
              {/*            tabIndex={0}*/}
              {/*            autoFocus*/}
              {/*            placeholder={*/}
              {/*              selectedTab.includes('Weights')*/}
              {/*                ? 'Search by file or trial'*/}
              {/*                : 'Search by trial'*/}
              {/*            }*/}
              {/*            icon={<SearchIcon />}*/}
              {/*            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>*/}
              {/*              setFieldValue('search', e.target.value, true)*/}
              {/*            }*/}
              {/*          />*/}
              {/*          <CustomButton*/}
              {/*            text="Search"*/}
              {/*            className="ms-4"*/}
              {/*            type="submit"*/}
              {/*          />*/}
              {/*        </div>*/}
              {/*      </Form>*/}
              {/*    )}*/}
              {/*  </Formik>*/}
              {/*  {selectedTab.includes('Weights') && (*/}
              {/*    <CustomButton*/}
              {/*      className="ms-4"*/}
              {/*      text="Export"*/}
              {/*      variant="secondary"*/}
              {/*      type="button"*/}
              {/*      onClick={handleExportClick}*/}
              {/*      icon={<img src={excelIcon} />}*/}
              {/*    />*/}
              {/*  )}*/}
              {/*</div>*/}
              <div className={'mt-5 d-flex justify-content-between'}>
                <div className={'d-flex align-items-center'}>
                  <div className={styles.searchBarContainer}>
                    <input
                      type="search"
                      className={`form-control ${styles.searchBar}`}
                      placeholder={
                        selectedTab.includes('Weights')
                          ? 'Search by file or trial'
                          : 'Search by trial'
                      }
                      onKeyDown={handleKeyDown}
                      onChange={handleChange}
                      value={searchValue.length > 0 ? searchValue : ''}
                    />
                    <label className={styles.iconSeach}>
                      <SearchIcon />
                    </label>
                  </div>

                  <CustomButton
                    text={'Search'}
                    className={'ms-4 fit-content'}
                    type={'button'}
                    onClick={handleSearch}
                  />
                </div>
                {selectedTab.includes('Weights') && (
                  <CustomButton
                    className={`ms-4 fit-content ${styles.exportButton}`}
                    text="Export"
                    variant="secondary"
                    type="button"
                    onClick={handleExportClick}
                    icon={<img src={excelIcon} alt={'excel'} />}
                  />
                )}
              </div>
              {selectedTab.includes('Weights') ? (
                <WeightsAndKeepCullSummary
                  ref={weightsAndKeepCullSummaryRef}
                  search={searchValue}
                  setIsLoading={(value) => setIsPageLoading(value)}
                />
              ) : (
                <FutureTrialSummary
                  ref={futureTrialSummaryRef}
                  search={searchValue}
                  setIsLoading={(value) => setIsPageLoading(value)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};
