import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import CustomButton from '../../components/shared/customButton/customButton';
import LiveDotIcon from '../../icons/liveDot.icon';
import NoteIcon from '../../icons/note.icon';
import Webcam from 'react-webcam';
import colors from '../../styles/variables.module.scss';
import styles from './dashboardWeightSection.module.scss';

type imageProps = {
  isEnterKey: boolean;
  isNoteVisible: boolean;
  save: boolean;
  isCurrentCapture: boolean;
  isCurrentCaptureNoteEditable: boolean;
  note?: (text: string) => void;
  continueSession?: () => void;
  barcode?: string;
  uploadGrainImage: (image: string) => void;
};
function WebcamImage({
  isEnterKey,
  isNoteVisible,
  save,
  isCurrentCapture,
  isCurrentCaptureNoteEditable,
  note,
  continueSession,
  barcode,
  uploadGrainImage,
}: imageProps) {
  const [img, setImg] = useState('');
  const webcamRef = useRef<Webcam>(null);
  const [text, setText] = useState('');
  const [displayingBarcode, setDisplayingBarcode] = useState('');
  const [maxWidth, setMaxWidth] = useState<number | undefined>(undefined);
  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined);
  const [error, setError] = useState('');
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  const handleEnterKeyPress = () => {
    if (isEnterKey) {
      const imageSrc = webcamRef.current?.getScreenshot();
      if (imageSrc) {
        setImg(imageSrc);
        uploadGrainImage(imageSrc);
      }
    }
  };
  useEffect(() => {
    if (isEnterKey) handleEnterKeyPress();
  }, [isEnterKey]);

  useEffect(() => {
    if (isCurrentCapture) {
      setText('');
      setDisplayingBarcode(barcode || '');
    }
  }, [isCurrentCapture, barcode]);

  // camera resolution setting
  useEffect(() => {
    const fetchCameraCapabilities = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        const track = stream.getVideoTracks()[0];
        const capabilities = track.getCapabilities();

        const height = capabilities.height?.max;

        if (height === 720) {
          setMaxWidth(1280);
        } else if (height === 1080) {
          setMaxWidth(1920);
        } else if (height === 1440) {
          setMaxWidth(2560);
        } else if (height === 2160) {
          setMaxWidth(3840);
        } else if (height === 4320) {
          setMaxWidth(7680);
        } else if (height === 7760) {
          setMaxWidth(10328);
        } else if (height === 6480) {
          setMaxWidth(12288);
        } else if (height === 8640) {
          setMaxWidth(15360);
        } else {
          const width = capabilities.width?.max;
          setMaxWidth(width);
        }

        setMaxHeight(height);
        track.stop();
      } catch (e) {
        setError('Failed to detect camera configurations');
      }
    };

    fetchCameraCapabilities(); // Call the async function immediately
  }, []);

  const videoConstraints = {
    facingMode: 'user',
    width: { ideal: maxWidth },
    height: { ideal: maxHeight },
  };

  return (
    <div className={'d-flex flex-column'}>
      <div className={`card ${styles.card}`}>
        <div className={'p-4'}>
          <div
            className={'d-flex justify-content-between align-items-center mb-3'}
          >
            {' '}
            <h6 className={'primary-color-text text-bolder'}>Live Feed</h6>
            <div className={styles.liveStatus}>
              <div className={styles.liveInner}>
                {/*<div>*/}
                <LiveDotIcon />
                {/*</div>*/}
                <h6 className={'text-danger font-size-default ms-2 '}> Live</h6>
              </div>
            </div>
          </div>
          {error && <h6 className={'text-danger'}>{error}</h6>}
          <Webcam
            audio={false}
            mirrored={false}
            className={styles.webCam}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            screenshotQuality={0.95}
            minScreenshotHeight={maxHeight}
            minScreenshotWidth={maxWidth}
            imageSmoothing={true}
          />
        </div>
      </div>
      {img.length > 0 && (
        <>
          <div className={`card mt-4 ${styles.card}`}>
            <div className={'p-4'}>
              <div
                className={
                  'd-flex justify-content-between align-items-center mb-3'
                }
              >
                {' '}
                <h6 className={'primary-color-text text-bolder'}>
                  {isCurrentCapture ? 'Current Capture' : 'Previous Capture'}
                </h6>
                <div className={styles.previousStatus}>
                  <div className={styles.previousInner}>
                    {/*<div>*/}
                    <LiveDotIcon color={colors.secondaryColor} />
                    {/*</div>*/}
                    <h6 className={'text-secondary font-size-default ms-2 '}>
                      {' '}
                      {displayingBarcode}
                    </h6>
                  </div>
                </div>
              </div>
              <img src={img} alt={'test'} className={styles.previousCapture} />

              <div className={'mt-4'}>
                {isNoteVisible && (
                  <>
                    <div className={'d-flex align-items-center'}>
                      <NoteIcon />
                      <h6 className={'primary-color-text ms-3 text-bolder'}>
                        Note on {isCurrentCapture ? 'Current' : 'Previous'}{' '}
                        Capture
                      </h6>
                    </div>
                    {isCurrentCaptureNoteEditable ? (
                      <div>
                        <textarea
                          rows={2}
                          className={styles.customtextarea}
                          value={text}
                          onChange={handleChange}
                          autoFocus={true}
                          disabled={!isCurrentCapture}
                        />
                        <div className={'d-flex justify-content-between'}>
                          <div className={`${styles.widthBtnNote}`}>
                            <CustomButton
                              text={'Save Note'}
                              disabled={text === '' || !isCurrentCapture}
                              onClick={() => {
                                note?.(text);
                              }}
                            />
                          </div>
                          <div className={`ms-3 ${styles.widthBtnNote}`}>
                            <CustomButton
                              text={'Continue Session'}
                              onClick={() => {
                                continueSession?.();
                              }}
                              disabled={!isCurrentCapture}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>{text || '--- --- ---'}</div>
                    )}
                  </>
                )}
                {save && isCurrentCapture && (
                  <div className={'d-flex justify-content-center'}>
                    <button
                      className={`btn mt-5 btn-success btn-lg ${styles.savedWeightButton}`}
                    >
                      Note Saved
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default WebcamImage;
