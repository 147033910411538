import RadioButtonWithCheckbox from './radioButtonWithCheckbox';
import React from 'react';

interface ButtonGroupProps {
  options: string[];
  selectedOption?: string | null;
  onSelect: (option: string) => void;
  buttonDisable?: boolean;
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({
  options,
  selectedOption,
  onSelect,
  buttonDisable,
}) => {
  return (
    <div className="d-flex flex-wrap">
      {options.map((option) => (
        <RadioButtonWithCheckbox
          key={option}
          label={option}
          active={selectedOption === option}
          onClick={() => onSelect(option)}
          disable={buttonDisable}
        />
      ))}
    </div>
  );
};

export default ButtonGroup;
