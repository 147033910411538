import AuthContext, { AgtUser } from '../../../context/auth.context';
import React, { useContext, useEffect, useRef, useState } from 'react';
import InitialLoadingSpinner from '../../../components/shared/loadingSpinner/initailLoadingSpinner';
import { SignInResponse } from '../../../typeDef/auth.model';
import { azureSignIn } from '../../../services/auth.service';
import { clearData } from '../../../services/database.service';
import { getCurrentUserProfile } from '../../../services/profile.services';
import loadingStyles from '../../../components/shared/loadingSpinner/loadingSpinner.module.scss';
import { useNavigate } from 'react-router-dom';

export default function Callback() {
  const navigate = useNavigate();
  const { user, setUser } = useContext(AuthContext);
  const [, setErrorMessage] = useState('');
  const codeProcessed = useRef(false);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Handle successful login
   * @param authResponse The response from the server
   * @returns void
   */
  const loginSuccess = async (authResponse: SignInResponse): Promise<void> => {
    setIsLoading(false);
    localStorage.setItem('token', authResponse.token);
    localStorage.setItem('refreshToken', authResponse.refreshToken);
    localStorage.setItem(
      'tokenExpiration',
      String(new Date().getTime() + authResponse.expiresIn * 1000)
    );

    const userResponse = await getCurrentUserProfile();
    localStorage.setItem('userType', userResponse.data.type);
    setUser(
      new AgtUser({ jwtToken: authResponse.token, ...userResponse.data })
    );

    dispatchEvent(new Event('storage'));
    navigate('/dashboard');
  };

  /**
   * Handle failed login
   * @param error The error from the server
   * @returns void
   */
  const loginFailure = (error: unknown) => {
    setIsLoading(false);
    if (
      error instanceof Object &&
      'response' in error &&
      error.response instanceof Object &&
      'data' in error.response &&
      error.response.data instanceof Object &&
      'message' in error.response.data &&
      typeof error.response.data.message === 'string'
    ) {
      switch (error.response.data.message) {
        case 'Cannot sign in non-local user, use Microsoft sign in instead.':
          return setErrorMessage('Please sign in with your AGT account.');
        case 'User not found':
          return setErrorMessage('User not found.');
        case 'Incorrect username or password':
          return setErrorMessage('Incorrect username or password.');
      }
    } else {
      setErrorMessage('An unknown error occurred.');
    }
  };

  // Handle callback from Azure AD
  useEffect(() => {
    // Check if there is a code query parameter
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code && !codeProcessed.current) {
      setIsLoading(true);
      codeProcessed.current = true;
      // If there is a code, use it to get a token
      (async () => {
        await azureSignIn({ code: code })
          .then((r) => loginSuccess(r.data))
          .catch(loginFailure);
      })();
    }
  }, []);

  useEffect(() => {
    if (user?.jwtToken || localStorage.getItem('token')) {
      // If there is a token, check if it is valid
      getCurrentUserProfile()
        .then((response) => {
          // If the token is valid, set the user and navigate to the dashboard
          setUser(
            new AgtUser({
              jwtToken: localStorage.getItem('token') ?? user?.jwtToken ?? '',
              email: response.data.email,
              firstName: response.data.firstName,
              lastName: response.data.lastName,
            })
          );
          navigate('/dashboard');
        })
        .catch(() => {
          // If the token is invalid, clear the data and remove the token
          (async () => {
            await clearData();
          })();
          setUser(undefined);
          localStorage.removeItem('token');
        });
    } else {
      // If there is no token, clear the data
      (async () => {
        await clearData();
      })();
    }
  }, [user]);

  return (
    <div className={`${isLoading && loadingStyles.app_while_loading}`}>
      {isLoading && <InitialLoadingSpinner />}
    </div>
  );
}
