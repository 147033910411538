import { ErrorResponse, PaginationType } from '../../typeDef/common.model';
import {
  GetSamplesRequest,
  GrainEntryResponse,
  MissingRequest,
  MissingViewCol,
  ScannedGrainStatus,
  Stat,
} from '../../typeDef/grain.model';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TableHeading, {
  SortDirection,
} from '../shared/tableHeading/tableHeading';
import { BlobProvider } from '@react-pdf/renderer';
import CloseIcon from '../../icons/close.icon';
import Label from '../shared/lable/lable';
import LoadingSpinner from '../shared/loadingSpinner/loadingSpinner';
import PaginationPages from '../shared/pagination/paginationPages';
import PrintMissing from './pdf';
import { getSamples } from '../../services/grain.service';
import loadingStyles from '../shared/loadingSpinner/loadingSpinner.module.scss';
import styles from './dashboard.module.scss';

type FutureMissingViewProps = {
  onCloseModal: (value: boolean) => void;
  futureTrialName?: string;
  keepCullCompleteStatus?: number;
};

const FutureMissingView = ({
  onCloseModal,
  futureTrialName,
  keepCullCompleteStatus,
}: FutureMissingViewProps) => {
  const [futureMissingData, setFutureMissingData] = useState<
    GrainEntryResponse[] | undefined
  >([]); // need to add futureMissingData
  const [paginationData, setPaginationData] = useState<PaginationType>();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const sortField = useRef(MissingRequest.MR_TRIALNAME);
  const sortOrder = useRef(SortDirection.ASC);
  const [pageSize, setPageSize] = useState(5);
  const [activePage, setActivePage] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState('');
  const sortSecondaryField = useRef(MissingRequest.MR_ROW);
  const sortSecondaryOrder = useRef(SortDirection.ASC);
  const sortTerritoryField = useRef(MissingRequest.MR_RANGE);
  const sortTerritoryOrder = useRef(SortDirection.ASC);

  const clickPrevious = () => {
    if (paginationData?.hasPreviousPage) {
      setActivePage(activePage - 1);
    }
  };

  /* click next */
  const clickNext = () => {
    if (paginationData?.hasNextPage) {
      setActivePage(activePage + 1);
    }
  };

  const getAllSamples = useCallback(async () => {
    setIsPageLoading(true);
    try {
      const query: Partial<GetSamplesRequest> = {
        future: Stat.KEEP,
        scannedKeepCullPrint: ScannedGrainStatus.FALSE,
        futureTrialName: futureTrialName,
        pageNumber: activePage,
        pageSize: pageSize,
        sortOrder: sortOrder.current ?? SortDirection.ASC,
        secondarySortOrder: sortSecondaryOrder.current ?? SortDirection.ASC,
        sortField: sortField.current ?? MissingRequest.MR_TRIALNAME,
        secondarySortField: sortSecondaryField.current ?? MissingRequest.MR_ROW,
        territorySortField:
          sortTerritoryField.current ?? MissingRequest.MR_RANGE,
        territorySortOrder: sortTerritoryOrder.current ?? SortDirection.ASC,
      };
      const response = await getSamples(query);
      setErrorMessage('');
      setFutureMissingData(response.data.items);
      setPaginationData(response.data.meta);
    } catch (e) {
      const errorResponse = e as ErrorResponse;
      setIsPageLoading(false);
      if (errorResponse.response.data.message === 'No items found') {
        setErrorMessage('No matching results...');
        setFutureMissingData([]);
      }
    }
    setIsPageLoading(false);
  }, [pageSize, activePage, sortOrder, sortField]);

  useEffect(() => {
    (async () => {
      await getAllSamples();
    })();
  }, [pageSize, activePage]);

  const handleOnSortFutureMissing = async (
    sortDirection: SortDirection,
    title: string
  ) => {
    if (title === MissingViewCol.ROW) {
      sortSecondaryOrder.current = sortDirection;
      sortSecondaryField.current = MissingRequest.MR_ROW;
    } else if (title === MissingViewCol.RANGE) {
      sortTerritoryOrder.current = sortDirection;
      sortTerritoryField.current = MissingRequest.MR_RANGE;
    } else {
      const sortBy =
        title === MissingViewCol.RANGE
          ? MissingRequest.MR_RANGE
          : title === MissingViewCol.ROW
          ? MissingRequest.MR_ROW
          : title === MissingViewCol.TRIALNAME
          ? MissingRequest.MR_TRIALNAME
          : title === MissingViewCol.BARCODE
          ? MissingRequest.MR_BARCODE
          : MissingRequest.MR_PLOT;

      sortField.current = sortBy;
      sortOrder.current = sortDirection;
    }

    await getAllSamples();
  };

  return (
    <div className={`${isPageLoading && loadingStyles.app_while_loading}`}>
      {isPageLoading && <LoadingSpinner />}
      <div className={styles.modalWidth}>
        <div className={'d-flex justify-content-between align-items-center'}>
          <div>
            <h6 className={'text-bolder primary-color-text ms-3'}>
              MISSING STATUS
            </h6>
          </div>
          <div className={'d-flex align-items-center'}>
            <BlobProvider
              document={
                <PrintMissing
                  tableData={futureMissingData}
                  name={futureTrialName}
                  completeRatio={keepCullCompleteStatus}
                  futureMissing
                />
              }
            >
              {({ url }) =>
                keepCullCompleteStatus !== 100 && (
                  <a
                    href={url || ''}
                    target="_blank"
                    rel="noreferrer"
                    className={`mt-2 mt-md-0 ${styles.pdfButton}`}
                  >
                    Print
                  </a>
                )
              }
            </BlobProvider>
            <div
              className={'d-flex align-items-center ms-4 pointer'}
              onClick={() => {
                onCloseModal(false);
              }}
            >
              <CloseIcon />
              <h6 className={'text-danger ms-2 mb-1'}>Close</h6>
            </div>
          </div>
        </div>
        <div className={'mt-3'}>
          <div className={styles.dividedLine} />
        </div>
        <div className={'d-flex justify-content-between mt-3'}>
          <div className={'d-flex'}>
            <p className={'primary-color-text'}>TRIAL:</p>
            <div className={'ms-2'} />
            <p>{futureTrialName}</p>
          </div>
          <div>
            <Label
              labelSuccess={keepCullCompleteStatus === 100}
              labelText={`${keepCullCompleteStatus}% COMPLETE`}
            />
          </div>
        </div>
        <table className="table table-striped table-hover mt-4">
          <thead className="table-primary">
            <tr>
              <th scope="col">
                <TableHeading
                  field={MissingViewCol.RANGE}
                  sortable={true}
                  onSort={(sortDirection, title) =>
                    handleOnSortFutureMissing(sortDirection, title)
                  }
                />
              </th>
              <th scope="col">
                <TableHeading
                  field={MissingViewCol.ROW}
                  sortable={true}
                  onSort={(sortDirection, title) =>
                    handleOnSortFutureMissing(sortDirection, title)
                  }
                />
              </th>
              <th scope="col">
                <TableHeading
                  field={MissingViewCol.TRIALNAME}
                  sortable={true}
                  onSort={(sortDirection, title) =>
                    handleOnSortFutureMissing(sortDirection, title)
                  }
                />
              </th>
              <th scope="col">
                <TableHeading
                  field={MissingViewCol.VAREITYNAME}
                  sortable={true}
                  onSort={(sortDirection, title) =>
                    handleOnSortFutureMissing(sortDirection, title)
                  }
                />
              </th>
              <th scope="col">
                <TableHeading
                  field={MissingViewCol.BARCODE}
                  sortable={true}
                  onSort={(sortDirection, title) =>
                    handleOnSortFutureMissing(sortDirection, title)
                  }
                />
              </th>
              <th scope="col">
                <TableHeading
                  field={MissingViewCol.PLOT}
                  sortable={true}
                  onSort={(sortDirection, title) =>
                    handleOnSortFutureMissing(sortDirection, title)
                  }
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {futureMissingData?.map((values, index) => {
              return (
                <tr key={index}>
                  <td>
                    <p className={styles.rowText}>{values.range}</p>
                  </td>
                  <td>
                    <p className={styles.rowText}>{values.row}</p>
                  </td>
                  <td>
                    <p className={styles.rowText}>{values.trialName}</p>
                  </td>
                  <td>
                    <p className={styles.rowText}>{values.name}</p>
                  </td>
                  <td>
                    <p className={styles.rowText}>{values.barcode}</p>
                  </td>
                  <td>
                    <p className={styles.rowText}>{values.plot}</p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {errorMessage && (
          <div>
            <h4 className={'mt-5 text-danger text-center'}>{errorMessage}</h4>
          </div>
        )}

        <div className={'mt-5'}>
          <div className={'d-flex justify-content-between'}>
            <div className={'d-flex align-items-center'}>
              <h6 className={`me-3 ${styles.rowTest}`}>Rows per page </h6>
              <select
                id="framework"
                className={` font-size-default ${styles.selectBox}`}
                value={pageSize}
                onChange={(e) => {
                  setPageSize(e.target.value as unknown as number);
                  setActivePage(1);
                }}
              >
                <option
                  value="05"
                  selected={true}
                  className={'font-size-default primary-color-text'}
                >
                  05
                </option>
                <option
                  value="10"
                  className={'font-size-default primary-color-text'}
                >
                  10
                </option>
                <option
                  value="15"
                  className={'font-size-default primary-color-text'}
                >
                  15
                </option>
                <option
                  value="20"
                  className={'font-size-default primary-color-text'}
                >
                  20
                </option>
                <option
                  value="100"
                  className={'font-size-default primary-color-text'}
                >
                  100
                </option>
                <option
                  value="200"
                  className={'font-size-default primary-color-text'}
                >
                  200
                </option>
              </select>
            </div>
            <PaginationPages
              hasPreviousPage={paginationData?.hasPreviousPage}
              hasNextPage={paginationData?.hasNextPage}
              clickPrevious={clickPrevious}
              clickNext={clickNext}
              setActivePage={(value) =>
                setActivePage(value as unknown as number)
              }
              currentPage={activePage}
              totalCount={paginationData?.itemCount ?? 10}
              pageSize={pageSize}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FutureMissingView;
