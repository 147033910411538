import React from 'react';

interface Props {
  size?: number;
  color?: string;
}

const WhiteCrossIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 7}
      height={size ?? 7}
      fill={color ?? 'none'}
      viewBox={'0 0 7 7'}
    >
      <path
        fill="#fff"
        d="M6.667.333a.792.792 0 0 0-1.12 0L3.5 2.381 1.453.333a.792.792 0 0 0-1.12 1.12L2.38 3.5.333 5.547a.792.792 0 0 0 1.12 1.12L3.5 4.619l2.047 2.048a.792.792 0 0 0 1.12-1.12L4.619 3.5l2.048-2.047a.792.792 0 0 0 0-1.12Z"
      />
    </svg>
  );
};

export default WhiteCrossIcon;
