import React from 'react';
import styles from './lable.module.scss';

interface Props {
  labelSuccess: boolean;
  labelText?: string;
}

const Label: React.FC<Props> = ({ labelSuccess, labelText }) => {
  return (
    <div
      className={`${
        labelSuccess ? styles.labelBoxSuccess : styles.labelBoxError
      }`}
    >
      <p>{labelText}</p>
    </div>
  );
};

export default Label;
