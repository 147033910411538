import * as React from 'react';
interface Props {
  size?: number;
  color?: string;
}
const ClockIcon: React.FC<Props> = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 16}
      height={size ?? 16}
      fill="none"
      viewBox={'0 0 16 16'}
    >
      <g clipPath="url(#a)">
        <path
          fill="#003359"
          d="M16 8A8 8 0 0 1 0 8a.667.667 0 1 1 1.333 0A6.667 6.667 0 1 0 8 1.333.667.667 0 1 1 8 0a8.009 8.009 0 0 1 8 8Zm-9.149-.667H5.333a.667.667 0 1 0 0 1.334h1.518A1.33 1.33 0 1 0 8.667 6.85V4.667a.667.667 0 0 0-1.334 0V6.85c-.2.117-.365.283-.482.482ZM1.218 5.856a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333Zm1.596-2.385a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333Zm2.372-1.577a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default ClockIcon;
