import * as React from 'react';
interface Props {
  size?: number;
}
const EditIcon: React.FC<Props> = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 16}
      height={size ?? 16}
      fill="none"
    >
      <g fill="#373737" clipPath="url(#a)">
        <path d="M12.438.62 4.31 8.748a3.31 3.31 0 0 0-.976 2.357V12a.667.667 0 0 0 .666.667h.896a3.308 3.308 0 0 0 2.356-.976l8.128-8.128a2.083 2.083 0 0 0 0-2.943 2.13 2.13 0 0 0-2.943 0Zm2 2L6.31 10.748a2.013 2.013 0 0 1-1.414.586h-.23v-.229c.002-.53.213-1.038.586-1.414l8.128-8.128a.765.765 0 0 1 1.057 0 .749.749 0 0 1 0 1.057Z" />
        <path d="M15.333 5.986a.667.667 0 0 0-.666.667V10H12a2 2 0 0 0-2 2v2.667H3.333a2 2 0 0 1-2-2V3.333a2 2 0 0 1 2-2h6.028a.667.667 0 1 0 0-1.333H3.333A3.337 3.337 0 0 0 0 3.333v9.334A3.337 3.337 0 0 0 3.333 16h7.562a3.312 3.312 0 0 0 2.358-.976l1.77-1.772A3.314 3.314 0 0 0 16 10.895V6.653a.666.666 0 0 0-.667-.667Zm-3.023 8.095a1.984 1.984 0 0 1-.977.534V12a.667.667 0 0 1 .667-.667h2.617a2.011 2.011 0 0 1-.534.976l-1.773 1.772Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default EditIcon;
