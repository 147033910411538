import * as React from 'react';
interface Props {
  size?: number;
  color?: string;
}
const DropdownArrow: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 15}
      height={size ?? 7}
      fill="none"
      viewBox={'0 0 15 7'}
    >
      <path
        fill={color ?? '#003359'}
        d="M7.616 6.943A2.846 2.846 0 0 1 5.6 6.112L.95 1.462a.572.572 0 0 1 .807-.81L6.41 5.304a1.708 1.708 0 0 0 2.414 0l4.65-4.65a.571.571 0 1 1 .809.808L9.63 6.111a2.846 2.846 0 0 1-2.015.832Z"
      />
    </svg>
  );
};
export default DropdownArrow;
