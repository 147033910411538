import {
  FolderRequest,
  ImageFolderResponse,
  SortDataList,
} from '../../typeDef/image.model';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { downloadFolder, getImageFolder } from '../../services/images.service';

import ActionIcon from '../../icons/action.icon';

import CloudDownloadIcon from '../../icons/cloudDownload.icon';
import CommonLayout from '../../components/layout/commonLayout';
import CustomButton from '../../components/shared/customButton/customButton';
import CustomDropdown from '../../components/shared/customDropdown/customDropdown';
import CustomModal from '../../components/shared/customModal/customModal';
import EyeIcon from '../../icons/eye.icon';
import Folder from '../../images/folder.png';
import JSZip from 'jszip';
import LoadingSpinner from '../../components/shared/loadingSpinner/loadingSpinner';
import PaginationPages from '../../components/shared/pagination/paginationPages';
import { PaginationType } from '../../typeDef/common.model';
import Popup from 'reactjs-popup';
import SearchIcon from '../../icons/search.icon';
import { SortDirection } from '../../components/shared/tableHeading/tableHeading';
import loadingStyles from './../../components/shared/loadingSpinner/loadingSpinner.module.scss';
import styles from '../../components/table/userList/userListTable.module.scss';
import { useNavigate } from 'react-router-dom';

enum SortType {
  TRIALNAME = 'trialName',
}
export default function ImageManager() {
  const [paginationData, setPaginationData] = useState<PaginationType>();
  const [selectAll, setSelectAll] = useState(false);
  const [folderData, setFolderData] = useState<ImageFolderResponse>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectedFolders, setSelectedFolders] = useState<string[]>([]);
  const [selectedFolderSize, setSelectedFolderSize] = useState<number[]>([]);
  const [searchField, setSearchField] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [sortValue, setSortValue] = useState('');
  const sortField = useRef(SortType.TRIALNAME);
  const [pageSize, setPageSize] = useState(10);
  const [activePage, setActivePage] = useState<number>(1);
  const [modalErrorMessage, setModalErrorMessage] = useState('');
  const [errorModal, setErrorModal] = useState(false);
  const navigate = useNavigate();

  const dataList = [
    { text: SortDataList.ALPHABETICAL_A_Z, value: '0' },
    { text: SortDataList.ALPHABETICAL_Z_A, value: '1' },
  ];

  /* click previous */
  const clickPrevious = () => {
    if (paginationData?.hasPreviousPage) {
      setActivePage(activePage - 1);
    }
  };

  /* click next */
  const clickNext = () => {
    if (paginationData?.hasNextPage) {
      setActivePage(activePage + 1);
    }
  };

  const handleChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearchField(e.target.value);
  };

  const getAllFolders = useCallback(async () => {
    setIsLoading(true);
    setSelectedItems([]);
    setSelectedFolders([]);
    setSelectAll(false);
    try {
      const query: Partial<FolderRequest> = {
        pageNumber: activePage,
        pageSize: pageSize,
        sortOrder:
          sortValue === SortDataList.ALPHABETICAL_Z_A
            ? SortDirection.DESC
            : SortDirection.ASC,
        sortField: sortField.current ?? SortType.TRIALNAME,
        ...(searchField && {
          trialName: searchField.toUpperCase().trim(),
        }),
      };
      const response = await getImageFolder(query);
      setErrorMessage('');
      setFolderData(response.data);
      setPaginationData(response.data.meta);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setErrorMessage('No Image Folders Found.');
    }
  }, [pageSize, activePage, searchField, sortField, sortValue]);

  const handleFolderDownload = async (
    trialName: string,
    folderSize: number,
    sizeUnit: string
  ) => {
    setIsLoading(true);

    const fileSizeInMB =
      sizeUnit === 'MB'
        ? folderSize
        : sizeUnit === 'KB'
        ? folderSize / 1024
        : folderSize / (1024 * 1024);

    if (fileSizeInMB > 500) {
      setModalErrorMessage(
        "The image folder's size has exceeded the maximum limit of 500 MB. Downloading is currently restricted. Please contact the administrator."
      );
      setErrorModal(true);
      setIsLoading(false);
      return;
    } else {
      try {
        const response = await downloadFolder(trialName);

        // Check if the response status is OK (200)
        if (response.status === 200) {
          // Create a ReadableStream from the response data
          const stream = response.data;

          // Create a Blob from the stream
          const blob = new Blob([stream], { type: 'application/octet-stream' });

          // Create an anchor element and set attributes to trigger the download
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = trialName + '.zip'; // Set the zip file name
          a.style.display = 'none';
          document.body.appendChild(a);
          a.click();

          // Clean up
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        } else {
          // Handle non-OK response status here, e.g., show an error message

          setModalErrorMessage('Download request failed.');
          setErrorModal(true);
        }

        setIsLoading(false);
      } catch (e) {
        // Handle any exceptions here, e.g., show an error message
        setModalErrorMessage('Error in downloading.');
        setErrorModal(true);
        setIsLoading(false);
      }
    }
  };

  const handleSelectedFoldersDownload = async () => {
    setIsLoading(true);

    const totalCountOfFolderSizes = selectedFolderSize.reduce(
      (sum, value) => sum + value,
      0
    );

    if (selectedFolders.length > 0) {
      if (Math.round(totalCountOfFolderSizes) > 500) {
        setModalErrorMessage(
          "The image folder's size has exceeded the maximum limit of 500 MB. Downloading is currently restricted. Please contact the administrator."
        );
        setErrorModal(true);
        setIsLoading(false);
        return;
      } else {
        const mainzip = new JSZip();

        const exportPromises = selectedFolders.map(async (fileName) => {
          try {
            const response = await downloadFolder(fileName);
            if (response.data) {
              const stream = response.data;
              const blob = new Blob([stream], {
                type: 'application/octet-stream',
              });

              const nestedZip = new JSZip();
              await nestedZip.loadAsync(blob);
              // Extract the files from the nested zip and add them to the main zip
              for (const file in nestedZip.files) {
                if (!nestedZip.files[file].dir) {
                  const fileData = await nestedZip.files[file].async(
                    'arraybuffer'
                  );
                  mainzip.file(fileName + '/' + file, fileData);
                }
              }
            }
          } catch (e) {
            setModalErrorMessage('Error downloading file');
            setErrorModal(true);
          }
        });

        try {
          await Promise.all(exportPromises);

          const mainZipBlob = await mainzip.generateAsync({ type: 'blob' });

          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(mainZipBlob);
          downloadLink.download = 'exportedFiles.zip';

          downloadLink.click();
        } catch (error) {
          setErrorMessage('Error exporting files');
          setErrorModal(true);
        }
      }
    }
    setIsLoading(false);
  };

  const handleSearch = async () => {
    try {
      if (searchField === '' || activePage === 1) {
        await getAllFolders();
      }
      setActivePage(1);
    } catch (e) {
      setErrorMessage('Something went wrong');
    }
  };

  useEffect(() => {
    (async () => {
      await getAllFolders();
    })();
  }, [pageSize, activePage, sortValue]);
  const handleItemToggle = (
    index: number,
    trialName: string,
    folderSize: number,
    sizeUnit: string
  ) => {
    const fileSizeInMB =
      sizeUnit === 'MB'
        ? folderSize
        : sizeUnit === 'KB'
        ? folderSize / 1024
        : folderSize / (1024 * 1024);

    const selectedIndex = selectedItems.indexOf(index);
    if (selectedIndex === -1) {
      // If the item is not already selected, add it to the selected items and folders arrays.
      setSelectedFolders([...selectedFolders, trialName]);
      setSelectedItems([...selectedItems, index]);
      setSelectedFolderSize([...selectedFolderSize, fileSizeInMB]);
    } else {
      // If the item is already selected, remove it from both selected items and folders arrays.
      const updatedItems = [...selectedItems];
      updatedItems.splice(selectedIndex, 1);
      setSelectedItems(updatedItems);

      const updatedFolders = [...selectedFolders];
      updatedFolders.splice(selectedIndex, 1);
      setSelectedFolders(updatedFolders);

      const updatedFolderSizes = [...selectedFolderSize];
      updatedFolderSizes.splice(selectedIndex, 1);
      setSelectedFolderSize(updatedFolderSizes);
    }
  };
  const handleSelectAll = () => {
    if (selectAll) {
      // If all items are already selected, deselect all
      setSelectedItems([]);
      setSelectedFolders([]);
      setSelectedFolderSize([]);
    } else {
      // If not all items are selected, select all
      const allItemIndices = folderData?.items.map((_, index) => index);
      if (allItemIndices) {
        setSelectedItems(allItemIndices);
        if (folderData)
          setSelectedFolders(folderData?.items.map((item) => item.trialName));
        const total = folderData?.items.reduce(
          (accumulator, item) => accumulator + item.folderSize,
          0
        );
        if (total) {
          setSelectedFolderSize([total]);
        }
      }
    }
    setSelectAll(!selectAll); // Toggle the selectAll state
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const pressedKey = event.key;

    if (pressedKey === 'Enter') {
      event.preventDefault();

      await handleSearch();
    }
  };

  return (
    <CommonLayout admin title={'Image Manager'} selectedPage={'imageManager'}>
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        {isLoading && <LoadingSpinner />}
        <div className={`${styles.mainContent}`}>
          <div className={styles.subTitle}>
            <h6 className={'primary-color-text text-bolder'}>
              All Images and Folders
            </h6>
          </div>
          <div
            className={'d-flex justify-content-between align-items-center mt-5'}
          >
            <div className={'d-flex align-items-center'}>
              <div className={styles.searchBarContainer}>
                <input
                  type="search"
                  className={`form-control ${styles.searchImage}`}
                  placeholder="Search"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
                <label className={styles.iconSeach}>
                  <SearchIcon />
                </label>
              </div>

              <CustomButton
                text={'Search'}
                className={'ms-4 fit-content'}
                type={'button'}
                onClick={handleSearch}
              />
            </div>
            <div className={'d-flex align-items-center'}>
              <h6 className={styles.sortByText}>Sort by</h6>
              <CustomDropdown
                dataList={dataList}
                placeHolder={'Alphabetical (A-Z)'}
                setFieldValue={(value) => setSortValue(value)}
              />
            </div>
            <div className={'d-flex align-items-center'}>
              <CustomButton
                text={'Select All'}
                className={styles.buttonWidth}
                onClick={handleSelectAll}
              />

              <CustomButton
                text={'Download Selected'}
                variant={selectedItems.length > 0 ? 'secondary' : 'gray'}
                className={`${
                  selectedItems.length < 0 && 'pointer-events-none'
                } ms-3 ${styles.buttonWidth} `}
                onClick={handleSelectedFoldersDownload}
              />
            </div>
          </div>
          {errorMessage ? (
            <div>
              <h4 className={'mt-5 text-danger text-center'}>{errorMessage}</h4>
            </div>
          ) : (
            <div className={'row mt-5'}>
              {folderData?.items.map((values, index) => (
                <div
                  onClick={() =>
                    handleItemToggle(
                      index,
                      values.trialName,
                      values.folderSize,
                      values.sizeUnit
                    )
                  }
                  className={
                    'col-3 d-flex align-items-center justify-content-between mb-5'
                  }
                  key={index}
                >
                  <div className={'d-flex align-items-center'}>
                    <input
                      type="checkbox"
                      className="form-check-input me-3"
                      checked={selectedItems.includes(index)}
                    />
                    <img src={Folder} alt={'image folder'} />
                    <div className={'ms-3'}>
                      <p className={'font-size-default'}>{values.trialName}</p>
                      <p className={'font-size-default folderNameColor-text'}>
                        {values.imageCount} Files
                      </p>
                      <p className={'font-size-default folderNameColor-text'}>
                        {Math.round(values.folderSize)} {values.sizeUnit}
                      </p>
                    </div>
                  </div>
                  <Popup
                    trigger={
                      <a className={''}>
                        <ActionIcon />
                      </a>
                    }
                    position="left top"
                  >
                    <div className={styles.actionContainer}>
                      <a
                        className={'pointer text-decoration-none'}
                        onClick={() =>
                          navigate('/imageList', {
                            state: {
                              trialName: values.trialName,
                              imageCount: values.imageCount,
                            },
                          })
                        }
                      >
                        <ul
                          className={`d-flex justify-content-start align-items-center ${styles.popUpSelection}`}
                        >
                          <EyeIcon />
                          <h6 className={styles.linkText}>View Folder</h6>
                        </ul>
                      </a>

                      <a
                        className={'pointer text-decoration-none'}
                        onClick={() =>
                          handleFolderDownload(
                            values.trialName,
                            values.folderSize,
                            values.sizeUnit
                          )
                        }
                      >
                        <ul
                          className={`d-flex justify-content-start align-items-center ${styles.popUpSelection}`}
                        >
                          <CloudDownloadIcon size={16} />
                          <h6 className={styles.linkText}>Download</h6>
                        </ul>
                      </a>
                    </div>
                  </Popup>
                </div>
              ))}
            </div>
          )}

          <div className={'mt-5'}>
            <div className={'d-flex justify-content-between'}>
              <div className={'d-flex align-items-center'}>
                <h6 className={`me-3 ${styles.rowTest}`}>Rows per page </h6>
                <select
                  id="framework"
                  className={` font-size-default ${styles.selectBox}`}
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(e.target.value as unknown as number);
                    setActivePage(1);
                  }}
                >
                  <option
                    value="05"
                    className={'font-size-default primary-color-text'}
                  >
                    05
                  </option>
                  <option
                    value="10"
                    selected={true}
                    className={'font-size-default primary-color-text'}
                  >
                    10
                  </option>
                  <option
                    value="15"
                    className={'font-size-default primary-color-text'}
                  >
                    15
                  </option>
                  <option
                    value="20"
                    className={'font-size-default primary-color-text'}
                  >
                    20
                  </option>
                  <option
                    value="100"
                    className={'font-size-default primary-color-text'}
                  >
                    100
                  </option>
                  <option
                    value="200"
                    className={'font-size-default primary-color-text'}
                  >
                    200
                  </option>
                </select>
              </div>
              <PaginationPages
                hasPreviousPage={paginationData?.hasPreviousPage}
                hasNextPage={paginationData?.hasNextPage}
                clickPrevious={clickPrevious}
                clickNext={clickNext}
                setActivePage={(value) =>
                  setActivePage(value as unknown as number)
                }
                currentPage={activePage}
                totalCount={paginationData?.itemCount ?? 10}
                pageSize={pageSize}
              />
            </div>
          </div>
        </div>
      </div>
      {errorModal && (
        <CustomModal
          open={errorModal}
          title={'Error'}
          deleteTitle
          onCloseModal={(val) => setErrorModal(val)}
        >
          <div className={styles.modalWidth}>
            <h6 className={'light-text text-normal'}>{modalErrorMessage}</h6>
            <div
              className={'d-flex justify-content-end align-items-center mt-5'}
            >
              <CustomButton
                text={'OK'}
                onClick={() => setErrorModal(false)}
                className={styles.buttonWidth}
              />
            </div>
          </div>
        </CustomModal>
      )}
    </CommonLayout>
  );
}
