import React from 'react';

interface Props {
  size?: number;
  color?: string;
}

const UserPlusIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 17}
      height={size ?? 17}
      fill="none"
    >
      <g clipPath="url(#a)">
        <path
          fill={color ?? '#003359'}
          d="M15.833 7.834H14.5V6.501a.667.667 0 1 0-1.334 0v1.333h-1.333a.667.667 0 1 0 0 1.333h1.333v1.334a.666.666 0 1 0 1.334 0V9.167h1.333a.667.667 0 0 0 0-1.333Z"
        />
        <path
          fill="#373737"
          d="M6.5 8.5a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm0-6.667a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334ZM6.5 9.834a6.007 6.007 0 0 0-6 6 .667.667 0 1 0 1.333 0 4.666 4.666 0 1 1 9.334 0 .667.667 0 1 0 1.333 0 6.007 6.007 0 0 0-6-6Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.5.5h16v16H.5z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UserPlusIcon;
