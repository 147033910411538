import {
  MasterDataRequest,
  MasterDataResponse,
} from '../typeDef/masterData.model';
import { AxiosResponse } from 'axios';
import { backendAPI } from '../utils/axios';

export async function getMasterData(
  query?: MasterDataRequest
): Promise<AxiosResponse<MasterDataResponse[]>> {
  return backendAPI.get<MasterDataResponse[]>(`/master-data`, {
    params: query,
  });
}
