import * as Yup from 'yup';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import BrowserIcon from '../../icons/browser.icon';
import CustomButton from '../shared/customButton/customButton';
import CustomInputFiled from '../shared/customInputField/customInputFiled';
import barcodeScanner from '../../images/barcode-scanner.png';
import styles from './scanCodeContainer.module.scss';

import { useNavigate } from 'react-router-dom';

type ScanProps = {
  barcode: string;
};

type ModalProps = {
  onEndSession: () => void;
  onOk: (barcode: string) => void;
  onSetNote?: (value: boolean) => void;
  onSetImages?: (value: boolean) => void;
  setWarningModal?: (value: boolean) => void;
  isLoading?: boolean;
  dataLoading?: boolean;
  noteButtonVisible?: boolean;
  weightNoteButtonVisible?: boolean;
  source: string;
  disable: boolean;
};

export default function ScanCodeContainer({
  onEndSession,
  onSetNote,
  onSetImages,
  setWarningModal,
  onOk,
  isLoading,
  dataLoading,
  noteButtonVisible,
  weightNoteButtonVisible,
  source,
  disable,
}: ModalProps) {
  const navigate = useNavigate();
  const [scanConnect] = useState(true); // Need to add setScanConnect when implementing integration
  const [, setBarCode] = useState(''); // barcode
  const [isInputFocused, setInputFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const initialVal: ScanProps = useMemo(() => {
    return {
      barcode: '',
    };
  }, []);

  const validationSchema = Yup.object().shape({
    barcode: Yup.string().required('Please enter barcode'),
  });

  // const handleSubmit = async (values: { barcode: string }) => {
  //   onOk(values?.barcode.trim().toUpperCase());
  // };

  // const handleSubmit = async (
  //   values: { barcode: string },
  //   { setFieldValue }: any
  // ) => {
  //   onOk(values?.barcode.trim().toUpperCase());
  //   setFieldValue('barcode', '');
  //   setBarCode('');
  // };

  const handleSubmit = async (
    values: { barcode: string },
    { resetForm }: FormikHelpers<{ barcode: string }>
  ) => {
    onOk(values?.barcode.trim().toUpperCase());
    resetForm();
    setBarCode('');
    inputRef.current?.focus();
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBarCode(event.target.value);
  };

  const handleKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>,
    submitForm: () => void
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      submitForm();
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      inputRef.current &&
      inputRef.current instanceof Node &&
      !inputRef.current.contains(event.target as Node)
    ) {
      setInputFocused(true);
    }
  };

  useEffect(() => {
    if (!disable && inputRef.current) {
      inputRef.current?.focus();
      setInputFocused(true);
    }
  }, [disable]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <Formik
      initialValues={initialVal}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue }) => (
        <Form
          className={`${styles.mainContainer}`}
          style={disable ? { border: 'none' } : {}}
        >
          <div className={'d-flex justify-content-start'}>
            <img src={barcodeScanner} alt="barCode scanner" />
            {scanConnect ? (
              <h6 className={styles.mainText1}>Waiting for a New Scan</h6>
            ) : (
              <h6 className={styles.mainText2}>Scanner Not Connected</h6>
            )}
          </div>
          <div
            className={
              'mt-4 d-flex flex-wrap justify-content-between align-items-center'
            }
          >
            <div className={`d-flex ${styles.staticHeightModalContainer}`}>
              <div className={'w-100'}>
                <CustomInputFiled
                  name={'barcode'}
                  ref={inputRef}
                  placeholder={'Type the bar code for manual entry'}
                  icon={
                    <BrowserIcon
                      size={23}
                      color={disable ? '#7D7D7D' : '#003359'}
                    />
                  }
                  className={styles.barcodeInputfield}
                  keyPress={() => handleKeyDown}
                  autoFocus={true}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('barcode', e.target.value);
                    if (isInputFocused) {
                      handleInputChange(e);
                    } else {
                      handleInputChange(e);
                    }
                  }}
                  keepConsistentHeight
                  onFocus={() => setInputFocused(true)}
                  onBlur={() => setInputFocused(false)}
                  disabled={disable}
                  from={'scanContainer'}
                />
              </div>
              <div className={'w-25'}>
                <CustomButton
                  text={'OK'}
                  type="submit"
                  className={styles.heightBtn1}
                  loading={isLoading}
                  disabled={disable}
                />
              </div>
            </div>
            <h6 className={styles.subText1}>or</h6>
            <div
              className={
                'mb-3 d-flex justify-content-between align-items-center'
              }
            >
              <CustomButton
                text={'Stop Session'}
                className={styles.heightBtn1}
                onClick={() => {
                  onEndSession();
                  navigate(source);
                }}
                disabled={disable}
              />
              {noteButtonVisible && (
                <>
                  <CustomButton
                    text={'Save a Note'}
                    className={`ms-3 ${styles.heightBtn1}`}
                    onClick={() => {
                      if (onSetNote) {
                        onSetNote(true);
                      }
                    }}
                    disabled={disable}
                  />
                  <CustomButton
                    loading={dataLoading}
                    text={'View Images'}
                    className={`ms-3 ${styles.heightBtn1}`}
                    onClick={() => {
                      if (onSetImages) {
                        if (navigator.onLine) {
                          onSetImages(true);
                        } else {
                          if (setWarningModal) {
                            setWarningModal(true);
                          }
                        }
                      }
                    }}
                    disabled={disable}
                  />
                </>
              )}
              {weightNoteButtonVisible && (
                <CustomButton
                  text={'Save a Note'}
                  className={`ms-3 ${styles.heightBtn1}`}
                  onClick={() => {
                    if (onSetNote) {
                      onSetNote(true);
                    }
                  }}
                  disabled={disable}
                />
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
