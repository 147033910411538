import { WeightTrait } from '../typeDef/grain.model';

/**
 * Check if the given trait involves imaging.
 * @param trait The trait to check.
 * @returns True if the trait is an imaging trait, false otherwise.
 */
export function isImagingTrait(trait: string | WeightTrait): boolean {
  return (
    trait === WeightTrait.IMAGING ||
    trait === WeightTrait.IMAGING_HLW ||
    trait === WeightTrait.IMAGING_HLW_SCREENINGS ||
    trait === WeightTrait.IMAGING_WEIGHT ||
    trait === WeightTrait.SCREENINGS_IMAGINGS
  );
}

export function getTraitString(selectedWeightTrait: string | null) {
  if (selectedWeightTrait === WeightTrait.YIELD) {
    return 'YIELD';
  } else if (selectedWeightTrait === WeightTrait.SCREENS) {
    return 'SCREENS';
  } else if (selectedWeightTrait === WeightTrait.HLW) {
    return 'HLW';
  } else if (selectedWeightTrait === WeightTrait.HLW_SCREENS) {
    return 'HLW+SCREENS';
  } else if (selectedWeightTrait === WeightTrait.IMAGING) {
    return 'IMAGING';
  } else if (selectedWeightTrait === WeightTrait.IMAGING_HLW) {
    return 'IMAGING+HLW';
  } else if (selectedWeightTrait === WeightTrait.IMAGING_WEIGHT) {
    return 'IMAGING+WEIGHT';
  } else if (selectedWeightTrait === WeightTrait.IMAGING_HLW_SCREENINGS) {
    return 'IMAGING+HLW+SCREENS';
  } else if (selectedWeightTrait === WeightTrait.SCREENINGS_IMAGINGS) {
    return 'SCREENS+IMAGING';
  }
}

export function getInitialsHlwLowerLimitValues(
  selectedWeightTrait: string | null
) {
  if (
    selectedWeightTrait === WeightTrait.HLW ||
    selectedWeightTrait === WeightTrait.HLW_SCREENS ||
    selectedWeightTrait === WeightTrait.IMAGING_HLW ||
    selectedWeightTrait === WeightTrait.IMAGING_HLW_SCREENINGS
  ) {
    return 250;
  } else {
    return 0;
  }
}

export function getInitialsHlwUpperLimitValues(
  selectedWeightTrait: string | null
) {
  if (
    selectedWeightTrait === WeightTrait.HLW ||
    selectedWeightTrait === WeightTrait.HLW_SCREENS ||
    selectedWeightTrait === WeightTrait.IMAGING_HLW ||
    selectedWeightTrait === WeightTrait.IMAGING_HLW_SCREENINGS
  ) {
    return 450;
  } else {
    return 0;
  }
}

export function getInitialsSampleLowerLimitValues(
  selectedWeightTrait: string | null
) {
  if (
    selectedWeightTrait === WeightTrait.IMAGING ||
    selectedWeightTrait === WeightTrait.IMAGING_WEIGHT ||
    selectedWeightTrait === WeightTrait.IMAGING_HLW ||
    selectedWeightTrait === WeightTrait.IMAGING_HLW_SCREENINGS ||
    selectedWeightTrait === WeightTrait.SCREENINGS_IMAGINGS
  ) {
    return 10;
  } else {
    return 0;
  }
}

export function getInitialsSampleUpperLimitValues(
  selectedWeightTrait: string | null
) {
  if (
    selectedWeightTrait === WeightTrait.IMAGING ||
    selectedWeightTrait === WeightTrait.IMAGING_WEIGHT ||
    selectedWeightTrait === WeightTrait.IMAGING_HLW ||
    selectedWeightTrait === WeightTrait.IMAGING_HLW_SCREENINGS ||
    selectedWeightTrait === WeightTrait.SCREENINGS_IMAGINGS
  ) {
    return 50;
  } else {
    return 0;
  }
}

export function formatPercentage(percentage: string) {
  percentage = percentage.replace('%', '');

  const roundedPercentage = parseFloat(percentage).toFixed(1);

  return roundedPercentage + '%';
}
