import React, { ReactElement } from 'react';
import BackgroundImage from '../../../images/profileBackground.png';
import CustomButton from '../customButton/customButton';
import getInitials from '../../../common/users';
import styles from './customImageUpload.module.scss';

type inputFiledProps = {
  id: string;
  filedText?: string;
  fileSelect?: (e: File) => void;
  handleDelete?: () => void;
  loading?: boolean;
  // buttonStatus?: string;
  disabled?: boolean;
  icon?: ReactElement;
  defaultFile?: string;
  maxSize?: number;
  onError?: (e: string) => void;
  oversizeError?: string;
  imageUrl?: Blob;
  userFullName?: string;
  changeDisable?: boolean;
};

const CustomImageUpload = ({
  changeDisable,
  fileSelect,
  maxSize,
  onError,
  oversizeError,
  handleDelete,
  imageUrl,
  loading,
  userFullName,
}: inputFiledProps) => {
  const [file, setFile] = React.useState<Blob>();

  const handleImageSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      maxSize &&
      e.target.files?.[0].size &&
      e.target.files?.[0].size > maxSize
    ) {
      onError?.(oversizeError || 'Image size must be less than 5MB.');
      return;
    }
    setFile(e.target.files?.[0]);
    if (e.target.files?.[0]) {
      if (fileSelect && e.target.files?.[0]) {
        fileSelect(e.target.files?.[0]);
      }
    }
  };

  function handleDragOver(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
  }

  function handleDrop(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();

    if (e.dataTransfer.items && e.dataTransfer.items[0]) {
      const item = e.dataTransfer.items[0];
      const File = item.getAsFile();
      if (item.kind === 'file') {
        if (file) {
          if (maxSize && File?.size && File.size > maxSize) {
            onError?.(oversizeError || 'Image size must be less than 5MB.');
            return;
          }
          if (!File?.type.includes('image')) {
            onError?.('File is not an image');
            return;
          }
          setFile(File);
          if (fileSelect) {
            fileSelect(File);
          }
        }
      }
    }
  }

  const handleDeleteImage = async () => {
    setFile(undefined);
    if (handleDelete) {
      await handleDelete();
    }
  };

  return (
    <div
      className={styles.imageUpload}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      {!file ? (
        <div className={styles.imageSize}>
          {imageUrl ? (
            <img
              src={URL.createObjectURL(imageUrl)}
              className={styles.imageSize}
              alt={'avatarSelected'}
            />
          ) : (
            <>
              <img
                src={BackgroundImage}
                className={styles.backgroundImage}
                alt={'BackgroundImage'}
              />
              <h2 className={`text-white text-bolder ${styles.imageText}`}>
                {getInitials(userFullName)}
              </h2>
            </>
          )}
        </div>
      ) : (
        <img
          src={URL.createObjectURL(file)}
          className={styles.imageSize}
          alt={'avatarSelected'}
        />
      )}
      <label htmlFor={'image-upload'} className={styles.textFiled}>
        <input
          type="file"
          id={'image-upload'}
          style={{ display: 'none' }}
          accept=".png ,.jpeg"
          onChange={handleImageSelect}
        />
        {!changeDisable && (
          <div className={`ms-3 ${styles.uploadIcon}`}>
            <CustomButton
              text={'Change Photo'}
              onClick={() => document.getElementById('image-upload')?.click()}
              loading={loading}
            />
            <CustomButton
              text={'Reset to Default'}
              variant={'secondary'}
              className={'mt-2'}
              type={'button'}
              onClick={handleDeleteImage}
            />
          </div>
        )}
      </label>
    </div>
  );
};

export default CustomImageUpload;
