import React from 'react';

interface Props {
  size?: number;
  color?: string;
}

const hideEyeIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 16}
      height={size ?? 17}
      fill="none"
      viewBox={'0 0 16 17'}
    >
      <g clipPath="url(#a)">
        <path
          fill={color ?? '#003359'}
          d="M15.514 6.78a10.577 10.577 0 0 0-2.247-2.607l1.866-1.866a.667.667 0 0 0-.942-.943l-2.03 2.033A8.036 8.036 0 0 0 8 2.27c-4.127 0-6.48 2.825-7.514 4.51a3.272 3.272 0 0 0 0 3.44 10.578 10.578 0 0 0 2.247 2.607L.867 14.693a.667.667 0 1 0 .942.943L3.844 13.6A8.035 8.035 0 0 0 8 14.73c4.127 0 6.48-2.825 7.514-4.51a3.272 3.272 0 0 0 0-3.44ZM1.622 9.522a1.945 1.945 0 0 1 0-2.046c.89-1.444 2.9-3.874 6.378-3.874a6.734 6.734 0 0 1 3.177.777L9.835 5.722a3.328 3.328 0 0 0-4.613 4.613l-1.54 1.54a9.149 9.149 0 0 1-2.06-2.352ZM10 8.5a2 2 0 0 1-2 2 1.968 1.968 0 0 1-.857-.2L9.8 7.643c.13.267.199.56.2.857Zm-4 0a2 2 0 0 1 2-2c.297.001.59.07.857.2L6.2 9.357A1.967 1.967 0 0 1 6 8.5Zm8.378 1.023c-.89 1.444-2.9 3.874-6.378 3.874a6.734 6.734 0 0 1-3.177-.777l1.342-1.342a3.328 3.328 0 0 0 4.613-4.613l1.54-1.54a9.147 9.147 0 0 1 2.06 2.352 1.945 1.945 0 0 1 0 2.046Z"
        />
        <path
          fill="#373737"
          fillOpacity={0.2}
          d="M15.514 6.78a10.577 10.577 0 0 0-2.247-2.607l1.866-1.866a.667.667 0 0 0-.942-.943l-2.03 2.033A8.036 8.036 0 0 0 8 2.27c-4.127 0-6.48 2.825-7.514 4.51a3.272 3.272 0 0 0 0 3.44 10.578 10.578 0 0 0 2.247 2.607L.867 14.693a.667.667 0 1 0 .942.943L3.844 13.6A8.035 8.035 0 0 0 8 14.73c4.127 0 6.48-2.825 7.514-4.51a3.272 3.272 0 0 0 0-3.44ZM1.622 9.522a1.945 1.945 0 0 1 0-2.046c.89-1.444 2.9-3.874 6.378-3.874a6.734 6.734 0 0 1 3.177.777L9.835 5.722a3.328 3.328 0 0 0-4.613 4.613l-1.54 1.54a9.149 9.149 0 0 1-2.06-2.352ZM10 8.5a2 2 0 0 1-2 2 1.968 1.968 0 0 1-.857-.2L9.8 7.643c.13.267.199.56.2.857Zm-4 0a2 2 0 0 1 2-2c.297.001.59.07.857.2L6.2 9.357A1.967 1.967 0 0 1 6 8.5Zm8.378 1.023c-.89 1.444-2.9 3.874-6.378 3.874a6.734 6.734 0 0 1-3.177-.777l1.342-1.342a3.328 3.328 0 0 0 4.613-4.613l1.54-1.54a9.147 9.147 0 0 1 2.06 2.352 1.945 1.945 0 0 1 0 2.046Z"
        />
        <path
          fill="#373737"
          fillOpacity={0.2}
          d="M15.514 6.78a10.577 10.577 0 0 0-2.247-2.607l1.866-1.866a.667.667 0 0 0-.942-.943l-2.03 2.033A8.036 8.036 0 0 0 8 2.27c-4.127 0-6.48 2.825-7.514 4.51a3.272 3.272 0 0 0 0 3.44 10.578 10.578 0 0 0 2.247 2.607L.867 14.693a.667.667 0 1 0 .942.943L3.844 13.6A8.035 8.035 0 0 0 8 14.73c4.127 0 6.48-2.825 7.514-4.51a3.272 3.272 0 0 0 0-3.44ZM1.622 9.522a1.945 1.945 0 0 1 0-2.046c.89-1.444 2.9-3.874 6.378-3.874a6.734 6.734 0 0 1 3.177.777L9.835 5.722a3.328 3.328 0 0 0-4.613 4.613l-1.54 1.54a9.149 9.149 0 0 1-2.06-2.352ZM10 8.5a2 2 0 0 1-2 2 1.968 1.968 0 0 1-.857-.2L9.8 7.643c.13.267.199.56.2.857Zm-4 0a2 2 0 0 1 2-2c.297.001.59.07.857.2L6.2 9.357A1.967 1.967 0 0 1 6 8.5Zm8.378 1.023c-.89 1.444-2.9 3.874-6.378 3.874a6.734 6.734 0 0 1-3.177-.777l1.342-1.342a3.328 3.328 0 0 0 4.613-4.613l1.54-1.54a9.147 9.147 0 0 1 2.06 2.352 1.945 1.945 0 0 1 0 2.046Z"
        />
        <path
          fill="#373737"
          fillOpacity={0.2}
          d="M15.514 6.78a10.577 10.577 0 0 0-2.247-2.607l1.866-1.866a.667.667 0 0 0-.942-.943l-2.03 2.033A8.036 8.036 0 0 0 8 2.27c-4.127 0-6.48 2.825-7.514 4.51a3.272 3.272 0 0 0 0 3.44 10.578 10.578 0 0 0 2.247 2.607L.867 14.693a.667.667 0 1 0 .942.943L3.844 13.6A8.035 8.035 0 0 0 8 14.73c4.127 0 6.48-2.825 7.514-4.51a3.272 3.272 0 0 0 0-3.44ZM1.622 9.522a1.945 1.945 0 0 1 0-2.046c.89-1.444 2.9-3.874 6.378-3.874a6.734 6.734 0 0 1 3.177.777L9.835 5.722a3.328 3.328 0 0 0-4.613 4.613l-1.54 1.54a9.149 9.149 0 0 1-2.06-2.352ZM10 8.5a2 2 0 0 1-2 2 1.968 1.968 0 0 1-.857-.2L9.8 7.643c.13.267.199.56.2.857Zm-4 0a2 2 0 0 1 2-2c.297.001.59.07.857.2L6.2 9.357A1.967 1.967 0 0 1 6 8.5Zm8.378 1.023c-.89 1.444-2.9 3.874-6.378 3.874a6.734 6.734 0 0 1-3.177-.777l1.342-1.342a3.328 3.328 0 0 0 4.613-4.613l1.54-1.54a9.147 9.147 0 0 1 2.06 2.352 1.945 1.945 0 0 1 0 2.046Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 .5h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default hideEyeIcon;
