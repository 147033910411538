import {
  KeepCullRequest,
  KeepOrCull,
  UpdatedGrainResponse,
  UploadImagesResponse,
  WeighGrainEntry,
  WeighGrainRequest,
  Weight,
} from '../typeDef/grain.model';
import { getTraitString, isImagingTrait } from '../common/grains';
import { AxiosResponse } from 'axios';
import { backendAPI } from '../utils/axios';
import { logger } from '../logger';

const DB_NAME = 'agtDB';
const DB_VERSION = 8;

/**
 * A wrapper for an IDBRequest object.
 * Resolves with the request result when the onsuccess event occurs.
 * @param request The IDBRequest object.
 * @returns {Promise<T>} The request result.
 * @throws If and what the IDBRequest throws.
 */
export function awaitRequest<T>(request: IDBRequest): Promise<T> {
  return new Promise((resolve, reject) => {
    request.onerror = (event) => {
      reject(event);
    };

    request.onsuccess = () => {
      resolve(request.result);
    };
  });
}

/**
 * Opens the indexed DB with the app specific name and version.
 * If an upgrade is required, automatically clears the DB and
 * creates the required stores and indexes.
 * @returns {Promise<IDBDatabase>} A database instance.
 */
export async function openDatabase(): Promise<IDBDatabase> {
  const request = indexedDB.open(DB_NAME, DB_VERSION);

  return new Promise((resolve, reject) => {
    // The DB could not be opened
    request.onerror = (event) => {
      reject(event);
    };

    // The DB is ready to use
    request.onsuccess = () => {
      resolve(request.result);
    };

    // This event will be fired if DB_VERSION is greater than the version
    // of the database stored in the user's browser. This will happen if
    // the user has previously used an older version of the app.
    request.onupgradeneeded = () => {
      const db = request.result;

      // Clear the database
      const storesNames = db.objectStoreNames;
      for (let i = 0; i < storesNames.length; i++) {
        const store = storesNames.item(i);
        if (store === null) {
          continue;
        }
        db.deleteObjectStore(store);
      }

      // Create an objectStore for the samples
      const samples = db.createObjectStore('samples', {
        keyPath: 'id',
      });
      samples.createIndex('barcode', 'barcode', { unique: true });
      samples.createIndex('trialName', 'trialName', { unique: false });
      samples.createIndex(
        'trialName, scannedWeight',
        ['trialName', 'scannedWeight'],
        {
          unique: false,
        }
      );
      samples.createIndex(
        'trialName, scannedKeepCull',
        ['trialName', 'scannedKeepCull'],
        {
          unique: false,
        }
      );
      samples.createIndex(
        'trialName, scannedKeepCullPrint',
        ['trialName', 'scannedKeepCullPrint'],
        {
          unique: false,
        }
      );
      samples.createIndex(
        'trialName, scannedWeight, future',
        ['trialName', 'scannedWeight', 'future'],
        {
          unique: false,
        }
      );
      samples.createIndex(
        'trialName, scannedKeepCull, future',
        ['trialName', 'scannedKeepCull', 'future'],
        {
          unique: false,
        }
      );
      samples.createIndex(
        'trialName, scannedKeepCullPrint, future',
        ['trialName', 'scannedKeepCullPrint', 'future'],
        {
          unique: false,
        }
      );

      // Create an objectStore for the weights
      db.createObjectStore('weights', {
        keyPath: 'id',
        autoIncrement: true,
      });

      // Create an objectStore for the keep/cull entries
      db.createObjectStore('keepCull', {
        keyPath: 'id',
        autoIncrement: true,
      });

      // Wait for the database to be ready
      const transaction = request.transaction;
      if (!transaction) {
        return resolve(db);
      }
      transaction.oncomplete = () => {
        resolve(db);
      };
      transaction.onerror = (e) => {
        reject(e);
      };
    };
  });
}

/**
 * Get the number of entries in the local database that have not been synced with the backend.
 * @returns {Promise<number>} The number of entries in the local database that have not been synced.
 */
export async function getUnsyncedData(): Promise<number> {
  const db = await openDatabase();
  const weights = await awaitRequest<Weight[]>(
    db.transaction(['weights'], 'readwrite').objectStore('weights').getAll()
  );
  const keepOrCulls = await awaitRequest<KeepOrCull[]>(
    db.transaction(['keepCull'], 'readwrite').objectStore('keepCull').getAll()
  );
  return weights.length + keepOrCulls.length;
}

/**
 * Checks if the local database is empty. This function should be called when the user logs out.
 * @returns {Promise<boolean>} True if the database is empty, false otherwise.
 */
export async function isUnsyncedData(): Promise<boolean> {
  const count = await getUnsyncedData();
  return count > 0;
}

/**
 * Syncs the weights data in the  local database with the backend.
 * This function should be called periodically when the user is online.
 * @returns {Promise<void>} A promise that resolves when the sync is complete.
 */
export async function syncWeightData(): Promise<void> {
  try {
    // Get all weights that have not been synced
    const db = await openDatabase();
    const weights = await awaitRequest<Weight[]>(
      db.transaction(['weights'], 'readwrite').objectStore('weights').getAll()
    );

    // Skip if there are no weight entries
    if (weights.length === 0) {
      return;
    }

    // Group the weights by trait, plotArea, cracked, and unit
    const weightsByTrait: {
      [key: string]: {
        weights: WeighGrainEntry[];
        ids: string[];
        images: File[];
      };
    } = {};
    await Promise.all(
      weights.map(async (weight) => {
        // Create the key based on the trait, plotArea, cracked, and unit
        const key = `${weight.trait}-${weight.plotArea}-${
          weight.hectolitrePlotArea
        }-${
          weight.crackedProportion !== undefined &&
          weight.crackedProportion !== null &&
          weight.crackedProportion !== 0 &&
          !isNaN(weight.crackedProportion)
        }-${weight.unit}`;

        // Add the result to the weightsByTrait object
        if (!weightsByTrait[key]) {
          weightsByTrait[key] = { weights: [], ids: [], images: [] };
        }
        // Keep track of the local ID so we can delete the entry from the local database
        // The local ID is separate to the sample ID in case a sample is weighed twice
        weightsByTrait[key].ids.push(weight.id);

        // This is the data that is sent to the backend
        weightsByTrait[key].weights.push({
          id: weight.sampleId,
          ...(weight.sampleWeight && { sample: Number(weight.sampleWeight) }),
          ...(weight.aboveWeight && { above: Number(weight.aboveWeight) }),
          ...(weight.belowWeight && { below: Number(weight.belowWeight) }),
          ...(weight.hectolitreWeight && {
            hectolitre: Number(weight.hectolitreWeight),
          }),
          ...(weight.bagWeight && { bag: Number(weight.bagWeight) }),
          ...(weight.crackedProportion && {
            cracked: weight.crackedProportion,
          }),
          ...(weight.imageNote && { imageNotes: weight.imageNote }),
          ...(weight.weightNote && { weightNotes: weight.weightNote }),
          ...(weight.crackedNote && { crackedNotes: weight.crackedNote }),
        });

        // If the trait is an imaging trait, keep track of the images separately
        // These are sent in a separate request
        if (isImagingTrait(weight.trait)) {
          weightsByTrait[key].images.push(
            await new Promise<File>((resolve) => {
              fetch(weight.image ?? '')
                .then((res) => res.blob())
                .then((blob) => {
                  resolve(
                    new File([blob], 'image.jpeg', {
                      type: 'image/jpeg',
                    })
                  );
                });
            })
          );
        }
      })
    );

    // Send any images first
    await Promise.all(
      Object.keys(weightsByTrait).map(async (key) => {
        const [trait] = key.split('-');
        if (isImagingTrait(trait)) {
          const formData = new FormData();
          weightsByTrait[key].images.forEach((image) =>
            formData.append('images', image)
          );

          // Send the request
          const result = await backendAPI.post<UploadImagesResponse>(
            '/images',
            formData
          );

          // Check if the request was successful
          if (result.data.paths.length !== weightsByTrait[key].weights.length) {
            throw new Error('Not all images were saved');
          }

          // Add the paths to the weights entries
          weightsByTrait[key].weights = weightsByTrait[key].weights.map(
            (weight, index) => ({
              ...weight,
              imagePath: result.data.paths[index],
            })
          );
        }
      })
    );

    // Send the weights to the backend
    await Promise.all(
      Object.keys(weightsByTrait).map(async (key) => {
        const [trait, plotArea, hectolitrePlotArea, cracked, unit] =
          key.split('-');

        // Create the request body
        const body: WeighGrainRequest = {
          trait: getTraitString(trait) ?? '',
          plotArea: Number(plotArea),
          hectolitrePlotArea: Number(hectolitrePlotArea),
          cracked: cracked === 'true',
          unit: unit.toUpperCase(),
          weights: weightsByTrait[key].weights,
          //workStation: 'workStation',
        };

        // Send the request
        const result = await backendAPI.post<
          UpdatedGrainResponse,
          AxiosResponse<UpdatedGrainResponse>,
          WeighGrainRequest
        >('/grain/weigh', body);

        // Check if the request was successful
        if (result.data.grain.length !== weightsByTrait[key].weights.length) {
          throw new Error('Not all weights were saved');
        }

        // Clear the weights from the local database
        const store = db
          .transaction(['weights'], 'readwrite')
          .objectStore('weights');
        await Promise.all(
          weightsByTrait[key].ids.map(async (id) =>
            awaitRequest(store.delete(id))
          )
        );
      })
    );
  } catch (err) {
    logger.log(err);
    // One of the requests failed. This means either the user is now offline
    // or the backend is down. Either way, we can't sync the data.
  }
}

/**
 * Syncs the keep/cull data in the local database with the backend.
 * This function should be called periodically when the user is online.
 * @returns {Promise<void>} A promise that resolves when the sync is complete.
 */
export async function syncKeepCullData(): Promise<void> {
  try {
    // Get all keep/cull entries that have not been synced
    const db = await openDatabase();
    const keepCulls = await awaitRequest<KeepOrCull[]>(
      db.transaction(['keepCull'], 'readwrite').objectStore('keepCull').getAll()
    );

    // Skip if there are no keep/cull entries
    if (keepCulls.length === 0) {
      return;
    }

    // For each value of printedLabel (true and false), send a request to the backend
    // with the samples that have that value
    for (const value of [true, false]) {
      // Get only the samples where the printedLabel matches the value
      const samples = keepCulls
        .filter((keepCull) => keepCull.printedLabel === value)
        .map((keepCull) => keepCull.id);

      // Skip if there are no keep/cull entries for this value
      if (samples.length === 0) {
        continue;
      }

      // Send the request to the backend
      const result = await backendAPI.post<
        UpdatedGrainResponse,
        AxiosResponse<UpdatedGrainResponse>,
        KeepCullRequest
      >('/grain/keep-cull', {
        samples,
        printLabels: value,
      });

      // Check if the request was successful
      if (result.data.grain.length !== samples.length) {
        throw new Error('Not all keep/cull entries were saved');
      }
    }

    // Clear the keep/cull entries from the local database
    const store = db
      .transaction(['keepCull'], 'readwrite')
      .objectStore('keepCull');
    await Promise.all(
      keepCulls.map(async (keepCull) => awaitRequest(store.delete(keepCull.id)))
    );
  } catch (err) {
    logger.log(err);
    // One of the requests failed. This means either the user is now offline
    // or the backend is down. Either way, we can't sync the data.
  }
}

/**
 * Clear the local database.
 * This function should be called when the user logs out.
 * @returns {Promise<void>} A promise that resolves when the database is cleared.
 */
export async function clearData(): Promise<void> {
  const db = await openDatabase();
  await awaitRequest(
    db.transaction(['samples'], 'readwrite').objectStore('samples').clear()
  );
  await awaitRequest(
    db.transaction(['weights'], 'readwrite').objectStore('weights').clear()
  );
}
