import React, { ReactNode, useEffect, useState } from 'react';

import Modal from 'react-modal';
import TikIcon from '../../../icons/tik.icon';
import WarningIcon from '../../../icons/warning.icon';
import WhiteCrossIcon from '../../../icons/whiteCross.icon';
import styles from './customModal.module.scss';

export type CustomModalProps = {
  open: boolean;
  onCloseModal: (value: boolean) => void;
  children: ReactNode;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
  hideCloseButton?: boolean;
  className?: string;
  backgroundColor?: string;
  deleteTitle?: boolean;
  customWidth?: boolean;
  title?: string;
  success?: boolean;
  warning?: boolean;
};

Modal.setAppElement('body');

const CustomModal: React.FC<CustomModalProps> = ({
  open = false,
  success = false,
  children,
  onCloseModal,
  shouldCloseOnOverlayClick = false,
  shouldCloseOnEsc = false,
  deleteTitle = false,
  className,
  backgroundColor,
  customWidth,
  title,
  warning,
}) => {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
    if (open && document) {
      document.body.setAttribute('style', 'overflow: hidden');
    }

    return () =>
      document && document.body.setAttribute('style', 'overflow: unset');
  }, [open]);

  const handleClose = () => {
    setIsOpen(false);
    onCloseModal(false);
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      contentLabel="customModal"
      className={`${className} ${styles.customModal} ${
        customWidth ? styles.customWidth : null
      } ${
        backgroundColor === 'above'
          ? styles.aboveColor
          : backgroundColor === 'below'
          ? styles.belowColor
          : ''
      }`}
      overlayClassName={styles.modalOverlay}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
    >
      <div>
        {deleteTitle && title ? (
          <div className={styles.deleteTitle}>
            <div className={styles.redIcon}>
              <WhiteCrossIcon />
            </div>
            <h6 className={'text-bolder primary-color-text ms-3'}>{title}</h6>
          </div>
        ) : success && title ? (
          <div className={styles.deleteTitle}>
            <div className={styles.successIcon}>
              <TikIcon />
            </div>
            <h6 className={'text-bolder primary-color-text ms-3'}>{title}</h6>
          </div>
        ) : warning && title ? (
          <div className={styles.deleteTitle}>
            <div className={styles.warningIcon}>
              <WarningIcon />
            </div>
            <h6 className={'text-bolder primary-color-text ms-3'}>{title}</h6>
          </div>
        ) : (
          title && (
            <div className={styles.title}>
              <h6 className={'text-bolder primary-color-text'}>{title}</h6>
            </div>
          )
        )}

        {children}
      </div>
    </Modal>
  );
};

export default CustomModal;
