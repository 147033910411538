import * as React from 'react';
import DownIcon from '../../../icons/down.icon';
import UpIcon from '../../../icons/up.icon';
import { WeightAndKeepCullSummaryCol } from '../../../typeDef/grain.model';
import styles from './tableHeading.module.scss';
import { useState } from 'react';

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

interface Props {
  field: string;
  sortable: boolean;
  columnType?: string;
  action?: boolean;
  onSort?: (direction: SortDirection, title: string) => void;
}

const TableHeading: React.FC<Props> = ({
  field,
  sortable,
  columnType,
  action,
  onSort,
}) => {
  const [up, setUp] = useState(false);
  const [down, setDown] = useState(false);

  return (
    <div className={styles.tableHeading}>
      <div
        className={`d-flex flex-row ${action ? 'justify-content-center' : ''}`}
      >
        <div
          onClick={() => {
            if (up) {
              setUp(false);
              setDown(true);
            } else {
              setUp(true);
              setDown(false);
            }
            if (onSort) {
              onSort(up ? SortDirection.ASC : SortDirection.DESC, field);
            }
          }}
        >
          <p
            className={styles.headerText}
            style={columnType === 'dateFromUser' ? { width: '6vw' } : {}}
          >
            {field === WeightAndKeepCullSummaryCol.MISSING_W
              ? WeightAndKeepCullSummaryCol.MISSING
              : field === WeightAndKeepCullSummaryCol.COMPLETE_W
              ? WeightAndKeepCullSummaryCol.COMPLETE
              : field}
          </p>
        </div>
        {sortable && (
          <div
            className={`d-flex flex-column align-items-center justify-content-center`}
          >
            <div
              onClick={() => {
                setUp(true);
                setDown(false);
                if (onSort) {
                  onSort(SortDirection.ASC, field);
                }
              }}
              className={styles.triangle}
            >
              {!up ? <UpIcon /> : <UpIcon color={'#BEBEBE'} />}
            </div>
            <div
              onClick={() => {
                setUp(false);
                setDown(true);
                if (onSort) {
                  onSort(SortDirection.DESC, field);
                }
              }}
              className={styles.triangle}
            >
              {!down ? <DownIcon /> : <DownIcon color={'#BEBEBE'} />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TableHeading;
