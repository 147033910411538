import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import CloudDownloadIcon from '../../icons/cloudDownload.icon';
import CustomButton from '../shared/customButton/customButton';

import styles from './fileImport.module.scss';

interface Props {
  fileSelect?: (e: File) => void;
  setMessageText: (value: string) => void;
  setIsLoading: (value: boolean) => void;
  setIsSuccess: (value: boolean) => void;
  seIsError: (value: boolean) => void;
  setOpenModal: (value: boolean) => void;
  isLoading: boolean;
  isInputClear?: boolean;
  enableDrop: boolean;
  from?: string;
}

const FileImport: React.FC<Props> = ({
  fileSelect,
  setMessageText,
  setIsLoading,
  setIsSuccess,
  seIsError,
  setOpenModal,
  isLoading,
  isInputClear,
  enableDrop,
  from,
}) => {
  //const [, setExcelFile] = useState<Blob>(); // should add Excel file(excelFile)
  // drag state
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const dropActive = useRef<boolean>(false);

  useEffect(() => {
    dropActive.current = enableDrop;
  }, [enableDrop]);

  // handle drag events
  const handleDrag = function (e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = async function (e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (dropActive.current) {
      dropActive.current = false;
      const fileTypes = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/csv',
      ];
      if (
        e.dataTransfer?.files &&
        e.dataTransfer.files[0] &&
        fileTypes.includes(e.dataTransfer.files[0].type)
      ) {
        // if (e.dataTransfer.files[0].size <= 10 * 1024 * 1024) {
        //setExcelFile(e.dataTransfer.files[0]);
        if (fileSelect) {
          fileSelect(e.dataTransfer.files[0]);
        }
        // } else {
        //   setIsLoading(false);
        //   setIsSuccess(false);
        //   seIsError(true);
        //   setMessageText('File size exceeds the maximum allowed size (10MB).');
        //   setOpenModal(true);
        // }
      } else {
        setIsLoading(false);
        setIsSuccess(false);
        seIsError(true);
        setMessageText('Please select only excel file types.');
        setOpenModal(true);
      }
    }
  };

  // triggers when file is selected with click
  const handleChange = async function (e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    const fileTypes = [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'text/csv',
    ];
    if (
      e.target.files &&
      e.target.files[0] &&
      fileTypes.includes(e.target.files[0].type)
    ) {
      // if (e.target.files[0].size <= 10 * 1024 * 1024) {
      //setExcelFile(e.target.files[0]);
      if (fileSelect) {
        fileSelect(e.target.files[0]);
      }
      // } else {
      //   setIsLoading(false);
      //   setIsSuccess(false);
      //   seIsError(true);
      //   setMessageText('File size exceeds the maximum allowed size (10MB).');
      //   setOpenModal(true);
      // }
    } else {
      setIsLoading(false);
      setIsSuccess(false);
      seIsError(true);
      setMessageText('Please select only excel file types.');
      setOpenModal(true);
    }
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current?.click();
  };

  return (
    <div className={styles.container}>
      <form
        id="form-file-upload"
        //onDragEnter={handleDrag}
        ref={(form) => {
          if (form) {
            form.addEventListener('dragenter', handleDrag);
            form.addEventListener('dragleave', handleDrag);
            form.addEventListener('dragover', handleDrag);
            form.addEventListener('drop', handleDrop);
          }
        }}
        onSubmit={(e) => e.preventDefault()}
        className={'d-flex justify-content-center'}
      >
        <input
          ref={inputRef}
          key={isInputClear ? 'reset' : 'file-input'}
          type="file"
          id="input-file-upload"
          multiple={true}
          accept={from === 'userManager' ? '.csv' : '.xlsx'}
          className={'d-none'}
          onChange={handleChange}
        />
        <label
          id="label-file-upload"
          htmlFor="input-file-upload"
          className={dragActive ? styles.dragActive : ''}
        >
          <div className={'d-flex justify-content-center'}>
            <CloudDownloadIcon />
            <h5 className={styles.mainText}>Drag file here to import</h5>
          </div>
          <div
            className={'mt-5 d-flex justify-content-between align-items-center'}
          >
            <div className={styles.dividedLine} />
            <h6 className={styles.subText1}>or</h6>
            <div className={styles.dividedLine} />
          </div>
          <div className={'mt-5 d-flex justify-content-center'}>
            <CustomButton
              text={'Choose From Files'}
              className={styles.heightBtn}
              type={'button'}
              onClick={onButtonClick}
              loading={isLoading}
            />
          </div>
          <div className={'mt-3 d-flex justify-content-center'}>
            <h6 className={styles.subText2}>{`+Supported file format:${
              from === 'userManager' ? ' .csv' : ' .xlxs'
            }`}</h6>
          </div>
          <div className={'mt-3 d-flex justify-content-center'}>
            <h6 className={styles.subText2}>
              {/*+Maximum upload file size: 10 MB*/}
              +Maximum limit of file records: 50000
            </h6>
          </div>
        </label>
        {dragActive && (
          <div
            id="drag-file-element"
            className={styles.dragFileElement}
            onDragEnter={(e: React.DragEvent) =>
              handleDrag(e as unknown as DragEvent)
            }
            onDragLeave={(e: React.DragEvent) =>
              handleDrag(e as unknown as DragEvent)
            }
            onDragOver={(e: React.DragEvent) =>
              handleDrag(e as unknown as DragEvent)
            }
            onDrop={(e: React.DragEvent) =>
              handleDrop(e as unknown as DragEvent)
            }
          ></div>
        )}
      </form>
    </div>
  );
};

export default FileImport;
