import React, { useEffect, useRef } from 'react';
import { Urls } from '../context/urls';
import { UserType } from '../typeDef/user.model';
import axios from 'axios';
import { azureSignOut } from '../services/auth.service';
import { useLoader } from '../context/LoaderContext';

interface TokenRefreshProviderProps {
  children: React.ReactNode;
}

export interface ErrorResponse {
  response: {
    data: {
      statusCode: number;
    };
  };
}

const TokenRefreshProvider: React.FC<TokenRefreshProviderProps> = ({
  children,
}) => {
  const intervalId = useRef<NodeJS.Timeout>();
  const sessionTimeUpdateInterval = useRef<NodeJS.Timeout>();
  const { showLoader, hideLoader } = useLoader();

  async function handleTokenExpiration() {
    localStorage.setItem('tokenExpirationKey', 'tokenExpirationValue');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('sessionTime');
    if (localStorage.getItem('userType') === UserType.PERMANENT) {
      const response = await azureSignOut();
      window.location.href = response.data.redirectTo;
      localStorage.removeItem('userType');
    } else {
      window.location.href = '/';
      localStorage.removeItem('userType');
    }
  }

  const updateSessionTime = () => {
    const nextSessionTime = new Date().getTime();
    localStorage.setItem('sessionTime', String(nextSessionTime));
  };

  const initializeSessionTime = () => {
    let sessionTime = localStorage.getItem('sessionTime');
    if (!sessionTime) {
      sessionTime = String(new Date().getTime());
      localStorage.setItem('sessionTime', sessionTime);
    } else {
      const currentTime = new Date().getTime();
      const sessionTimeMillis = parseInt(sessionTime, 10);

      const timeDifference = currentTime - sessionTimeMillis;

      if (timeDifference > 90000) {
        handleTokenExpiration();
        return;
      }
    }
  };

  const checkTokenAndRefresh = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    const expiration = localStorage.getItem('tokenExpiration');

    if (refreshToken && expiration) {
      const expirationTime = parseInt(expiration) - 300000;

      const currentTime = new Date().getTime();

      if (currentTime >= expirationTime) {
        try {
          const response = await axios.post(
            `${Urls.BASE_URL}/auth/refresh`,
            {},
            {
              headers: {
                Authorization: `Bearer ${refreshToken}`,
              },
            }
          );

          const newAccessToken = response.data.token;
          const newRefreshToken = response.data.refreshToken;
          const newExpirationTime = response.data.expiresIn;

          localStorage.setItem('token', newAccessToken);
          localStorage.setItem('refreshToken', newRefreshToken);
          localStorage.setItem(
            'tokenExpiration',
            String(new Date().getTime() + newExpirationTime * 1000)
          );
          hideLoader();
        } catch (e) {
          const errorResponse = e as ErrorResponse;
          if (errorResponse.response.data.statusCode === 401) {
            localStorage.setItem(
              'errorMessageKey',
              'Invalid Session. Please log in again to regain access to your account'
            );
            handleTokenExpiration();
          }
          hideLoader();
        }
      }
    }
    hideLoader();
  };

  // Function to set up the interval
  const setupTokenRefreshInterval = () => {
    // Set up an interval to periodically check the token
    const tokenRefreshInterval = 300000; // 5 minutes
    const sessionUpdateInterval = 60000; // 1 minutes

    intervalId.current = setInterval(
      checkTokenAndRefresh,
      tokenRefreshInterval
    );

    sessionTimeUpdateInterval.current = setInterval(
      updateSessionTime,
      sessionUpdateInterval
    );
  };

  // Set up the token refresh logic when the component mounts
  useEffect(() => {
    initializeSessionTime();

    (async () => {
      await checkTokenAndRefresh();
    })();
    setupTokenRefreshInterval();

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId.current);
      clearInterval(sessionTimeUpdateInterval.current);
    };
  }, []);

  // check if token have
  useEffect(() => {
    if (localStorage.getItem('token') === '') {
      window.location.href = '/';
    }
  }, []);

  // Listen for visibility changes and refresh token if the tab becomes active
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // console.log('Tab is now active');
        (async () => {
          showLoader();
          await checkTokenAndRefresh();
          hideLoader();
          await setupTokenRefreshInterval();
        })();
      } else {
        // console.log('Tab is now inactive');
        clearInterval(intervalId.current);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return <>{children}</>;
};

export default TokenRefreshProvider;
