import React from 'react';

interface Props {
  size?: number;
  color?: string;
}

const CloseIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 12}
      height={size ?? 12}
      fill="none"
    >
      <g clipPath="url(#a)">
        <path
          fill={color ?? '#D35959'}
          d="M11.14.635a.476.476 0 0 0-.665 0L5.638 5.352.802.635a.476.476 0 0 0-.664 0 .453.453 0 0 0 0 .648L4.974 6 .138 10.718a.453.453 0 0 0 0 .648.476.476 0 0 0 .664 0l4.836-4.718 4.837 4.718a.476.476 0 0 0 .664 0 .453.453 0 0 0 0-.648L6.303 6l4.836-4.717a.453.453 0 0 0 0-.648Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 .5h11.277v11H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloseIcon;
