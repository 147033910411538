import React, { useContext, useEffect, useState } from 'react';
import {
  UserRole,
  UserRoleDisplayFormat,
  UserType,
} from '../../typeDef/user.model';
import AuthContext from '../../context/auth.context';
import CommonLayout from '../layout/commonLayout';
import CustomImageUpload from '../shared/customImageUpload/customImageUpload';
import EmailIcon from '../../icons/email.icon';
import LoadingSpinner from '../shared/loadingSpinner/loadingSpinner';
import LocationIcon from '../../icons/location.icon';
import UserIcon from '../../icons/user.icon';
import UsernameIcon from '../../icons/username.icon';
import { getProfileImage } from '../../common/users';
import loadingStyles from '../shared/loadingSpinner/loadingSpinner.module.scss';
import styles from './styles.module.scss';
function userProfile() {
  const { user } = useContext(AuthContext);
  const [imageSrc, setImageSrc] = useState<Blob>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setImageSrc(await getProfileImage());
    })();
  }, []);

  return (
    <>
      <CommonLayout
        userProfile
        title={'User Management'}
        setPageLoading={(value) => {
          setIsLoading(value);
        }}
      >
        <div className={`${isLoading && loadingStyles.app_while_loading}`}>
          {isLoading && <LoadingSpinner />}
          <div className={styles.userProfile}>
            <h6 className={'primary-color-text text-bolder'}>User Details</h6>

            <div className={'mt-5'}>
              <p className={'text-dark text-semi-bold mb-2'}>First Name</p>
              <div className={'d-flex align-items-center'}>
                <UserIcon />
                <p className={'primary-color-text ms-2 text-semi-bold'}>
                  {user?.firstName}
                </p>
              </div>
            </div>
            <div className={'mt-5'}>
              <p className={'text-dark text-semi-bold mb-2'}>Last Name</p>
              <div className={'d-flex align-items-center'}>
                <UserIcon />
                <p className={'primary-color-text ms-2 text-semi-bold'}>
                  {user?.lastName}
                </p>
              </div>
            </div>
            <div className={'mt-5'}>
              <p className={'text-dark text-semi-bold mb-2'}>User Type</p>
              <div className={styles.userType}>
                <div className={styles.dot} />
                <p className={'text-success ms-2'}>
                  {user?.type === UserType.PERMANENT
                    ? UserRoleDisplayFormat.STAFF
                    : user?.type === UserType.CASUAL &&
                      user?.role === UserRole.USER
                    ? UserRoleDisplayFormat.GUEST
                    : `${user?.role}`}
                </p>
              </div>
            </div>
            <div className={'mt-5'}>
              <p className={'text-dark text-semi-bold mb-2'}>Email ID</p>
              <div className={'d-flex align-items-center'}>
                <EmailIcon />
                <p className={'primary-color-text ms-2 text-semi-bold'}>
                  {user?.email}
                </p>
              </div>
            </div>
            <div className={'mt-5'}>
              <p className={'text-dark text-semi-bold mb-2'}>Username</p>
              <div className={'d-flex align-items-center'}>
                <UsernameIcon />
                <p className={'primary-color-text ms-2 text-semi-bold'}>
                  {user?.username}
                </p>
              </div>
            </div>
            <div className={'mt-5'}>
              <p className={'text-dark text-semi-bold mb-2'}>Location</p>
              <div className={'d-flex align-items-center'}>
                <LocationIcon />
                <p className={'primary-color-text ms-2 text-semi-bold'}>
                  {user?.location ? user?.location : 'N/A'}
                </p>
              </div>
            </div>
            <div className={'mt-5'}>
              <p className={'text-dark text-semi-bold mb-2'}>User Photo</p>
              <CustomImageUpload
                id={'profile'}
                imageUrl={imageSrc}
                changeDisable
                userFullName={`${user?.firstName} ${user?.lastName}`}
              />
            </div>
          </div>
        </div>
      </CommonLayout>
    </>
  );
}
export default userProfile;
