import { FieldHookConfig, useField } from 'formik';
import React, {
  HTMLProps,
  KeyboardEventHandler,
  ReactNode,
  forwardRef,
  useCallback,
  useState,
} from 'react';
import EyeIcon from '../../../icons/eye.icon';
import HidePasswordIcon from '../../../icons/newHideEye.icon';

import styles from './customInputFiled.module.scss';

interface InputProps extends HTMLProps<HTMLInputElement> {
  icon?: ReactNode;
  pwError?: boolean;
  keyPress?: KeyboardEventHandler<HTMLInputElement>;
  // onKeyPress?: KeyboardEventHandler<HTMLInputElement> & (() => void);
  keepConsistentHeight?: boolean;
  ref?: React.Ref<HTMLInputElement>;
  onFocus?: () => void;
  onBlur?: () => void;
  autoComplete?: string;
  from?: string;
}

const InputField = forwardRef<
  HTMLInputElement,
  FieldHookConfig<string> & InputProps
>((props, ref) => {
  const [field, meta] = useField(props);
  const [inputType, setInputType] = useState(props.type);
  const [value, setValue] = useState(props?.value);

  const handleChangeFieldType = useCallback(() => {
    setInputType(inputType === 'password' ? 'text' : 'password');
    setValue(props?.value);
  }, [inputType, value]);

  const handleChange = function (event: React.ChangeEvent<HTMLInputElement>) {
    if (props.onChange) {
      props.onChange(event);
    }
  };
  return (
    <div>
      <div className={`${styles.formField} ${props.className ?? ''}`}>
        <input
          {...field}
          ref={ref}
          id={props.id || props.name}
          className={`${
            props.icon ? styles.iconCustomField : styles.customField
          } ${props.className ?? ''} ${
            props.is === 'large' ? 'form-control-lg' : ''
          }`}
          style={
            props.disabled
              ? { background: '#EDEDED', borderColor: '#BEBEBE' }
              : {}
          }
          type={inputType}
          placeholder={props.placeholder}
          disabled={props.disabled}
          autoFocus={props.autoFocus !== false}
          onKeyDown={props.keyPress}
          value={
            props.defaultValue === '0' || props.value === '' ? '' : field.value
          }
          onChange={handleChange}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          autoComplete={props.autoComplete}
        />
        {props.icon && (
          <div className={`me-3 ${styles.verifyIcon}`}>{props.icon}</div>
        )}
        {props.placeholder && (
          <label
            htmlFor={props.id || props.name}
            className={`form-label ${styles.formLabel}`}
            style={props.from === 'scanContainer' ? { fontSize: '12px' } : {}}
          >
            {props.placeholder}
          </label>
        )}
        {props.type === 'password' && (
          <button
            type="button"
            className={`btn ${styles.showHidePassword}`}
            onClick={handleChangeFieldType}
          >
            {inputType === 'password' ? (
              <HidePasswordIcon size={16} />
            ) : (
              <EyeIcon size={16} />
            )}
          </button>
        )}
      </div>
      {!props.keepConsistentHeight && meta.touched && meta.error ? (
        <div className={styles.error}>{meta.error}</div>
      ) : null}
      {props.keepConsistentHeight ? (
        <div
          className={
            meta.touched && meta.error ? styles.error : styles.errorNotVisible
          }
        >
          {meta.touched && meta.error ? meta.error : '...'}
        </div>
      ) : null}
    </div>
  );
});

InputField.displayName = 'InputField';
export default InputField;
