import CustomButton from '../shared/customButton/customButton';
import CustomModal from '../shared/customModal/customModal';
import React from 'react';
import styles from './messageModal.module.scss';

type MessageModalProps = {
  title: string;
  isSuccess: boolean;
  isError: boolean;
  onCloseModal: (value: boolean) => void;
  openModal: boolean;
  messageText: string;
  isLoading?: boolean;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onPrimaryButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  from?: string;
};

const MessageModal = ({
  title,
  isSuccess,
  isError,
  onCloseModal,
  openModal,
  messageText,
  primaryButtonText = 'OK',
  secondaryButtonText,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  from,
}: MessageModalProps) => {
  return (
    <CustomModal
      title={title}
      className={styles.onTop}
      success={isSuccess}
      deleteTitle={isError}
      open={openModal}
      onCloseModal={(val) => onCloseModal(val)}
    >
      <div className={styles.modalWidth}>
        <div
          className={from === 'userManager' ? styles.modalContentHeight : ''}
        >
          <h6 className={'light-text text-normal'}>{messageText}</h6>
        </div>
        <div className={'d-flex justify-content-end align-items-center mt-5'}>
          <CustomButton
            text={primaryButtonText}
            onClick={() => {
              onCloseModal(false);
              onPrimaryButtonClick?.();
            }}
            className={styles.buttonWidth}
          />
          {secondaryButtonText && (
            <CustomButton
              text={secondaryButtonText}
              variant="secondary"
              onClick={() => {
                onCloseModal(false);
                onSecondaryButtonClick?.();
              }}
              className={`${styles.ml2} ${styles.buttonWidth}`}
            />
          )}
        </div>
      </div>
    </CustomModal>
  );
};
export default MessageModal;
