import * as React from 'react';
interface Props {
  size?: number;
  color?: string;
}
const LiveDotIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 10}
      height={size ?? 10}
      fill="none"
      viewBox={'0 0 10 10'}
    >
      <circle cx={5} cy={5} r={4.5} stroke={color ?? '#D35959'} />
      <circle cx={5} cy={5} r={3} fill={color ?? '#D35959'} />
    </svg>
  );
};
export default LiveDotIcon;
