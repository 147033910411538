import React, { useEffect, useRef, useState } from 'react';
import { WeightUnit } from '../../typeDef/grain.model';

import styles from './saveWeightInput.module.scss';

type ModalProps = {
  onOk: (weight?: number) => void;
  weightUnits?: string;
};

export default function SaveWeightInput({ onOk, weightUnits }: ModalProps) {
  const [weight, setWeight] = useState<number>();
  const [isInputFocused, setInputFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  function weightConverter(weightInput: number) {
    if (weightUnits === WeightUnit.KILOGRAMS) {
      return weightInput / 1000;
    } else {
      return weightInput;
    }
  }

  const handleSaveClick = () => {
    onOk(weightConverter(weight ?? 0));
    inputRef.current?.focus();
    setWeight(0);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    /* we are getting the input in grams and convert currently */
    // so it can easily backspaced and edited
    const scaleWeight = Number(event.target.value);
    setWeight(scaleWeight);
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const pressedKey = event.key;

    if (pressedKey === 'Enter') {
      event.preventDefault();
      if (weight && weight > 0) {
        handleSaveClick();
      }
    }
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (
      inputRef.current &&
      inputRef.current instanceof Node &&
      !inputRef.current.contains(e.target as Node)
    ) {
      setInputFocused(true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <>
      <div>
        <div className={styles.weightDisplay}>
          <div>
            <input
              ref={inputRef}
              type="number"
              value={weight}
              onChange={isInputFocused ? handleInputChange : handleInputChange}
              onKeyDown={handleKeyDown}
              style={{
                opacity: 0,
                position: 'absolute',
                left: '0',
                top: '0',
                width: '100%',
                height: '100%',
                pointerEvents: 'auto',
              }}
              autoFocus={true}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
            />
            <span>{weightConverter(weight ?? 0) || '----'}</span>
          </div>
          <span>{weightUnits || WeightUnit.GRAMS}</span>
        </div>
      </div>
    </>
  );
}
