import CommonLayout from '../../components/layout/commonLayout';
import React from 'react';
import UserListTable from '../../components/table/userList/userListTable';

export default function UserManager() {
  return (
    <CommonLayout admin title={'User Manager'} selectedPage={'userManager'}>
      <UserListTable />
    </CommonLayout>
  );
}
