import { CreateLocalUserRequest, UserResponse } from '../typeDef/user.model';
import { AxiosResponse } from 'axios';
import { UpdateCurrentUserProfileRequest } from '../typeDef/profile.model';
import { backendAPI } from '../utils/axios';

export async function getCurrentUserProfile(): Promise<
  AxiosResponse<UserResponse>
> {
  return backendAPI.get<UserResponse>(`/profile`);
}

export async function updateCurrentUserProfile(
  body: UpdateCurrentUserProfileRequest
): Promise<AxiosResponse<UserResponse>> {
  return backendAPI.patch<UserResponse>(`/profile`, body);
}

export async function deleteCurrentUserProfile(): Promise<
  AxiosResponse<Partial<UserResponse>>
> {
  return backendAPI.delete<UserResponse>(`/profile`);
}

export async function uploadCurrentUserProfileImage(
  body: FormData
): Promise<AxiosResponse<UserResponse>> {
  return backendAPI.post<CreateLocalUserRequest, AxiosResponse<UserResponse>>(
    `/profile/image`,
    body
  );
}

export async function getCurrentUserProfileImage(): Promise<
  AxiosResponse<UserResponse>
> {
  return backendAPI.get<UserResponse>(`/profile/image`);
}

export async function deleteCurrentUserProfileImage(): Promise<
  AxiosResponse<Partial<UserResponse>>
> {
  return backendAPI.delete<UserResponse>(`/profile/image`);
}
