import * as React from 'react';
interface Props {
  size?: number;
  color?: string;
}
const DownIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 8}
      height={size ?? 6}
      fill="none"
    >
      <path
        fill={color ?? '#003359'}
        d="M4.41 4.915a.5.5 0 0 1-.82 0L1.05 1.287A.5.5 0 0 1 1.46.5h5.08a.5.5 0 0 1 .41.787L4.41 4.915Z"
      />
    </svg>
  );
};
export default DownIcon;
