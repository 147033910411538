import React, { useEffect, useState } from 'react';
import moment from 'moment';
import packageJson from '../package.json';

interface MetaData {
  buildDate: string;
}

const buildDateGreaterThan = (
  latestDate: string,
  currentDate: string | number
): boolean => {
  const momLatestDateTime = moment(latestDate);
  const momCurrentDateTime = moment(currentDate);

  return momLatestDateTime.isAfter(momCurrentDateTime);
};

function withClearCache<P extends React.HTMLAttributes<HTMLElement>>(
  Component: React.ComponentType<P>
) {
  const ClearCacheComponent: React.FC<P> = (props) => {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState<boolean>(false);

    const refreshCacheAndReload = () => {
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then(async (names) => {
          for (const name of names) {
            await caches.delete(name);
          }
        });
      }
      // delete browser cache and hard reload
      window.location.reload();
    };

    useEffect(() => {
      fetch('/meta.json')
        .then((response) => response.json())
        .then((meta: MetaData) => {
          const latestVersionDate = meta.buildDate;
          const currentVersionDate = packageJson.buildDate;

          const shouldForceRefresh = buildDateGreaterThan(
            latestVersionDate,
            currentVersionDate
          );
          if (shouldForceRefresh) {
            setIsLatestBuildDate(false);
            refreshCacheAndReload();
          } else {
            setIsLatestBuildDate(true);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error fetching build information:', error);
          setIsLatestBuildDate(true); // Assume the build is still valid
        });
    }, []);

    return (
      <React.Fragment>
        {isLatestBuildDate ? <Component {...props} /> : null}
      </React.Fragment>
    );
  };

  return ClearCacheComponent;
}

export default withClearCache;
