import {
  CreateLocalUserRequest,
  ReactivateDisableUser,
  UpdateUser,
  UserRequest,
  UserResponse,
} from '../typeDef/user.model';
import { AxiosResponse } from 'axios';
import { CommonPageResponse } from '../typeDef/common.model';
import { backendAPI } from '../utils/axios';

export async function createUser(
  body: Partial<CreateLocalUserRequest>
): Promise<AxiosResponse<UserResponse>> {
  return backendAPI.post<CreateLocalUserRequest, AxiosResponse<UserResponse>>(
    `/users`,
    body
  );
}

export async function getAllUsers(
  query?: UserRequest
): Promise<AxiosResponse<CommonPageResponse<UserResponse>>> {
  return backendAPI.get<CommonPageResponse<UserResponse>>(`/users`, {
    params: query,
  });
}

export async function getUser(
  userId: string
): Promise<AxiosResponse<UserResponse>> {
  return backendAPI.get<UserResponse>(`/users/${userId}`);
}

export async function updateUser(
  userId: string,
  body: UpdateUser
): Promise<AxiosResponse<UserResponse>> {
  return backendAPI.patch<UserResponse>(`/users/${userId}`, body);
}

export async function deleteUser(
  userId: string | undefined
): Promise<AxiosResponse<Partial<UserResponse>>> {
  return backendAPI.delete<UserResponse>(`/users/${userId}`);
}

export async function reactivateDisableUser(
  body: ReactivateDisableUser
): Promise<AxiosResponse<UserResponse>> {
  return backendAPI.post<UserResponse>(`/users/reactivateUser`, body);
}

export async function uploadUserProfileImage(
  userId: string | undefined,
  body: FormData
): Promise<AxiosResponse<UserResponse>> {
  return backendAPI.post<CreateLocalUserRequest, AxiosResponse<UserResponse>>(
    `/users/${userId}/image`,
    body,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'multipart/form-data',
      },
    }
  );
}
export async function getUserProfileImage(
  userId: string | undefined
): Promise<AxiosResponse<UserResponse>> {
  return backendAPI.get<UserResponse>(`/users/${userId}/image`);
}
export async function deleteUserProfileImage(
  userId: string | undefined
): Promise<AxiosResponse<Partial<UserResponse>>> {
  return backendAPI.delete<UserResponse>(`/users/${userId}/image`);
}
