import React from 'react';

interface Props {
  size?: number;
  color?: string;
}

const SearchIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 15}
      height={size ?? 14}
      fill="none"
      viewBox={'0 0 15 14'}
    >
      <g clipPath="url(#a)">
        <path
          fill={color ?? '#003359'}
          d="m14.033 12.86-3.397-3.398a5.702 5.702 0 1 0-.805.805l3.398 3.398a.569.569 0 0 0 .804-.805Zm-7.802-2.444a4.554 4.554 0 1 1 0-9.108 4.554 4.554 0 0 1 0 9.108Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.539.17H14.2V13.83H.539z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchIcon;
