import React, { useState } from 'react';
import CommonLayout from '../../components/layout/commonLayout';
import { ErrorResponse } from '../../typeDef/common.model';
import FileImport from '../../components/fileImport/fileImport';
import LoadingSpinner from '../../components/shared/loadingSpinner/loadingSpinner';
import MessageModal from '../../components/modal/messageModal';
import { importFile } from '../../services/excel.service';
import loadingStyles from '../../components/shared/loadingSpinner/loadingSpinner.module.scss';

import styles from '../../components/fileImport/fileImport.module.scss';

export default function ImportFile() {
  const [openModal, setOpenModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, seIsError] = useState(false);
  const [clearInput, setClearInput] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messageText, setMessageText] = useState('');

  const importExcelFile = async (excelFile: File) => {
    try {
      // add database;
      setIsLoading(true);
      const data = new FormData();
      data.append('file', excelFile);
      await importFile(data);
      setIsLoading(false);
      setIsSuccess(true);
      seIsError(false);
      setMessageText(`${excelFile.name} successfully imported.`);
      setOpenModal(true);
      setClearInput(true);
    } catch (err) {
      const errorResponse = err as ErrorResponse;
      setIsLoading(false);
      setIsSuccess(false);
      seIsError(true);
      setMessageText(
        errorResponse.response.data.message === 'File already exists'
          ? ` File “${excelFile.name}” already exists. Please try again with a different file or filename.`
          : `${errorResponse.response.data.message}.`
      );
      setOpenModal(true);
      setClearInput(true);
    }
  };

  return (
    <div className={`${isLoading && loadingStyles.app_while_loading}`}>
      {isLoading && <LoadingSpinner />}
      <CommonLayout
        admin
        selectedPage={'importFile'}
        title={'Import File'}
        setPageLoading={(value) => {
          setIsLoading(value);
        }}
      >
        <div className={styles.mainContent}>
          <div className={'mb-5'}>
            <h3 className={styles.title}>
              Import Files for Weight and Keep/Cull
            </h3>
          </div>
          <FileImport
            fileSelect={async (value) => {
              await importExcelFile(value);
            }}
            isInputClear={clearInput}
            setMessageText={(value) => setMessageText(value)}
            setIsLoading={(value) => setIsLoading(value)}
            setIsSuccess={(value) => setIsSuccess(value)}
            seIsError={(value) => seIsError(value)}
            setOpenModal={(value) => setOpenModal(value)}
            isLoading={isLoading}
            enableDrop={!isLoading}
          />
          {openModal && (
            <MessageModal
              title={isSuccess ? 'File Imported' : 'File Error'}
              isSuccess={isSuccess}
              isError={isError}
              onCloseModal={(value) => setOpenModal(value)}
              openModal={openModal}
              messageText={messageText}
            />
          )}
        </div>
      </CommonLayout>
    </div>
  );
}
