import {
  ImageDownloadRequest,
  ImageDownloadResponse,
  ImageRequest,
  ImageResponse,
} from '../../typeDef/image.model';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TableHeading, {
  SortDirection,
} from '../../components/shared/tableHeading/tableHeading';
import { downloadImage, getImageDetails } from '../../services/images.service';
import { useLocation, useNavigate } from 'react-router-dom';
import ActionIcon from '../../icons/action.icon';
import { AxiosResponse } from 'axios/index';
import ButtonGroup from '../../components/shared/radioButtonWithCheckbox/buttonGroup';
import CloseIcon from '../../icons/close.icon';
import CloudDownloadIcon from '../../icons/cloudDownload.icon';
import CommonLayout from '../../components/layout/commonLayout';
import CustomButton from '../../components/shared/customButton/customButton';
import CustomModal from '../../components/shared/customModal/customModal';
import EyeIcon from '../../icons/eye.icon';
import Folder from '../../images/folder.png';
import GrainImage from '../dashboardWeightSection/grainImage';
import JSZip from 'jszip';
import LoadingSpinner from '../../components/shared/loadingSpinner/loadingSpinner';
import PaginationPages from '../../components/shared/pagination/paginationPages';
import { PaginationType } from '../../typeDef/common.model';
import Popup from 'reactjs-popup';
import SearchIcon from '../../icons/search.icon';
import { SortNameType } from '../dashboardWeightSection/viewImagesModal';
import TableRow from '../../components/table/userList/userListTableRow/userListTableRow';
import { convertDateFormat } from '../../common/users';
import loadingStyles from '../../components/shared/loadingSpinner/loadingSpinner.module.scss';
import styles from '../../components/table/userList/userListTable.module.scss';

enum ViewType {
  GRID_VIEW = 'Grid View',
  LIST_VIEW = 'List View',
}

export enum SortType {
  BARCODE = 'barcode',
  UPLOADDATE = 'uploadDate',
  UPLOADEDBY = 'uploadedBy',
}

export default function ImageList() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [selectedFolders, setSelectedFolders] = useState<string[]>([]);
  const [imagePreview, setImagePreview] = useState(false);
  const [selected, setSelected] = useState<string>(ViewType.GRID_VIEW);
  const [selectAll, setSelectAll] = useState(false);
  const [fileName, setFileName] = useState('');
  const [imageData, setData] = useState<ImageResponse>();
  const [paginationData, setPaginationData] = useState<PaginationType>();
  const [pageSize, setPageSize] = useState(10);
  const [activePage, setActivePage] = useState<number>(1);
  const sortOrder = useRef(SortDirection.ASC);
  const sortField = useRef(SortType.BARCODE);
  const [searchField, setSearchField] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleItemToggle = (index: number, imageName: string) => {
    const selectedIndex = selectedItems.indexOf(index);
    if (selectedIndex === -1) {
      // If the item is not already selected, add it to the selected items and folders arrays.
      setSelectedFolders([...selectedFolders, imageName]);
      setSelectedItems([...selectedItems, index]);
    } else {
      // If the item is already selected, remove it from both selected items and folders arrays.
      const updatedItems = [...selectedItems];
      updatedItems.splice(selectedIndex, 1);
      setSelectedItems(updatedItems);

      const updatedFolders = [...selectedFolders];
      updatedFolders.splice(selectedIndex, 1);
      setSelectedFolders(updatedFolders);
    }
  };
  const handleDownload = async (fileString: string) => {
    setIsLoading(true);
    try {
      const query: Partial<ImageDownloadRequest> = {
        compress: false,
      };
      const response: AxiosResponse<ImageDownloadResponse> =
        await downloadImage(location.state.trialName, fileString, query);

      if (response.status === 200 && response.data.data) {
        const blob = new Blob([new Uint8Array(response.data.data)], {
          type: 'application/octet-stream',
        });
        const url = window.URL.createObjectURL(blob);

        // Create an anchor element and set attributes to trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = fileString;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        setIsLoading(false);
      }
      setIsLoading(false);
      setErrorMessage('');
      return response;
    } catch (e) {
      setErrorMessage('Something went wrong!');
      setIsLoading(false);
    }
  };

  const handleSelectedImagesDownload = async () => {
    setIsLoading(true);
    if (selectedFolders.length > 0) {
      const mainzip = new JSZip();

      const query: Partial<ImageDownloadRequest> = {
        compress: false,
      };

      const downloadRequests = selectedFolders.map((folderName) =>
        downloadImage(location.state.trialName, folderName, query)
      );

      try {
        const downloadResponses = await Promise.all(downloadRequests);

        downloadResponses.forEach((response, index) => {
          if (response && response.data.data) {
            mainzip.file(selectedFolders[index], response.data.data);
          }
        });

        const mainZipBlob = await mainzip.generateAsync({ type: 'blob' });

        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(mainZipBlob);
        downloadLink.download = 'exportedFiles.zip';

        downloadLink.click();
      } catch (error) {
        setErrorMessage('Error downloading or exporting files');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getAllImages = useCallback(async () => {
    setIsImageLoading(true);
    setSelectedItems([]);
    setSelectedFolders([]);
    setSelectAll(false);
    try {
      const query: Partial<ImageRequest> = {
        trialName: location.state.trialName,
        pageNumber: activePage,
        pageSize: pageSize,
        sortOrder: sortOrder.current ?? SortDirection.ASC,
        sortField: sortField.current ?? SortType.BARCODE,
        ...(searchField && {
          barcode: searchField.toLowerCase().trim(),
        }),
      };
      const response: AxiosResponse<ImageResponse> = await getImageDetails(
        location.state.trialName,
        query
      );
      if (response.data) {
        setData(response.data);
      }
      setPaginationData(response.data.meta);
      setErrorMessage('');
      setIsImageLoading(false);
    } catch (e) {
      setErrorMessage('No images Found.');
      setIsImageLoading(false);
    }
  }, [pageSize, activePage, searchField, sortField]);

  const handleChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearchField(e.target.value);
  };

  const clickPrevious = () => {
    if (paginationData?.hasPreviousPage) {
      setActivePage(activePage - 1);
    }
  };

  /* click next */
  const clickNext = () => {
    if (paginationData?.hasNextPage) {
      setActivePage(activePage + 1);
    }
  };

  const handleSearch = async () => {
    try {
      if (searchField === '' || activePage === 1) {
        await getAllImages();
      }
      setActivePage(1);
    } catch (e) {
      // setErrorMessage('Something went wrong');
    }
  };

  // useEffect(() => {
  //   setData(imageData);
  // }, [imageData]);

  useEffect(() => {
    (async () => {
      await getAllImages();
    })();
  }, [pageSize, activePage]);

  const handleSelectAll = () => {
    if (selectAll) {
      // If all items are already selected, deselect all
      setSelectedItems([]);
      setSelectedFolders([]);
    } else {
      // If not all items are selected, select all
      const allItemIndices = imageData?.items.map((_, index) => index);
      if (allItemIndices) {
        setSelectedItems(allItemIndices);
        if (imageData)
          setSelectedFolders(imageData?.items.map((item) => item.fileName));
      }
    }
    setSelectAll(!selectAll); // Toggle the selectAll state
  };
  const handleOnSort = async (sortDirection: SortDirection, title: string) => {
    const sortBy =
      title === SortNameType.NAME
        ? SortType.BARCODE
        : title === SortNameType.UPLOADEDBY
        ? SortType.UPLOADEDBY
        : SortType.UPLOADDATE;
    //setSortField(sortBy);
    sortField.current = sortBy;
    //setSortOrder(sortDirection);
    sortOrder.current = sortDirection;
    await getAllImages();
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const pressedKey = event.key;

    if (pressedKey === 'Enter') {
      event.preventDefault();

      await handleSearch();
    }
  };

  return (
    <CommonLayout admin title={'Image Manager'} selectedPage={'imageManager'}>
      <>
        <div
          className={`${
            (isLoading || isImageLoading) && loadingStyles.app_while_loading
          }`}
        >
          {(isLoading || isImageLoading) && <LoadingSpinner />}
          <div className={`${styles.mainContent}`}>
            <p className={`font-size-default mb-3 ${styles.subTitle}`}>
              <span
                className={
                  'primary-color-text text-normal text-italic pointer text-decoration-none'
                }
              >
                <a onClick={() => navigate('/imageManager')}>
                  Image Manager &gt;
                </a>
              </span>
              <span className={'ms-2 text'}>{location.state.trialName}</span>
            </p>
            <div
              className={'d-flex justify-content-between align-items-center'}
            >
              <div className={'d-flex align-items-center'}>
                <img src={Folder} alt={'folder'} />
                <h6 className={'primary-color-text text-bolder ms-3'}>
                  {location.state.trialName}
                </h6>
                <div className={`ms-3 ${styles.imageCount}`}>
                  <p className={'success-color-text font-size-default'}>
                    {location.state.imageCount} Files
                  </p>
                </div>
              </div>
              <ButtonGroup
                options={[ViewType.GRID_VIEW, ViewType.LIST_VIEW]}
                selectedOption={selected}
                onSelect={(option: string) => {
                  setSelected(option);
                }}
              />
            </div>
            <div
              className={
                'd-flex justify-content-between align-items-center mt-5'
              }
            >
              <div className={'d-flex align-items-center'}>
                <div className={styles.searchBarContainer}>
                  <input
                    type="search"
                    className={`form-control ${styles.searchBarViewImage}`}
                    placeholder="Search"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                  <label className={styles.iconSeach}>
                    <SearchIcon />
                  </label>
                </div>

                <CustomButton
                  text={'Search'}
                  className={'ms-4 fit-content'}
                  type={'button'}
                  onClick={handleSearch}
                />
              </div>
              <div className={'d-flex align-items-center'}>
                <CustomButton
                  text={'Select All'}
                  className={styles.buttonWidth}
                  onClick={handleSelectAll}
                  // onClick={() => setCreateUserModal(true)}
                />
                <CustomButton
                  text={'Download Selected'}
                  variant={selectedItems.length > 0 ? 'secondary' : 'gray'}
                  className={`ms-3 ${styles.buttonWidth}`}
                  onClick={handleSelectedImagesDownload}
                />
              </div>
            </div>
            {errorMessage ? (
              <div>
                <h4 className={'mt-5 text-danger text-center'}>
                  {errorMessage}
                </h4>
              </div>
            ) : (
              <>
                {selected === ViewType.LIST_VIEW && (
                  <table className="table table-striped table-hover mt-5">
                    <thead className="table-primary">
                      <tr>
                        {/*<th scope="col"></th>*/}
                        <th scope="col" className={'table-primary'}>
                          <div className={'mb-3'}>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              onChange={handleSelectAll}
                              checked={
                                selectedFolders.length ===
                                imageData?.items.length
                              }
                            />
                          </div>
                        </th>
                        <th scope={'col'} />
                        <th scope="col">
                          <TableHeading
                            field={'NAME'}
                            sortable={true}
                            onSort={(sortDirection, title) =>
                              handleOnSort(sortDirection, title)
                            }
                          />
                        </th>
                        <th scope="col">
                          <TableHeading
                            field={'UPLOADED BY'}
                            sortable={true}
                            onSort={(sortDirection, title) =>
                              handleOnSort(sortDirection, title)
                            }
                          />
                        </th>
                        <th scope="col">
                          <TableHeading
                            field={'UPLOADED DATE'}
                            sortable={true}
                            onSort={(sortDirection, title) =>
                              handleOnSort(sortDirection, title)
                            }
                          />
                        </th>

                        <th scope="col">
                          <TableHeading
                            action
                            field={'ACTIONS'}
                            sortable={false}
                          />
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {imageData?.items.map((items, index) => {
                        return (
                          <tr key={items.id}>
                            <td className={styles.widthColSelection}>
                              <div className={styles.rowText}>
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  // onChange={() => handleCheckboxClick(item)}
                                  // checked={selectedFile === item.fileName}
                                  onChange={() =>
                                    handleItemToggle(index, items.fileName)
                                  }
                                  checked={selectedFolders.includes(
                                    items.fileName
                                  )}
                                />
                              </div>
                            </td>
                            <th scope="row" className={styles.rowHeight}>
                              <div className={styles.imageSize}>
                                <GrainImage
                                  trialName={location.state.trialName}
                                  fileName={items.fileName}
                                  loading={(value: boolean) =>
                                    setIsLoading(value)
                                  }
                                />
                              </div>
                            </th>
                            <td className={styles.widthCol}>
                              <TableRow text={items.fileName} />
                            </td>
                            <td className={styles.widthCol}>
                              <TableRow text={items.uploadedBy} />
                            </td>
                            <td className={styles.widthColDate}>
                              <TableRow
                                text={convertDateFormat(
                                  new Date(items.uploadDate)
                                )}
                              />
                            </td>
                            <td className={styles.widthActionCol}>
                              {/*<div className={styles.actionIcon}></div>*/}
                              <Popup
                                trigger={
                                  <a className={''}>
                                    <ActionIcon />
                                  </a>
                                }
                                position="left top"
                              >
                                <div className={styles.actionContainer}>
                                  <a
                                    className={'pointer text-decoration-none'}
                                    onClick={() => {
                                      setImagePreview(true);
                                      setFileName(items.fileName);
                                    }}
                                  >
                                    <ul
                                      className={`d-flex justify-content-start align-items-center ${styles.popUpSelection}`}
                                    >
                                      <EyeIcon />
                                      <h6 className={styles.linkText}>
                                        View Image
                                      </h6>
                                    </ul>
                                  </a>

                                  <a
                                    className={'pointer text-decoration-none'}
                                    onClick={() => {
                                      setFileName(items.fileName);
                                      handleDownload(items.fileName);
                                    }}
                                  >
                                    <ul
                                      className={`d-flex justify-content-start align-items-center ${styles.popUpSelection}`}
                                    >
                                      <CloudDownloadIcon size={16} />
                                      <h6 className={styles.linkText}>
                                        Download
                                      </h6>
                                    </ul>
                                  </a>
                                </div>
                              </Popup>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}

                {selected === ViewType.GRID_VIEW && (
                  <div className={'row mt-5'}>
                    {imageData?.items.map((values, index) => (
                      <div
                        onClick={() => handleItemToggle(index, values.fileName)}
                        className={
                          'col-3 d-flex align-items-center justify-content-between mb-5'
                        }
                        key={index}
                      >
                        <div className={'d-flex align-items-center'}>
                          <input
                            type="checkbox"
                            className="form-check-input me-3"
                            checked={selectedItems.includes(index)}
                          />
                          <GrainImage
                            trialName={location.state.trialName}
                            fileName={values.fileName}
                            loading={(value: boolean) => setIsLoading(value)}
                          />
                          <div className={'ms-3'}>
                            <p className={'font-size-default'}>
                              {values.fileName}
                            </p>
                          </div>
                        </div>
                        <Popup
                          trigger={
                            <a className={''}>
                              <ActionIcon />
                            </a>
                          }
                          position="left top"
                        >
                          <div className={styles.actionContainer}>
                            <a
                              className={'pointer text-decoration-none'}
                              onClick={() => {
                                setImagePreview(true);
                                setFileName(values.fileName);
                              }}
                            >
                              <ul
                                className={`d-flex justify-content-start align-items-center ${styles.popUpSelection}`}
                              >
                                <EyeIcon />
                                <h6 className={styles.linkText}>View Image</h6>
                              </ul>
                            </a>

                            <a
                              className={'pointer text-decoration-none'}
                              onClick={() => {
                                setFileName(values.fileName);
                                handleDownload(values.fileName);
                              }}
                            >
                              <ul
                                className={`d-flex justify-content-start align-items-center ${styles.popUpSelection}`}
                              >
                                <CloudDownloadIcon size={16} />
                                <h6 className={styles.linkText}>Download</h6>
                              </ul>
                            </a>
                          </div>
                        </Popup>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
            <div className={'mt-5'}>
              {/*<Pagination*/}
              {/*  userData={userData}*/}
              {/*  setUserData={(value) => setUserData(value)}*/}
              {/*/>*/}

              <div className={'d-flex justify-content-between'}>
                <div className={'d-flex align-items-center'}>
                  <h6 className={`me-3 ${styles.rowTest}`}>Rows per page </h6>
                  <select
                    id="framework"
                    className={` font-size-default ${styles.selectBox}`}
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(e.target.value as unknown as number);
                      setActivePage(1);
                    }}
                  >
                    <option
                      value="05"
                      className={'font-size-default primary-color-text'}
                    >
                      05
                    </option>
                    <option
                      value="10"
                      selected={true}
                      className={'font-size-default primary-color-text'}
                    >
                      10
                    </option>
                    <option
                      value="15"
                      className={'font-size-default primary-color-text'}
                    >
                      15
                    </option>
                    <option
                      value="20"
                      className={'font-size-default primary-color-text'}
                    >
                      20
                    </option>
                    <option
                      value="100"
                      className={'font-size-default primary-color-text'}
                    >
                      100
                    </option>
                    <option
                      value="200"
                      className={'font-size-default primary-color-text'}
                    >
                      200
                    </option>
                  </select>
                </div>
                <PaginationPages
                  hasPreviousPage={paginationData?.hasPreviousPage}
                  hasNextPage={paginationData?.hasNextPage}
                  clickPrevious={clickPrevious}
                  clickNext={clickNext}
                  setActivePage={(value) =>
                    setActivePage(value as unknown as number)
                  }
                  currentPage={activePage}
                  totalCount={paginationData?.itemCount ?? 10}
                  pageSize={pageSize}
                />
              </div>
            </div>
          </div>
        </div>
        <CustomModal
          open={imagePreview}
          onCloseModal={() => setImagePreview(false)}
        >
          <div
            className={`d-flex justify-content-end pointer align-items-center mb-2`}
            onClick={() => setImagePreview(false)}
          >
            <CloseIcon size={14} />

            <h6 className={'text-danger ms-2 mb-1'}>Close</h6>
          </div>
          <GrainImage
            previewImage
            trialName={location.state.trialName}
            fileName={fileName}
            loading={(value: boolean) => setIsLoading(value)}
          />
        </CustomModal>
      </>
    </CommonLayout>
  );
}
