import React, {
  ChangeEvent,
  KeyboardEvent,
  ReactNode,
  RefObject,
  SyntheticEvent,
  createRef,
  useEffect,
  useRef,
  useState,
} from 'react';

import DropdownArrow from '../../../icons/dropdownarrow.icon';
import styles from './customDropdown.module.scss';

import { useClickOutside } from '../hooks/useClickoutsideHook';

import { v4 } from 'uuid';

// import CloseIcon from '../../../icons/close.icon';

export type DropdownDataList = {
  text: string;
  value: string;
  groupBy?: string;
  id?: string;
  name?: string;
};

type CustomDropdownProps = {
  id?: string;
  clear?: boolean;
  placeHolder?: string;
  children?: React.ReactNode;
  selectedListIcon?: ReactNode;
  listIcon?: ReactNode;
  disabled?: boolean;
  dataList?: DropdownDataList[];
  selectedValue?: DropdownDataList;
  inputFieldStyles?: string;
  bold?: boolean;
  name?: string;
  enableSearch?: boolean;
  getSelectedItem?: (item: DropdownDataList, id: string) => void;
  sortDropdown?: boolean;
  maxResults?: number;
  icon?: ReactNode;
  iconOn?: 'start' | 'end';
  removeGutter?: boolean;
  onFocus?: (id: string) => void;
  onBlur?: (id: string) => void;
  defaultStyles?: boolean;
  onChange?: (text: string) => void;
  searchedValue?: string;
  isDisabled?: boolean;
  dropUp?: boolean;
  downArrow?: boolean;
  setFieldValue?: (value: string) => void;
};

const CustomDropdown = ({
  id = v4(),
  placeHolder,
  disabled = false,
  children,
  selectedListIcon,
  listIcon,
  dataList = [],
  selectedValue,
  bold = true,
  inputFieldStyles,
  name = 'dropdownInput',
  enableSearch = false,
  getSelectedItem,
  sortDropdown = false,
  maxResults = -1,
  icon,
  iconOn = 'start',
  defaultStyles = true,
  removeGutter = false,
  onFocus,
  onBlur,
  onChange,
  searchedValue,
  isDisabled,
  dropUp = false,
  setFieldValue,
}: CustomDropdownProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [active, setActive] = useState(false);
  const [cursor, setCursor] = useState(-1);
  const [inputValue, setInputValue] = useState(selectedValue?.text ?? '');
  const [filteredData, setFilteredData] = useState(dataList);
  const [openContainer, setOpenContainer] = useState(false);
  const [selected, setSelected] = useState<DropdownDataList>(
    enableSearch
      ? { text: '', value: '' }
      : selectedValue
      ? selectedValue
      : { text: placeHolder ?? '', value: '' }
  );
  const itemRefs = useRef<RefObject<HTMLDivElement>[]>([]);
  const width = useRef(0);
  itemRefs.current = dataList.map(
    (_, i) => itemRefs.current[i] ?? createRef<HTMLDivElement>()
  );

  useEffect(() => {
    setSelected(selectedValue ?? { text: '', value: '' });
    setInputValue(selectedValue?.text ?? ''); // added to update the input selected value on component refresh
  }, [selectedValue]);

  useEffect(() => {
    width.current = window.innerWidth;
  }, []);

  const handleClick = (event: SyntheticEvent) => {
    event.stopPropagation();
    onFocus?.(id);
    if (!disabled) {
      if (active) {
        setActive(false);
      } else {
        setActive(true);
      }
    }

    // scroll dropdown to current selected element and make sure keyUp keyDown events work from the selected element
    if (itemRefs && itemRefs.current) {
      const index = itemRefs.current.findIndex(
        (item) => item?.current?.innerText === selected?.text
      );

      if (index > -1) {
        itemRefs.current[index]?.current?.scrollIntoView();
        setCursor(index);
      }
    }
  };

  function getSortedResult(array: DropdownDataList[]) {
    return array.sort((a: DropdownDataList, b: DropdownDataList) => {
      return a.text.localeCompare(b.text);
    });
  }

  const handleFocus = (event: ChangeEvent<HTMLInputElement>) => {
    // onFocus?.(id);
    if (width.current > 600) {
      event.target.select();
    }
    if (!disabled) {
      // if(active){
      //   setActive(false)
      //   console.log(active,"143")
      // }else{
      // setActive(true)
      // console.log(active,"146")
      // }
      setOpenContainer(true);
    }
    event.target.autocomplete = 'off';
    if (!selected) {
      let dropdown = dataList;
      if (sortDropdown) {
        dropdown = getSortedResult(dataList);
      }
      if (maxResults > 0) {
        dropdown = dropdown.slice(0, maxResults);
      }
      setFilteredData(dropdown);
    }
  };

  const handleBlur = () => {
    onBlur?.(id);
    // handleSelectItem({text: '', value: ''});
    // setActive(false);
  };

  useEffect(() => {
    let dropdown = dataList;
    if (sortDropdown) {
      dropdown = getSortedResult(dropdown);
    }
    if (maxResults > 0) {
      dropdown = dropdown.slice(0, maxResults);
    }
    setFilteredData(dropdown);
  }, [dataList, maxResults, sortDropdown]);

  const onchange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (onChange) onChange(value);
    let filterData = dataList;
    setInputValue(value);
    if (value) {
      if (sortDropdown) {
        filterData = getSortedResult(filterData);
      }

      filterData = filterData.filter(
        (item) => item.name?.toLowerCase().startsWith(value?.toLowerCase())
      );
    }
    if (value === '') {
      setSelected({ text: '', value: '' });
      setInputValue('');
    }

    if (sortDropdown) {
      filterData = getSortedResult(filterData);
    }

    if (maxResults > 0) {
      filterData = filterData.slice(0, maxResults);
    }
    setFilteredData(filterData);
  };

  const handleSelectItem = (item: DropdownDataList) => {
    setSelected(item);
    setInputValue(item.text);
    setActive(!active);
    getSelectedItem?.(item, id);

    let dropdown = dataList;
    if (sortDropdown) {
      dropdown = getSortedResult(dropdown);
    }
    if (maxResults > 0) {
      dropdown = dropdown.slice(0, maxResults);
    }
    setFilteredData(dropdown);
    setOpenContainer(false);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const key = event.key;
    let index = cursor;
    if (filteredData && filteredData.length) {
      if (key === 'ArrowDown') {
        index = cursor < filteredData.length - 1 ? cursor + 1 : 0;
        setCursor(index);
        setSelected(filteredData[index]);
        setInputValue(filteredData[index].text);
        if (itemRefs && itemRefs.current) {
          itemRefs.current
            .find(
              (item) => item?.current?.innerText === filteredData[index].text
            )
            ?.current?.scrollIntoView();
        }
      } else if (key === 'ArrowUp') {
        index = cursor > 0 ? cursor - 1 : filteredData.length - 1;
        setCursor(index);
        setSelected(filteredData[index]);
        setInputValue(filteredData[index].text);
        if (itemRefs && itemRefs.current) {
          itemRefs.current
            .find(
              (item) => item?.current?.innerText === filteredData[index].text
            )
            ?.current?.scrollIntoView();
        }
      } else if (key === 'Enter') {
        const selectedItemIndex = filteredData.findIndex(
          (item) => JSON.stringify(item) === JSON.stringify(selected)
        );
        handleSelectItem(
          index > -1
            ? filteredData[index]
            : selectedItemIndex > -1
            ? filteredData[selectedItemIndex]
            : { text: '', value: '' }
        );
        index = -1;
        setCursor(index);
        if (inputRef) {
          inputRef.current?.blur();
        }
      }
    }
  };

  const domNode = useClickOutside(() => {
    if (active) {
      setActive(false);
      setInputValue(selected.text);
      handleSelectItem(selected);
    }
  });

  const handleSearchedResults = (
    originalText: string,
    searchedText: string
  ): React.ReactElement => {
    const startIndex = originalText
      .toLocaleLowerCase()
      .trim()
      .indexOf(searchedText.toLocaleLowerCase().trim());

    if (startIndex < 0) return <>{originalText}</>;

    return (
      <>
        {originalText.substring(0, startIndex)}
        <b>
          {originalText.substring(startIndex, startIndex + searchedText.length)}
        </b>
        {originalText.substring(
          startIndex + searchedText.length,
          originalText.length
        )}
      </>
    );
  };

  /**
   * add this for setting input value
   **/
  useEffect(() => {
    if (setFieldValue) {
      setFieldValue(inputValue);
    }
  }, [inputValue]);

  return (
    <div
      data-test-id="customDropdown"
      ref={domNode}
      className={[
        styles.customDropdown,
        openContainer ? styles.open : styles.closed,
        removeGutter ? styles.removeGutter : '',
      ].join(' ')}
    >
      <div className={styles.customDropdownContent}>
        <div className={styles.fieldContainer}>
          <input
            ref={inputRef}
            type="text"
            id={id}
            name={name}
            className={[
              defaultStyles ? styles.customFieldDefault : styles.customField,
              iconOn === 'start'
                ? icon && styles.iconSpaceStart
                : icon && styles.iconSpaceEnd,
              inputFieldStyles,
              !bold ? 'not-bold' : '',
            ].join(' ')}
            autoComplete="off"
            aria-autocomplete="none"
            disabled={isDisabled}
            value={inputValue}
            onClick={handleClick}
            onFocus={handleFocus}
            onChange={onchange}
            onBlur={handleBlur}
            placeholder={inputValue ?? placeHolder}
            readOnly={disabled}
            onKeyDown={(event) => handleKeyDown(event)}
          />
          <label
            htmlFor={id}
            className={[
              styles.formLabel,
              icon && iconOn === 'start'
                ? defaultStyles
                  ? styles.labelSpaceStartWithIconDefault
                  : styles.labelSpaceStartWithIcon
                : defaultStyles
                ? styles.labelSpaceEndWithIconDefault
                : styles.labelSpaceEndtWithIcon,
              defaultStyles
                ? styles.labelSpaceStartDefault
                : styles.labelSpaceStart,
            ].join(' ')}
          >
            {!inputValue && (
              <div className={`light-text text-normal`}>{placeHolder}</div>
            )}
          </label>
          {icon && (
            <label
              htmlFor={id}
              className={[
                styles.icon,
                defaultStyles ? styles.iconDefault : null,
                iconOn === 'start' ? styles.iconStart : styles.iconEnd,
              ].join(' ')}
            >
              {icon}
            </label>
          )}
          <label
            htmlFor={id}
            className={[
              styles.icon,
              defaultStyles ? styles.iconDefault : null,
              styles.iconEnd,
            ].join(' ')}
          >
            <DropdownArrow />
          </label>
          {children && (
            <label
              htmlFor={id}
              className={[
                styles.icon,
                defaultStyles ? styles.iconDefault : null,
                iconOn === 'start' ? styles.iconStart : styles.iconEnd,
              ].join(' ')}
            >
              <h6>{children}</h6>
            </label>
          )}
        </div>

        {active && (
          <div
            // layout
            // variants={dropdownVariant}
            // initial="hidden"
            // animate="visible"
            // exit="hidden"
            className={[
              defaultStyles
                ? styles.customDropdownBodyDefault
                : styles.customDropdownBody,
              dropUp ? styles.dropUp : styles.customDropdownBody,
            ].join(' ')}
          >
            <div className={styles.customDropdownBodyInnerBox}>
              {children || (filteredData && filteredData.length) ? (
                filteredData.map((item: DropdownDataList, index) => {
                  return (
                    <div key={v4()} ref={itemRefs.current[index]}>
                      {item.groupBy && (
                        <h6 className="mt-5 mb-2">{item.groupBy}</h6>
                      )}
                      <div
                        data-test-id="bodyItem"
                        key={v4()}
                        className={[
                          styles.customDropdownBodyItem,
                          item.name === selected.text ? styles.selected : '',
                        ].join(' ')}
                        onClick={() => {
                          handleSelectItem(item);
                        }}
                      >
                        <div className="d-flex">
                          {item.name === selected.text ? (
                            <div className="me-3">{selectedListIcon}</div>
                          ) : (
                            <div className="me-3">{listIcon}</div>
                          )}

                          {/* <span>
                            {searchedValue && item.text.indexOf(searchedValue) > 0
                              ? item.text.split(searchedValue).join('<strong>' + searchedValue + '</strong>')
                              : item.text}
                          </span> */}
                          {item.text ? (
                            // <h6>
                            <span>
                              {searchedValue
                                ? handleSearchedResults(
                                    item.text,
                                    searchedValue
                                  )
                                : item.text}
                            </span>
                          ) : (
                            // </h6>
                            // <h6>
                            <span>
                              {searchedValue
                                ? handleSearchedResults(
                                    item.name ?? '',
                                    searchedValue
                                  )
                                : item.name}
                            </span>
                            // </h6>
                          )}
                          {/* item.text.replace(searchedValue.capitalize(), '000') */}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="pt-3 pb-2">No Matching data...</p>
              )}
            </div>
          </div>
        )}
        {/* </AnimatePresence> */}
      </div>
    </div>
  );
};

export default CustomDropdown;
