import React from 'react';
import styles from './loadingSpinner.module.scss';

export default function InitialLoadingSpinner() {
  return (
    <div className={`${styles.initial_spinner_container}`}>
      <div className={`${styles.initial_loading_spinner}`}></div>
    </div>
  );
}
