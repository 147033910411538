import * as React from 'react';
interface Props {
  size?: number;
  color?: string;
}
const CalendarIcon: React.FC<Props> = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 15}
      height={size ?? 16}
      fill="none"
    >
      <g clipPath="url(#a)">
        <path
          fill="#003359"
          d="M11.875 1.75h-.625v-.625a.625.625 0 1 0-1.25 0v.625H5v-.625a.625.625 0 0 0-1.25 0v.625h-.625A3.129 3.129 0 0 0 0 4.875v7.5A3.129 3.129 0 0 0 3.125 15.5h8.75A3.129 3.129 0 0 0 15 12.375v-7.5a3.129 3.129 0 0 0-3.125-3.125ZM1.25 4.875A1.875 1.875 0 0 1 3.125 3h8.75a1.875 1.875 0 0 1 1.875 1.875V5.5H1.25v-.625Zm10.625 9.375h-8.75a1.875 1.875 0 0 1-1.875-1.875V6.75h12.5v5.625a1.875 1.875 0 0 1-1.875 1.875Z"
        />
        <path
          fill="#003359"
          d="M7.5 10.813a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.874Z"
        />
        <path
          fill="#003359"
          d="M4.375 10.813a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.874ZM10.625 10.813a.937.937 0 1 0 0-1.875.937.937 0 0 0 0 1.874Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 .5h15v15H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default CalendarIcon;
