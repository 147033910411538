import React from 'react';

interface Props {
  size?: number;
  color?: string;
}

const UploadIcon: React.FC<Props> = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 16}
      height={size ?? 16}
      fill="none"
      viewBox={'0 0 16 16'}
    >
      <g fill="#E6A30D" clipPath="url(#a)">
        <path d="M12.6 0H3.4A3.37 3.37 0 0 0 0 3.333v2a.667.667 0 1 0 1.333 0v-2a2.036 2.036 0 0 1 2.067-2h9.2a2.036 2.036 0 0 1 2.067 2v9.333a2.036 2.036 0 0 1-2.067 2H3.4a2.036 2.036 0 0 1-2.067-2v-2a.667.667 0 1 0-1.333 0v2A3.37 3.37 0 0 0 3.4 16h9.2a3.37 3.37 0 0 0 3.4-3.333V3.333A3.37 3.37 0 0 0 12.6 0Z" />
        <path d="M2 8a.667.667 0 0 0 .667.666l8.792-.02-2.882 2.882a.666.666 0 1 0 .942.943l3.058-3.058a2 2 0 0 0 0-2.828L9.52 3.528a.667.667 0 0 0-.943.943l2.841 2.842-8.752.02A.667.667 0 0 0 2 7.999Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UploadIcon;
