import React from 'react';

interface Props {
  size?: number;
}

const EmailIcon: React.FC<Props> = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 16}
      height={size ?? 16}
      fill="none"
      viewBox={'0 0 16 16'}
    >
      <g clipPath="url(#a)">
        <path
          fill="#003359"
          d="M12.667.667H3.333A3.337 3.337 0 0 0 0 4v8a3.337 3.337 0 0 0 3.333 3.333h9.334A3.337 3.337 0 0 0 16 12V4A3.338 3.338 0 0 0 12.667.667ZM3.333 2h9.334a2 2 0 0 1 1.853 1.258L9.415 8.364a2.005 2.005 0 0 1-2.83 0L1.48 3.258A2 2 0 0 1 3.333 2Zm9.334 12H3.333a2 2 0 0 1-2-2V5l4.31 4.306a3.338 3.338 0 0 0 4.714 0L14.667 5v7a2 2 0 0 1-2 2Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmailIcon;
