import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import CrackedGrainIcon from '../../icons/crackedGrain.icon';
import CustomButton from '../../components/shared/customButton/customButton';
import CustomModal from '../../components/shared/customModal/customModal';
import InputField from '../../components/shared/customInputField/customInputFiled';
import PencilIcon from '../../icons/pencil.icon';
import styles from './dashboardWeightSection.module.scss';

type crackedGrainTypes = {
  show: boolean;
  savePortion?: () => void;
  onCloseModal: () => void;
  setEnterCrackedProportion: (value: string) => void;
  setEnterCrackedNote: (value: string) => void;
};
function CrackedGrainPortionModal({
  savePortion,
  show,
  onCloseModal,
  setEnterCrackedProportion,
  setEnterCrackedNote,
}: crackedGrainTypes) {
  const [loading, setLoading] = useState<boolean>(false);
  const [note, setNote] = useState('');
  const [isInputFocused, setInputFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const initialVal = useMemo(() => {
    return {
      crackedProportion: '',
      note: '',
    };
  }, []);

  const validationSchema = Yup.object().shape({
    crackedProportion: Yup.string()
      .matches(
        /^(\d{1,2}(\.\d{1,2})?|100(\.0{1,2})?)$/,
        'Invalid percentage format'
      )
      .required('Please enter cracked proportion.'),
    note: Yup.string(),
  });

  const handleSubmit = () => {
    setLoading(true);
    if (savePortion) {
      savePortion();
      setLoading(false);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      inputRef.current &&
      inputRef.current instanceof Node &&
      !inputRef.current.contains(event.target as Node)
    ) {
      setInputFocused(true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);
  useEffect(() => {
    setNote('');
  }, [show]);
  return (
    <CustomModal open={show} onCloseModal={onCloseModal}>
      <Formik
        initialValues={initialVal}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (
          <Form className={styles.modal}>
            <div>
              <div>
                <h6 className={'primary-color-text text-bolder mb-3'}>
                  Cracked Grains Proportion
                </h6>
                <InputField
                  autoFocus={true}
                  type={'number'}
                  name={'crackedProportion'}
                  icon={<CrackedGrainIcon />}
                  onFocus={() => setInputFocused(true)}
                  onBlur={() => setInputFocused(false)}
                  //placeholder={'----'}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('crackedProportion', e.target.value);
                    if (isInputFocused) {
                      setEnterCrackedProportion(e.target.value);
                    } else {
                      setEnterCrackedProportion(e.target.value);
                    }
                  }}
                />
              </div>
              <div className={'mt-4'}>
                <h6 className={'primary-color-text text-bolder mb-3'}>
                  Note on Cracked Grains
                </h6>
                {/*<InputField*/}
                {/*  name={'note'}*/}
                {/*  icon={<PencilIcon />}*/}
                {/*  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {*/}
                {/*    setFieldValue('note', e.target.value);*/}
                {/*    setEnterCrackedNote(e.target.value);*/}
                {/*  }}*/}
                {/*/>*/}
                <div className={styles.textAreaContainer}>
                  <div className={styles.iconContainer}>
                    <PencilIcon />
                  </div>
                  <textarea
                    rows={2}
                    name={'note'}
                    className={styles.customTextarea}
                    value={note}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                      setFieldValue('note', e.target.value);
                      setEnterCrackedNote(e.target.value);
                      setNote(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <CustomButton
              type={'submit'}
              className={' mt-4'}
              text={'Save Info'}
              loading={loading}
            />
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
}

export default CrackedGrainPortionModal;
