import * as React from 'react';
import {
  UserRoleDisplayFormat,
  UserType,
} from '../../../../typeDef/user.model';
import Popup from 'reactjs-popup';
import styles from './userListTableRow.module.scss';

interface Props {
  text: string;
  type?: string;
  addElipsis?: boolean;
}
const TableRow: React.FC<Props> = ({ text, type, addElipsis }) => {
  const textRef = React.useRef<HTMLParagraphElement | null>(null);

  return (
    <div style={{ paddingLeft: '8px', paddingRight: '8px' }}>
      {!addElipsis ? (
        <p
          ref={textRef}
          // title={shouldShowTooltip ? text : undefined}
          className={
            type === UserType.PERMANENT
              ? styles.rowTextPermanent
              : type === UserType.CASUAL && text === UserRoleDisplayFormat.ADMIN
              ? styles.rowTextAdmin
              : type === UserType.CASUAL && text === UserRoleDisplayFormat.GUEST
              ? styles.rowTextGuest
              : addElipsis
              ? styles.elipsis
              : styles.rowText
          }
        >
          {type === UserType.PERMANENT
            ? `● ${text}`
            : type === UserType.CASUAL
            ? `● ${text}`
            : text}
        </p>
      ) : (
        <Popup
          on={'hover'}
          trigger={
            <p
              ref={textRef}
              // title={shouldShowTooltip ? text : undefined}
              className={styles.elipsis}
            >
              {text}
            </p>
          }
          position="bottom center"
        >
          <div className={styles.actionContainer}>
            <p>{text}</p>
          </div>
        </Popup>
      )}
    </div>
  );
};

export default TableRow;
