import React from 'react';

interface Props {
  size?: number;
  color?: string;
}

const LockIcon: React.FC<Props> = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 16}
      height={size ?? 16}
      fill="none"
      viewBox={'0 0 16 16'}
    >
      <g clipPath="url(#a)">
        <path
          fill="#003359"
          d="M12.667 5.616v-.95a4.667 4.667 0 0 0-9.334 0v.95a3.333 3.333 0 0 0-2 3.05v4A3.337 3.337 0 0 0 4.667 16h6.666a3.337 3.337 0 0 0 3.334-3.333v-4a3.334 3.334 0 0 0-2-3.051Zm-8-.95a3.333 3.333 0 0 1 6.666 0v.667H4.667v-.666Zm8.666 8a2 2 0 0 1-2 2H4.667a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2h6.666a2 2 0 0 1 2 2v4Z"
        />
        <path
          fill="#373737"
          d="M8 9.333a.667.667 0 0 0-.667.667v1.333a.667.667 0 1 0 1.334 0V10A.667.667 0 0 0 8 9.333Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LockIcon;
