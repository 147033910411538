import React from 'react';

interface Props {
  size?: number;
  color?: string;
}

const EyeIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 16}
      height={size ?? 16}
      fill="none"
      viewBox={'0 0 16 16'}
    >
      <g clipPath="url(#a)">
        <path
          fill={color ?? '#003359'}
          d="M15.514 6.28C14.48 4.594 12.128 1.77 8 1.77S1.52 4.595.486 6.28a3.272 3.272 0 0 0 0 3.44C1.52 11.406 3.872 14.23 8 14.23s6.48-2.825 7.514-4.51a3.272 3.272 0 0 0 0-3.44Zm-1.137 2.743C13.49 10.467 11.48 12.897 8 12.897c-3.48 0-5.49-2.43-6.377-3.874a1.945 1.945 0 0 1 0-2.046C2.51 5.533 4.52 3.103 8 3.103c3.48 0 5.49 2.428 6.377 3.874a1.945 1.945 0 0 1 0 2.046Z"
        />
        <path
          fill="#373737"
          d="M8 4.667a3.333 3.333 0 1 0 0 6.666 3.333 3.333 0 0 0 0-6.667ZM8 10a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EyeIcon;
