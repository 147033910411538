import * as Yup from 'yup';

import { Form, Formik } from 'formik';
import React, { useState } from 'react';
// import {
//   getScannedKeepsCount,
//   getScannedSamplesCount,
//   getTrialSize,
//   getTrialSizeOfKeep,
//   scanBarcode,
// } from '../../services/grain.service';

import ButtonGroup from '../../components/shared/radioButtonWithCheckbox/buttonGroup';
import ClockIcon from '../../icons/clock.icon';
import CommonLayout from '../../components/layout/commonLayout';
import CustomButton from '../../components/shared/customButton/customButton';
import CustomInputFiled from '../../components/shared/customInputField/customInputFiled';
//import { KeepCullResultLocationState } from './keepcullResult';
import { LabelType } from '../../typeDef/devices.model';
//import MessageModal from '../../components/modal/messageModal';
//import ScanCode from '../../components/scanCode/scanCode';
//import { ScanContext } from '../../typeDef/grain.model';
//import SplitsIcon from '../../icons/split.icon';
import {
  //isPrinterConnected,
  requestPrinterAccess,
} from '../../services/devices.service';
//import { logger } from '../../logger';
//import { playBeepSound } from '../../common/playSounds';
import styles from '../dashboardWeightSection/dashboardWeightSection.module.scss';
import { useNavigate } from 'react-router-dom';

export default function KeepCullSelection() {
  const navigate = useNavigate();

  //const [loading, setLoading] = useState(false);

  // Form value state variables
  const [keepCullPrintSelection, setKeepCullPrintSelection] = useState<
    'Yes' | 'No'
  >('No');
  const [keepCullPrintType, setKeepCullPrintType] = useState<
    '1D barcode' | '2D barcode' | 'QR barcode'
  >('2D barcode');
  // const [splittingSelection, setSplittingSelection] = useState<'Yes' | 'No'>(
  //   'No'
  // );
  const [numberOfSplits, setNumberOfSplits] = useState<number>(2);
  const [delaySeconds, setDelaySeconds] = useState<number>(2);

  // Modal state variables
  //const [isScanCodeModalVisible, setScanCodeModalVisible] = useState(false);
  // const [openModal, setOpenModal] = useState(false);
  // const [isSuccess, setIsSuccess] = useState(false);
  // const [isError, seIsError] = useState(false);
  // const [messageText, setMessageText] = useState('');

  // Form validation schema
  const validationSchema = Yup.object().shape({
    splits: Yup.number()
      .min(2, 'This value must be greater than or equal to 2')
      .max(10, 'This value must be less than or equal to 10')
      .required('This value is required.'),
    delaySeconds: Yup.number()
      .min(1, 'This value must be greater than or equal to 1')
      .max(10, 'This value must be less than or equal to 10')
      .required('This value is required.'),
  });

  // /**
  //  * Navigate to the keep cull result page
  //  * @param barcode The barcode to scan
  //  * @returns {void}
  //  */
  // const goToNextScreen = async (barcode: string): Promise<void> => {
  //   setLoading(true);
  //   try {
  //     // Get the scan context
  //     const context =
  //       keepCullPrintSelection === 'Yes'
  //         ? ScanContext.KEEP_CULL_PRINT
  //         : ScanContext.KEEP_CULL;
  //
  //     // Get the sample details
  //     const sample = await scanBarcode(barcode, context);
  //
  //     // If no sample found, show error message
  //     if (!sample) {
  //       setLoading(false);
  //       setIsSuccess(false);
  //       seIsError(true);
  //       playBeepSound();
  //       setMessageText(
  //         navigator.onLine
  //           ? `No data was found`
  //           : `
  //             No data was found.
  //             The device is offline, so the barcode may not have been synced yet.
  //           `
  //       );
  //       setOpenModal(true);
  //       return;
  //     }
  //
  //     // If the sample does not have enough data, show error message
  //     const splits = splittingSelection === 'Yes' ? numberOfSplits : 1;
  //     if (
  //       keepCullPrintSelection === 'Yes' &&
  //       sample.future === 'KEEP' &&
  //       (sample.trials.length < splits || sample.entries.length < splits)
  //     ) {
  //       setLoading(false);
  //       setIsSuccess(false);
  //       seIsError(true);
  //       playBeepSound();
  //       setMessageText(
  //         splits === 1
  //           ? `The sample ${barcode} does not have any future trial data.`
  //           : `The sample ${barcode} does not have enough data to be split ${numberOfSplits} times.`
  //       );
  //       setOpenModal(true);
  //       return;
  //     }
  //     if (sample.future === '' || sample.future === null) {
  //       setLoading(false);
  //       setIsSuccess(false);
  //       seIsError(true);
  //       playBeepSound();
  //       setMessageText(
  //         `Barcode scanned was found, but FUTURE content is invalid entry
  //                 (FUTURE column must contain “KEEP” or “CULL”)`
  //       );
  //       setOpenModal(true);
  //       return;
  //     }
  //     // Get the trial information
  //     const trialSize = await getTrialSize(sample.trialName);
  //     const trialOfKeepSize = await getTrialSizeOfKeep(sample.trialName);
  //
  //     const scannedSamplesCount = await getScannedSamplesCount(
  //       sample.trialName,
  //       context
  //     );
  //     const scannedKeepsCount = await getScannedKeepsCount(
  //       sample.trialName,
  //       context
  //     );
  //
  //     // Connect to the printer
  //     if (keepCullPrintSelection === 'Yes') {
  //       if (!(await isPrinterConnected())) {
  //         setLoading(false);
  //         setIsSuccess(false);
  //         seIsError(true);
  //         playBeepSound();
  //         setMessageText('Could not connect to printer.');
  //         setOpenModal(true);
  //         return;
  //       }
  //     }
  //
  //     // Navigate to the result page
  //     setLoading(false);
  //     const state: KeepCullResultLocationState = {
  //       scannedSample: sample,
  //       trialSize: trialSize ?? 1,
  //       trialOfKeepSize: trialOfKeepSize ?? 1,
  //       scannedSamples: scannedSamplesCount ?? 0,
  //       scannedKeeps: scannedKeepsCount ?? 0,
  //       printingLabels: keepCullPrintSelection === 'Yes',
  //       labelType:
  //         keepCullPrintType === '1D barcode'
  //           ? LabelType.ONE_DIMENSION_BARCODE
  //           : keepCullPrintType === '2D barcode'
  //           ? LabelType.TWO_DIMENSION_BARCODE
  //           : LabelType.QR_CODE,
  //       numberOfSplits: splits,
  //       printingDelay: splittingSelection === 'Yes' ? delaySeconds : 0,
  //     };
  //     navigate('/keepCull/result', {
  //       state: state,
  //     });
  //   } catch (e) {
  //     // If any error occurs, show error message
  //     logger.log(e);
  //     playBeepSound();
  //     setMessageText('An unknown error occured');
  //     setLoading(false);
  //     setIsSuccess(false);
  //     seIsError(true);
  //     setOpenModal(true);
  //   }
  // };

  // Render the component
  return (
    <CommonLayout admin title={'Keep/Cull'} selectedPage={'keepCull'}>
      <div className="w-100">
        <Formik
          initialValues={{
            splits: numberOfSplits,
            delaySeconds: delaySeconds,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            setNumberOfSplits(values.splits);
            setDelaySeconds(values.delaySeconds);
            //setScanCodeModalVisible(true);

            //const splits = splittingSelection === 'Yes' ? values.splits : 1;

            const state = {
              printingLabels: keepCullPrintSelection === 'Yes',
              labelType:
                keepCullPrintType === '1D barcode'
                  ? LabelType.ONE_DIMENSION_BARCODE
                  : keepCullPrintType === '2D barcode'
                  ? LabelType.TWO_DIMENSION_BARCODE
                  : LabelType.QR_CODE,
              //numberOfSplits: splits,
              printingDelay: values.delaySeconds,
            };
            navigate('/keepCull/result', {
              state: state,
            });
          }}
          enableReinitialize={true}
        >
          {({ setFieldValue, resetForm }) => (
            <Form className={styles.userProfile}>
              <div className="row">
                <div className="col-lg-6">
                  <div className={`card ${styles.customCard}`}>
                    <div className={styles.customCardBodyDashboard}>
                      <h6 className="card-title mb-3 mt-4">
                        Are you printing labels?
                      </h6>
                      <ButtonGroup
                        options={['No', 'Yes']}
                        selectedOption={keepCullPrintSelection}
                        onSelect={async (option: string) => {
                          if (option === 'No') {
                            //setSplittingSelection('No');
                            resetForm();
                          } else {
                            await requestPrinterAccess();
                          }
                          setKeepCullPrintSelection(option as 'Yes' | 'No');
                        }}
                      />
                      {keepCullPrintSelection === 'Yes' && (
                        <>
                          <h6 className="card-title mb-3 mt-4">Print Type</h6>
                          <ButtonGroup
                            options={['1D barcode', '2D barcode', 'QR barcode']}
                            selectedOption={keepCullPrintType}
                            onSelect={(option: string) => {
                              setKeepCullPrintType(
                                option as
                                  | '1D barcode'
                                  | '2D barcode'
                                  | 'QR barcode'
                              );
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={'row'}>
                <div className="col-lg-6 mt-4">
                  <div className={`card ${styles.customCard}`}>
                    <div className={`${styles.customCardBody} pb-5`}>
                      {/*{keepCullPrintSelection === 'Yes' && (*/}
                      {/*  <div>*/}
                      {/*    <h6 className="card-title mb-4">*/}
                      {/*      Are you splitting samples?*/}
                      {/*    </h6>*/}
                      {/*    <ButtonGroup*/}
                      {/*      options={['Yes', 'No']}*/}
                      {/*      selectedOption={splittingSelection}*/}
                      {/*      onSelect={(option: string) => {*/}
                      {/*        if (option === 'No') {*/}
                      {/*          resetForm();*/}
                      {/*        }*/}
                      {/*        setSplittingSelection(option as 'Yes' | 'No');*/}
                      {/*      }}*/}
                      {/*    />*/}
                      {/*  </div>*/}
                      {/*)}*/}
                      <div className={`${styles.customButtonWrapper} mb-2`}>
                        {keepCullPrintSelection === 'Yes' && (
                          <div className={'row'}>
                            {/*<div className={'col-sm-6'}>*/}
                            {/*  <h6 className="card-title mt-3 mb-3">*/}
                            {/*    No of Splits*/}
                            {/*  </h6>*/}
                            {/*  <CustomInputFiled*/}
                            {/*    name={'splits'}*/}
                            {/*    icon={<SplitsIcon />}*/}
                            {/*    onChange={(*/}
                            {/*      e: React.ChangeEvent<HTMLInputElement>*/}
                            {/*    ) => setFieldValue('splits', e.target.value)}*/}
                            {/*  />*/}
                            {/*</div>*/}
                            <div className={'col-sm-6 '}>
                              <h6 className="card-title mb-3">
                                Seconds delay between prints
                              </h6>
                              <CustomInputFiled
                                name={'delaySeconds'}
                                icon={<ClockIcon />}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  setFieldValue('delaySeconds', e.target.value)
                                }
                              />
                            </div>
                          </div>
                        )}
                        <CustomButton
                          type="submit"
                          className={'mt-3'}
                          text="Run Keep/Cull"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      {/*{openModal && (*/}
      {/*  <MessageModal*/}
      {/*    title={isSuccess ? 'Success' : 'Error'}*/}
      {/*    isSuccess={isSuccess}*/}
      {/*    isError={isError}*/}
      {/*    onCloseModal={(value) => setOpenModal(value)}*/}
      {/*    openModal={openModal}*/}
      {/*    messageText={messageText}*/}
      {/*  />*/}
      {/*)}*/}

      {/*<ScanCode*/}
      {/*  source={'/keepCull'}*/}
      {/*  show={isScanCodeModalVisible}*/}
      {/*  onHide={() => {*/}
      {/*    setScanCodeModalVisible(false);*/}
      {/*  }}*/}
      {/*  onOk={async (barcode: string) => {*/}
      {/*    await goToNextScreen(barcode);*/}
      {/*  }}*/}
      {/*  onEndSession={() => {*/}
      {/*    null;*/}
      {/*  }}*/}
      {/*  isLoading={loading}*/}
      {/*/>*/}
    </CommonLayout>
  );
}
