import React from 'react';

interface Props {
  size?: number;
  color?: string;
}

const MenuIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 18}
      height={size ?? 18}
      fill="none"
      viewBox={'0 0 18 18'}
    >
      <g fill={color ?? '#373737'} clipPath="url(#a)">
        <path d="M16.792 8.25H1.208C.818 8.25.5 8.586.5 9s.317.75.708.75h15.584c.39 0 .708-.336.708-.75s-.317-.75-.708-.75ZM16.792 3H1.208C.818 3 .5 3.336.5 3.75s.317.75.708.75h15.584c.39 0 .708-.336.708-.75S17.183 3 16.792 3ZM16.792 13.5H1.208c-.39 0-.708.336-.708.75s.317.75.708.75h15.584c.39 0 .708-.336.708-.75s-.317-.75-.708-.75Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.5 0h17v18H.5z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MenuIcon;
