import React from 'react';

interface Props {
  size?: number;
  color?: string;
}

const KeepCullIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 16}
      height={size ?? 16}
      fill="none"
      viewBox={'0 0 16 16'}
    >
      <g clipPath="url(#a)">
        <path
          fill={color ?? 'currentColor'}
          d="m13.3 3.69-2.324-2.323A4.635 4.635 0 0 0 7.676 0h-3.01a3.337 3.337 0 0 0-3.333 3.333v9.334A3.337 3.337 0 0 0 4.667 16h6.666a3.337 3.337 0 0 0 3.334-3.333V6.99a4.635 4.635 0 0 0-1.367-3.3Zm-.943.943c.204.21.379.445.52.7H10a.667.667 0 0 1-.667-.666V1.789c.255.142.49.317.7.52l2.324 2.324Zm.976 8.034a2 2 0 0 1-2 2H4.667a2 2 0 0 1-2-2V3.333a2 2 0 0 1 2-2h3.01c.11 0 .215.022.323.032v3.302a2 2 0 0 0 2 2h3.302c.01.108.031.213.031.323v5.677ZM11.15 8.874a.667.667 0 0 1-.023.942l-2.393 2.276a2 2 0 0 1-2.817-.011l-1.027-.916a.667.667 0 0 1 .888-.996l1.057.942a.668.668 0 0 0 .97.027l2.4-2.287a.667.667 0 0 1 .945.023Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KeepCullIcon;
