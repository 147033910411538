import { PaginationType } from './common.model';
export interface IWeightEntry {
  id: string;
  sample?: number;
  above?: number;
  below?: number;
  hectolitre?: number;
  bag?: number;
  cracked?: number;
  crackedNotes?: string;
  imagePath?: string;
  imageNotes?: string;
}

export enum WeightUnit {
  GRAMS = 'Grams',
  KILOGRAMS = 'Kilograms',
}

export enum Stat {
  KEEP = 'KEEP',
  CULL = 'CULL',
}

export enum BoolValue {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
}

export enum Answer {
  YES = 'Yes',
  NO = 'No',
}

export enum TabSwitch {
  WEIGH_AND_KC_SUMMARY = 'Weights and Keep/Cull summary',
  FUTURE_TRAIL_SUMMARY = 'Future Trial Summary',
  WEIGHT = 'Weight',
  KC_LABEL_NP = 'Keep/Cull (Label Not Printed)',
  KC_LABEL_P = 'Keep/Cull (Label Printed)',
}

export enum ScannedGrainStatus {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
}

export enum WeightAndKeepCullSummaryCol {
  FILE_NAME = 'FILE NAME',
  TRAIL_NAME = 'EXPERIMENT NAME',
  MISSING = '# MISSING',
  COMPLETE = '% COMPLETE',
  MISSING_W = '# MISSING_W',
  COMPLETE_W = '% COMPLETE_W',
  W_AND_KC_MISSING_ACTION = 'MISSING ACTION',
}

export enum TrailsRequest {
  TR_FILE_NAME = 'fileName',
  TR_TRAIL_NAME = 'trialName',
  TR_LAST_UPDATE = 'lastUpdated',
  TR_KC_MISSING = 'keepCullMissing',
  TR_KC_COMPLETE = 'keepCullComplete',
  TR_W_MISSING = 'weightMissing',
  TR_W_COMPLETE = 'weightComplete',
}

export enum FutureTrailSummaryCol {
  FUTURE_TRAIL = 'FUTURE TRIAL',
  FT_MISSING = '#MISSING',
  FT_COMPLETE = '%COMPLETE',
  FT_MISSING_ACTION = 'MISSING ACTION',
}

export enum FutureTrailRequest {
  FTR_FUTURE_TRAIL = 'futureTrialName',
  FTR_KC_MISSING = 'keepCullMissing',
  FTR_KC_COMPLETE = 'keepCullComplete',
}

export enum MissingViewCol {
  RANGE = 'RANGE',
  ROW = 'ROW',
  NAME = 'NAME',
  TRIALNAME = 'EXPERIMENT',
  BARCODE = 'BARCODE',
  PLOT = 'PLOT',
  VAREITYNAME = 'VARIETY NAME',
}

export enum MissingRequest {
  MR_RANGE = 'pRange',
  MR_ROW = 'pRow',
  MR_NAME = 'name',
  MR_TRIALNAME = 'trialName',
  MR_BARCODE = 'barcode',
  MR_PLOT = 'plot',
  MR_EXPERIMENT = 'experiment',
}

/**
 * The individual entries in a weigh grain request.
 */
export interface WeighGrainEntry {
  id: string;
  sample?: number;
  above?: number;
  below?: number;
  hectolitre?: number;
  bag?: number;
  cracked?: number;
  crackedNotes?: string;
  imagePath?: string;
  imageNotes?: string;
  weightNotes?: string;
}

export enum ScanContext {
  WEIGHT = 'WEIGHT',
  KEEP_CULL = 'KEEP_CULL',
  KEEP_CULL_PRINT = 'KEEP_CULL_PRINT',
}

export enum WeightTrait {
  YIELD = 'Yield',
  SCREENS = 'Screens',
  HLW = 'HLW',
  HLW_SCREENS = 'HLW + Screens',
  IMAGING = 'Imaging',
  IMAGING_HLW = 'Imaging + HLW',
  IMAGING_WEIGHT = 'Imaging + Weight',
  SCREENINGS_IMAGINGS = 'Screenings + Imaging',
  IMAGING_HLW_SCREENINGS = 'Imaging + HLW + Screenings',
}

/**
 * The request body for the weigh grain endpoint.
 */
export interface WeighGrainRequest {
  trait: string;
  unit: string;
  plotArea?: number;
  hectolitrePlotArea?: number;
  cracked?: boolean;
  weights: WeighGrainEntry[];
  workStation?: string;
}

/**
 * The request body for the keep-cull endpoint.
 */
export interface KeepCullRequest {
  samples: string[];
  printLabels: boolean;
}

/**
 * The response body for the weigh grain and keep-cull endpoints.
 */
export interface UpdatedGrainResponse {
  grain: string[];
}

/**
 * The response body for the image upload endpoint.
 */
export interface UploadImagesResponse {
  paths: string[];
}

export type TrialFile = {
  barcode: string;
  future?: string;
  id: string;
  name: string;
  range?: string;
  row?: string;
  scannedKeepCull?: string;
  scannedKeepCullPrint?: string;
  scannedWeight?: string;
};

// GRAIN RELATED OBJECT TYPES

/**
 * A sample is a grain entry in a trial.
 * It is identified by a unique ID and a unique barcode.
 */
export type Sample = {
  id: string;
  trialName: string;
  barcode: string;
  future: string;
  name: string;
  range: string;
  row: string;
  scannedKeepCull: BoolValue;
  scannedKeepCullPrint: BoolValue;
  scannedWeight: BoolValue;
  entries: string[];
  trials: string[];
  version: number;
};

/**
 * A weight entry stored in the local database.
 * This is the data that is synced with the backend, though
 * it is not the same as the type that is sent to the backend.
 */
export type Weight = {
  id: string;
  sampleId: string;
  trait: WeightTrait;
  unit: WeightUnit;
  plotArea?: number;
  hectolitrePlotArea?: number;
  sampleWeight?: number;
  aboveWeight?: number;
  belowWeight?: number;
  hectolitreWeight?: number;
  bagWeight?: number;
  crackedProportion?: number;
  crackedNote?: string;
  image?: string;
  imageNote?: string;
  weightNote?: string;
};

export type KeepOrCull = {
  id: string;
  printedLabel: boolean;
};

export interface GrainEntryResponse {
  id: string;
  barcode: string;
  trialName: string;
  experiment: string;
  prange: string;
  prow: string;
  name: string;
  future: string;
  scanw: string;
  scankc: string;
  scankcp: string;
  entries: string[];
  trials: string[];
}

export interface GrainEntryResponse {
  id: string;
  barcode: string;
  trialName: string;
  range: string;
  row: string;
  name: string;
  future: string;
  scanw: string;
  scankc: string;
  scankcp: string;
  entries: string[];
  trials: string[];
  plot: string;
  items?: [];
  meta?: PaginationType;
}

export interface GetSamplesRequest {
  trialName?: string;
  fileName?: string;
  future?: Stat;
  scannedWeight?: ScannedGrainStatus;
  scannedKeepCull?: ScannedGrainStatus;
  scannedKeepCullPrint?: ScannedGrainStatus;
  futureTrialName?: string;

  /**
   * Pagination params
   */
  pageNumber?: number;
  pageSize?: number;

  /**
   * Sort params
   */
  sortField?: string;
  secondarySortField?: string;
  territorySortField?: string;
  sortOrder?: string;
  secondarySortOrder?: string;
  territorySortOrder?: string;
}

export interface GetTrialsRequest {
  trialName?: string;
  fileName?: string;

  /**
   * Pagination params
   */
  pageNumber?: number;
  pageSize?: number;

  /**
   * Sort params
   */
  sortField?: string;
  sortOrder?: string;
}

export interface GetTrialsResponse {
  fileName: string;
  trialName: string;
  experiment: string;
  keepCullMissing: string;
  keepCullComplete: number;
  //keepCullCompleteLabelNotPrinted: number;
  keepCullCompleteLabelPrinted: number;
  weightMissing: string;
  weightComplete: number;
  lastUpdated: Date;
  items: [];
  meta: PaginationType;
}

export interface GetFutureTrialsRequest {
  trialName?: string;
  futureTrialName?: string;

  /**
   * Pagination params
   */
  pageNumber?: number;
  pageSize?: number;

  /**
   * Sort params
   */
  sortField?: string;
  sortOrder?: string;
}

export interface GetFutureTrialsResponse {
  trialName: string;
  futureTrialName: string;
  keepCullMissing: number;
  keepCullComplete: number;
  lastUpdated: Date;
  items: [];
  meta: PaginationType;
}

export interface GetVersionResponse {
  version: number;
}
