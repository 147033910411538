import React from 'react';

interface Props {
  size?: number;
  color?: string;
}

const OverviewIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 16}
      height={size ?? 16}
      fill="none"
      viewBox={'0 0 16 16'}
    >
      <g fill={color ?? 'currentColor'} clipPath="url(#a)">
        <path d="M3 11.667H1.333A1.334 1.334 0 0 0 0 13v1.667A1.333 1.333 0 0 0 1.333 16H3a1.334 1.334 0 0 0 1.333-1.333V13A1.333 1.333 0 0 0 3 11.667Zm0 3H1.333V13H3v1.667ZM14.667 11.667H13A1.334 1.334 0 0 0 11.667 13v1.667A1.334 1.334 0 0 0 13 16h1.667A1.333 1.333 0 0 0 16 14.667V13a1.333 1.333 0 0 0-1.333-1.333Zm0 3H13V13h1.667v1.667ZM3 5.833H1.333A1.333 1.333 0 0 0 0 7.166v1.667a1.333 1.333 0 0 0 1.333 1.333H3a1.333 1.333 0 0 0 1.333-1.333V7.166A1.333 1.333 0 0 0 3 5.833Zm0 3H1.333V7.166H3v1.667ZM14.667 5.833H13a1.333 1.333 0 0 0-1.333 1.333v1.667A1.334 1.334 0 0 0 13 10.166h1.667A1.333 1.333 0 0 0 16 8.833V7.166a1.333 1.333 0 0 0-1.333-1.333Zm0 3H13V7.166h1.667v1.667ZM3 0H1.333A1.333 1.333 0 0 0 0 1.333V3a1.333 1.333 0 0 0 1.333 1.333H3A1.333 1.333 0 0 0 4.333 3V1.333A1.333 1.333 0 0 0 3 0Zm0 3H1.333V1.333H3V3ZM8.833 11.667H7.167A1.333 1.333 0 0 0 5.833 13v1.667A1.333 1.333 0 0 0 7.167 16h1.666a1.333 1.333 0 0 0 1.334-1.333V13a1.334 1.334 0 0 0-1.334-1.333Zm0 3H7.167V13h1.666v1.667ZM8.833 5.833H7.167a1.333 1.333 0 0 0-1.334 1.333v1.667a1.333 1.333 0 0 0 1.334 1.333h1.666a1.333 1.333 0 0 0 1.334-1.333V7.166a1.333 1.333 0 0 0-1.334-1.333Zm0 3H7.167V7.166h1.666v1.667ZM8.833 0H7.167a1.333 1.333 0 0 0-1.334 1.333V3a1.333 1.333 0 0 0 1.334 1.333h1.666A1.333 1.333 0 0 0 10.167 3V1.333A1.333 1.333 0 0 0 8.833 0Zm0 3H7.167V1.333h1.666V3ZM14.667 0H13a1.333 1.333 0 0 0-1.333 1.333V3A1.334 1.334 0 0 0 13 4.333h1.667A1.333 1.333 0 0 0 16 3V1.333A1.333 1.333 0 0 0 14.667 0Zm0 3H13V1.333h1.667V3Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OverviewIcon;
