import beepSound from '../sounds/beep-sound-8333.mp3';
import cullSound from '../sounds/beeep-43965.mp3';
import keepSound from '../sounds/censor-beep-88052.mp3';

export async function playBeepSound(): Promise<void> {
  try {
    // https://pixabay.com/sound-effects/search/beep/?manual_search=1
    // License: https://pixabay.com/service/license-summary/
    const audio = new Audio(beepSound);
    await audio.play();
  } catch {
    /* */
  }
}

export async function playKeepSound(): Promise<void> {
  try {
    const audio = new Audio(keepSound);
    await audio.play();
  } catch {
    /* */
  }
}

export async function playCullSound(): Promise<void> {
  try {
    const audio = new Audio(cullSound);
    await audio.play();
  } catch {
    /* */
  }
}
