import * as React from 'react';
interface Props {
  size?: number;
  color?: string;
}
const TikIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 10}
      height={size ?? 8}
      fill="none"
      viewBox={'0 0 10 8'}
    >
      <path
        stroke={color ?? '#fff'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2.083}
        d="m2 4 2 2 4-4"
      />
    </svg>
  );
};
export default TikIcon;
