import React, { useEffect } from 'react';
import CameraIcon from '../../icons/camera.icon';
import CustomButton from '../shared/customButton/customButton';
import CustomModal from '../shared/customModal/customModal';
import WhiteCameraIcon from '../../icons/whiteCamera.icon';
import styles from '../saveWeight/saveWeight.module.scss';

type ModalProps = {
  show: boolean;
  onHide: () => void;
  title?: string;
  onEnterPress: (value: boolean) => void;
  scanModalOpen: (value: boolean) => void;
};

export default function CameraModal({
  show,
  onHide,
  onEnterPress,
  scanModalOpen,
}: ModalProps) {
  const handleEnterKeyPress = (
    event?:
      | React.KeyboardEvent<Element>
      | React.MouseEvent<Element>
      | KeyboardEvent
  ) => {
    if (event && 'key' in event && event.key !== 'Enter') {
      return;
    }

    onEnterPress(true);
    scanModalOpen(true);
    onHide();
  };

  useEffect(() => {
    const keydownListener = (event: KeyboardEvent) =>
      handleEnterKeyPress(event);

    if (show) {
      document.addEventListener('keydown', keydownListener);
      return () => document.removeEventListener('keydown', keydownListener);
    } else {
      document.removeEventListener('keydown', keydownListener);
    }
  }, [show]);

  return (
    <CustomModal onCloseModal={onHide} open={show}>
      <div className={styles.cameraModal}>
        <div className={'d-flex align-items-center'}>
          <div>
            <CameraIcon />
          </div>
          <h6 className={'primary-color-text text-bolder ms-3'}>
            Capture the Image Now{' '}
          </h6>
        </div>
        <CustomButton
          type={'submit'}
          text={'OK'}
          className={'mt-5'}
          icon={<WhiteCameraIcon />}
          onClick={handleEnterKeyPress}
        />
      </div>
    </CustomModal>
  );
}
