import { ErrorResponse, PaginationType } from '../../typeDef/common.model';
import {
  GetTrialsRequest,
  GetTrialsResponse,
  TrailsRequest,
  WeightAndKeepCullSummaryCol,
} from '../../typeDef/grain.model';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import TableHeading, {
  SortDirection,
} from '../shared/tableHeading/tableHeading';
import { getFile, getRecordCountOfFile } from '../../services/excel.service';
import CustomButton from '../shared/customButton/customButton';
import CustomModal from '../shared/customModal/customModal';
import { ExportFile } from '../../typeDef/excel.model';
import JSZip from 'jszip';
import Lable from '../shared/lable/lable';
import OpenMissingView from './openMissingView';
import PaginationPages from '../shared/pagination/paginationPages';
import { getTrails } from '../../services/grain.service';

import styles from '../table/userList/userListTable.module.scss';

export interface WeightsAndKeepCullSummaryHandle {
  handleExport: () => void;
  handleSearch: () => void;
}

export const WeightsAndKeepCullSummary = forwardRef<
  WeightsAndKeepCullSummaryHandle,
  { search: string; setIsLoading: (value: boolean) => void }
>(({ search, setIsLoading }, ref) => {
  const [openMissingModal, setOpenMissingModal] = useState<boolean>(false);
  const [trailData, setTrailData] = useState<GetTrialsResponse[]>([]);
  const [paginationData, setPaginationData] = useState<PaginationType>();
  const [selectedFile, setSelectedFile] = useState<string>('');
  const [selectedTrailFile, setSelectedTrailFile] = useState<string>('');
  const [selectedKeepCullComplete, setSelectedKeepCullComplete] =
    useState<number>();
  // const [
  //   selectedKeepCullNotPrintedLabelComplete,
  //   setSelectedKeepCullNotPrintedLabelComplete,
  // ] = useState<number>();
  const [
    selectedKeepCullPrintedLabelComplete,
    setSelectedKeepCullPrintedLabelComplete,
  ] = useState<number>();
  const [selectedWeightComplete, setSelectedWeightComplete] =
    useState<number>();
  const sortField = useRef(TrailsRequest.TR_LAST_UPDATE);
  const sortOrder = useRef(SortDirection.DESC);
  const [pageSize, setPageSize] = useState(10);
  const [activePage, setActivePage] = useState<number>(1);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]); // An array to store selected files
  //const [totalRecordCount, setTotalRecordCount] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [warningMessage, setWarningMessage] = useState('');
  const [searchErrorMessage, setSearchErrorMessage] = useState('');
  const [errorModal, setErrorModal] = useState(false);
  const [warningModal, setWarningModal] = useState(false);

  const clickPrevious = () => {
    if (paginationData?.hasPreviousPage) {
      setActivePage(activePage - 1);
    }
  };

  /* click next */
  const clickNext = () => {
    if (paginationData?.hasNextPage) {
      setActivePage(activePage + 1);
    }
  };

  const getExcelFiles = async () => {
    setIsLoading(true);

    const zip = new JSZip();

    const exportPromises = selectedFiles.map(async (fileName) => {
      const query: Partial<ExportFile> = {
        trials: [], // The trials to export. If blank, will export all trials
      };

      try {
        const response = await getFile(fileName, query);
        if (selectedFiles.length > 1) {
          const fileData = response.data;

          zip.file(fileName, fileData);
        } else {
          const blob = new Blob([response.data], {
            type: response.headers['content-type'],
          });

          const blobURL = URL.createObjectURL(blob);

          const downloadLink = document.createElement('a');
          downloadLink.href = blobURL;
          downloadLink.download = fileName;

          downloadLink.click();
        }
      } catch (e) {
        setErrorMessage('Error downloading file');
        setErrorModal(true);
      }
    });

    try {
      await Promise.all(exportPromises);
      if (selectedFiles.length > 1) {
        const zipBlob = await zip.generateAsync({ type: 'blob' });

        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(zipBlob);
        downloadLink.download = 'exportedFiles.zip';

        downloadLink.click();
      }
    } catch (error) {
      setErrorMessage('Error exporting files');
      setErrorModal(true);
    }

    setIsLoading(false);
  };

  const handleExport = async () => {
    if (selectedFiles.length > 0) {
      // Calculate the total record count for selected files
      let totalRecordCount = 0;

      setIsLoading(true);

      const recordCountPromises = selectedFiles.map(async (fileName) => {
        const query: Partial<ExportFile> = {
          trials: [], // The trials to export. If blank, will export all trials
        };
        try {
          const response = await getRecordCountOfFile(fileName, query);
          totalRecordCount += Number(response.data);
        } catch (e) {
          setErrorMessage('Error fetching record count');
          setErrorModal(true);
        }
      });

      try {
        await Promise.all(recordCountPromises);

        if (totalRecordCount <= 50000) {
          // Proceed with exporting files
          await getExcelFiles();
        } else {
          setWarningMessage(
            'This Export exceeds the maximum limit of 50000 records!'
          );
          setWarningModal(true);
        }
      } catch (error) {
        setErrorMessage('Error exporting files');
        setErrorModal(true);
      } finally {
        setIsLoading(false);
      }
    } else {
      setWarningMessage('Please select a file to export!');
      setWarningModal(true);
    }
  };

  const getAllTrails = useCallback(async () => {
    setIsLoading(true);
    try {
      const query: Partial<GetTrialsRequest> = {
        pageNumber: activePage,
        pageSize: pageSize,
        sortOrder: sortOrder.current ?? SortDirection.ASC,
        sortField: sortField.current ?? TrailsRequest.TR_LAST_UPDATE,
        ...(search && {
          trialName: search.toLowerCase().trim(),
          fileName: search.toLowerCase().trim(),
        }),
      };
      const response = await getTrails(query);
      setSearchErrorMessage('');
      setTrailData(response.data.items);
      setPaginationData(response.data.meta);
      setIsLoading(false);
    } catch (e) {
      const errorResponse = e as ErrorResponse;
      setIsLoading(false);
      if (errorResponse.response.data.message === 'No items found') {
        setSearchErrorMessage('No matching results...');
        setTrailData([]);
      }
    }
  }, [pageSize, activePage, search, sortOrder, sortField]);

  useEffect(() => {
    (async () => {
      await getAllTrails();
    })();
  }, [pageSize, activePage]);

  const handleOnSort = async (sortDirection: SortDirection, title: string) => {
    const sortBy =
      title === WeightAndKeepCullSummaryCol.FILE_NAME
        ? TrailsRequest.TR_FILE_NAME
        : title === WeightAndKeepCullSummaryCol.TRAIL_NAME
        ? TrailsRequest.TR_TRAIL_NAME
        : title === WeightAndKeepCullSummaryCol.MISSING
        ? TrailsRequest.TR_KC_MISSING
        : title === WeightAndKeepCullSummaryCol.COMPLETE
        ? TrailsRequest.TR_KC_COMPLETE
        : title === WeightAndKeepCullSummaryCol.MISSING_W
        ? TrailsRequest.TR_W_MISSING
        : TrailsRequest.TR_W_COMPLETE;

    sortField.current = sortBy;
    sortOrder.current = sortDirection;

    await getAllTrails();
  };

  // const handleCheckboxClick = (item: GetTrialsResponse) => {
  //   if (selectedFile === item.fileName) {
  //     setSelectedFile('');
  //   } else {
  //     setSelectedFile(item.fileName);
  //   }
  // };

  const toggleFileSelection = async (fileName: string) => {
    //setIsLoading(true);
    // const query: Partial<ExportFile> = {
    //   trials: [], // The trials to export. If blank, will export all trials
    // };

    try {
      // const response = await getRecordCountOfFile(fileName, query);
      // const recordCount = Number(response.data);

      setSelectedFiles((prevSelected) => {
        if (prevSelected.includes(fileName)) {
          const updatedSelectedFiles = prevSelected.filter(
            (item) => item !== fileName
          );
          // const newTotal = totalRecordCount - recordCount;
          // setTotalRecordCount(newTotal);
          return updatedSelectedFiles;
        } else {
          const updatedSelectedFiles = [...prevSelected, fileName];
          // const newTotal = totalRecordCount + recordCount;
          // setTotalRecordCount(newTotal);
          return updatedSelectedFiles;
        }
      });
      //setIsLoading(false);
    } catch (e) {
      //setIsLoading(false);
      setErrorMessage('Something went wrong');
      setErrorModal(true);
    }
  };

  const handleHeaderCheckboxClick = async () => {
    //setIsLoading(true);
    if (selectedFiles.length === trailData.length) {
      setSelectedFiles([]);
      setIsLoading(false);
    } else {
      const newSelectedFiles = trailData.map((item) => item.fileName);

      // let sum = 0;
      // for (const fileName of newSelectedFiles) {
      //   const query: Partial<ExportFile> = {
      //     trials: [], // The trials to export. If blank, will export all trials
      //   };
      //
      //   try {
      //     const response = await getRecordCountOfFile(fileName, query);
      //     const recordCount = Number(response.data);
      //     sum += recordCount;
      //   } catch (e) {
      //     setErrorMessage('Something went wrong');
      //     setErrorModal(true);
      //   }
      // }
      setSelectedFiles(newSelectedFiles);
      //setTotalRecordCount(sum);
      //setIsLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    handleExport: handleExport,
    handleSearch: async () => {
      if (search === '' || activePage === 1) {
        await getAllTrails();
      }
      setActivePage(1);
    },
  }));

  return (
    <div>
      <table className="table table-striped table-hover mt-5">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th
              scope="col"
              colSpan={2}
              className={`table-light text-center centered text-italic text-bold ${styles.borderClass}`}
            >
              <h6 className={`primary-color-text ${styles.rowTextCheck}`}>
                Keep/Cull
              </h6>
            </th>
            <th
              scope="col"
              colSpan={2}
              className={`table-light text-center centered text-italic text-bold ${styles.borderClass}`}
            >
              <h6 className={`primary-color-text ${styles.rowTextCheck}`}>
                Weights
              </h6>
            </th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
          <tr>
            <th scope="col" className={'table-primary'}>
              <div className={'mb-3'}>
                <input
                  type="checkbox"
                  className="form-check-input"
                  onChange={handleHeaderCheckboxClick}
                  checked={selectedFiles.length === trailData.length}
                />
              </div>
            </th>
            <th scope="col" className={'table-primary'}>
              <TableHeading
                field={WeightAndKeepCullSummaryCol.FILE_NAME}
                sortable={true}
                onSort={(sortDirection, title) =>
                  handleOnSort(sortDirection, title)
                }
              />
            </th>
            <th scope="col" className={'table-primary'}>
              <TableHeading
                field={WeightAndKeepCullSummaryCol.TRAIL_NAME}
                sortable={true}
                onSort={(sortDirection, title) =>
                  handleOnSort(sortDirection, title)
                }
              />
            </th>
            <th
              scope="col"
              className={`table-light ${styles.subborderClassLeft}`}
            >
              <TableHeading
                field={WeightAndKeepCullSummaryCol.MISSING}
                sortable={true}
                onSort={(sortDirection, title) =>
                  handleOnSort(sortDirection, title)
                }
              />
            </th>
            <th
              scope="col"
              className={`table-light ${styles.subborderClassRight}`}
            >
              <TableHeading
                field={WeightAndKeepCullSummaryCol.COMPLETE}
                sortable={true}
                onSort={(sortDirection, title) =>
                  handleOnSort(sortDirection, title)
                }
              />
            </th>
            <th
              scope="col"
              className={`table-light ${styles.subborderClassLeft}`}
            >
              <TableHeading
                field={WeightAndKeepCullSummaryCol.MISSING_W}
                sortable={true}
                onSort={(sortDirection, title) =>
                  handleOnSort(sortDirection, title)
                }
              />
            </th>
            <th
              scope="col"
              className={`table-light ${styles.subborderClassRight}`}
            >
              <TableHeading
                field={WeightAndKeepCullSummaryCol.COMPLETE_W}
                sortable={true}
                onSort={(sortDirection, title) =>
                  handleOnSort(sortDirection, title)
                }
              />
            </th>
            <th scope="col" className={'table-primary'}>
              <TableHeading
                field={WeightAndKeepCullSummaryCol.W_AND_KC_MISSING_ACTION}
                sortable={false}
                onSort={(sortDirection, title) =>
                  handleOnSort(sortDirection, title)
                }
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {trailData?.map((item: GetTrialsResponse, index: number) => {
            return (
              <tr key={index} className={styles.borderBottom}>
                <td className={styles.rowResponsive}>
                  <div className={styles.rowTextCheck}>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      // onChange={() => handleCheckboxClick(item)}
                      // checked={selectedFile === item.fileName}
                      onChange={() => toggleFileSelection(item.fileName)}
                      checked={selectedFiles.includes(item.fileName)}
                    />
                  </div>
                </td>
                <td className={styles.rowResponsive}>
                  <p className={styles.rowText}>{item.fileName}</p>
                </td>
                <td className={styles.rowResponsive}>
                  <p className={styles.rowText}>{item.trialName}</p>
                </td>
                <td className={styles.subborderClassLeft}>
                  {item.keepCullMissing === '0' ? (
                    <p className={styles.rowText}>
                      <Lable
                        labelSuccess={true}
                        labelText={`${item.keepCullMissing}`}
                      />
                    </p>
                  ) : (
                    <p className={styles.rowTextWithoutLabel}>
                      {item.keepCullMissing}
                    </p>
                  )}
                </td>
                <td className={styles.subborderClassRight}>
                  <p className={styles.rowText}>
                    <Lable
                      labelSuccess={
                        item.keepCullComplete * 100 === 100 ? true : false
                      }
                      labelText={`${
                        Math.floor(item.keepCullComplete * 100 * 10) / 10
                      }%`}
                    />
                  </p>
                </td>
                <td>
                  {item.weightMissing === '0' ? (
                    <p className={styles.rowText}>
                      <Lable
                        labelSuccess={true}
                        labelText={`${item.weightMissing}`}
                      />
                    </p>
                  ) : (
                    <p className={styles.rowTextWithoutLabel}>
                      {item.weightMissing}
                    </p>
                  )}
                </td>
                <td className={styles.subborderClassRight}>
                  <p className={styles.rowText}>
                    <Lable
                      labelSuccess={
                        item.weightComplete * 100 === 100 ? true : false
                      }
                      labelText={`${
                        Math.floor(item.weightComplete * 100 * 10) / 10
                      }%`}
                    />
                  </p>
                </td>
                <td>
                  <div className={styles.rowText}>
                    {(item.weightMissing !== '0' ||
                      item.keepCullMissing !== '0') && (
                      <CustomButton
                        text={'View Missing'}
                        className={'fit-content'}
                        type={'button'}
                        onClick={() => {
                          setOpenMissingModal(true);
                          setSelectedFile(item.fileName);
                          setSelectedTrailFile(item.trialName);
                          setSelectedKeepCullComplete(
                            item.keepCullComplete * 100
                          );
                          // setSelectedKeepCullNotPrintedLabelComplete(
                          //   item.keepCullCompleteLabelNotPrinted * 100
                          // );
                          setSelectedKeepCullPrintedLabelComplete(
                            item.keepCullCompleteLabelPrinted * 100
                          );
                          setSelectedWeightComplete(item.weightComplete * 100);
                        }}
                      />
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {searchErrorMessage && (
        <div>
          <h4 className={'mt-5 text-danger text-center'}>
            {searchErrorMessage}
          </h4>
        </div>
      )}

      <div className={'mt-5'}>
        <div className={'d-flex justify-content-between'}>
          <div className={'d-flex align-items-center'}>
            <h6 className={`me-3 ${styles.rowTest}`}>Rows per page </h6>
            <select
              id="framework"
              className={` font-size-default ${styles.selectBox}`}
              value={pageSize}
              onChange={(e) => {
                setPageSize(e.target.value as unknown as number);
                setActivePage(1);
              }}
            >
              <option
                value="05"
                className={'font-size-default primary-color-text'}
              >
                05
              </option>
              <option
                value="10"
                selected={true}
                className={'font-size-default primary-color-text'}
              >
                10
              </option>
              <option
                value="15"
                className={'font-size-default primary-color-text'}
              >
                15
              </option>
              <option
                value="20"
                className={'font-size-default primary-color-text'}
              >
                20
              </option>
              <option
                value="100"
                className={'font-size-default primary-color-text'}
              >
                100
              </option>
              <option
                value="200"
                className={'font-size-default primary-color-text'}
              >
                200
              </option>
            </select>
          </div>
          <PaginationPages
            hasPreviousPage={paginationData?.hasPreviousPage}
            hasNextPage={paginationData?.hasNextPage}
            clickPrevious={clickPrevious}
            clickNext={clickNext}
            setActivePage={(value) => setActivePage(value as unknown as number)}
            currentPage={activePage}
            totalCount={paginationData?.itemCount ?? 10}
            pageSize={pageSize}
          />
        </div>
      </div>
      {openMissingModal && (
        <CustomModal
          open={openMissingModal}
          onCloseModal={() => setOpenMissingModal(false)}
        >
          <OpenMissingView
            onCloseModal={(value) => {
              setOpenMissingModal(value);
            }}
            fileName={selectedFile}
            trailName={selectedTrailFile}
            keepCullCompleteStatus={
              Math.floor((selectedKeepCullComplete ?? 0) * 10) / 10
            }
            // keepCullNotPrintedLabelCompleteStatus={
            //   Math.floor((selectedKeepCullNotPrintedLabelComplete ?? 0) * 10) /
            //   10
            // }
            keepCullPrintedLabelCompleteStatus={
              Math.floor((selectedKeepCullPrintedLabelComplete ?? 0) * 10) / 10
            }
            weightCompleteStatus={
              Math.floor((selectedWeightComplete ?? 0) * 10) / 10
            }
          />
        </CustomModal>
      )}
      {errorModal && (
        <CustomModal
          open={errorModal}
          title={'Error'}
          deleteTitle
          onCloseModal={(val) => setErrorModal(val)}
        >
          <div className={styles.modalWidth}>
            <h6 className={'light-text text-normal'}>{errorMessage}</h6>
            <div
              className={
                'd-flex justify-content-end align-items-center mt-5 w-100'
              }
            >
              <CustomButton
                text={'OK'}
                onClick={() => setErrorModal(false)}
                className={styles.buttonWidth}
              />
            </div>
          </div>
        </CustomModal>
      )}
      {warningModal && (
        <CustomModal
          open={warningModal}
          title={'Warning'}
          warning
          onCloseModal={(val) => setWarningModal(val)}
        >
          <div className={styles.modalWidth}>
            <h6 className={'light-text text-normal'}>{warningMessage}</h6>
            <div
              className={
                'd-flex justify-content-end align-items-center mt-5 w-100'
              }
            >
              <CustomButton
                text={'OK'}
                onClick={() => setWarningModal(false)}
                className={styles.buttonWidth}
              />
            </div>
          </div>
        </CustomModal>
      )}
    </div>
  );
});

WeightsAndKeepCullSummary.displayName = 'WeightsAndKeepCullSummary';
