import React from 'react';

interface Props {
  size?: number;
  color?: string;
}

const Microsoft: React.FC<Props> = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 18}
      height={size ?? 18}
      fill="none"
      viewBox={'0 0 18 18'}
    >
      <g clipPath="url(#a)">
        <path fill="#F1511B" d="M8.555 8.555H0V0h8.555v8.555Z" />
        <path fill="#80CC28" d="M18 8.555H9.445V0H18v8.555Z" />
        <path fill="#00ADEF" d="M8.554 18H0V9.446h8.554V18Z" />
        <path fill="#FBBC09" d="M18 18H9.445V9.446H18V18Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Microsoft;
