import NextIcon from '../../../icons/next.icon';
import PreviousIcon from '../../../icons/previous.icon';
import React from 'react';

import styles from '../../table/userList/userListTable.module.scss';
import { usePagination } from '../hooks/usePaginationHook';
// Define the DOTS constant
const DOTS = '...';
interface Props {
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
  clickPrevious: () => void;
  clickNext: () => void;
  setActivePage: (value: number | string) => void;
  currentPage: number;
  totalCount: number;
  pageSize: number;
  siblingCount?: number;
}
const PaginationPages: React.FC<Props> = ({
  hasPreviousPage,
  hasNextPage,
  clickPrevious,
  clickNext,
  setActivePage,
  currentPage,
  totalCount,
  pageSize,
  siblingCount = 1,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  return (
    <div className={'d-flex align-items-center'}>
      <nav aria-label="Pagination " className={'d-flex align-items-center'}>
        <button onClick={clickPrevious} className={styles.button}>
          <PreviousIcon color={hasPreviousPage ? '#003359' : '#E9E9E9'} />
        </button>
        <button
          className={`px-2 py-2 text-decoration-none ${styles.button}`}
          onClick={clickPrevious}
        >
          <span className={styles.rowTest}>Previous</span>
        </button>

        {paginationRange &&
          paginationRange.map((pageNumber, index) => {
            /**
             * If the pageItem is a DOT, render the DOTS unicode character
             */
            if (pageNumber === DOTS) {
              return (
                <span key={index} className={`${styles.rowTest}`}>
                  ...
                </span>
              );
            }

            return (
              <li
                key={index}
                className={`text-decoration-none pointer ${
                  pageNumber === currentPage
                    ? styles.pageNumberBoxActive
                    : styles.pageNumberBox
                }`}
                onClick={() => setActivePage(pageNumber)}
              >
                {pageNumber}
              </li>
            );
          })}

        <button
          className={`px-2 py-2 text-decoration-none ${styles.button}`}
          onClick={clickNext}
        >
          <span className={styles.rowTest}>Next</span>
        </button>
        <button className={styles.button} onClick={clickNext}>
          <NextIcon color={hasNextPage ? '#003359' : '#E9E9E9'} />
        </button>
      </nav>
    </div>
  );
};

export default PaginationPages;
