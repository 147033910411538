import React, { useState } from 'react';
import CustomButton from '../shared/customButton/customButton';
import { ErrorResponse } from '../../typeDef/common.model';
import FileImport from '../fileImport/fileImport';
import { importNewUsersFile } from '../../services/excel.service';
import styles from '../table/userList/userListTable.module.scss';

type UserCreateProps = {
  onCloseModal: (value: boolean) => void;
  setIsSuccess: (value: boolean) => void;
  setIsError: (value: boolean) => void;
  setOpenModal: (value: boolean) => void;
  setMessageText: (value: string) => void;
};

const FileUploadModal = ({
  onCloseModal,
  setIsSuccess,
  setIsError,
  setOpenModal,
  setMessageText,
}: UserCreateProps) => {
  const [isLoading, setIsLoading] = useState(false);
  // const [fileValue, setFileValue] = useState<File>();
  // const [error, setError] = useState(false);
  const [clearInput, setClearInput] = useState(false);

  const importExcelFile = async (excelFile: File) => {
    try {
      //setError(false);
      setIsLoading(true);
      //setFileValue(excelFile);
      const data = new FormData();
      data.append('file', excelFile);
      await importNewUsersFile(data);
      setIsLoading(false);
      setIsSuccess(true);
      setIsError(false);
      setMessageText(`${excelFile.name} successfully imported.`);
      setOpenModal(true);
      setClearInput(true);
    } catch (err) {
      const errorResponse = err as ErrorResponse;
      setIsLoading(false);
      setIsSuccess(false);
      setIsError(true);
      setMessageText(
        errorResponse.response.data.message === 'File already exists'
          ? ` File “${excelFile.name}” already exists. Please try again with a different file or filename.`
          : errorResponse.response.data.message.includes(
              'Invalid Opening Quote:'
            )
          ? `Invalid file content. Ensure you are uploading a valid CSV file.`
          : `${errorResponse.response.data.message}.`
      );
      //setError(true);
      setOpenModal(true);
      setClearInput(true);
    }
  };

  return (
    <div>
      <FileImport
        fileSelect={async (value) => {
          await importExcelFile(value);
        }}
        isInputClear={clearInput}
        setMessageText={(value) => setMessageText(value)}
        setIsLoading={(value) => setIsLoading(value)}
        setIsSuccess={(value) => setIsSuccess(value)}
        seIsError={(value) => {
          setIsError(value);
          //setError(value);
        }}
        setOpenModal={(value) => setOpenModal(value)}
        isLoading={isLoading}
        enableDrop={!isLoading}
        from={'userManager'}
      />
      <div className={'d-flex justify-content-end align-items-center mt-5'}>
        <CustomButton
          text={'Cancel'}
          variant={'transparent'}
          onClick={() => onCloseModal(false)}
          className={styles.buttonWidth}
        />
        {/*<CustomButton*/}
        {/*  text={'Ok'}*/}
        {/*  className={`ms-4 ${styles.buttonWidth}`}*/}
        {/*  type={'button'}*/}
        {/*  disabled={error || !fileValue}*/}
        {/*  onClick={() => onCloseModal(false)}*/}
        {/*/>*/}
      </div>
    </div>
  );
};
export default FileUploadModal;
