import * as React from 'react';
interface Props {
  size?: number;
  color?: string;
}
const UpIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 8}
      height={size ?? 6}
      fill="none"
    >
      <path
        fill={color ?? '#003359'}
        d="M4.41 1.085a.5.5 0 0 0-.82 0L1.05 4.713a.5.5 0 0 0 .41.787h5.08a.5.5 0 0 0 .41-.787L4.41 1.085Z"
      />
    </svg>
  );
};
export default UpIcon;
