import { LabelType } from '../typeDef/devices.model';
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper';

/**
 * Generates the label based on the label type.
 * Labels are created using the ZPL language.
 * @param labelType The type of label to be generated
 * @param trial The sample trial
 * @param entry The sample entry
 * @returns {string} The label to be printed.
 * The return value of this function can be passed to the printLabel function
 */
export function generateLabel(
  labelType: LabelType,
  trial: string,
  entry: string
): string {
  switch (labelType) {
    case LabelType.ONE_DIMENSION_BARCODE:
      return `
        ^XA
          ^FO120,20
            ^CF0,15
            ^FDTrial:
          ^FS
          ^FO120,50
            ^CF0,35
            ^FD${trial}
          ^FS
          ^FO370,20
            ^CF0,15
            ^FDEntry:
          ^FS
          ^FO370,50
            ^CF0,35
            ^FD${entry}
          ^FS
          ^FO150,110
            ^BY1,3
            ^BCN,80,N,N,N
            ^FD${entry}-${trial}^FS
        ^XZ
        ^PQ1,0,1,Y
      `;
    case LabelType.TWO_DIMENSION_BARCODE:
      return `
        ^XA
          ^FO120,20
            ^CF0,20
            ^FDTrial:
          ^FS
          ^FO120,50
            ^CF0,50
            ^FD${trial}
          ^FS
          ^FO120,110
            ^CF0,20
            ^FDEntry:
          ^FS
          ^FO120,140
            ^CF0,50
            ^FD${entry}
          ^FS
          ^FO360,100
            ^BXN,5,200
            ^FD${entry}-${trial}^FS
        ^XZ
        ^PQ1,0,1,Y
      `;
    case LabelType.QR_CODE:
      return `
        ^XA
          ^FO120,20
            ^CF0,20
            ^FDTrial:
          ^FS
          ^FO120,50
            ^CF0,50
            ^FD${trial}
          ^FS
          ^FO120,110
            ^CF0,20
            ^FDEntry:
          ^FS
          ^FO120,140
            ^CF0,50
            ^FD${entry}
          ^FS
          ^FO360,95
            ^BQN,2,4
            ^FDMA,${entry}-${trial}^FS
        ^XZ
        ^PQ1,0,1,Y
      `;
  }
}

/**
 * Prints the label to the printer
 * @param label The label to be printed, generated by the generateLabel function
 * @returns {Promise<void>} A promise that resolves when the label is printed
 */
export async function printLabel(label: string): Promise<void> {
  const browserPrint = new ZebraBrowserPrintWrapper();
  const printer = await browserPrint.getDefaultPrinter();
  if (
    (await navigator.usb.getDevices()).find(
      (d) => d.serialNumber?.toLowerCase() === printer.uid.toLowerCase()
    ) === undefined
  ) {
    throw new Error('Could not connect to printer');
  }
  browserPrint.setPrinter(printer);
  await browserPrint.print(label);
}

/**
 * Print many labels
 * @param labels The labels to be printed, generated by the generateLabel function
 * @param delay The delay between each label in milliseconds
 * @returns {Promise<void>} A promise that resolves when all the labels are printed
 */
export async function printLabels(
  labels: string[],
  delay = 2000
): Promise<void> {
  if (labels.length == 0) {
    return;
  }
  await printLabel(labels[0]);
  await new Promise((resolve) =>
    setTimeout(() => printLabels(labels.slice(1), delay).then(resolve), delay)
  );
}

/**
 * Checks if the printer is connected
 * @returns True if the printer is connected, false otherwise
 */
export async function isPrinterConnected(): Promise<boolean> {
  const browserPrint = new ZebraBrowserPrintWrapper();
  const printer = await browserPrint.getDefaultPrinter();
  return (
    (await navigator.usb.getDevices()).find(
      (d) => d.serialNumber?.toLowerCase() === printer.uid.toLowerCase()
    ) !== undefined
  );
}

/**
 * Request Access to printer
 */
export async function requestPrinterAccess(): Promise<boolean> {
  const browserPrint = new ZebraBrowserPrintWrapper();
  const printer = await browserPrint.getDefaultPrinter();

  try {
    const isConnected =
      (await navigator.usb.getDevices()).find(
        (d) => d.serialNumber?.toLowerCase() === printer.uid.toLowerCase()
      ) !== undefined;

    if (!isConnected) {
      throw new Error('Device is not connected');
    }

    return isConnected;
  } catch (e) {
    const devices = await navigator.usb.requestDevice({
      filters: [
        { serialNumber: printer.uid },
        { serialNumber: printer.uid.toUpperCase() },
        { serialNumber: printer.uid.toLowerCase() },
      ],
    });

    return !!devices;
  }
}
