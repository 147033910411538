import { Urls } from '../../context/urls';
import { UserType } from '../../typeDef/user.model';
import axios from 'axios';
import { azureSignOut } from '../../services/auth.service';

const backendAPI = axios.create({
  baseURL: `${Urls.BASE_URL}`,
});

async function handleTokenExpiration() {
  localStorage.setItem('tokenExpirationKey', 'tokenExpirationValue');
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('tokenExpiration');
  localStorage.removeItem('sessionTime');
  if (localStorage.getItem('userType') === UserType.PERMANENT) {
    const response = await azureSignOut();
    window.location.href = response.data.redirectTo;
    localStorage.removeItem('userType');
  } else {
    window.location.href = '/';
    localStorage.removeItem('userType');
  }
}

backendAPI.interceptors.request.use(
  async (request) => {
    try {
      let token;
      try {
        token = localStorage.getItem('token');

        if (token) {
          request.headers.Authorization = `Bearer ${token}`;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('token', e);
      }
    } catch (e) {
      /* empty */
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// axiosHttp.interceptors.response.use(
//   (response) => {
//     //const url = response.config.url;
//
//     //setLocalStorageToken(token);
//     return response;
//   },
//   (error) => {
//     if (error.response.status === 401) {
//       //(`unauthorized :)`);
//       //localStorage.removeItem("persist:root");
//       //removeLocalStorageToken
//       //window.location.href = "/login";
//     }
//     return Promise.reject(error);
//   }
// );

backendAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      error.response.status === 401 &&
      !error.config._retry &&
      error.response.data.path !== '/v1/auth/signin'
    ) {
      //localStorage.setItem('errorMessageKey', error.response.data.message);
      localStorage.setItem(
        'errorMessageKey',
        'Invalid Session. Please log in again to regain access to your account'
      );
      handleTokenExpiration();
    }
    return Promise.reject(error);
  }
);

const excelAPI = axios.create({
  baseURL: `${Urls.EXCEL_BASE_URL}`,
});

excelAPI.interceptors.request.use(
  async (request) => {
    try {
      let token;
      try {
        token = localStorage.getItem('token');
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('token', e);
      }

      if (token) request.headers.Authorization = token ? `Bearer ${token}` : '';
    } catch (e) {
      /* empty */
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const imageDownloadAPI = axios.create({
  baseURL: `${Urls.IMAGE_DOWNLOAD_BASE_URL}`,
});

imageDownloadAPI.interceptors.request.use(
  async (request) => {
    try {
      let token;
      try {
        token = localStorage.getItem('token');
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('token', e);
      }

      if (token) request.headers.Authorization = token ? `Bearer ${token}` : '';
    } catch (e) {
      /* empty */
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { backendAPI, excelAPI, imageDownloadAPI };
