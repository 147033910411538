import React from 'react';
import styles from './customTabSwitch.module.scss';

interface TabSwitchProps {
  tabs: string[];
  selectedTab: string;
  onTabChange: (selected: string) => void;
}

const CustomTabSwitch: React.FC<TabSwitchProps> = ({
  tabs,
  selectedTab,
  onTabChange,
}) => {
  return (
    <div>
      <div className={`btn-group ${styles.tabGroup}`} role="group">
        {tabs.map((tab, i) => (
          <button
            key={`${i} - ${tab}`}
            type="button"
            className={`btn btn-outline-primary ${styles.tabSwitchButton} ${
              selectedTab === tab ? styles.active : ''
            }`}
            onClick={() => onTabChange(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CustomTabSwitch;
