import React from 'react';

interface Props {
  size?: number;
  color?: string;
}

const WeightIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 16}
      height={size ?? 16}
      fill="none"
      viewBox={'0 0 16 16'}
    >
      <g clipPath="url(#a)">
        <path
          fill={color ?? 'currentColor'}
          d="M15.933 7.625A8 8 0 0 0 0 8.667a7.919 7.919 0 0 0 2.506 5.808c.607.562 1.408.87 2.236.858h6.511a3.33 3.33 0 0 0 2.34-.952 7.963 7.963 0 0 0 2.34-6.756Zm-3.272 5.8a2 2 0 0 1-1.408.575H4.742c-.487.01-.96-.167-1.32-.494a6.599 6.599 0 0 1-2.089-4.84 6.676 6.676 0 0 1 2.226-4.97A6.587 6.587 0 0 1 7.99 2a6.662 6.662 0 0 1 4.67 11.428v-.003Zm-7.456-1.902a.667.667 0 1 1-.934.954 5.333 5.333 0 0 1 4.49-9.09.667.667 0 0 1 .566.756.66.66 0 0 1-.756.565 4 4 0 0 0-3.366 6.815Zm8.074-3.618a5.396 5.396 0 0 1-.36 2.83 5.29 5.29 0 0 1-1.191 1.742.668.668 0 0 1-.933-.954 3.987 3.987 0 0 0 1.162-3.428.667.667 0 1 1 1.32-.19h.002Zm-3.995.42a1.355 1.355 0 1 1-.943-.942l2.52-2.521a.667.667 0 1 1 .944.943l-2.521 2.52Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WeightIcon;
