import {
  BoolValue,
  GetFutureTrialsRequest,
  GetFutureTrialsResponse,
  GetSamplesRequest,
  GetTrialsRequest,
  GetTrialsResponse,
  GetVersionResponse,
  GrainEntryResponse,
  Sample,
  ScanContext,
  Stat,
  Weight,
} from '../typeDef/grain.model';
import {
  awaitRequest,
  openDatabase,
  syncKeepCullData,
  syncWeightData,
} from './database.service';
import { AxiosResponse } from 'axios';
import { backendAPI } from '../utils/axios';

export async function getVersion(
  barcode: string
): Promise<AxiosResponse<GetVersionResponse>> {
  return backendAPI.get<GetVersionResponse>(`/grain/${barcode}/version`);
}

/**
 * Scan a barcode and return the sample that was scanned.
 * First search the local database for the barcode. If the barcode is not in the
 * local database, send a request to the backend. If the barcode is in the backend,
 * save it to the local database and return it.
 * @param barcode The barcode to scan
 * @returns {Promise<Sample | null>} The sample that was scanned, or null if the
 * barcode is not in the database or the user is offline
 */
export async function scanBarcode(
  barcode: string,
  context?: ScanContext
): Promise<Sample | null> {
  let sample: Sample | null = null;

  // First check if the barcode is already in the local database
  const db = await openDatabase();
  sample =
    (await awaitRequest<Sample>(
      db
        .transaction(['samples'])
        .objectStore('samples')
        .index('barcode')
        .get(barcode)
    )) ?? null;

  let isContinue = false;

  if (navigator.onLine && sample) {
    const versionResponse = await getVersion(barcode);
    isContinue = sample.version !== versionResponse.data.version;
  }

  if ((!sample && navigator.onLine) || isContinue) {
    // If the barcode is not in the local database, check if the user is online
    // and send a request to the backend
    // This request will return all samples in the same trial as the barcode
    let data: Sample[] = [];
    try {
      const response = await backendAPI.get<Sample[]>(`/grain/scan/${barcode}`);
      data = response.data;
    } catch (e) {
      // If the barcode is not in the backend, return null
      return null;
    }

    // If the samples exist in the backend, save them to the local database
    const store = db
      .transaction(['samples'], 'readwrite')
      .objectStore('samples');
    await Promise.all(data.map((s) => awaitRequest<Sample>(store.put(s))));

    // Finally, find the sample that was scanned
    sample = data.find((s) => s.barcode === barcode) ?? null;
  }

  // If a sample was found, update its scan property based on the context
  // Note: this will only update the local database, not the backend
  // The backend will be updated when the user completes the current process
  // (weighing, keep/cull, etc.) and the local database is synced with the backend
  if (sample && context) {
    switch (context) {
      case ScanContext.KEEP_CULL:
        sample.scannedKeepCull = BoolValue.TRUE;
        break;
      case ScanContext.KEEP_CULL_PRINT:
        sample.scannedKeepCullPrint = BoolValue.TRUE;
        break;
      case ScanContext.WEIGHT:
        sample.scannedWeight = BoolValue.TRUE;
        break;
    }

    // Update the sample in the local database
    await awaitRequest<Sample>(
      db
        .transaction(['samples'], 'readwrite')
        .objectStore('samples')
        .put(sample)
    );
  }

  // Return the sample that was scanned
  return sample;
}

/**
 * Get the number of samples in a trial. This function only returns the number
 * of samples in the local database. The context in which this function is used
 * will be during a session at the begining of which, the database is synced with
 * the backend. Therefore, the number of samples in the local database should be
 * the same as the number of samples in the backend.
 * @param trialName The name of the trial
 * @returns {Promise<number | null>} The number of samples in the trial, or null
 */
export async function getTrialSize(trialName: string): Promise<number | null> {
  // Find entries in the local database that match the trial name
  const db = await openDatabase();
  const data = await awaitRequest<Sample[]>(
    db
      .transaction(['samples'], 'readwrite')
      .objectStore('samples')
      .index('trialName')
      .getAll(trialName)
  );

  // Return the number of entries in the trial
  return data?.length ?? null;
}

export async function getTrialSizeOfKeep(
  trialName: string
): Promise<number | null> {
  // Find entries in the local database that match the trial name
  const db = await openDatabase();
  const data = await awaitRequest<Sample[]>(
    db
      .transaction(['samples'], 'readwrite')
      .objectStore('samples')
      .index('trialName')
      .getAll(trialName)
  );

  // Filter the entries where future is 'KEEP'
  const filteredData = data?.filter((entry) => entry.future === 'KEEP');

  // Return the number of filtered entries or null
  return filteredData?.length ?? null;
}

/**
 * Get the number of samples in a trial that have been scanned for a given context.
 * @param trialName The name of the trial
 * @param context The context(s) in which the samples were scanned
 * @returns {Promise<number | null>} The number of samples in the trial that have
 */
export async function getScannedSamplesCount(
  trialName: string,
  context: ScanContext
): Promise<number | null>;
export async function getScannedSamplesCount(
  trialName: string,
  context: ScanContext[]
): Promise<number | null>;
export async function getScannedSamplesCount(
  trialName: string,
  context: ScanContext | ScanContext[]
): Promise<number | null> {
  // If the context is an array, recursively call this function for each context
  if (Array.isArray(context)) {
    const counts = await Promise.all(
      context.map(
        async (c) => (await getScannedSamplesCount(trialName, c)) ?? NaN
      )
    );
    const result = counts.reduce((a, b) => a + b, 0);
    return isNaN(result) ? null : result;
  }

  // If the context is not an array, return the number of samples scanned for that context
  // Find entries in the local database that match the trial name
  const db = await openDatabase();
  const data = await awaitRequest<Sample[]>(
    db
      .transaction(['samples'], 'readwrite')
      .objectStore('samples')
      .index(
        `trialName, scanned${
          context === ScanContext.WEIGHT
            ? 'Weight'
            : context === ScanContext.KEEP_CULL
            ? 'KeepCull'
            : 'KeepCullPrint'
        }`
      )
      .getAll([trialName, BoolValue.TRUE])
  );

  // Return the number of entries in the trial
  return data?.length ?? null;
}

/**
 * Get the number of keep samples in a trial that have been scanned for a given context.
 * @param trialName The name of the trial
 * @param context The context(s) in which the samples were scanned
 * @returns {Promise<number | null>} The number of samples in the trial that have
 */
export async function getScannedKeepsCount(
  trialName: string,
  context: ScanContext
): Promise<number | null>;
export async function getScannedKeepsCount(
  trialName: string,
  context: ScanContext[]
): Promise<number | null>;
export async function getScannedKeepsCount(
  trialName: string,
  context: ScanContext | ScanContext[]
): Promise<number | null> {
  // If the context is an array, recursively call this function for each context
  if (Array.isArray(context)) {
    const counts = await Promise.all(
      context.map(
        async (c) => (await getScannedKeepsCount(trialName, c)) ?? NaN
      )
    );
    const result = counts.reduce((a, b) => a + b, 0);
    return isNaN(result) ? null : result;
  }

  // If the context is not an array, return the number of samples scanned for that context
  const db = await openDatabase();
  const data = await awaitRequest<Sample[]>(
    db
      .transaction(['samples'], 'readwrite')
      .objectStore('samples')
      .index(
        `trialName, scanned${
          context === ScanContext.WEIGHT
            ? 'Weight'
            : context === ScanContext.KEEP_CULL
            ? 'KeepCull'
            : 'KeepCullPrint'
        }, future`
      )
      .getAll([trialName, BoolValue.TRUE, Stat.KEEP])
  );

  // Return the number of entries in the trial
  return data?.length ?? null;
}

/**
 * Add a weight to the local database.
 * Note the difference between sampleId and id. The sampleId is the ID of the sample
 * that in the backend, and the id is the ID of the weight entry in the local database.
 * They are kept separate as the same sample may be weighed multiple times, and each
 * weight entry must have a unique ID.
 * @param weight The weight to add to the local database
 * @returns {Promise<void>} A promise that resolves when the weight has been added
 */
export async function weighSample(weight: Omit<Weight, 'id'>): Promise<void> {
  // Add the weight to the local database
  const db = await openDatabase();
  await awaitRequest<Weight>(
    db.transaction(['weights'], 'readwrite').objectStore('weights').add(weight)
  );

  // If online, sync the data now
  if (navigator.onLine) {
    await syncWeightData();
  }
}

/**
 * Save an entry that has been kept or culled to the local database.
 * Uses put instead of add because the entry may already exist in the database, and
 * the sample ID is the primary key. Note that this may override data if the entry
 * is scanned with a different context (e.g. keep/cull vs. keep/cull print) before
 * the local database is synced with the backend.
 * @param id The ID of the sample
 * @param printedLabel Whether or not a label has been printed for the sample
 * @returns {Promise<void>} A promise that resolves when the entry has been saved
 */
export async function keepOrCullSample(
  id: string,
  printedLabel: boolean
): Promise<void> {
  // Add the entry to the local database
  const db = await openDatabase();
  await awaitRequest<Weight>(
    db
      .transaction(['keepCull'], 'readwrite')
      .objectStore('keepCull')
      .put({ id, printedLabel })
  );

  // If online, sync the data now
  if (navigator.onLine) {
    syncKeepCullData();
  }
}

export async function getSamples(
  query: GetSamplesRequest
): Promise<AxiosResponse<GrainEntryResponse>> {
  return backendAPI.get<GrainEntryResponse>(`/grain/samples`, {
    params: query,
  });
}

export async function getTrails(
  query: GetTrialsRequest
): Promise<AxiosResponse<GetTrialsResponse>> {
  return backendAPI.get<GetTrialsResponse>(`/grain/trials`, {
    params: query,
  });
}

export async function getFutureTrails(
  query: GetFutureTrialsRequest
): Promise<AxiosResponse<GetFutureTrialsResponse>> {
  return backendAPI.get<GetFutureTrialsResponse>(`/grain/future-trials`, {
    params: query,
  });
}
