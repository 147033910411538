import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import React, { useMemo, useState } from 'react';

import CustomButton from '../../components/shared/customButton/customButton';
import CustomInputField from '../../components/shared/customInputField/customInputFiled';
import LockIcon from '../../icons/lock.icon';
import { ResetPasswordRequest } from '../../typeDef/auth.model';
import SignInImage from '../../images/sign.webp';
import agtLeaf from '../../images/agtLeaf.webp';
import { resetPassword } from '../../services/auth.service';

import styles from './signIn.module.scss';

import { useNavigate } from 'react-router-dom';

type ResetPasswordProps = {
  password: string;
  confirmPassword: string;
};

function ResetPassword() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const initialVal: ResetPasswordProps = useMemo(() => {
    return {
      password: '',
      confirmPassword: '',
    };
  }, []);

  const getCharacterValidationError = (str: string) => {
    return `Your password must have at least 1 ${str} character`;
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('Please enter your password.')
      .min(8, 'Password must have at least 8 characters')
      .matches(/[0-9]/, getCharacterValidationError('digit'))
      .matches(/[!@#$%^&*]/, getCharacterValidationError('special'))
      .matches(/[a-z]/, getCharacterValidationError('lowercase'))
      .matches(/[A-Z]/, getCharacterValidationError('uppercase')),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref('password')],
        'Confirm password must match with the password'
      )
      .required('Please enter your confirm password.'),
  });

  const handleSubmit = async (values: ResetPasswordProps) => {
    try {
      const resetPasswd: Partial<ResetPasswordRequest> = {};
      resetPasswd.token = window.location.href.split('?')[1].split('token=')[1];
      resetPasswd.password = values.confirmPassword;

      await resetPassword(resetPasswd);
      navigate('/');
    } catch (e) {
      setErrorMessage('Something went wrong');
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialVal}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (
          <Form>
            <div className={'d-flex justify-content-between w-100'}>
              <div className={'w-50'}>
                <img
                  src={SignInImage}
                  alt="forget password image"
                  className={styles.logoImage}
                />
              </div>
              <div className={styles.formContainer}>
                <div className={'d-flex justify-content-center'}>
                  <img src={agtLeaf} alt={'leaf'} className={styles.image} />
                </div>
                <h2
                  className={
                    'primary-color-text text-bolder mt-4 mt-xl-0 d-flex justify-content-center'
                  }
                >
                  Welcome to AGT Weights and Keep/Cull
                </h2>
                <h6
                  className={'mt-5 light-text  d-flex justify-content-center'}
                >
                  Reset Your Password
                </h6>

                <div className={'mt-4'}>
                  <p className={'primary-color-text mb-2'}>Password</p>
                  <CustomInputField
                    type={'password'}
                    // placeholder={'Password'}
                    name={'password'}
                    icon={<LockIcon />}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('password', e.target.value)
                    }
                  />
                </div>
                <div className={'mt-4'}>
                  <p className={'primary-color-text mb-2'}>Confirm Password</p>
                  <CustomInputField
                    type={'password'}
                    // placeholder={'Confirm Password'}
                    name={'confirmPassword'}
                    icon={<LockIcon />}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('confirmPassword', e.target.value)
                    }
                  />
                </div>
                {errorMessage && (
                  <div>
                    <p className={'mt-4 text-danger text-center'}>
                      <small>{errorMessage}</small>
                    </p>
                  </div>
                )}
                <div className={'mt-5'}>
                  <CustomButton
                    text={'Set New Password'}
                    className={styles.heightBtn}
                    type={'submit'}
                  />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ResetPassword;
