import * as React from 'react';

interface Props {
  size?: number;
}
const CrackedGrainIcon: React.FC<Props> = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 16}
      height={size ?? 16}
      fill="none"
      viewBox={'0 0 16 16'}
    >
      <g clipPath="url(#a)">
        <path
          fill="#003359"
          d="M14.651 7.51C14.41 3.815 10.983.693 7.154.666A1.333 1.333 0 0 0 6 0H4.667A2.667 2.667 0 0 0 2 2.667v10.666A2.667 2.667 0 0 0 4.667 16H6a1.333 1.333 0 0 0 1.154-.667h.18a7.36 7.36 0 0 0 6.911-4.866 7.275 7.275 0 0 0 .406-2.958Zm-2.939 4.59A5.94 5.94 0 0 1 7.333 14h-.666a.666.666 0 0 0-.667.667H4.667a1.333 1.333 0 0 1-1.334-1.334v-.666H4a.667.667 0 0 0 0-1.334h-.667V10H4a.667.667 0 0 0 0-1.333h-.667V7.333H4A.667.667 0 1 0 4 6h-.667V4.667H4a.667.667 0 1 0 0-1.334h-.667v-.666a1.333 1.333 0 0 1 1.334-1.334H6A.667.667 0 0 0 6.667 2h.437c3.171 0 6.018 2.563 6.216 5.596a5.95 5.95 0 0 1-1.608 4.505ZM7.333 4.668A1.333 1.333 0 0 0 6 6v4a1.342 1.342 0 0 0 1.317 1.326 3.393 3.393 0 0 0 3.334-3 3.333 3.333 0 0 0-3.318-3.66Zm0 5.333V6a2 2 0 0 1 1.991 2.2 2.06 2.06 0 0 1-1.99 1.8Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default CrackedGrainIcon;
