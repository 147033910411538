import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { GrainEntryResponse, MissingViewCol } from '../../typeDef/grain.model';
import Logo from '../../images/agt_letterhead.jpg';
import React from 'react';

export interface PrintMissingsProps {
  overView?: string;
  tableData?: GrainEntryResponse[];
  name?: string;
  title?: string;
  trialName?: string;
  completeRatio?: number;
  futureMissing?: boolean;
}

const disableHyphenation = (word: string) => [word];
Font.registerHyphenationCallback(disableHyphenation);

const PrintMissing = (props: PrintMissingsProps) => {
  Font.register({
    family: 'Roboto-bold',
    src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
    fontWeight: 'bold',
  });
  const styles = StyleSheet.create({
    page: {
      // flexDirection: 'row', // Set to 'row' for two-page layout
      backgroundColor: '#fff',
      padding: '20px 20px 20px 40px',
    },
    logo: {
      height: 40,
      width: 90,
    },
    table: {
      marginTop: 10,
      borderLeft: '1px solid #003359',
      borderBottom: '1px solid #003359',
      borderRight: '1px solid #003359',
    },
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
    },
    heading: {
      padding: 10,
      backgroundColor: '#66B1E9',
      width: 150,
      fontSize: 12,
      color: '#003359',
    },
    headingSmall: {
      padding: 10,
      backgroundColor: '#66B1E9',
      width: 80,
      fontSize: 12,
      color: '#003359',
    },
    headingName: {
      padding: 10,
      backgroundColor: '#66B1E9',
      width: 150,
      fontSize: 12,
      color: '#003359',
    },
    headingvariety: {
      padding: 10,
      backgroundColor: '#66B1E9',
      width: 150,
      fontSize: 12,
      color: '#003359',
    },
    tableBody: {
      padding: 10,
      paddingBottom: 9,
      width: 150,
      fontSize: 10,
      borderBottom: '1px solid #66B1E9',
      flexWrap: 'wrap',
    },
    tableBodySmall: {
      padding: 10,
      paddingBottom: 9,
      width: 80,
      fontSize: 10,
      borderBottom: '1px solid #66B1E9',
      flexWrap: 'wrap',
    },
    tableBodyName: {
      padding: 10,
      paddingBottom: 9,
      width: 150,
      fontSize: 10,
      borderBottom: '1px solid #66B1E9',
      flexWrap: 'wrap',
    },
    title: {
      fontWeight: 'bold',
      color: '#003359',
      fontFamily: 'Roboto-bold',
      fontSize: 18,
      marginTop: 10,
    },
    subtilte: {
      fontSize: 12,
      color: '#003359',
    },
    secondSubtitle: {
      fontSize: 12,
      color: '#003359',
      width: 100,
    },
    label: {
      fontSize: 12,
      backgroundColor: `rgba(211, 89, 89, 0.25)`,
      borderRadius: 5,
      flexDirection: 'row',
      padding: '10px 6px',
      color: '#000',
    },
    successLable: {
      fontSize: 12,
      background: 'rgba(34, 176, 49, 0.3)',
      borderRadius: 5,
      flexDirection: 'row',
      padding: '10px 6px',
      color: '#000',
    },
    completeRatioText: {
      color: '#000',
    },
  });

  return (
    <Document pageLayout={'singlePage'}>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: 100,
            position: 'absolute',
            right: 20,
            top: 30,
          }}
        >
          <Image src={Logo} style={styles.logo} />
        </View>
        <View style={{ marginTop: 40 }}>
          <Text style={styles.title}>{props.title} Missing Status</Text>
        </View>
        {props.futureMissing ? (
          <View
            style={{
              flexDirection: 'row',
              marginTop: 15,
              justifyContent: 'space-between',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.subtilte}>TRIAL NAME : </Text>
              <Text style={styles.subtilte}>{props.name}</Text>
            </View>
            <Text
              style={
                props.completeRatio === 100 ? styles.successLable : styles.label
              }
            >
              {props.completeRatio} % COMPLETE
            </Text>
          </View>
        ) : (
          <View
            style={{
              flexDirection: 'row',
              marginTop: 15,
              justifyContent: 'space-between',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <View
              style={{
                flexDirection: `${
                  props.name && props.name?.length > 20 ? 'column' : 'row'
                }`,
              }}
            >
              <Text style={styles.subtilte}>FILE NAME : </Text>
              <Text
                style={
                  `${props.trialName && props.trialName?.length > 20}`
                    ? styles.secondSubtitle
                    : styles.subtilte
                }
              >
                {props.name}
              </Text>
            </View>
            <View
              style={{
                flexDirection: `${
                  props.trialName && props.trialName?.length > 15
                    ? 'column'
                    : 'row'
                }`,
              }}
            >
              <Text style={styles.subtilte}>EXPERIMENT NAME : </Text>
              <Text
                style={
                  `${props.trialName && props.trialName?.length > 15}`
                    ? styles.secondSubtitle
                    : styles.subtilte
                }
              >
                {props.trialName}
              </Text>
            </View>

            <Text
              style={
                props.completeRatio === 100 ? styles.successLable : styles.label
              }
            >
              {props.completeRatio} % COMPLETE
            </Text>
          </View>
        )}
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.headingSmall}>{MissingViewCol.RANGE}</Text>
            <Text style={styles.headingSmall}>{MissingViewCol.ROW}</Text>

            {props.futureMissing ? (
              <Text style={styles.headingvariety}>
                {MissingViewCol.VAREITYNAME}
              </Text>
            ) : (
              <Text style={styles.headingName}>{MissingViewCol.NAME}</Text>
            )}
            <Text style={styles.heading}>{MissingViewCol.BARCODE}</Text>
            <Text style={styles.headingSmall}>{MissingViewCol.PLOT}</Text>
          </View>
          {props.tableData?.map((value, index) => (
            <View style={styles.tableRow} key={index}>
              <Text style={styles.tableBodySmall}>{value.range}</Text>
              <Text style={styles.tableBodySmall}>{value.row}</Text>

              {props.futureMissing ? (
                <Text style={styles.tableBody}>{value.name}</Text>
              ) : (
                <Text style={styles.tableBodyName}>{value.name}</Text>
              )}
              <Text style={styles.tableBody}>{value.barcode}</Text>
              <Text style={styles.tableBodySmall}>{value.plot}</Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default PrintMissing;
