import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import SpinnerIcon from '../../../icons/spinner.icon';
import colors from '../../../styles/variables.module.scss';

import styles from './customButton.module.scss';

type ButtonProps = {
  text?: string;
  type?: 'button' | 'submit' | 'reset';
  icon?: ReactElement;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large';
  className?: string;
  variant?:
    | 'primary'
    | 'secondary'
    | 'special'
    | 'danger'
    | 'success'
    | 'gray'
    | 'transparent';
  loading?: boolean;
  outlined?: boolean;
  capitalize?: boolean;
  iconSide?: 'left' | 'right';
  onClick?: () => void;
  subText?: string;
  id?: string;
  download?: boolean;
};

const CustomButton: React.FC<ButtonProps> = ({
  text = '',
  type = 'button',
  icon,
  disabled = false,
  size = 'medium',
  className,
  variant = 'primary',
  loading = false,
  outlined = false,
  iconSide = 'left',
  onClick,
  id,
  download = false,
}) => {
  const [isLoading, setIsLoading] = useState(loading ?? false);
  const [loadingClass, setLoadingClass] = useState('');

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const variantClass = useMemo<string>(() => {
    switch (variant) {
      case 'secondary':
        setLoadingClass(
          !outlined ? styles.secondaryLoading : styles.secondaryLoadingOutline
        );
        return !outlined ? styles.secondary : styles.secondaryOutlined;
      case 'special':
        setLoadingClass(
          !outlined ? styles.specialLoading : styles.specialLoadingOutline
        );
        return !outlined ? styles.special : styles.specialOutlined;
      case 'danger':
        setLoadingClass(
          !outlined ? styles.dangerLoading : styles.dangerLoadingOutline
        );
        return !outlined ? styles.danger : styles.dangerOutlined;
      case 'success':
        setLoadingClass(
          !outlined ? styles.successLoading : styles.successLoadingOutline
        );
        return !outlined ? styles.success : styles.successOutlined;
      case 'gray':
        setLoadingClass(
          !outlined ? styles.grayLoading : styles.grayLoadingOutline
        );
        return !outlined ? styles.gray : styles.grayOutlined;
      case 'transparent':
        setLoadingClass(
          !outlined
            ? styles.transparentLoading
            : styles.transparentLoadingOutline
        );
        return !outlined ? styles.transparent : styles.transparentOutlined;
      default:
        setLoadingClass(
          !outlined ? styles.primaryLoading : styles.primaryLoadingOutline
        );
        return !outlined ? styles.primary : styles.primaryOutlined;
    }
  }, [outlined, variant]);

  const sizeClass = useMemo<string>(() => {
    switch (size) {
      case 'small':
        return styles.small;
      case 'large':
        return styles.large;
      default:
        return styles.medium;
    }
  }, [size]);

  return (
    <button
      id={id}
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={`${className} ${iconSide === 'right' ? styles.flip : null} ${
        download ? styles.widthBtn : null
      } ${styles.customButton} 
      
      
      ${variantClass} ${sizeClass} ${isLoading ? loadingClass : null}`}
    >
      {icon && !isLoading && (
        <div className={`me-4 ${styles.customButtonIcon}`}>{icon}</div>
      )}

      {isLoading && (
        <div className={styles.customButtonIcon}>
          <SpinnerIcon size={15} color={colors.whiteColor} />
        </div>
      )}
      <div className={`${styles.customButtonText} `}>{text}</div>
    </button>
  );
};

export default CustomButton;
