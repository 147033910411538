import * as React from 'react';
interface Props {
  size?: number;
}
const TrashIcon: React.FC<Props> = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 16}
      height={size ?? 16}
      fill="none"
    >
      <g fill="#373737" clipPath="url(#a)">
        <path d="M14 2.667h-2.067A3.34 3.34 0 0 0 8.667 0H7.333a3.34 3.34 0 0 0-3.266 2.667H2A.667.667 0 1 0 2 4h.667v8.667A3.337 3.337 0 0 0 6 16h4a3.338 3.338 0 0 0 3.333-3.333V4H14a.667.667 0 1 0 0-1.333ZM7.333 1.333h1.334a2.004 2.004 0 0 1 1.886 1.334H5.447a2.004 2.004 0 0 1 1.886-1.334ZM12 12.667a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4h8v8.667Z" />
        <path d="M6.667 12a.667.667 0 0 0 .666-.667v-4a.667.667 0 0 0-1.333 0v4a.667.667 0 0 0 .667.666ZM9.333 12a.667.667 0 0 0 .667-.667v-4a.667.667 0 1 0-1.333 0v4a.667.667 0 0 0 .666.666Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default TrashIcon;
