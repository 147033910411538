import React from 'react';

interface Props {
  size?: number;
}

const LocationIcon: React.FC<Props> = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 16}
      height={size ?? 16}
      fill="none"
      viewBox={'0 0 16 16'}
    >
      <g clipPath="url(#a)">
        <path
          fill="#003359"
          d="M8 10.667a2 2 0 0 0 .895-.21l1.333-.666A1.99 1.99 0 0 0 11.334 8V6.022a1.99 1.99 0 0 0-1.106-1.789l-1.333-.666a2.01 2.01 0 0 0-1.789 0l-1.333.666a1.99 1.99 0 0 0-1.106 1.789V8a1.99 1.99 0 0 0 1.105 1.79l1.334.666a2 2 0 0 0 .894.21ZM6 8V6.022a.667.667 0 0 1 .034-.2l1.454.727c.322.16.702.16 1.025 0l1.453-.727a.667.667 0 0 1 .034.2V8a.663.663 0 0 1-.368.597l-1.334.666a.673.673 0 0 1-.596 0l-1.334-.666A.663.663 0 0 1 6 8Zm6.667 6.667h-1.806l2.325-2.148a7.333 7.333 0 1 0-10.371 0l2.325 2.148H3.334a.667.667 0 0 0 0 1.333h9.333a.667.667 0 1 0 0-1.333ZM3.757 3.09a6 6 0 0 1 8.505 8.466l-2.656 2.454A2.342 2.342 0 0 1 6.38 14l-2.623-2.424a6.007 6.007 0 0 1 0-8.485Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LocationIcon;
