import {
  AzureSignInRequest,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  SignInRequest,
  SignInResponse,
  SignInURLResponse,
  UpdatePasswordRequest,
  UpdatePasswordResponse,
} from '../typeDef/auth.model';
import { AxiosResponse } from 'axios';
import { backendAPI } from '../utils/axios';

export async function signInURL(): Promise<AxiosResponse<SignInURLResponse>> {
  return backendAPI.get<SignInURLResponse>(`/auth/signin-url`);
}

export async function signIn(
  body: Partial<SignInRequest>
): Promise<AxiosResponse<SignInResponse>> {
  return backendAPI.post<SignInRequest, AxiosResponse<SignInResponse>>(
    `/auth/signin`,
    body
  );
}

export async function azureSignIn(
  body: AzureSignInRequest
): Promise<AxiosResponse<SignInResponse>> {
  return backendAPI.post<AzureSignInRequest, AxiosResponse<SignInResponse>>(
    `/auth/azure-signin`,
    body
  );
}

export async function azureSignOut(): Promise<
  AxiosResponse<{ redirectTo: string }>
> {
  return backendAPI.get<{ redirectTo: string }>(`/auth/azure-signOut`);
}

export async function forgotPassword(
  body: Partial<ForgotPasswordRequest>
): Promise<AxiosResponse<ForgotPasswordResponse>> {
  return backendAPI.post<
    ForgotPasswordRequest,
    AxiosResponse<ForgotPasswordResponse>
  >(`/auth/forgot-password`, body);
}

export async function resetPassword(
  body: Partial<ResetPasswordRequest>
): Promise<AxiosResponse<ResetPasswordResponse>> {
  return backendAPI.post<
    ResetPasswordRequest,
    AxiosResponse<ResetPasswordResponse>
  >(`/auth/reset-password`, body);
}

export async function updatePassword(
  body: Partial<UpdatePasswordRequest>
): Promise<AxiosResponse<UpdatePasswordResponse>> {
  return backendAPI.post<
    UpdatePasswordRequest,
    AxiosResponse<UpdatePasswordResponse>
  >(`/auth/update-password`, body);
}
