import React, { ChangeEvent, useEffect, useState } from 'react';
import CustomButton from '../../components/shared/customButton/customButton';
import NoteIcon from '../../icons/note.icon';
import styles from './dashboardWeightSection.module.scss';

type weightNoteProps = {
  save: boolean;
  isCurrentBarcode: boolean;
  isCurrentBarcodeNoteEditable: boolean;
  note?: (text: string) => void;
  continueSession?: () => void;
};
function WeightNote({
  save,
  isCurrentBarcode,
  isCurrentBarcodeNoteEditable,
  note,
  continueSession,
}: weightNoteProps) {
  const [text, setText] = useState('');
  const [hasChanged, setHasChanged] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
    setHasChanged(true);
  };

  useEffect(() => {
    if (isCurrentBarcode) {
      setText('');
    }
  }, [isCurrentBarcode]);

  return (
    <div className={'d-flex flex-column'}>
      <>
        <div className={`card ${styles.card}`}>
          <div className={'p-4'}>
            <div className={'mt-4'}>
              <div className={'d-flex align-items-center'}>
                <NoteIcon />
                <h6 className={'primary-color-text ms-3 text-bolder'}>
                  Note on {isCurrentBarcode ? 'Current' : 'Previous'} Barcode
                </h6>
              </div>
              {isCurrentBarcodeNoteEditable ? (
                <div>
                  <textarea
                    rows={2}
                    className={styles.customtextarea}
                    value={text}
                    onChange={handleChange}
                    autoFocus={true}
                    disabled={!isCurrentBarcode}
                  />
                  <div className={'d-flex justify-content-between'}>
                    <div className={`${styles.widthBtnNote}`}>
                      <CustomButton
                        text={'Save Note'}
                        disabled={text === '' || !isCurrentBarcode}
                        onClick={() => {
                          note?.(text);
                          setHasChanged(false);
                        }}
                      />
                    </div>
                    <div className={`ms-3 ${styles.widthBtnNote}`}>
                      <CustomButton
                        text={'Continue Session'}
                        onClick={() => {
                          continueSession?.();
                        }}
                        disabled={!isCurrentBarcode}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div>{text || '--- --- ---'}</div>
              )}

              {save && !hasChanged && (
                <div className={'d-flex justify-content-center'}>
                  <button
                    className={`btn mt-5 btn-success btn-lg ${styles.savedWeightButton}`}
                  >
                    Note Saved
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default WeightNote;
