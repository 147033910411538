export enum MasterDataCategory {
  LOCATION = 'location',
  WORK_STATION = 'workStation',
}

export enum MasterDataStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface MasterDataRequest {
  id?: string;
  category?: MasterDataCategory;
  value?: string;
  status?: MasterDataStatus;
  createdAt?: Date;
  updatedAt?: Date;
  createdBy?: string;
  updatedBy?: string;
}
export interface MasterDataResponse {
  id: string;
  category: MasterDataCategory;
  value: string;
  status: MasterDataStatus;
  createdAt: Date;
  updatedAt: Date;
  createdBy: string;
  updatedBy: string;
}
