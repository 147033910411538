import {
  ImageDownloadRequest,
  ImageDownloadResponse,
} from '../../typeDef/image.model';
import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import Loader from '../../components/shared/loadingSpinner/imageLoader';
import { getImages } from '../../services/images.service';
import styles from '../../components/table/userList/userListTable.module.scss';

type GrainImageProps = {
  trialName: string;
  fileName: string;
  previewImage?: boolean;
  loading: (value: boolean) => void;
};

const GrainImage = ({
  fileName,
  trialName,
  loading,
  previewImage,
}: GrainImageProps) => {
  const [imageSrc, setImageSrc] = useState<Blob>();
  const [imageLoading, setImageLoading] = useState<boolean>(false);

  const getImagesSample = useCallback(async () => {
    loading(true);
    setImageLoading(true);
    try {
      const query: Partial<ImageDownloadRequest> = {
        compress: !previewImage,
      };

      const response: AxiosResponse<ImageDownloadResponse> = await getImages(
        trialName,
        fileName,
        query
      );

      if (response.data.data) {
        // Assuming response.data.image is a streamable file
        const blob = new Blob([new Uint8Array(response.data.data)], {
          type: 'application/octet-stream',
        });
        setImageSrc(blob);
        loading(false);
        setImageLoading(false);
      } else {
        loading(false);
        setImageLoading(false);
      }
    } catch (e) {
      loading(false);
      setImageLoading(false);
    }
  }, [fileName, trialName]);

  useEffect(() => {
    (async () => {
      await getImagesSample();
    })();
  }, [trialName, fileName]);

  return (
    <div className={previewImage ? styles.previewImage : styles.imageSize}>
      {previewImage && imageLoading ? (
        <div className={styles.loadingContainer}>
          <Loader />
        </div>
      ) : (
        <img
          src={imageSrc ? URL.createObjectURL(imageSrc) : undefined}
          className={styles.backgroundImage}
          alt={'Loading...'}
        />
      )}
    </div>
  );
};

export default GrainImage;
