import { AxiosResponse } from 'axios';
import { ExportFile } from '../typeDef/excel.model';
import { excelAPI } from '../utils/axios';

export async function importFile(body: FormData): Promise<AxiosResponse<void>> {
  return excelAPI.post<'', AxiosResponse<void>>(`/excel`, body, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data',
    },
  });
}

export async function importNewUsersFile(
  body: FormData
): Promise<AxiosResponse<void>> {
  return excelAPI.post<'', AxiosResponse<void>>(`/excel/importUsers`, body, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data',
    },
  });
}

export async function getFile(
  fileName: string,
  query?: ExportFile
): Promise<AxiosResponse<''>> {
  return excelAPI.get<''>(`/excel/${fileName}`, {
    headers: {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Specify the expected file type
    },
    params: query,
    responseType: 'blob',
  });
}
export async function getRecordCountOfFile(
  fileName: string,
  query?: ExportFile
): Promise<AxiosResponse<''>> {
  return excelAPI.get<''>(`/excel/${fileName}/recordCount`, {
    params: query,
  });
}
