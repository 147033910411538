import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import {
  MasterDataCategory,
  MasterDataRequest,
} from '../../typeDef/masterData.model';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  UserRole,
  UserRoleDisplayFormat,
  UserType,
} from '../../typeDef/user.model';
import {
  deleteUserProfileImage,
  getUser,
  getUserProfileImage,
  updateUser,
  uploadUserProfileImage,
} from '../../services/user.service';
import { useLocation, useNavigate } from 'react-router-dom';
import ButtonGroup from '../shared/radioButtonWithCheckbox/buttonGroup';
import CommonLayout from '../layout/commonLayout';
import CustomButton from '../shared/customButton/customButton';
import CustomDropdown from '../shared/customDropdown/customDropdown';
import CustomImageUpload from '../shared/customImageUpload/customImageUpload';
import CustomInputFiled from '../shared/customInputField/customInputFiled';
import CustomModal from '../shared/customModal/customModal';
import EmailIcon from '../../icons/email.icon';
import { ErrorResponse } from '../../typeDef/common.model';
import LoadingSpinner from '../shared/loadingSpinner/loadingSpinner';
import LocationIcon from '../../icons/location.icon';
import LockIcon from '../../icons/lock.icon';
import MessageModal from '../modal/messageModal';
import UserDeleteModal from '../modal/userDeleteModal';
import UserIcon from '../../icons/user.icon';
import UsernameIcon from '../../icons/username.icon';
import { getMasterData } from '../../services/masterData.service';

import loadingStyles from '../shared/loadingSpinner/loadingSpinner.module.scss';
import styles from '../userProfile/styles.module.scss';

type UserDataType = {
  createdAt: string;
  createdBy: string;
  email: string;
  firstName: string;
  lastName: string;
  id: string;
  role: string;
  type: string;
  location: string;
  profileImageKey: string;
  updatedAt: string;
  updatedBy: string;
};

type UpdateUserProps = {
  firstName?: string;
  lastName?: string;
  email?: string;
  role?: UserRole;
  type: UserType;
  location?: string;
  password?: string;
  username?: string;
};

type DropDownType = {
  text: string;
  value: string;
};

function viewUser() {
  const navigate = useNavigate();
  const location = useLocation();
  const [openModal, setOpenModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, seIsError] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selected, setSelected] = useState<string | null>(
    location.state.role === UserRole.USER
      ? UserRoleDisplayFormat.GUEST
      : UserRoleDisplayFormat.ADMIN
  );
  const [userProfileData, setUserProfileData] = useState<UserDataType>();
  const [imageSrc, setImageSrc] = useState<Blob>();
  const [locationValue, setLocationValue] = useState<string>('');
  const [locationDropdownOptions, setLocationDropdownOptions] = useState<
    DropDownType[]
  >([]);
  //const [imageSrc, setImageSrc] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imageIsLoading, setImageIsLoading] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [deleteClick, setDeleteClick] = useState(false);

  const initialVal: UpdateUserProps = useMemo(() => {
    return {
      firstName: location.state.firstName,
      lastName: location.state.lastName,
      email: location.state.email,
      role: location.state.role,
      type: location.state.type,
      location: location.state.location,
      username: location.state.username,
      password: '',
    };
  }, [location.state]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Please enter first name.'),
    lastName: Yup.string().required('Please enter last name.'),
  });

  const getLocationList = async () => {
    try {
      const query: Partial<MasterDataRequest> = {
        category: MasterDataCategory.LOCATION,
      };
      const response = await getMasterData(query);
      const updatedOptions = response.data.map((da) => ({
        text: da.value,
        value: da.category,
      }));
      setLocationDropdownOptions(updatedOptions);
    } catch {
      setIsSuccess(false);
      seIsError(true);
      setMessageText('Something went wrong');
      setOpenModal(true);
    }
  };

  useEffect(() => {
    (async () => {
      await getLocationList();
    })();
  }, []);
  const handleSubmit = async (values: UpdateUserProps) => {
    setLoading(true);
    try {
      const response = await updateUser(location.state.userId, {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email !== '' ? values.email : null,
        role:
          selected === UserRoleDisplayFormat.GUEST
            ? UserRole.USER
            : UserRole.ADMIN,
        type: UserType.CASUAL,
        password: values.password,
        username: values.username,
        location: locationValue ? locationValue : location.state.location,
      });

      let imageUploadResponse;
      if (imageSrc && imageSrc instanceof File) {
        const data = new FormData();
        data.append('file', imageSrc);
        imageUploadResponse = await uploadUserProfileImage(
          userProfileData?.id,
          data
        );
        if (response.data && imageUploadResponse?.data) {
          setLoading(false);
          setSuccessModal(true);
        } else {
          setLoading(false);
          setErrorMessage('Update unsuccessful');
          setErrorModal(true);
        }
      } else {
        if (deleteClick) {
          await deleteUserProfileImage(userProfileData?.id);
          setDeleteClick(false);
        }
        if (response.data) {
          setLoading(false);
          setSuccessModal(true);
        }
      }
    } catch (err) {
      const errorResponse = err as ErrorResponse;
      setLoading(false);
      if (errorResponse.response.data.message === 'Email is not valid') {
        setErrorMessage(
          `Update unsuccessful. This ${errorResponse.response.data.message} for admin users`
        );
      } else if (
        errorResponse.response.data.message === 'Username is already taken'
      ) {
        setErrorMessage(
          `Update unsuccessful. This ${errorResponse.response.data.message}`
        );
      } else {
        setErrorMessage('Update unsuccessful');
      }
      setErrorModal(true);
    }
  };

  const getUserData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await getUser(location.state.userId);
      setUserProfileData(response.data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setIsSuccess(false);
      seIsError(true);
      setMessageText('Something went wrong');
      setOpenModal(true);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getUserData();
    })();
  }, [location.state.userId, getUserData]);

  // const uploadImage = useCallback(
  //   async (fileValue: File, userId: string | undefined) => {
  //     try {
  //       if (fileValue) {
  //         const data = new FormData();
  //         data.append('file', fileValue);
  //         await uploadUserProfileImage(userId, data);
  //       }
  //     } catch (e) {
  //       setIsSuccess(false);
  //       seIsError(true);
  //       setMessageText('Something went wrong');
  //       setOpenModal(true);
  //     }
  //   },
  //   []
  // );

  const getUsersProfileImage = useCallback(async () => {
    setImageIsLoading(true);
    try {
      const response = await getUserProfileImage(location.state.userId);
      const blob = new Blob([new Uint8Array(response.data.data)], {
        type: 'application/octet-stream',
      });
      setImageSrc(blob);
      setImageIsLoading(false);
    } catch (e) {
      setIsSuccess(false);
      seIsError(true);
      setMessageText('Something went wrong');
      setImageIsLoading(false);
      //setOpenModal(true);
    }
  }, [userProfileData?.id]);

  useEffect(() => {
    (async () => {
      await getUsersProfileImage();
    })();
  }, [getUsersProfileImage]);

  // const handleDeleteUploadImage = async (userId: string | undefined) => {
  //   await deleteUserProfileImage(userId);
  //   setImageSrc(undefined);
  // };

  return (
    <>
      <CommonLayout admin title={'User Manager'}>
        <Formik
          initialValues={initialVal}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ setFieldValue }) => (
            <Form className={styles.viewUser}>
              <p className={'font-size-small mb-3'}>
                <span
                  className={
                    'primary-color-text text-normal text-italic pointer text-decoration-none'
                  }
                >
                  <a onClick={() => navigate('/userManager')}>User List /</a>
                </span>
                <span className={'ms-2 text'}>{location.state.firstName}</span>
              </p>
              <h6 className={'primary-color-text text-bolder'}>
                Edit User Details
              </h6>
              <div
                className={`${
                  (isLoading || imageIsLoading) &&
                  loadingStyles.app_while_loading
                }`}
              >
                {(isLoading || imageIsLoading) && <LoadingSpinner />}
                <div className={'mt-5 d-flex'}>
                  <div>
                    <p className={'text-dark text-semi-bold mb-2'}>
                      First Name
                    </p>
                    <CustomInputFiled
                      name={'firstName'}
                      icon={<UserIcon />}
                      className={styles.inputWidth}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('firstName', e.target.value)
                      }
                    />
                  </div>
                  <div className={'ms-5'}>
                    <p className={'text-dark text-semi-bold mb-2'}>Last Name</p>
                    <CustomInputFiled
                      autoFocus={false}
                      name={'lastName'}
                      icon={<UserIcon />}
                      className={styles.inputWidth}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('lastName', e.target.value)
                      }
                    />
                  </div>
                </div>

                <div className={'mt-5 d-flex al '}>
                  <div className={`${styles.inputWidth}`}>
                    <p className={'text-dark text-semi-bold mb-2'}>Email ID</p>
                    <CustomInputFiled
                      autoFocus={false}
                      name={'email'}
                      icon={<EmailIcon />}
                      className={styles.inputWidth}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('email', e.target.value)
                      }
                    />
                  </div>
                  <div className={`ms-5 ${styles.inputWidth}`}>
                    <div>
                      <p className={'text-dark text-semi-bold mb-2'}>
                        Location
                      </p>
                      <div className={styles.inputWidth}>
                        <CustomDropdown
                          icon={<LocationIcon />}
                          // placeHolder={'Location'}
                          dataList={locationDropdownOptions}
                          selectedValue={
                            locationValue !== ''
                              ? locationDropdownOptions[
                                  locationDropdownOptions.findIndex(
                                    (object) => {
                                      return object.text === locationValue;
                                    }
                                  )
                                ]
                              : locationDropdownOptions[
                                  locationDropdownOptions.findIndex(
                                    (object) => {
                                      return (
                                        object.text === location.state.location
                                      );
                                    }
                                  )
                                ]
                          }
                          setFieldValue={(value) => setLocationValue(value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className={'d-flex justify-content-between  mt-5'}>
                  <div>
                    {/*<p className={'primary-color-text text-bold mb-2'}>*/}
                    {/*  Username*/}
                    {/*</p>*/}
                    {/*<div*/}
                    {/*  className={`d-flex align-items-center ${styles.inputWidth}`}*/}
                    {/*>*/}
                    {/*  <p className={'text-semi-bold mt-2'}>*/}
                    {/*    {location.state.username}*/}
                    {/*  </p>*/}
                    {/*</div>*/}

                    <p className={'text-dark text-semi-bold mb-2'}>Username</p>
                    <CustomInputFiled
                      autoFocus={false}
                      name={'username'}
                      icon={<UsernameIcon />}
                      className={styles.inputWidth}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('username', e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <div className={'d-flex'}>
                      <div className={'ms-5'}>
                        <p className={'text-dark text-semi-bold mb-2'}>
                          Password
                        </p>
                        <CustomInputFiled
                          autoFocus={false}
                          name={'password'}
                          icon={<LockIcon />}
                          type={'password'}
                          className={styles.inputWidth}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue('password', e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={'mt-5'}>
                  <p className={'mb-2'}>User Type/Role</p>
                  <ButtonGroup
                    options={[
                      UserRoleDisplayFormat.GUEST,
                      UserRoleDisplayFormat.ADMIN,
                    ]}
                    selectedOption={selected}
                    onSelect={(option: string) => {
                      setSelected(option);
                    }}
                  />
                </div>

                <div className={'mt-5'}>
                  <p className={'text-dark text-semi-bold mb-2'}>User Photo</p>
                  <CustomImageUpload
                    id={'profile'}
                    fileSelect={(value) => {
                      //await uploadImage(value, userProfileData?.id);
                      // const imageUrl = URL.createObjectURL(value);
                      setImageSrc(value);
                    }}
                    handleDelete={() => {
                      setImageSrc(undefined);
                      setDeleteClick(true);
                    }}
                    imageUrl={imageSrc}
                    userFullName={`${userProfileData?.firstName} ${userProfileData?.lastName}`}
                    maxSize={5 * 1024 * 1024}
                    onError={(value) => {
                      setErrorModal(true);
                      setErrorMessage(value);
                    }}
                  />
                </div>
                <div
                  className={
                    'mt-5 d-flex justify-content-between align-items-center w-100'
                  }
                >
                  <CustomButton
                    text={'Delete User'}
                    className={'fit-content'}
                    type={'button'}
                    variant={'secondary'}
                    onClick={() => setDeleteModal(true)}
                  />

                  <div className={'d-flex align-items-center'}>
                    <CustomButton
                      text={'Update Profile'}
                      className={'fit-content'}
                      type={'submit'}
                      loading={loading}
                    />
                    <div className={'ms-5'}>
                      <p
                        className={
                          'text-danger text-bolder pointer text-danger-underline'
                        }
                        onClick={() => navigate('/userManager')}
                      >
                        Cancel
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        {deleteModal && (
          <CustomModal
            open={deleteModal}
            title={'Delete User'}
            deleteTitle
            onCloseModal={(val) => setDeleteModal(val)}
          >
            <UserDeleteModal
              onCloseModal={setDeleteModal}
              userId={userProfileData?.id}
              fullName={`${userProfileData?.firstName} ${userProfileData?.lastName}`}
              fromPage={'ViewUser'}
            />
          </CustomModal>
        )}
        {errorModal && (
          <CustomModal
            open={errorModal}
            title={'Error'}
            deleteTitle
            onCloseModal={(val) => setErrorModal(val)}
          >
            <div className={styles.modalWidth}>
              <h6 className={'light-text text-normal'}>{errorMessage}</h6>
              <div
                className={
                  'd-flex justify-content-between align-items-center mt-5'
                }
              >
                <CustomButton
                  text={'Ok'}
                  onClick={() => setErrorModal(false)}
                  className={styles.buttonWidth}
                />
              </div>
            </div>
          </CustomModal>
        )}
        {successModal && (
          <CustomModal
            title={'Success'}
            open={successModal}
            success
            onCloseModal={(val) => setSuccessModal(val)}
          >
            <div className={styles.modalWidth}>
              <h6 className={'light-text text-normal'}>
                Profile successfully Updated.
              </h6>
              <div
                className={
                  'd-flex justify-content-between align-items-center mt-5'
                }
              >
                <CustomButton
                  text={'Ok'}
                  onClick={() => {
                    setSuccessModal(false);
                    navigate('/userManager');
                  }}
                  className={styles.buttonWidth}
                />
              </div>
            </div>
          </CustomModal>
        )}
        {openModal && (
          <MessageModal
            title={isSuccess ? 'Success' : 'Error'}
            isSuccess={isSuccess}
            isError={isError}
            onCloseModal={(value) => setOpenModal(value)}
            openModal={openModal}
            messageText={messageText}
          />
        )}
      </CommonLayout>
    </>
  );
}
export default viewUser;
