import * as React from 'react';
interface Props {
  size?: number;
}
const ActionIcon: React.FC<Props> = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 16}
      height={size ?? 16}
      fill="none"
    >
      <g clipPath="url(#a)">
        <path
          fill="#003359"
          d="M8 3A1.25 1.25 0 1 0 8 .5 1.25 1.25 0 0 0 8 3Z"
        />
        <path
          fill="#373737"
          d="M8 9.25a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM8 15.5A1.25 1.25 0 1 0 8 13a1.25 1.25 0 0 0 0 2.5Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.5.5h15v15H.5z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default ActionIcon;
