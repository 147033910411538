import * as React from 'react';
interface Props {
  size?: number;
  color?: string;
}
const PreviousIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 14}
      height={size ?? 18}
      fill="none"
    >
      <g clipPath="url(#a)">
        <path
          fill={color ?? '#003359'}
          d="M9.3 13.5a.54.54 0 0 1-.384-.157L4.49 8.918a2.708 2.708 0 0 1 0-3.835L8.916.657a.544.544 0 0 1 .769.77L5.26 5.852a1.625 1.625 0 0 0 0 2.297l4.425 4.425a.542.542 0 0 1-.385.926Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 .5h13v13H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default PreviousIcon;
