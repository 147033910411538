import AuthContext, { AgtUser } from '../../context/auth.context';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import CommonLayout from '../../components/layout/commonLayout';
import LoadingSpinner from '../../components/shared/loadingSpinner/loadingSpinner';
import Sample from '../../images/sample.png';
import { getCurrentUserProfile } from '../../services/profile.services';
import loadingStyles from '../../components/shared/loadingSpinner/loadingSpinner.module.scss';
import { useNavigate } from 'react-router-dom';

export default function TestDashboard() {
  const navigate = useNavigate();
  const { setUser } = useContext(AuthContext);
  const accessToken = localStorage.getItem('token') as string;
  const [isLoading, setIsLoading] = useState(false);

  const checkCurrentUser = useCallback(async () => {
    try {
      const response = await getCurrentUserProfile();

      setUser(new AgtUser({ jwtToken: accessToken, ...response.data }));
    } catch (e) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    (async () => {
      await checkCurrentUser();
    })();
  }, []);

  return (
    <CommonLayout
      admin
      title={'Overview'}
      selectedPage={'overview'}
      setPageLoading={(value) => setIsLoading(value)}
    >
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        {isLoading && <LoadingSpinner />}
        <img src={Sample} alt={'test image'} />
      </div>
    </CommonLayout>
  );
}
