export interface imageType {
  id: string;
  fileName: string;
  barcode: string;
  uploadDate: Date;
  uploadedBy: string;
}
export interface ImageResponse {
  items: imageType[];
  meta: {
    pageNumber: number;
    pageSize: number;
    itemCount: number;
    pageCount: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
  };
}

export interface ImageFolderResponse {
  items: [
    {
      trialName: string;
      imageCount: number;
      folderSize: number;
      sizeUnit: string;
    },
  ];
  meta: {
    pageNumber: number;
    pageSize: number;
    itemCount: number;
    pageCount: number;
    hasPreviousPage: boolean;
    hasNextPage: boolean;
  };
}
export interface FolderRequest {
  /**
   * Pagination params
   */
  pageNumber?: number;
  pageSize?: number;

  /**
   * Sort params
   */
  sortField?: string;
  sortOrder?: string;
}
export interface ImageDownloadResponse {
  data?: Buffer;
}

export interface ImageFolderDownloadResponse {
  data?: Buffer;
}
export interface ImageDownloadRequest {
  compress?: boolean;
}
export interface ImageRequest {
  id?: string;
  trialName?: string;
  barcode?: string;
  uploadDate?: Date;
  uploadedBy?: string;

  /**
   * Pagination params
   */
  pageNumber?: number;
  pageSize?: number;

  /**
   * Sort params
   */
  sortField?: string;
  sortOrder?: string;
}

export enum SortDataList {
  ALPHABETICAL_Z_A = 'Alphabetical (Z-A)',
  ALPHABETICAL_A_Z = 'Alphabetical (A-Z)',
}
