import * as React from 'react';
interface Props {
  size?: number;
  color?: string;
}
const NextIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 23}
      height={size ?? 24}
      fill="none"
    >
      <g clipPath="url(#a)">
        <path
          fill={color ?? '#003359'}
          d="M8.792 18.5a.54.54 0 0 1-.545-.541.542.542 0 0 1 .16-.385l4.425-4.425a1.625 1.625 0 0 0 0-2.297L8.407 6.427a.544.544 0 1 1 .77-.77l4.425 4.426a2.706 2.706 0 0 1 0 3.835l-4.426 4.425a.54.54 0 0 1-.384.157Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M5 5.5h13v13H5z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default NextIcon;
