import {
  FolderRequest,
  ImageDownloadRequest,
  ImageDownloadResponse,
  ImageFolderResponse,
  ImageRequest,
  ImageResponse,
} from '../typeDef/image.model';
import { backendAPI, imageDownloadAPI } from '../utils/axios';
import { AxiosResponse } from 'axios';

export async function getImageFolder(
  query?: FolderRequest
): Promise<AxiosResponse<ImageFolderResponse>> {
  return backendAPI.get<ImageFolderResponse>(`/images`, { params: query });
}
export async function getImageDetails(
  trialName: string,
  query?: ImageRequest
): Promise<AxiosResponse<ImageResponse>> {
  return backendAPI.get<ImageResponse>(`/images/details/${trialName}`, {
    params: query,
  });
}
export async function getImages(
  trialName: string,
  fileName: string,
  options?: ImageDownloadRequest
): Promise<AxiosResponse<ImageDownloadResponse>> {
  return backendAPI.get<ImageDownloadResponse>(
    `/images/display/${trialName}/${fileName}`,
    {
      params: options,
    }
  );
}

export async function downloadImage(
  trialName: string,
  fileName: string,
  options?: ImageDownloadRequest
): Promise<AxiosResponse<ImageDownloadResponse>> {
  return imageDownloadAPI.get<ImageDownloadResponse>(
    `/images/download/${trialName}/${fileName}`,
    {
      headers: {
        Accept: 'image/jpg',
      },
      params: options,
    }
  );
}

export async function downloadFolder(
  trialName: string
): Promise<AxiosResponse<''>> {
  return imageDownloadAPI.get<''>(`/images/download/${trialName}`, {
    responseType: 'blob',
  });
}
