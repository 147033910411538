import CheckSquareIcon from '../../../icons/checkSquare.icon';
import React from 'react';
import styles from './radioButtonWithCheckbox.module.scss';

interface Props {
  label: string;
  active: boolean;
  onClick: () => void;
  disable?: boolean;
}

const RadioButtonWithCheckbox: React.FC<Props> = ({
  label,
  active,
  onClick,
  disable,
}) => {
  return (
    <button
      className={`${
        disable ? styles.customRadioCheckBtnDisable : styles.customRadioCheckBtn
      } ${active ? styles.active : ''}`}
      onClick={onClick}
      type={'button'}
      disabled={disable}
    >
      <p
        className={`${styles.radioButtonTextContainer} ${
          disable
            ? 'light-disable-text'
            : active
            ? 'white-text'
            : 'primary-color-text'
        }`}
      >
        {label}
      </p>
      <div className={styles.radioCheckboxContainer}>
        {active ? (
          <CheckSquareIcon />
        ) : (
          <span className={styles.inactiveSpaceContainer}></span>
        )}
      </div>
    </button>
  );
};

export default RadioButtonWithCheckbox;
