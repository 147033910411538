import React, { ReactNode, useEffect, useState } from 'react';
import {
  syncKeepCullData,
  syncWeightData,
} from '../../services/database.service';
import styles from './offline.module.scss';

interface OfflineSyncProps {
  children: ReactNode;
  visible?: boolean;
}

/**
 * A container component used to periodically try to sync local data with the backend.
 * Also displays an online/offline indicator in the bottom corner.
 * @param param The component props, including child components and the visibility property
 * @returns The react component
 */
function OfflineSync({
  children,
  visible = true,
}: OfflineSyncProps): JSX.Element {
  // Check if the user is online
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);
  useEffect(() => {
    const handleOffline = () => {
      setIsOnline(navigator.onLine);
    };
    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOffline);
    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOffline);
    };
  }, []);

  // Every 10 seconds, if the user is online, sync the data
  useEffect(() => {
    const interval = setInterval(() => {
      if (isOnline) {
        syncWeightData();
        syncKeepCullData();
      }
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      {visible && (
        <div
          className={`${styles.indicator} ${
            isOnline ? styles.online : styles.offline
          }`}
        />
      )}
      <div>{children}</div>
    </div>
  );
}

export default OfflineSync;
