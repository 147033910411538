import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { UserRole, UserType } from '../../typeDef/user.model';
import { clearData, isUnsyncedData } from '../../services/database.service';
import getInitials, { getProfileImage } from '../../common/users';
import AuthContext from '../../context/auth.context';
import Avatar from 'react-avatar';
import CustomButton from '../shared/customButton/customButton';
import CustomModal from '../shared/customModal/customModal';
import ImageManageIcon from '../../icons/imageManage.icon';
import ImportIcon from '../../icons/import.icon';
import KeepCullIcon from '../../icons/keepCull.icon';
import Logo from '../../images/Logo.png';
import MenuIcon from '../../icons/menu.icon';
import OverviewIcon from '../../icons/overview.icon';
import Popup from 'reactjs-popup';
import UserIcon from '../../icons/user.icon';
import WeightIcon from '../../icons/weight.icon';
import { azureSignOut } from '../../services/auth.service';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';

type layoutProps = {
  userProfile?: boolean;
  children?: ReactNode;
  admin?: boolean;
  title?: string;
  selectedPage?: string;
  setPageLoading?: (value: boolean) => void;
};

enum SelectPageType {
  OVERVIEW = 'overview',
  IMPORTFILE = 'importFile',
  USERMANAGER = 'userManager',
  IMAGEMANAGER = 'imageManager',
  WEIGHT = 'weight',
  KEEPCULL = 'keepCull',
}
const CommonLayout = ({
  userProfile,
  children,
  admin,
  title,
  selectedPage,
}: layoutProps) => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(AuthContext);
  const [activeButton, setActiveButton] = useState(
    selectedPage === SelectPageType.OVERVIEW
      ? 0
      : selectedPage === SelectPageType.IMPORTFILE
      ? 1
      : selectedPage === SelectPageType.WEIGHT
      ? 2
      : selectedPage === SelectPageType.KEEPCULL
      ? 3
      : selectedPage === SelectPageType.IMAGEMANAGER
      ? 4
      : selectedPage === SelectPageType.USERMANAGER
      ? 5
      : -1
  );
  const [tab, setTab] = useState('User Profile Settings');
  const [navCollapsed, setNavCollapsed] = useState(false);
  const [modal, setModal] = useState(false);
  const [imageSrc, setImageSrc] = useState<Blob>();
  //const [loading, setLoading] = useState(false);
  let isApplicationsPrinted = false;
  const navButtons = [
    {
      label: 'Overview',
      icon: <OverviewIcon />,
      value: '/dashboard',
      dashboard: true,
    },
    {
      label: 'User Profile Settings',
      icon: <UserIcon />,
      value: '/userProfile',
      dashboard: false,
    },
  ];
  const adminNavButtons = useMemo(
    () => [
      {
        label: 'Overview',
        icon: <OverviewIcon />,
        value: '/dashboard',
        dashboard: true,
        display: true,
      },
      {
        label: 'Import File',
        icon: <ImportIcon />,
        value: '/importFile',
        dashboard: false,
        display: true,
      },
      {
        label: 'Weight',
        icon: <WeightIcon />,
        value: '/weight',
        dashboard: false,
        display: true,
      },
      {
        label: 'Keep/Cull',
        icon: <KeepCullIcon />,
        value: '/keepCull',
        dashboard: false,
        display: true,
      },
      {
        label: 'Image Manager',
        icon: <ImageManageIcon />,
        value: '/imageManager',
        dashboard: false,
        display: true,
      },
      {
        label: 'User Manager',
        icon: <UserIcon />,
        value: '/userManager',
        dashboard: false,
        display:
          user?.role === UserRole.ADMIN && user?.type === UserType.CASUAL,
      },
    ],
    [user]
  );

  const handleTabAdmin = (value: string, index: number) => {
    setActiveButton(index);
    navigate(value);
  };

  const handleTab = (value: string, index: number) => {
    setTab(value);
    setActiveButton(index);
    navigate(value);
  };

  useEffect(() => {
    setActiveButton(activeButton);
  }, [activeButton]);

  useEffect(() => {
    (async () => {
      setImageSrc(await getProfileImage());
    })();
  }, []);

  const logout = async () => {
    // Clear local database and remove token
    await clearData();
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('userType');
    localStorage.removeItem('sessionTime');
    setUser(undefined);
    if (user?.type === UserType.PERMANENT) {
      const response = await azureSignOut();
      window.location.href = response.data.redirectTo;
    } else {
      navigate('/');
    }
  };

  // Check if there is unsynced data every 5 seconds
  // or when the modal is opened
  const [unsyncedData, setUnsyncedData] = useState(false);
  const checkDataCallback = () => {
    isUnsyncedData().then((res) => {
      setUnsyncedData(res);
    });
  };
  useEffect(checkDataCallback, [modal]);
  useEffect(() => {
    const interval = setInterval(checkDataCallback, 5000);
    return () => clearInterval(interval);
  });

  return (
    <>
      <div className={styles.container}>
        <div
          className={`${styles.sideMenu} ${
            navCollapsed ? styles.sideMenuCollapsed : styles.sideMenuNormal
          }`}
        >
          <div
            className={`d-flex align-items-center w-100 ${
              navCollapsed
                ? 'justify-content-center'
                : 'justify-content-between'
            }`}
          >
            {!navCollapsed && <img src={Logo} alt={'agt logo'} />}
            <div
              className={'pointer'}
              onClick={() => setNavCollapsed(!navCollapsed)}
            >
              <MenuIcon />
            </div>
          </div>

          <nav className={styles.navigation}>
            {!admin ? (
              <>
                {navButtons.map(({ label, icon, dashboard, value }, index) => (
                  <>
                    {!navCollapsed && (
                      <div className={'mb-3'} key={index}>
                        <p className={'primary-color-text text-bolder'}>
                          {dashboard ? 'Dashboard' : ''}
                          {!dashboard && !isApplicationsPrinted
                            ? 'Profile'
                            : ''}
                        </p>
                      </div>
                    )}
                    <button
                      key={`nav-button-${label}`}
                      type="button"
                      className={`${styles.navButton} ${
                        label === tab ? styles.navButtonActive : null
                      } ${
                        navCollapsed && tab === label
                          ? styles.radiusNone
                          : styles.radius
                      } ${navCollapsed ? styles.collapsed : null}`}
                      onClick={() => handleTab(value, index)}
                    >
                      {navCollapsed ? (
                        <Popup
                          position={'right center'}
                          on={'hover'}
                          trigger={
                            <div
                              className={`${styles.navButtonIcon}  ${
                                navCollapsed ? 'me-0' : null
                              }`}
                            >
                              {icon}
                            </div>
                          }
                        >
                          <div className={styles.popUpContent}>
                            <p className={'primary-color-text'}>{label}</p>
                          </div>
                        </Popup>
                      ) : (
                        <div
                          className={`${styles.navButtonIcon}  ${
                            navCollapsed ? 'me-0' : null
                          }`}
                        >
                          {icon}
                        </div>
                      )}
                      {!navCollapsed && label}
                    </button>
                    {!dashboard &&
                      !isApplicationsPrinted &&
                      (isApplicationsPrinted = true)}
                  </>
                ))}
              </>
            ) : (
              <>
                {!navCollapsed && userProfile && (
                  <div className={'mb-3'}>
                    <p className={'primary-color-text text-bolder'}>Profile</p>
                  </div>
                )}
                {adminNavButtons.map(
                  ({ label, icon, dashboard, value, display }, index) =>
                    display && (
                      <>
                        {!navCollapsed && (
                          <div className={'mb-3'} key={index}>
                            <p className={'primary-color-text text-bolder'}>
                              {dashboard ? 'Dashboard' : ''}
                              {!dashboard && !isApplicationsPrinted
                                ? 'Applications'
                                : ''}
                            </p>
                          </div>
                        )}
                        <button
                          key={`nav-button-${label}`}
                          type="button"
                          className={`${styles.navButton} ${
                            activeButton === index
                              ? styles.navButtonActive
                              : null
                          } ${
                            navCollapsed && activeButton === index
                              ? styles.radiusNone
                              : styles.radius
                          } ${navCollapsed ? styles.collapsed : null}`}
                          onClick={() => handleTabAdmin(value, index)}
                        >
                          {navCollapsed ? (
                            <Popup
                              position={'right center'}
                              on={'hover'}
                              trigger={
                                <div
                                  className={`${styles.navButtonIcon}  ${
                                    navCollapsed ? 'me-0' : null
                                  }`}
                                >
                                  {icon}
                                </div>
                              }
                            >
                              <div className={styles.popUpContent}>
                                <p className={'primary-color-text'}>{label}</p>
                              </div>
                            </Popup>
                          ) : (
                            <div
                              className={`${styles.navButtonIcon}  ${
                                navCollapsed ? 'me-0' : null
                              }`}
                            >
                              {icon}
                            </div>
                          )}
                          {!navCollapsed && label}
                        </button>
                        {!dashboard &&
                          !isApplicationsPrinted &&
                          (isApplicationsPrinted = true)}
                      </>
                    )
                )}
              </>
            )}
          </nav>
        </div>
        <div className={styles.rightSide}>
          <div className={styles.rightSideTitle}>
            <div className={'d-flex align-items-center'}>
              {navCollapsed && (
                <div>
                  <img src={Logo} alt={'agt logo'} className={'me-3'} />
                </div>
              )}
              <h5 className={'primary-color-text text-bolder'}>{title}</h5>
            </div>
            <Popup
              trigger={
                <div
                  className={'d-flex align-items-center'}
                  // onClick={() => navigate('/userProfile')}
                >
                  {imageSrc ? (
                    <div className={'pointer'}>
                      <Avatar
                        src={URL.createObjectURL(imageSrc)}
                        round={true}
                        size={'40'}
                      />
                    </div>
                  ) : (
                    <div className={`pointer ${styles.profile}`}>
                      <p className={'text-bolder text-white'}>
                        {getInitials(`${user?.firstName} ${user?.lastName}`)}
                      </p>
                    </div>
                  )}

                  <p className={'pointer primary-color-text text-bolder ms-2'}>
                    {`${user?.firstName} ${user?.lastName}`}
                  </p>
                </div>
              }
            >
              <div className={styles.headerListContent}>
                <h6
                  className={styles.menuItem}
                  onClick={() => navigate('/userProfile')}
                >
                  User Profile
                </h6>
                <h6 className={styles.menuItem} onClick={() => setModal(true)}>
                  Log Out
                </h6>
              </div>
            </Popup>
          </div>
          <div className={styles.contentPadding}>{children}</div>
          {modal && (
            <CustomModal
              open={modal}
              onCloseModal={(val) => setModal(val)}
              title={'Logout'}
            >
              <div className={styles.modalWidth}>
                {unsyncedData && (
                  <div>
                    <b
                      className={
                        'primary-color-text d-flex justify-content-center'
                      }
                    >
                      You have unsynced data.
                      <br />
                      {navigator.onLine
                        ? 'Please wait a few moments before logging out while your data is saved.'
                        : 'You are currently offline. Please connect to the internet to save your data'}
                    </b>
                    <br />
                  </div>
                )}
                <h6
                  className={'primary-color-text d-flex justify-content-center'}
                >
                  Are you sure you wish to logout?
                </h6>

                <div className={'mt-5'}>
                  <CustomButton
                    text={'No, stay on the app'}
                    type={'submit'}
                    onClick={() => setModal(false)}
                  />
                </div>
                <div className={'mt-3'}>
                  <CustomButton
                    variant={'secondary'}
                    text={'Yes, logout'}
                    type={'button'}
                    onClick={logout}
                  />
                </div>
              </div>
            </CustomModal>
          )}
        </div>
      </div>
    </>
  );
};

export default CommonLayout;
