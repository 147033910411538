import React, { useEffect, useRef, useState } from 'react';
import { Sample, ScanContext, Stat } from '../../typeDef/grain.model';

import {
  generateLabel,
  isPrinterConnected,
  printLabels,
} from '../../services/devices.service';
import {
  getScannedKeepsCount,
  getScannedSamplesCount,
  getTrialSize,
  getTrialSizeOfKeep,
  keepOrCullSample,
  scanBarcode,
} from '../../services/grain.service';
import {
  playBeepSound,
  playCullSound,
  playKeepSound,
} from '../../common/playSounds';
import { useLocation, useNavigate } from 'react-router-dom';

import CommonLayout from '../../components/layout/commonLayout';
import KeepTikIcon from '../../icons/keepTik.icon';
import { LabelType } from '../../typeDef/devices.model';
import MessageModal from '../../components/modal/messageModal';
//import ScanCode from '../../components/scanCode/scanCode';
import ScanCodeContainer from '../../components/scanCodeContainer/scanCodeContainer';
import ScannedFocusIcon from '../../icons/scanned.icon';
import StatIcon from '../../icons/stat.icon';
import WhiteCrossIcon from '../../icons/whiteCross.icon';

import { logger } from 'workbox-core/_private';
import styles from './../dashboardWeightSection/dashboardWeightSection.module.scss';

/**
 * An interface for the state of the keep cull result page.
 * These values are passed from the keep cull page.
 */
export interface KeepCullResultLocationState {
  scannedSample: Sample;
  trialSize: number;
  trialOfKeepSize: number;
  scannedSamples: number;
  scannedKeeps: number;
  printingLabels: boolean;
  numberOfSplits: number;
  printingDelay: number;
  labelType: LabelType;
}

export const KeepCullResult = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [firstRound, setFirstRound] = useState(true);

  // Current sample state variables
  // const [scannedSample, setScannedSample] = useState<Sample>(
  //   location.state.scannedSample
  // );
  // const [trialSize, setTrialSize] = useState<number>(location.state.trialSize);
  // const [trialOfKeepSize, setTrialOfKeepSize] = useState<number>(
  //   location.state.trialOfKeepSize
  // );
  // const [scannedSamples, setScannedSamples] = useState<number>(
  //   location.state.scannedSamples
  // );
  // const [scannedKeeps, setScannedKeeps] = useState<number>(
  //   location.state.scannedKeeps
  // );

  const [scannedSample, setScannedSample] = useState<Sample>();
  const [trialSize, setTrialSize] = useState<number>();
  const [trialOfKeepSize, setTrialOfKeepSize] = useState<number>();
  const [scannedSamples, setScannedSamples] = useState<number>();
  const [scannedKeeps, setScannedKeeps] = useState<number>();

  // Keep cull settings state variables
  const [printingLabels] = useState<boolean>(location.state.printingLabels);
  //const [numberOfSplits] = useState<number>(location.state.numberOfSplits);
  const numberOfSplits = useRef<number>(1);
  const [printingDelay] = useState<number>(location.state.printingDelay);
  const [labelType] = useState<LabelType>(location.state.labelType);

  // Modal state variables
  //const [isScanCodeModalVisible, setScanCodeModalVisible] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, seIsError] = useState(false);
  const [messageText, setMessageText] = useState('');

  const scannedSamplesTrailsAndEntries =
    scannedSample?.trials && scannedSample?.trials.length > 0
      ? scannedSample?.trials.map((item, index) => ({
          item1: item,
          item2: scannedSample?.entries[index],
        }))
      : scannedSample?.entries.map((item, index) => ({
          item1: scannedSample?.trials[index],
          item2: item,
        }));

  /**
   * First round of keep cull
   * @param barcode The barcode to scan
   * @returns {void}
   */
  const goToFirstRound = async (barcode: string): Promise<void> => {
    setLoading(true);
    try {
      // Get the scan context
      const context = printingLabels
        ? ScanContext.KEEP_CULL_PRINT
        : ScanContext.KEEP_CULL;

      // Get the sample details
      const sample = await scanBarcode(barcode, context);

      // If no sample found, show error message
      if (!sample) {
        setLoading(false);
        setIsSuccess(false);
        seIsError(true);
        playBeepSound();
        setMessageText(
          navigator.onLine
            ? `No data was found`
            : `
              No data was found.
              The device is offline, so the barcode may not have been synced yet.
            `
        );
        setOpenModal(true);
        return;
      }

      // If the sample does not have enough data, show error message
      //const splits = splittingSelection === 'Yes' ? numberOfSplits : 1;

      const splits = sample.entries.length;
      numberOfSplits.current = splits;

      // if (
      //   printingLabels &&
      //   sample.future === 'KEEP'
      //   // &&
      //   // (sample.trials.length < splits || sample.entries.length < splits)
      // ) {
      //   setLoading(false);
      //   setIsSuccess(false);
      //   seIsError(true);
      //   playBeepSound();
      //   setMessageText(
      //     splits === 1
      //       ? `The sample ${barcode} does not have any future trial data.`
      //       : `The sample ${barcode} does not have enough data to be split ${numberOfSplits.current} times.`
      //   );
      //   setOpenModal(true);
      //   return;
      // }
      if (sample.future === '' || sample.future === null) {
        setLoading(false);
        setIsSuccess(false);
        seIsError(true);
        playBeepSound();
        setMessageText(
          `Barcode scanned was found, but FUTURE content is invalid entry
                  (FUTURE column must contain “KEEP” or “CULL”)`
        );
        setOpenModal(true);
        return;
      }
      // Get the trial information
      const trialSizeCount = await getTrialSize(sample.trialName);
      const trialSizeOfKeepCount = await getTrialSizeOfKeep(sample.trialName);

      const scannedSamplesCount = await getScannedSamplesCount(
        sample.trialName,
        context
      );
      const scannedKeepsCount = await getScannedKeepsCount(
        sample.trialName,
        context
      );

      // Connect to the printer
      if (printingLabels) {
        if (!(await isPrinterConnected())) {
          setLoading(false);
          setIsSuccess(false);
          seIsError(true);
          playBeepSound();
          setMessageText('Could not connect to printer.');
          setOpenModal(true);
          return;
        }
      }

      setScannedSample(sample);
      setLoading(false);
      setTrialSize(trialSizeCount ?? 1);
      setTrialOfKeepSize(trialSizeOfKeepCount ?? 1);
      setScannedSamples(scannedSamplesCount ?? 0);
      setScannedKeeps(scannedKeepsCount ?? 0);

      setFirstRound(false);

      if (sample.future === Stat.KEEP) {
        playKeepSound();
      } else if (sample.future === Stat.CULL) {
        playCullSound();
      }

      // const state: KeepCullResultLocationState = {
      //   scannedSample: sample,
      //   trialSize: trialSize ?? 1,
      //   trialOfKeepSize: trialOfKeepSize ?? 1,
      //   scannedSamples: scannedSamplesCount ?? 0,
      //   scannedKeeps: scannedKeepsCount ?? 0,
      //   printingLabels: keepCullPrintSelection === 'Yes',
      //   labelType:
      //     keepCullPrintType === '1D barcode'
      //       ? LabelType.ONE_DIMENSION_BARCODE
      //       : keepCullPrintType === '2D barcode'
      //       ? LabelType.TWO_DIMENSION_BARCODE
      //       : LabelType.QR_CODE,
      //   numberOfSplits: splits,
      //   printingDelay: splittingSelection === 'Yes' ? delaySeconds : 0,
      // };
      // navigate('/keepCull/result', {
      //   state: state,
      // });
    } catch (e) {
      // If any error occurs, show error message
      logger.log(e);
      playBeepSound();
      setMessageText('An unknown error occured');
      setLoading(false);
      setIsSuccess(false);
      seIsError(true);
      setOpenModal(true);
    }
  };

  /**
   * Run the keep cull process.
   * @param sample The sample to keep or cull
   * @returns {void}
   */
  const runKeepCull = async (sample: Sample): Promise<void> => {
    if (printingLabels && sample.future === Stat.KEEP) {
      // Create the labels
      const labels = [];
      for (let i = 0; i < numberOfSplits.current; i++) {
        labels.push(
          generateLabel(
            labelType,
            sample.trials[i].trim(),
            sample.entries[i].trim()
          )
        );
      }

      try {
        // Print the labels
        await printLabels(labels, printingDelay * 1000);
      } catch (e) {
        setLoading(false);
        setIsSuccess(false);
        seIsError(true);
        playBeepSound();
        setMessageText('The printer is not connected.');
        setOpenModal(true);
        return;
      }
    }

    // Record that the sample has been scanned
    await keepOrCullSample(sample.id, printingLabels);

    // Open the scan code modal
    //setScanCodeModalVisible(true);
  };

  /**
   * Scan a barcode and update the state with the new sample.
   * @param barcode The barcode to scan
   * @returns {void}
   */
  const newScan = async (barcode: string): Promise<void> => {
    setLoading(true);
    try {
      // Get the scan context
      const context = printingLabels
        ? ScanContext.KEEP_CULL_PRINT
        : ScanContext.KEEP_CULL;

      // Get the sample details
      const sample = await scanBarcode(barcode, context);

      // If no sample found, show error message
      if (!sample) {
        setLoading(false);
        setIsSuccess(false);
        seIsError(true);
        playBeepSound();
        setMessageText(
          navigator.onLine
            ? `No data was found.`
            : `
              No data was found.
              The device is offline, so the barcode may not have been synced yet.
            `
        );
        setOpenModal(true);
        return;
      }

      numberOfSplits.current = sample.entries.length;

      // If the sample does not have enough data, show error message
      // if (
      //   printingLabels &&
      //   sample.future === 'KEEP'
      //   // &&
      //   // (sample.trials.length < numberOfSplits ||
      //   //   sample.entries.length < numberOfSplits)
      // ) {
      //   setLoading(false);
      //   setIsSuccess(false);
      //   seIsError(true);
      //   playBeepSound();
      //   setMessageText(
      //     numberOfSplits.current === 1
      //       ? `The sample ${barcode} does not have any future trial data.`
      //       : `The sample ${barcode} does not have enough data to be split ${numberOfSplits.current} times.`
      //   );
      //   setOpenModal(true);
      //   return;
      // }
      if (sample.future === null || sample.future === '') {
        setLoading(false);
        setIsSuccess(false);
        seIsError(true);
        playBeepSound();
        setMessageText(
          `Barcode scanned was found, but FUTURE content is invalid entry
                 (FUTURE column must contain “KEEP” or “CULL”)`
        );
        setOpenModal(true);
        return;
      }
      // Get the trial information
      const trialSizeCount = await getTrialSize(sample.trialName);
      const trialSizeOfKeepCount = await getTrialSizeOfKeep(sample.trialName);

      const scannedSamplesCount = await getScannedSamplesCount(
        sample.trialName,
        context
      );
      const scannedKeepsCount = await getScannedKeepsCount(
        sample.trialName,
        context
      );

      // Update the current sample state
      setScannedSample(sample);
      setLoading(false);
      setTrialSize(trialSizeCount ?? 1);
      setTrialOfKeepSize(trialSizeOfKeepCount ?? 1);
      setScannedSamples(scannedSamplesCount ?? 0);
      setScannedKeeps(scannedKeepsCount ?? 0);

      // Close the modal
      //setScanCodeModalVisible(false);

      if (sample.future === Stat.KEEP) {
        playKeepSound();
      } else if (sample.future === Stat.CULL) {
        playCullSound();
      }
    } catch (e) {
      // If any error occurs, show error message
      logger.log(e);
      playBeepSound();
      setMessageText('An unknown error occured.');
      setLoading(false);
      setIsSuccess(false);
      seIsError(true);
      setOpenModal(true);
    }
  };

  // Run the keep cull process on the scanned sample
  useEffect(() => {
    if (scannedSample) {
      runKeepCull(scannedSample);
    }
  }, [scannedSample]);

  // Render the page
  return (
    <CommonLayout admin title={'Keep/Cull'} selectedPage={'keepCull'}>
      <div className="w-100">
        <div className="row">
          <div className={'col-lg-8'}>
            <div>
              <ScanCodeContainer
                source={'/keepCull'}
                onOk={async (barcode: string) => {
                  if (firstRound) {
                    await goToFirstRound(barcode);
                  } else {
                    await newScan(barcode);
                  }
                }}
                onEndSession={() => {
                  null;
                }}
                isLoading={loading}
                disable={false}
              />
            </div>
            <div className={'row mt-4'}>
              <div className="col-lg-6 mt-4 mt-lg-0">
                <div className={`card ${styles.card}`}>
                  <div className={styles.customCardBody2}>
                    <div className={styles.progressHeaderWrapper}>
                      <div className={styles.progressHeaderSuccess}>
                        {scannedKeeps ?? 0} / {trialOfKeepSize ?? 0}
                      </div>
                      <div className={styles.progressHeaderTitle}>
                        Scanned Keeps
                      </div>
                    </div>
                    <div className={`progress ${styles.customProgress}`}>
                      <div
                        className={`progress-bar bg-success ${styles.customProgressBarSuccess}`}
                        role="progressbar"
                        style={{
                          width: `${
                            ((scannedKeeps ?? 0) / (trialOfKeepSize ?? 1)) * 100
                          }%`,
                        }}
                        aria-valuenow={scannedKeeps}
                        aria-valuemin={0}
                        aria-valuemax={trialOfKeepSize}
                      ></div>
                    </div>

                    <div className={styles.progressHeaderWrapper}>
                      <div className={styles.progressHeaderWarning}>
                        {scannedSamples ?? 0} / {trialSize ?? 0}
                      </div>
                      <div className={styles.progressHeaderTitle}>
                        Scanned Samples
                      </div>
                    </div>
                    <div className={`progress ${styles.customProgress}`}>
                      <div
                        className={`progress-bar bg-warning ${styles.customProgressBarWarning}`}
                        role="progressbar"
                        style={{
                          width: `${
                            ((scannedSamples ?? 0) / (trialSize ?? 1)) * 100
                          }%`,
                        }}
                        aria-valuenow={scannedSamples}
                        aria-valuemin={0}
                        aria-valuemax={trialSize}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className={`card ${styles.card}`}>
                  <div className={styles.customCardBody2}>
                    <div className={styles.topicContainer}>
                      <div className={'d-flex align-items-center'}>
                        <ScannedFocusIcon />
                        <h6 className={`primary-color-text ms-2`}>Scanned</h6>
                      </div>
                    </div>

                    <div className={`mt-2  `}>
                      <h3 className={'primary-color-text'}>
                        {scannedSample ? scannedSample.barcode : 'N/A'}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 mt-4">
              <div className={`card ${styles.card}`}>
                <div className={styles.customCardBody}>
                  <div className={styles.topicContainer}>
                    <div className={'d-flex align-items-center'}>
                      <StatIcon />
                      <h6 className={'primary-color-text text-bolder ms-3'}>
                        Keep/Cull Status
                      </h6>
                    </div>
                  </div>
                  {scannedSample?.future === Stat.KEEP ? (
                    <div className={'mt-4 d-flex align-items-center'}>
                      <div className={styles.keepStatusIcon}>
                        <KeepTikIcon />
                      </div>
                      <h1 className={'success-color-text ms-2'}>KEEP</h1>
                    </div>
                  ) : scannedSample?.future === Stat.CULL ? (
                    <div className={'mt-2 d-flex align-items-center'}>
                      <div className={styles.cullStatusIcon}>
                        <WhiteCrossIcon size={15} />
                      </div>
                      <h1 className={'text-danger ms-2'}>CULL</h1>
                    </div>
                  ) : (
                    'N/A'
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={'col-lg-4'}>
            {/*{!printingLabels && (*/}
            {/*  <div className={`card ${styles.card}`}>*/}
            {/*    {scannedSample?.trials.map((trial, index) => (*/}
            {/*      <div*/}
            {/*        key={index}*/}
            {/*        className={`d-flex justify-content-center ${styles.customCardBody}`}*/}
            {/*      >*/}
            {/*        <h5 className={'d-flex primary-color-text text-bolder'}>*/}
            {/*          Trial {index + 1} -{' '}*/}
            {/*          <span className={'text-normal ms-2'}>{trial}</span>*/}
            {/*        </h5>*/}
            {/*      </div>*/}
            {/*    ))}*/}
            {/*  </div>*/}
            {/*)}*/}
            {scannedSamplesTrailsAndEntries &&
              scannedSamplesTrailsAndEntries?.length > 0 &&
              scannedSamplesTrailsAndEntries?.map((record, index) => (
                <div
                  key={index}
                  className={`d-flex justify-content-between ${styles.customRecord}`}
                >
                  <h5
                    className={
                      'd-flex flex-column primary-color-text text-bolder'
                    }
                  >
                    Trial {index + 1}
                    <span className={'text-normal mt-1'}>
                      {record.item1 ? record.item1 : 'N/A'}
                    </span>
                  </h5>
                  <div className={styles.verticalLine}></div>
                  <h5
                    className={
                      'd-flex flex-column primary-color-text text-bolder'
                    }
                  >
                    Entry {index + 1}
                    <span className={'text-normal mt-1'}>
                      {record.item2 ? record.item2 : 'N/A'}
                    </span>
                  </h5>
                </div>
              ))}

            {scannedSamplesTrailsAndEntries?.length === 0 && (
              <div
                className={`d-flex justify-content-between ${styles.customRecord}`}
              >
                <h5
                  className={
                    'd-flex flex-column primary-color-text text-bolder'
                  }
                >
                  Trial
                  <span className={'text-normal mt-1'}>{'N/A'}</span>
                </h5>
                <div className={styles.verticalLine}></div>
                <h5
                  className={
                    'd-flex flex-column primary-color-text text-bolder'
                  }
                >
                  Entry
                  <span className={'text-normal mt-1'}>{'N/A'}</span>
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>

      {openModal && (
        <MessageModal
          title={isSuccess ? 'Success' : 'Error'}
          isSuccess={isSuccess}
          isError={isError}
          onCloseModal={(value) => {
            setOpenModal(value);

            // If the printer is not connected, navigate to the keep cull page
            if (messageText === 'The printer is not connected.') {
              navigate('/keepCull');
            }
          }}
          openModal={openModal}
          messageText={messageText}
        />
      )}
      {/*<ScanCode*/}
      {/*  source={'/keepCull'}*/}
      {/*  show={isScanCodeModalVisible}*/}
      {/*  onHide={() => {*/}
      {/*    setScanCodeModalVisible(false);*/}
      {/*  }}*/}
      {/*  onOk={async (barcode: string) => {*/}
      {/*    await newScan(barcode);*/}
      {/*  }}*/}
      {/*  onEndSession={() => {*/}
      {/*    null;*/}
      {/*  }}*/}
      {/*  isLoading={loading}*/}
      {/*  section={'keepCullResult'}*/}
      {/*  future={scannedSample.future}*/}
      {/*  cull={Stat.CULL}*/}
      {/*  keep={Stat.KEEP}*/}
      {/*/>*/}
    </CommonLayout>
  );
};
