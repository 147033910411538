import { Dispatch, SetStateAction, createContext } from 'react';

export class AgtUser {
  public createdAt: string;
  public createdBy: string;
  public email: string;
  public username: string;
  public firstName: string;
  public lastName: string;
  public id: string;
  public role: string;
  public type: string;
  public location: string;
  public profileImageKey: string;
  public updatedAt: string;
  public updatedBy: string;
  public jwtToken: string;

  constructor(data: Partial<AgtUser>) {
    this.createdAt = data.createdAt || '';
    this.createdBy = data.createdBy || '';
    this.email = data.email || '';
    this.username = data.username || '';
    this.firstName = data.firstName || '';
    this.lastName = data.lastName || '';
    this.id = data.id || '';
    this.role = data.role || '';
    this.type = data.type || '';
    this.location = data.location || '';
    this.profileImageKey = data.profileImageKey || '';
    this.updatedAt = data.updatedAt || '';
    this.updatedBy = data.updatedBy || '';
    this.jwtToken = data.jwtToken || '';
  }
}

type AuthType = {
  user?: AgtUser;
  setUser: Dispatch<SetStateAction<AgtUser | undefined>>;
};

export default createContext<AuthType>({
  user: undefined,
  setUser: () => null,
});
