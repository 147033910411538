export enum UserRole {
  USER = 'User',
  BREEDER = 'Breeder',
  ADMIN = 'Admin',
}

export enum UserName {
  FIRSTNAME = 'firstName',
  LASTNAME = 'lastName',
  EMAIL = 'email',
  USERNAME = 'username',
  LOCATION = 'location',
  CREATEDATE = 'createdAt',
  LASTLOGIN = 'lastLogin',
  DISABLEDATE = 'disableDate',
  STATUS = 'isActive',
  TYPE = 'type',
}
export enum UserRoleDisplayFormat {
  GUEST = 'Casual',
  ADMIN = 'Admin',
  STAFF = 'Staff',
}

export enum UserType {
  CASUAL = 'Casual',
  PERMANENT = 'Permanent',
}

export enum UserRoleType {
  ADMIN = 'Admin',
  CASUAL = 'Casual',
  PERMANENT_USER = 'Permanent User',
}

export interface UpdateCurrentUser {
  firstName: string;
  lastName: string;
}

export interface UserRequest {
  /**
   * Search params
   */
  firstName?: string;
  lastName?: string;
  email?: string;
  location?: string;

  /**
   * Filter params
   */
  role?: UserRole;
  type?: UserType;

  /**
   * Pagination params
   */
  pageNumber?: number;
  pageSize?: number;

  /**
   * Sort params
   */
  sortField?: string;
  secondarySortField?: string;
  sec?: string;
  sortOrder?: string;
  secondarySortOrder?: string;
}
export interface UserResponse {
  paths: string;
  id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  role: UserRole;
  type: UserType;
  location: string;
  profileImageKey: string;
  lastLogin: string;
  disableDate: string;
  isActive: boolean;
  credentials: {
    id: string;
    password: string;
    salt: string;
    resetToken: string;
    createdAt: string;
    updatedAt: string;
  };
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  data: Buffer;
}

export interface CreateLocalUserRequest {
  firstName: string;
  lastName: string;
  email: string | null;
  role: UserRole;
  type: UserType;
  location: string;
  password: string;
  username: string;
  disableDate: string | null;
}

export interface UpdateUser {
  firstName?: string;
  lastName?: string;
  email?: string | null;
  role?: UserRole;
  type: UserType;
  location?: string;
  password?: string;
  username?: string;
}

export type UserDataType = {
  createdAt: string;
  createdBy: string;
  email: string;
  firstName: string;
  lastName: string;
  id: string;
  role: string;
  type: string;
  location: string;
  profileImageKey: string;
  updatedAt: string;
  updatedBy: string;
};

export interface ReactivateDisableUser {
  id: string;
  type?: string;
}
