import React, { useCallback, useEffect, useState } from 'react';
import BackgroundImage from '../../../images/profileBackground.png';
import getInitials from '../../../common/users';
import { getUserProfileImage } from '../../../services/user.service';
import styles from './userListTable.module.scss';

type ProfileImageProps = {
  userId: string;
  firstName: string;
  lastName: string;
  profileImageKey: string;
  loading: (value: boolean) => void;
};

const ProfileImage = ({
  userId,
  firstName,
  lastName,
  profileImageKey,
  loading,
}: ProfileImageProps) => {
  const [imageSrc, setImageSrc] = useState<Blob>();

  const getUserListTableProfileImage = useCallback(async () => {
    loading(true);
    try {
      const response = await getUserProfileImage(userId);
      const blob = new Blob([new Uint8Array(response.data.data)], {
        type: 'application/octet-stream',
      });
      setImageSrc(blob);
      setTimeout(() => {
        loading(false);
      }, 3000);
    } catch (e) {
      loading(false);
    }
  }, [userId]);

  useEffect(() => {
    (async () => {
      if (profileImageKey) {
        await getUserListTableProfileImage();
      }
    })();
  }, [profileImageKey]);

  return (
    <div className={styles.imageSize}>
      <img
        src={imageSrc ? URL.createObjectURL(imageSrc) : BackgroundImage}
        className={styles.backgroundImageUserTable}
        alt={'backGroundImage'}
      />
      {!imageSrc && (
        <h4 className={`text-white text-bolder ${styles.imageText}`}>
          {getInitials(`${firstName} ${lastName}`)}
        </h4>
      )}
    </div>
  );
};

export default ProfileImage;
