const baseUrl = 'https://35.169.154.230.nip.io:4000/v1';
const excelBaseUrl = 'https://35.169.154.230.nip.io:4001/v1';
const imageDownloadBaseUrl = 'https://35.169.154.230.nip.io:4002/v1';

export const Urls = {
  BASE_URL: process.env.BASE_URL ?? `${baseUrl}`,
  EXCEL_BASE_URL: process.env.EXCEL_BASE_URL ?? `${excelBaseUrl}`,
  IMAGE_DOWNLOAD_BASE_URL:
    process.env.IMAGE_DOWNLOAD_BASE_URL ?? `${imageDownloadBaseUrl}`,
};
