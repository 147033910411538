import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import {
  MasterDataCategory,
  MasterDataRequest,
} from '../../typeDef/masterData.model';
import React, { useEffect, useMemo, useState } from 'react';
import {
  //ScanContext,
  WeightTrait,
  WeightUnit,
} from '../../typeDef/grain.model';
import {
  getInitialsHlwLowerLimitValues,
  getInitialsHlwUpperLimitValues,
  getInitialsSampleLowerLimitValues,
  getInitialsSampleUpperLimitValues,
} from '../../common/grains';
// import {
//   getScannedKeepsCount,
//   getScannedSamplesCount,
//   getTrialSize,
//   getTrialSizeOfKeep,
//   scanBarcode,
// } from '../../services/grain.service';
import ArchiveIcon from '../../icons/archive.icon';
import ButtonGroup from '../../components/shared/radioButtonWithCheckbox/buttonGroup';
import CommonLayout from '../../components/layout/commonLayout';
//import CrackedGrainPortionModal from './crackedGrainPortionModal';
import CustomButton from '../../components/shared/customButton/customButton';
import CustomInputFiled from '../../components/shared/customInputField/customInputFiled';
//import CustomModal from '../../components/shared/customModal/customModal';
//import MessageModal from '../../components/modal/messageModal';
//import ScanCode from '../../components/scanCode/scanCode';
import { getMasterData } from '../../services/masterData.service';
//import { logger } from '../../logger';
//import { playBeepSound } from '../../common/playSounds';
import styles from './dashboardWeightSection.module.scss';
import { useNavigate } from 'react-router-dom';

export type WeightTraitInputTypes = {
  plotArea?: number;
  hectolitrePlotArea?: number;
  hlwLowerLimit?: number | string;
  hlwUpperLimit?: number | string;
  sampleLowerLimit?: number | string;
  sampleUpperLimit?: number | string;
  // screeningLowerLimit?: number;
  // screeningUpperLimit?: number;
  barcode?: string;
};

export type Workstation = {
  text: string;
  value: string;
};

export default function DashboardWeightSection() {
  const navigate = useNavigate();
  // const [openModal, setOpenModal] = useState(false);
  // const [isSuccess, setIsSuccess] = useState(false);
  // const [isError, seIsError] = useState(false);
  // const [messageText, setMessageText] = useState('');
  const [weightingCrackedGrains, setWeightingCrackedGrains] = useState<
    string | null
  >('No');
  const [selectedWeightTrait, setSelectedWeightTrait] = useState<string | null>(
    WeightTrait.YIELD
  );
  const [weightUnits, setWeightUnits] = useState<string | null>(
    WeightUnit.GRAMS
  );
  const [, setWorkStationDropdownOptions] = useState<Workstation[]>([]);
  const [workstation, setWorkstation] = useState<Workstation>();
  const [WeightTraitArr] = useState<string[]>([
    WeightTrait.YIELD,
    WeightTrait.SCREENS,
    WeightTrait.HLW,
    WeightTrait.HLW_SCREENS,
    WeightTrait.IMAGING,
    WeightTrait.IMAGING_HLW,
    WeightTrait.IMAGING_WEIGHT,
    WeightTrait.SCREENINGS_IMAGINGS,
    WeightTrait.IMAGING_HLW_SCREENINGS,
  ]);
  //const [isScanCodeModalVisible, setScanCodeModalVisible] = useState(false);

  //const [loading, setLoading] = useState(false);

  const [hLWLowerLimit, setHLWLowerLimit] = useState('');
  const [hLWUpperLimit, setHLWUpperLimit] = useState('');
  const [sampleLowerLimit, setSampleLowerLimit] = useState('');
  const [sampleUpperLimit, setSampleUpperLimit] = useState('');

  const getWorkStationList = async () => {
    try {
      const query: Partial<MasterDataRequest> = {
        category: MasterDataCategory.WORK_STATION,
      };
      const response = await getMasterData(query);
      const updatedOptions = response.data.map((da) => ({
        text: da.value,
        value: da.category,
      }));
      setWorkStationDropdownOptions(updatedOptions);
      setWorkstation(updatedOptions[0]);
    } catch {
      // setIsSuccess(false);
      // seIsError(true);
      // setMessageText('Something went wrong');
      // setOpenModal(true);
    }
  };

  useEffect(() => {
    (async () => {
      await getWorkStationList();
    })();
  }, []);

  function weightConverter(weight: number) {
    if (weightUnits === WeightUnit.KILOGRAMS) {
      return weight / 1000;
    }
    // else if (weightUnits === WeightUnit.GRAMS) {
    //   return weight * 1000;
    // }
    else {
      return weight;
    }
  }

  const initialVal = useMemo(() => {
    return {
      plotArea: selectedWeightTrait === WeightTrait.YIELD ? 4.2 : 30,
      hectolitrePlotArea: 500,
      hlwLowerLimit: hLWLowerLimit
        ? weightConverter(parseFloat(hLWLowerLimit))
        : weightConverter(getInitialsHlwLowerLimitValues(selectedWeightTrait)),
      hlwUpperLimit: hLWUpperLimit
        ? weightConverter(parseFloat(hLWUpperLimit))
        : weightConverter(getInitialsHlwUpperLimitValues(selectedWeightTrait)),
      sampleLowerLimit: sampleLowerLimit
        ? weightConverter(parseFloat(sampleLowerLimit))
        : weightConverter(
            getInitialsSampleLowerLimitValues(selectedWeightTrait)
          ),
      sampleUpperLimit: sampleUpperLimit
        ? weightConverter(parseFloat(sampleUpperLimit))
        : weightConverter(
            getInitialsSampleUpperLimitValues(selectedWeightTrait)
          ),
      // screeningLowerLimit: 0,
      // screeningUpperLimit: 0,
      selectedWeightTrait: selectedWeightTrait,
    };
  }, [weightUnits, selectedWeightTrait]);

  const plotAreaSchema = Yup.object({
    plotArea: Yup.number()
      .required(`This value is required.`)
      .min(0, `This value must be greater than 0.`)
      .max(10000, `This value must be less than 10000.`),
  });

  const hectolitrePlotAreaSchema = Yup.object({
    hectolitrePlotArea: Yup.number()
      .required(`This value is required.`)
      .min(0, `This value must be greater than 0.`)
      .max(10000, `This value must be less than 10000.`),
  });

  const hlwLimitSchema = Yup.object({
    hlwLowerLimit: Yup.number()
      .required(`This value is required.`)
      .min(0, `This value must be greater than 0.`)
      .max(10000, `This value must be less than 10000.`)
      .lessThan(
        Yup.ref('hlwUpperLimit'),
        'This value must be less than the upper limit.'
      ),
    hlwUpperLimit: Yup.number()
      .required(`This value is required.`)
      .min(0, `This value must be greater than 0.`)
      .max(10000, `This value must be less than 10000.`)
      .moreThan(
        Yup.ref('hlwLowerLimit'),
        'This value msut be greater than the lower limit.'
      ),
  });

  const sampleLimitSchema = Yup.object({
    sampleLowerLimit: Yup.number()
      .required(`This value is required.`)
      .min(0, `This value must be greater than 0.`)
      .max(10000, `This value must be less than 10000.`)
      .lessThan(
        Yup.ref('sampleUpperLimit'),
        'This value must be less than the upper limit.'
      ),
    sampleUpperLimit: Yup.number()
      .required(`This value is required.`)
      .min(0, `This value must be greater than 0.`)
      .max(10000, `This value must be less than 10000.`)
      .moreThan(
        Yup.ref('sampleLowerLimit'),
        'This value msut be greater than the lower limit.'
      ),
  });

  const validationSchema = Yup.object().shape({
    ...((selectedWeightTrait === WeightTrait.YIELD ||
      selectedWeightTrait === WeightTrait.IMAGING ||
      selectedWeightTrait === WeightTrait.IMAGING_HLW ||
      selectedWeightTrait === WeightTrait.IMAGING_WEIGHT ||
      selectedWeightTrait === WeightTrait.SCREENINGS_IMAGINGS ||
      selectedWeightTrait === WeightTrait.IMAGING_HLW_SCREENINGS) &&
      plotAreaSchema.fields),

    ...((selectedWeightTrait === WeightTrait.HLW ||
      selectedWeightTrait === WeightTrait.HLW_SCREENS ||
      selectedWeightTrait === WeightTrait.IMAGING_HLW ||
      selectedWeightTrait === WeightTrait.IMAGING_HLW_SCREENINGS) &&
      hectolitrePlotAreaSchema.fields),

    ...((selectedWeightTrait === WeightTrait.HLW ||
      selectedWeightTrait === WeightTrait.HLW_SCREENS ||
      selectedWeightTrait === WeightTrait.IMAGING_HLW ||
      selectedWeightTrait === WeightTrait.IMAGING_HLW_SCREENINGS) &&
      hlwLimitSchema.fields),

    ...((selectedWeightTrait === WeightTrait.IMAGING ||
      selectedWeightTrait === WeightTrait.IMAGING_HLW ||
      selectedWeightTrait === WeightTrait.IMAGING_WEIGHT ||
      selectedWeightTrait === WeightTrait.SCREENINGS_IMAGINGS ||
      selectedWeightTrait === WeightTrait.IMAGING_HLW_SCREENINGS) &&
      sampleLimitSchema.fields),
  });

  // const [weightTraitInputs, setWeightTraitInputs] =
  //   useState<WeightTraitInputTypes>({});

  // const goToNextScreen = async (barcode: string) => {
  //   setLoading(true);
  //   try {
  //     // Get the sample details from the barcode
  //     const sample = await scanBarcode(barcode, ScanContext.WEIGHT);
  //
  //     // If no sample found, show error message
  //     if (!sample) {
  //       setLoading(false);
  //       setIsSuccess(false);
  //       seIsError(true);
  //       playBeepSound();
  //       setMessageText(
  //         navigator.onLine
  //           ? `No data was found`
  //           : `
  //             No data was found.
  //             The device is offline, so the barcode may not have been synced yet.
  //           `
  //       );
  //       setOpenModal(true);
  //       return;
  //     }
  //
  //     // Get the trial information
  //     const trialSize = await getTrialSize(sample.trialName);
  //     const trialSizeOfKeep = await getTrialSizeOfKeep(sample.trialName);
  //
  //     const scannedSamplesCount = await getScannedSamplesCount(
  //       sample.trialName,
  //       ScanContext.WEIGHT
  //     );
  //     const scannedKeepsCount = await getScannedKeepsCount(
  //       sample.trialName,
  //       ScanContext.WEIGHT
  //     );
  //
  //     // Set the weight trait inputs and navigate to the next screen
  //     setWeightTraitInputs({ ...weightTraitInputs, barcode: barcode });
  //     setLoading(false);
  //     navigate('/weight/processing', {
  //       state: {
  //         weightTraitInputs: weightTraitInputs,
  //         selectedWeightTrait: selectedWeightTrait,
  //         weightUnits: weightUnits,
  //         trailFileDetails: sample,
  //         workStation: workstation,
  //         weightingCrackedGrains: weightingCrackedGrains,
  //         allTrailFileRecordsCount: trialSize,
  //         allTrailFileRecordsOfKeepCount: trialSizeOfKeep,
  //         allWeightScannedCount: scannedSamplesCount,
  //         allScannedKeepsCount: scannedKeepsCount,
  //       },
  //     });
  //   } catch (e) {
  //     // If any error occurs, show error message
  //     logger.log(e);
  //     playBeepSound();
  //     setMessageText('An unknown error occured');
  //     setLoading(false);
  //     setIsSuccess(false);
  //     seIsError(true);
  //     setOpenModal(true);
  //   }
  // };

  useEffect(() => {
    if (
      selectedWeightTrait === WeightTrait.YIELD ||
      selectedWeightTrait === WeightTrait.HLW ||
      selectedWeightTrait === WeightTrait.IMAGING ||
      selectedWeightTrait === WeightTrait.IMAGING_HLW ||
      selectedWeightTrait === WeightTrait.IMAGING_WEIGHT
    )
      setWeightingCrackedGrains('No');
  }, [selectedWeightTrait]);

  useEffect(() => {
    if (WeightTraitArr.length > 0) {
      if (!WeightTraitArr.find((wt) => wt === selectedWeightTrait))
        setSelectedWeightTrait(WeightTraitArr?.[0]);
    }
  }, [WeightTraitArr]);

  return (
    <CommonLayout admin title={'Weight'} selectedPage={'weight'}>
      <div className={'w-100'}>
        <div className="row">
          {/* <div className="col-lg-6">
            <div className={`card ${styles.customCard}`}>
              <div className={styles.customCardBodyDashboard}>
                <h6 className="card-title mb-4">Workstation</h6>
                <CustomDropdown
                  icon={<PcIcon />}
                  dataList={workStationDropdownOptions}
                  selectedValue={workstation}
                  onChange={() => (item: Workstation) => {
                    setWorkstation(item);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 mt-3 mt-lg-0">
            <div className={`card ${styles.customCard}`}>
              <div className={styles.customCardBodyDashboard}>
                <h6 className="card-title mb-3">
                  Are you weighing cracked grains?
                </h6>
                <ButtonGroup
                  options={['No', 'Yes']}
                  selectedOption={weightingCrackedGrains}
                  onSelect={(option: string) => {
                    setWeightingCrackedGrains(option);
                  }}
                  buttonDisable={
                    selectedWeightTrait === WeightTrait.YIELD ||
                    selectedWeightTrait === WeightTrait.HLW ||
                    selectedWeightTrait === WeightTrait.IMAGING ||
                    selectedWeightTrait === WeightTrait.IMAGING_HLW ||
                    selectedWeightTrait === WeightTrait.IMAGING_WEIGHT
                      ? true
                      : false
                  }
                />
              </div>
            </div>
          </div> */}
          <div className="col-lg-12 mt-4">
            <div className={`card ${styles.customCard}`}>
              <div className={`${styles.customCardBody} pb-5`}>
                <h6 className="card-title mb-4">Traits</h6>
                <ButtonGroup
                  options={WeightTraitArr}
                  selectedOption={selectedWeightTrait}
                  onSelect={(option: string) => {
                    setSelectedWeightTrait(option);
                    setHLWLowerLimit('');
                    setHLWUpperLimit('');
                    setSampleLowerLimit('');
                    setSampleUpperLimit('');
                  }}
                />
                {(selectedWeightTrait === WeightTrait.SCREENS ||
                  selectedWeightTrait === WeightTrait.HLW_SCREENS ||
                  selectedWeightTrait === WeightTrait.SCREENINGS_IMAGINGS ||
                  selectedWeightTrait ===
                    WeightTrait.IMAGING_HLW_SCREENINGS) && (
                  <div>
                    <div className="border my-5"></div>
                    <h6 className="card-title mb-3">
                      Are you estimating cracked grains?
                    </h6>
                    <ButtonGroup
                      options={['No', 'Yes']}
                      selectedOption={weightingCrackedGrains}
                      onSelect={(option: string) => {
                        setWeightingCrackedGrains(option);
                      }}
                    />
                  </div>
                )}
                <div className="border my-5"></div>
                <h6 className="card-title mb-2">Weight Unit</h6>
                <ButtonGroup
                  options={[WeightUnit.GRAMS, WeightUnit.KILOGRAMS]}
                  selectedOption={weightUnits}
                  onSelect={(option: string) => {
                    setWeightUnits(option);
                  }}
                />

                <Formik
                  initialValues={initialVal}
                  validationSchema={validationSchema}
                  onSubmit={(values: WeightTraitInputTypes) => {
                    //setWeightTraitInputs(values);
                    //setScanCodeModalVisible(true);
                    navigate('/weight/processing', {
                      state: {
                        weightTraitInputs: values,
                        selectedWeightTrait: selectedWeightTrait,
                        weightUnits: weightUnits,
                        workStation: workstation,
                        weightingCrackedGrains: weightingCrackedGrains,
                      },
                    });
                  }}
                  enableReinitialize={true}
                >
                  {({ setFieldValue }) => (
                    <Form className={styles.userProfile}>
                      <div className="row mt-4">
                        {(selectedWeightTrait === WeightTrait.HLW ||
                          selectedWeightTrait === WeightTrait.HLW_SCREENS ||
                          selectedWeightTrait === WeightTrait.IMAGING_HLW ||
                          selectedWeightTrait ===
                            WeightTrait.IMAGING_HLW_SCREENINGS) && (
                          <div className="col-xxl-4 col-lg-6">
                            <div>
                              <h6 className="card-title mt-3 mb-3">
                                {/*Set Hectolitre Plot Area or Vessel Size (ml)*/}
                                Set HLW Vessel Size (ml)
                              </h6>
                              <CustomInputFiled
                                name={'hectolitrePlotArea'}
                                tabIndex={1}
                                autoFocus={false}
                                icon={<ArchiveIcon />}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  setFieldValue(
                                    'hectolitrePlotArea',
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        )}
                        {(selectedWeightTrait === WeightTrait.YIELD ||
                          selectedWeightTrait === WeightTrait.IMAGING ||
                          selectedWeightTrait === WeightTrait.IMAGING_HLW ||
                          selectedWeightTrait === WeightTrait.IMAGING_WEIGHT ||
                          selectedWeightTrait ===
                            WeightTrait.IMAGING_HLW_SCREENINGS ||
                          selectedWeightTrait ===
                            WeightTrait.SCREENINGS_IMAGINGS) && (
                          <div className="col-xxl-4 col-lg-6">
                            <div>
                              <h6 className="card-title mt-3 mb-3">
                                {/*Set Sample Plot Area or Vessel Size (ml)*/}
                                {selectedWeightTrait === WeightTrait.YIELD
                                  ? 'Set Plot Area (m²)'
                                  : 'Set Imaging Sample Vessel Size (ml)'}
                              </h6>
                              <CustomInputFiled
                                name={'plotArea'}
                                tabIndex={0}
                                autoFocus={false}
                                icon={<ArchiveIcon />}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => setFieldValue('plotArea', e.target.value)}
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="row mt-3">
                        <div className="col-xxl-4 col-lg-6">
                          {(selectedWeightTrait === WeightTrait.HLW ||
                            selectedWeightTrait === WeightTrait.HLW_SCREENS ||
                            selectedWeightTrait === WeightTrait.IMAGING_HLW ||
                            selectedWeightTrait ===
                              WeightTrait.IMAGING_HLW_SCREENINGS) && (
                            <div className="row">
                              <div className="col-sm-6">
                                <h6 className="card-title mt-3 mb-3">
                                  HLW Lower Limit
                                </h6>
                                <CustomInputFiled
                                  name={'hlwLowerLimit'}
                                  tabIndex={2}
                                  autoFocus={false}
                                  icon={<ArchiveIcon />}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setFieldValue(
                                      'hlwLowerLimit',
                                      e.target.value
                                    );
                                    setHLWLowerLimit(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="col-sm-6">
                                <h6 className="card-title mt-3 mb-3">
                                  HLW Upper Limit
                                </h6>
                                <CustomInputFiled
                                  name={'hlwUpperLimit'}
                                  tabIndex={3}
                                  autoFocus={false}
                                  icon={<ArchiveIcon />}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setFieldValue(
                                      'hlwUpperLimit',
                                      e.target.value
                                    );
                                    setHLWUpperLimit(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          {(selectedWeightTrait === WeightTrait.IMAGING ||
                            selectedWeightTrait ===
                              WeightTrait.IMAGING_WEIGHT ||
                            selectedWeightTrait ===
                              WeightTrait.SCREENINGS_IMAGINGS) && (
                            <div className="row">
                              <div className="col-sm-6">
                                <h6 className="card-title mt-3 mb-3">
                                  Sample Lower Limit
                                </h6>
                                <CustomInputFiled
                                  name={'sampleLowerLimit'}
                                  tabIndex={4}
                                  autoFocus={false}
                                  icon={<ArchiveIcon />}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setFieldValue(
                                      'sampleLowerLimit',
                                      e.target.value
                                    );
                                    setSampleLowerLimit(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="col-sm-6">
                                <h6 className="card-title mt-3 mb-3">
                                  Sample Upper Limit
                                </h6>
                                <CustomInputFiled
                                  name={'sampleUpperLimit'}
                                  tabIndex={5}
                                  autoFocus={false}
                                  icon={<ArchiveIcon />}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setFieldValue(
                                      'sampleUpperLimit',
                                      e.target.value
                                    );
                                    setSampleUpperLimit(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="col-xxl-4 col-lg-6">
                          {/*{selectedWeightTrait === WeightTrait.HLW_SCREENS && (*/}
                          {/*  <div className="row">*/}
                          {/*    <div className="col-sm-6">*/}
                          {/*      <h6 className="card-title mt-3 mb-3">*/}
                          {/*        Screening Lower Limit*/}
                          {/*      </h6>*/}
                          {/*      <CustomInputFiled*/}
                          {/*        name={'screeningLowerLimit'}*/}
                          {/*        icon={<ArchiveIcon />}*/}
                          {/*        onChange={(*/}
                          {/*          e: React.ChangeEvent<HTMLInputElement>*/}
                          {/*        ) =>*/}
                          {/*          setFieldValue(*/}
                          {/*            'screeningLowerLimit',*/}
                          {/*            e.target.value*/}
                          {/*          )*/}
                          {/*        }*/}
                          {/*      />*/}
                          {/*    </div>*/}
                          {/*    <div className="col-sm-6">*/}
                          {/*      <h6 className="card-title mt-3 mb-3">*/}
                          {/*        Screening Upper Limit*/}
                          {/*      </h6>*/}
                          {/*      <CustomInputFiled*/}
                          {/*        name={'screeningUpperLimit'}*/}
                          {/*        icon={<ArchiveIcon />}*/}
                          {/*        onChange={(*/}
                          {/*          e: React.ChangeEvent<HTMLInputElement>*/}
                          {/*        ) =>*/}
                          {/*          setFieldValue(*/}
                          {/*            'screeningUpperLimit',*/}
                          {/*            e.target.value*/}
                          {/*          )*/}
                          {/*        }*/}
                          {/*      />*/}
                          {/*    </div>*/}
                          {/*  </div>*/}
                          {/*)}*/}
                          {(selectedWeightTrait === WeightTrait.IMAGING_HLW ||
                            selectedWeightTrait ===
                              WeightTrait.IMAGING_HLW_SCREENINGS) && (
                            <div className="row">
                              <div className="col-sm-6">
                                <h6 className="card-title mt-3 mb-3">
                                  Sample Lower Limit
                                </h6>
                                <CustomInputFiled
                                  name={'sampleLowerLimit'}
                                  tabIndex={6}
                                  autoFocus={false}
                                  icon={<ArchiveIcon />}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setFieldValue(
                                      'sampleLowerLimit',
                                      e.target.value
                                    );
                                    setSampleLowerLimit(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="col-sm-6">
                                <h6 className="card-title mt-3 mb-3">
                                  Sample Upper Limit
                                </h6>
                                <CustomInputFiled
                                  name={'sampleUpperLimit'}
                                  tabIndex={7}
                                  autoFocus={false}
                                  icon={<ArchiveIcon />}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setFieldValue(
                                      'sampleUpperLimit',
                                      e.target.value
                                    );
                                    setSampleUpperLimit(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        className={`${styles.customButtonWrapper} mt-5 mb-2`}
                      >
                        <CustomButton type="submit" text="Run AGT Weights" />
                      </div>
                    </Form>
                  )}
                </Formik>

                {/*<ScanCode*/}
                {/*  show={isScanCodeModalVisible}*/}
                {/*  onHide={() => {*/}
                {/*    setScanCodeModalVisible(false);*/}
                {/*  }}*/}
                {/*  onEndSession={() => null}*/}
                {/*  onOk={async (barcode: string) => {*/}
                {/*    await goToNextScreen(barcode);*/}
                {/*  }}*/}
                {/*  isLoading={loading}*/}
                {/*  source={'/weight'}*/}
                {/*/>*/}

                {/*<CustomModal*/}
                {/*  open={crackedGrainPortionModal}*/}
                {/*  onCloseModal={() => {*/}
                {/*    setCrackedGrainPortionModal(false);*/}
                {/*  }}*/}
                {/*>*/}
                {/*  <CrackedGrainPortionModal*/}
                {/*    savePortion={async () => {*/}
                {/*      setCrackedGrainPortionModal(false);*/}
                {/*      await goToNextScreen(barCode);*/}
                {/*    }}*/}
                {/*    setEnterCrackedProportion={(value) =>*/}
                {/*      setCrackedProportion(value)*/}
                {/*    }*/}
                {/*    setEnterCrackedNote={(value) => setCrackedNote(value)}*/}
                {/*  />*/}
                {/*</CustomModal>*/}

                {/*{openModal && (*/}
                {/*  <MessageModal*/}
                {/*    title={isSuccess ? 'Success' : 'Error'}*/}
                {/*    isSuccess={isSuccess}*/}
                {/*    isError={isError}*/}
                {/*    onCloseModal={(value) => setOpenModal(value)}*/}
                {/*    openModal={openModal}*/}
                {/*    messageText={messageText}*/}
                {/*  />*/}
                {/*)}*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
}
