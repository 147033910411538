import * as React from 'react';
interface Props {
  size?: number;
  color?: string;
}
const ScannedFocusIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 14}
      height={size ?? 14}
      fill="none"
      viewBox={'0 0 14 14'}
    >
      <g clipPath="url(#a)">
        <path
          fill={color ?? '#003359'}
          d="M11.083 14H10.5a.583.583 0 0 1 0-1.167h.583a1.75 1.75 0 0 0 1.75-1.75V10.5a.583.583 0 0 1 1.167 0v.583A2.92 2.92 0 0 1 11.083 14ZM14 3.5v-.583A2.92 2.92 0 0 0 11.083 0H10.5a.583.583 0 0 0 0 1.167h.583a1.75 1.75 0 0 1 1.75 1.75V3.5A.583.583 0 0 0 14 3.5Zm-9.917 9.917a.583.583 0 0 0-.583-.584h-.583a1.75 1.75 0 0 1-1.75-1.75V10.5A.583.583 0 0 0 0 10.5v.583A2.92 2.92 0 0 0 2.917 14H3.5a.583.583 0 0 0 .583-.583ZM1.167 3.5v-.583a1.75 1.75 0 0 1 1.75-1.75H3.5A.583.583 0 0 0 3.5 0h-.583A2.92 2.92 0 0 0 0 2.917V3.5a.583.583 0 0 0 1.167 0ZM9.333 7a2.333 2.333 0 1 0-4.666 0 2.333 2.333 0 0 0 4.666 0ZM8.167 7a1.167 1.167 0 1 1-2.334 0 1.167 1.167 0 0 1 2.334 0Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h14v14H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default ScannedFocusIcon;
