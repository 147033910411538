import React from 'react';

interface Props {
  size?: number;
  color?: string;
}

const WarningIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 24}
      height={size ?? 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 19V19.01M12 15V5"
        stroke={color ?? '#FFFFFF'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WarningIcon;
