import { ImageRequest, ImageResponse } from '../../typeDef/image.model';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TableHeading, {
  SortDirection,
} from '../../components/shared/tableHeading/tableHeading';
import { AxiosResponse } from 'axios';
import CloseIcon from '../../icons/close.icon';
import CustomButton from '../../components/shared/customButton/customButton';
import CustomModal from '../../components/shared/customModal/customModal';

import EditIcon from '../../icons/edit.icon';

import Folder from '../../images/folder.png';
import GrainImage from './grainImage';
import LoadingSpinner from '../../components/shared/loadingSpinner/loadingSpinner';
import PaginationPages from '../../components/shared/pagination/paginationPages';
import { PaginationType } from '../../typeDef/common.model';
import SearchIcon from '../../icons/search.icon';
import { SortType } from '../imgeManager/imageList';
import TableRow from '../../components/table/userList/userListTableRow/userListTableRow';
import { convertDateFormat } from '../../common/users';
import { getImageDetails } from '../../services/images.service';
import loadingStyles from '../../components/shared/loadingSpinner/loadingSpinner.module.scss';

import styles from '../../components/table/userList/userListTable.module.scss';

type imageViewProps = {
  showModal: boolean;
  onHide: () => void;
  onContinue: (value: boolean) => void;
  trialName: string;
};

export enum SortNameType {
  NAME = 'NAME',
  UPLOADEDBY = 'UPLOADED BY',
}
function WebcamImage({
  showModal,
  onHide,
  onContinue,
  trialName,
}: imageViewProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(false);
  const [fileName, setFileName] = useState('');
  const [imageData, setData] = useState<ImageResponse>();
  const [paginationData, setPaginationData] = useState<PaginationType>();
  const [pageSize, setPageSize] = useState(10);
  const [activePage, setActivePage] = useState<number>(1);
  const sortOrder = useRef(SortDirection.DESC);
  const sortField = useRef('uploadDate');
  const [searchField, setSearchField] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const clickPrevious = () => {
    if (paginationData?.hasPreviousPage) {
      setActivePage(activePage - 1);
    }
  };

  /* click next */
  const clickNext = () => {
    if (paginationData?.hasNextPage) {
      setActivePage(activePage + 1);
    }
  };

  const getAllImages = useCallback(async () => {
    setIsLoading(true);
    setData(undefined);
    try {
      const query: Partial<ImageRequest> = {
        trialName: trialName,
        pageNumber: activePage,
        pageSize: pageSize,
        sortOrder: sortOrder.current ?? SortDirection.ASC,
        sortField: sortField.current ?? 'uploadDate',
        ...(searchField && {
          barcode: searchField.toLowerCase().trim(),
        }),
      };
      const response: AxiosResponse<ImageResponse> = await getImageDetails(
        trialName,
        query
      );
      if (response.data) {
        setData(response.data);
      }
      setPaginationData(response.data.meta);
      setErrorMessage('');
      setIsLoading(false);
    } catch (e) {
      setErrorMessage('No previous images found.');
      setIsLoading(false);
    }
  }, [pageSize, activePage, searchField, sortOrder, sortField, trialName]);

  // useEffect(() => {
  //   setData(imageData);
  // }, [imageData]);

  useEffect(() => {
    (async () => {
      await getAllImages();
    })();
  }, [pageSize, activePage, showModal]);

  const handleChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearchField(e.target.value);
  };

  const handleSearch = async () => {
    try {
      if (searchField === '' || activePage === 1) {
        await getAllImages();
      }
      setActivePage(1);
    } catch (e) {
      setErrorMessage('Something went wrong');
    }
  };

  const handleOnSort = async (sortDirection: SortDirection, title: string) => {
    const sortBy =
      title === SortNameType.NAME
        ? SortType.BARCODE
        : title === SortNameType.UPLOADEDBY
        ? SortType.UPLOADEDBY
        : SortType.UPLOADEDBY;
    //setSortField(sortBy);
    sortField.current = sortBy;
    //setSortOrder(sortDirection);
    sortOrder.current = sortDirection;
    await getAllImages();
  };
  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const pressedKey = event.key;

    if (pressedKey === 'Enter') {
      event.preventDefault();

      await handleSearch();
    }
  };
  useEffect(() => {
    sortField.current = 'uploadDate';
    setActivePage(1);
  }, [showModal]);
  return (
    <CustomModal open={showModal} onCloseModal={onHide}>
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        {isLoading && <LoadingSpinner />}
        <div className={`${styles.mainContentViewImages}`}>
          <div className={'d-flex justify-content-between align-items-center'}>
            <div className={'d-flex align-items-center'}>
              <img src={Folder} alt={'folder'} />
              <h6 className={'primary-color-text text-bolder ms-3'}>
                {trialName}
              </h6>
            </div>
            <div
              className={'d-flex justify-content-between align-items-center'}
            >
              <div className={'d-flex align-items-center'}>
                <div className={styles.searchBarContainer}>
                  <input
                    type="search"
                    className={`form-control ${styles.searchBarViewImage}`}
                    placeholder="Search"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                  <label className={styles.iconSeach}>
                    <SearchIcon />
                  </label>
                </div>

                <CustomButton
                  text={'Search'}
                  className={'ms-4 fit-content'}
                  type={'button'}
                  onClick={handleSearch}
                />
              </div>
            </div>
          </div>
          {errorMessage ? (
            <div>
              <h4 className={'mt-5 text-danger text-center'}>{errorMessage}</h4>
            </div>
          ) : (
            <table className="table table-striped table-hover mt-5">
              <thead className="table-primary">
                <tr>
                  <th scope="col"></th>
                  <th scope="col">
                    <TableHeading
                      field={'NAME'}
                      sortable={true}
                      onSort={(sortDirection, title) =>
                        handleOnSort(sortDirection, title)
                      }
                    />
                  </th>
                  <th scope="col">
                    <TableHeading
                      field={'UPLOADED BY'}
                      sortable={true}
                      onSort={(sortDirection, title) =>
                        handleOnSort(sortDirection, title)
                      }
                    />
                  </th>
                  <th scope="col">
                    <TableHeading
                      field={'UPLOADED DATE'}
                      sortable={true}
                      onSort={(sortDirection, title) =>
                        handleOnSort(sortDirection, title)
                      }
                    />
                  </th>

                  <th scope="col">
                    <TableHeading action field={'ACTIONS'} sortable={false} />
                  </th>
                </tr>
              </thead>

              <tbody>
                {imageData?.items.map((items) => {
                  return (
                    <tr key={items.id}>
                      <th scope="row" className={styles.rowHeight}>
                        <div className={styles.imageSize}>
                          <GrainImage
                            trialName={trialName}
                            fileName={items.fileName}
                            loading={(value) => setIsLoading(value)}
                          />
                        </div>
                      </th>
                      <td className={styles.widthCol}>
                        <TableRow text={items.fileName} />
                      </td>
                      <td className={styles.widthCol}>
                        <TableRow text={items.uploadedBy} />
                      </td>
                      <td className={styles.widthColDate}>
                        <TableRow
                          text={convertDateFormat(new Date(items.uploadDate))}
                        />
                      </td>
                      <td className={styles.widthActionCol}>
                        {/*<div className={styles.actionIcon}></div>*/}
                        <div
                          onClick={() => {
                            setImagePreview(true);
                            setFileName(items.fileName);
                          }}
                        >
                          <EditIcon />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          <div className={'mt-5'}>
            {/*<Pagination*/}
            {/*  userData={userData}*/}
            {/*  setUserData={(value) => setUserData(value)}*/}
            {/*/>*/}

            <div className={'d-flex justify-content-between'}>
              <div className={'d-flex align-items-center'}>
                <h6 className={`me-3 ${styles.rowTest}`}>Rows per page </h6>
                <select
                  id="framework"
                  className={` font-size-default ${styles.selectBox}`}
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(e.target.value as unknown as number);
                    setActivePage(1);
                  }}
                >
                  <option
                    value="05"
                    className={'font-size-default primary-color-text'}
                  >
                    05
                  </option>
                  <option
                    value="10"
                    selected={true}
                    className={'font-size-default primary-color-text'}
                  >
                    10
                  </option>
                  <option
                    value="15"
                    className={'font-size-default primary-color-text'}
                  >
                    15
                  </option>
                  <option
                    value="20"
                    className={'font-size-default primary-color-text'}
                  >
                    20
                  </option>
                  <option
                    value="100"
                    className={'font-size-default primary-color-text'}
                  >
                    100
                  </option>
                  <option
                    value="200"
                    className={'font-size-default primary-color-text'}
                  >
                    200
                  </option>
                </select>
              </div>
              <PaginationPages
                hasPreviousPage={paginationData?.hasPreviousPage}
                hasNextPage={paginationData?.hasNextPage}
                clickPrevious={clickPrevious}
                clickNext={clickNext}
                setActivePage={(value) =>
                  setActivePage(value as unknown as number)
                }
                currentPage={activePage}
                totalCount={paginationData?.itemCount ?? 10}
                pageSize={pageSize}
              />
            </div>
          </div>
          <div
            className={
              'd-flex justify-content-end align-items-center w-100 mt-4'
            }
          >
            <CustomButton
              text={'Continue The Session'}
              className={styles.buttonWidth}
              onClick={() => {
                setSearchField('');
                onContinue(true);
                onHide();
              }}
            />
          </div>
        </div>
      </div>
      <CustomModal
        open={imagePreview}
        onCloseModal={() => setImagePreview(false)}
      >
        <div
          className={`d-flex justify-content-end pointer align-items-center mb-2`}
          onClick={() => setImagePreview(false)}
        >
          <CloseIcon size={14} />

          <h6 className={'text-danger ms-2 mb-1'}>Close</h6>
        </div>
        <GrainImage
          previewImage
          trialName={trialName}
          fileName={fileName}
          loading={(value) => setIsLoading(value)}
        />
      </CustomModal>
    </CustomModal>
  );
}

export default WebcamImage;
