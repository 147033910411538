import { getCurrentUserProfileImage } from '../services/profile.services';

export default function getInitials(name: string | undefined): string {
  let trimmedFullName = '';
  if (name) {
    trimmedFullName = name.trim();
  }

  const nameParts = trimmedFullName.split(' ');

  let initials = '';

  for (const namePart of nameParts) {
    if (namePart.length > 0) {
      initials += namePart[0].toUpperCase();
    }
    if (initials.length >= 2) {
      break;
    }
  }

  return initials;
}

export function capitalizeFirstLetter(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function convertDateFormat(dateObj: Date): string {
  // Extract date components
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
  const day = String(dateObj.getDate()).padStart(2, '0');

  // Extract time components
  const hours = String(dateObj.getHours()).padStart(2, '0');
  const minutes = String(dateObj.getMinutes()).padStart(2, '0');

  // Create the desired format
  const formattedTimestamp = `${year}-${month}-${day} ${hours}:${minutes}`;
  return formattedTimestamp;
}

export function convertDisableDateFormat(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export const getProfileImage = async () => {
  try {
    const response = await getCurrentUserProfileImage();
    const blob = new Blob([new Uint8Array(response.data.data)], {
      type: 'application/octet-stream',
    });
    return blob;
  } catch (e) {
    //setOpenModal(true);
  }
};
