import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import React, { useMemo, useState } from 'react';

import CustomButton from '../../components/shared/customButton/customButton';
import CustomInputField from '../../components/shared/customInputField/customInputFiled';
import EmailIcon from '../../icons/email.icon';
import { ForgotPasswordRequest } from '../../typeDef/auth.model';
import SignInImage from '../../images/sign.webp';
import UploadIcon from '../../icons/upload.icon';
import agtLeaf from '../../images/agtLeaf.webp';
import { forgotPassword } from '../../services/auth.service';

import styles from './signIn.module.scss';

import { useLocation } from 'react-router-dom';

type ForgotPasswordProps = {
  email: string;
};

function ForgetPassword() {
  //const navigate = useNavigate();
  const location = useLocation();
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const initialVal: ForgotPasswordProps = useMemo(() => {
    return {
      email: location.state.email,
    };
  }, [location.state.email]);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Please enter your email address.')
      .email('Please enter a valid email address.'),
  });

  const handleSubmit = async (values: ForgotPasswordProps) => {
    try {
      const email: Partial<ForgotPasswordRequest> = {};
      email.email = values.email;

      await forgotPassword(email)
        .then(() => setSuccess(true))
        .catch();
    } catch (e) {
      setErrorMessage('Something went wrong');
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialVal}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (
          <Form>
            <div className={'d-flex justify-content-between w-100'}>
              <div className={'w-50'}>
                <img
                  src={SignInImage}
                  alt="forget password image"
                  className={styles.logoImage}
                />
              </div>
              <div className={styles.formContainer}>
                <div className={'d-flex justify-content-center'}>
                  <img src={agtLeaf} alt={'leaf'} className={styles.image} />
                </div>
                <h2
                  className={
                    'primary-color-text text-bolder mt-4 mt-xl-0 d-flex justify-content-center'
                  }
                >
                  Welcome to AGT Weights and Keep/Cull
                </h2>
                <h6
                  className={
                    'mt-5 light-text  d-flex justify-content-center text-bolder'
                  }
                >
                  Forgot Password
                </h6>
                {success ? (
                  <>
                    <div className={`mt-5 ${styles.yelloInfoBox}`}>
                      <UploadIcon />
                      <p className={'text-bold secondary-color-text ms-3'}>
                        Please check your email for password reset request
                      </p>
                    </div>
                    {/*<div className={'mt-5'}>*/}
                    {/*  <CustomButton*/}
                    {/*    text={'Done'}*/}
                    {/*    type={'button'}*/}
                    {/*    onClick={() => navigate('/')}*/}
                    {/*    className={styles.heightBtn}*/}
                    {/*  />*/}
                    {/*</div>*/}
                  </>
                ) : (
                  <>
                    <div className={'mt-5'}>
                      <p className={'primary-color-text mb-2'}>Email Address</p>
                      <CustomInputField
                        icon={<EmailIcon />}
                        name={'email'}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          setFieldValue('email', e.target.value)
                        }
                      />
                    </div>
                    {errorMessage && (
                      <div>
                        <p className={'mt-4 text-danger text-center'}>
                          <small>{errorMessage}</small>
                        </p>
                      </div>
                    )}
                    <div className={'mt-5'}>
                      <CustomButton
                        text={'Send New Password Request'}
                        className={styles.heightBtn}
                        type={'submit'}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ForgetPassword;
