import React from 'react';

interface Props {
  size?: number;
  color?: string;
}

const ImportIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 16}
      height={size ?? 16}
      fill="none"
      viewBox={'0 0 16 16'}
    >
      <g clipPath="url(#a)">
        <path
          fill={color ?? 'currentColor'}
          d="M12.497 13.556a.667.667 0 0 1-.054.942l-1.057.943a1.935 1.935 0 0 1-1.376.56 2.01 2.01 0 0 1-1.424-.589l-1.03-.916a.666.666 0 1 1 .887-.996l.89.796V9.333a.667.667 0 1 1 1.334 0v4.964l.89-.795a.666.666 0 0 1 .94.054Zm-.26-8.649a.726.726 0 0 1-.48-.49A5.333 5.333 0 0 0 1.43 4.98a5.105 5.105 0 0 0 .533 3.453A3.632 3.632 0 0 0 .038 12.2a3.895 3.895 0 0 0 3.75 3.133h1.545a.667.667 0 1 0 0-1.333H3.79a2.54 2.54 0 0 1-2.43-1.988 2.313 2.313 0 0 1 1.222-2.4 1.329 1.329 0 0 0 .528-1.79 4 4 0 1 1 7.374-3.009 2.066 2.066 0 0 0 1.363 1.368 3.98 3.98 0 0 1 2.808 4.14 3.585 3.585 0 0 1-.74 1.94.667.667 0 0 0 1.057.812 4.977 4.977 0 0 0 1.013-2.648 5.307 5.307 0 0 0-3.747-5.518Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ImportIcon;
