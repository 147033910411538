import { ErrorResponse, PaginationType } from '../../typeDef/common.model';
import {
  GetSamplesRequest,
  GrainEntryResponse,
  MissingRequest,
  MissingViewCol,
  ScannedGrainStatus,
  Stat,
  TabSwitch,
} from '../../typeDef/grain.model';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TableHeading, {
  SortDirection,
} from '../shared/tableHeading/tableHeading';
import { BlobProvider } from '@react-pdf/renderer';
import CloseIcon from '../../icons/close.icon';
import CustomTabSwitch from '../shared/customTabSwitch/customTabSwtch';
import Label from '../shared/lable/lable';
import LoadingSpinner from '../shared/loadingSpinner/loadingSpinner';
import PaginationPages from '../shared/pagination/paginationPages';
import PrintMissing from './pdf';
import { getSamples } from '../../services/grain.service';

import loadingStyles from '../shared/loadingSpinner/loadingSpinner.module.scss';
import styles from './dashboard.module.scss';

type OpenMissingViewProps = {
  onCloseModal: (value: boolean) => void;
  fileName: string;
  trailName: string;
  keepCullCompleteStatus?: number;
  keepCullNotPrintedLabelCompleteStatus?: number;
  keepCullPrintedLabelCompleteStatus?: number;
  weightCompleteStatus?: number;
};

const OpenMissingView = ({
  onCloseModal,
  fileName,
  trailName,
  keepCullCompleteStatus,
  keepCullPrintedLabelCompleteStatus,
  weightCompleteStatus,
}: OpenMissingViewProps) => {
  const [selectedTab, setSelectedTab] = useState<string>(TabSwitch.WEIGHT);
  const [missingData, setMissingData] = useState<
    GrainEntryResponse[] | undefined
  >([]);
  const [paginationData, setPaginationData] = useState<PaginationType>();
  const [isPageLoading, setIsPageLoading] = useState(false);
  const sortField = useRef(MissingRequest.MR_ROW);
  const sortSecondaryField = useRef(MissingRequest.MR_RANGE);
  const sortOrder = useRef(SortDirection.ASC);
  const sortSecondaryOrder = useRef(SortDirection.ASC);
  const [pageSize, setPageSize] = useState(5);
  const [activePage, setActivePage] = useState<number>(1);
  const [errorMessage, setErrorMessage] = useState('');

  const clickPrevious = () => {
    if (paginationData?.hasPreviousPage) {
      setActivePage(activePage - 1);
    }
  };

  /* click next */
  const clickNext = () => {
    if (paginationData?.hasNextPage) {
      setActivePage(activePage + 1);
    }
  };

  const getAllSamples = useCallback(async () => {
    setIsPageLoading(true);
    try {
      const query: Partial<GetSamplesRequest> = {
        pageNumber: activePage,
        pageSize: pageSize,
        sortOrder: sortOrder.current ?? SortDirection.ASC,
        sortField: sortField.current ?? MissingRequest.MR_ROW,
        secondarySortField:
          sortSecondaryField.current ?? MissingRequest.MR_RANGE,

        secondarySortOrder: sortSecondaryOrder.current ?? SortDirection.ASC,

        fileName: fileName,
        trialName: trailName,
        future: Stat.KEEP,
        ...(selectedTab === TabSwitch.WEIGHT
          ? { scannedWeight: ScannedGrainStatus.FALSE }
          : selectedTab === TabSwitch.KC_LABEL_NP
          ? {
              scannedKeepCull: ScannedGrainStatus.FALSE,
              //scannedKeepCullPrint: ScannedGrainStatus.FALSE,
            }
          : {
              //scannedKeepCull: ScannedGrainStatus.FALSE,
              scannedKeepCullPrint: ScannedGrainStatus.FALSE,
            }),
      };
      const response = await getSamples(query);
      setErrorMessage('');
      setMissingData(response.data.items);
      setMissingData(response.data.items);
      setPaginationData(response.data.meta);
      setIsPageLoading(false);
    } catch (e) {
      const errorResponse = e as ErrorResponse;
      setIsPageLoading(false);
      if (errorResponse.response.data.message === 'No items found') {
        setErrorMessage('All barcodes scanned!');
        setMissingData([]);
      }
    }
  }, [
    pageSize,
    activePage,
    sortOrder,
    sortField,
    selectedTab,
    sortSecondaryField,
    sortSecondaryOrder,
  ]);

  useEffect(() => {
    (async () => {
      await getAllSamples();
    })();
  }, [pageSize, activePage, selectedTab]);

  const handleOnSortMissing = async (
    sortDirection: SortDirection,
    title: string
  ) => {
    const sortBy =
      title === MissingViewCol.RANGE
        ? MissingRequest.MR_RANGE
        : title === MissingViewCol.ROW
        ? MissingRequest.MR_ROW
        : title === MissingViewCol.NAME
        ? MissingRequest.MR_NAME
        : title === MissingViewCol.BARCODE
        ? MissingRequest.MR_BARCODE
        : MissingRequest.MR_PLOT;

    sortField.current = sortBy;
    sortOrder.current = sortDirection;
    if (title === MissingViewCol.RANGE)
      sortSecondaryOrder.current = sortDirection;

    await getAllSamples();
  };

  const handleTabChange = async (selected: string) => {
    setSelectedTab(selected);
    sortField.current = MissingRequest.MR_ROW;
    sortOrder.current = SortDirection.ASC;
    sortSecondaryOrder.current = SortDirection.ASC;
    sortSecondaryField.current = MissingRequest.MR_RANGE;
    setActivePage(1);
  };

  return (
    <div className={`${isPageLoading && loadingStyles.app_while_loading}`}>
      {isPageLoading && <LoadingSpinner />}
      <div className={styles.modalWidth}>
        <div className={'d-flex justify-content-between align-items-center'}>
          <div>
            <h6 className={'text-bolder primary-color-text ms-3'}>
              MISSING STATUS
            </h6>
          </div>
          <div>
            <CustomTabSwitch
              tabs={[
                TabSwitch.WEIGHT,
                TabSwitch.KC_LABEL_NP,
                TabSwitch.KC_LABEL_P,
              ]}
              selectedTab={selectedTab}
              onTabChange={handleTabChange}
            />
          </div>
          <div className={'d-flex align-items-center'}>
            <BlobProvider
              document={
                <PrintMissing
                  tableData={missingData}
                  title={selectedTab}
                  name={fileName}
                  trialName={trailName}
                  completeRatio={
                    selectedTab === TabSwitch.WEIGHT
                      ? weightCompleteStatus
                      : selectedTab === TabSwitch.KC_LABEL_NP
                      ? keepCullCompleteStatus
                      : keepCullPrintedLabelCompleteStatus
                  }
                />
              }
            >
              {({ url }) =>
                ((selectedTab === TabSwitch.WEIGHT &&
                  weightCompleteStatus !== 100) ||
                  (selectedTab === TabSwitch.KC_LABEL_NP &&
                    keepCullCompleteStatus !== 100) ||
                  (selectedTab === TabSwitch.KC_LABEL_P &&
                    keepCullPrintedLabelCompleteStatus !== 100)) && (
                  <a
                    href={url || ''}
                    target="_blank"
                    rel="noreferrer"
                    className={`mt-2 mt-md-0 ${styles.pdfButton}`}
                  >
                    Print
                  </a>
                )
              }
            </BlobProvider>

            <div
              className={'d-flex align-items-center ms-4 pointer'}
              onClick={() => {
                onCloseModal(false);
              }}
            >
              <CloseIcon />
              <h6 className={'text-danger ms-2 mb-1'}>Close</h6>
            </div>
          </div>
        </div>
        <div className={'mt-3'}>
          <div className={styles.dividedLine} />
        </div>
        <div className={'d-flex justify-content-between mt-3'}>
          <div className={'d-flex'}>
            <p className={'primary-color-text'}>FILE NAME: </p>
            <div className={'ms-2'} />
            <p>{fileName}</p>
          </div>
          <div className={'d-flex'}>
            <p className={'primary-color-text'}>EXPERIMENT NAME: </p>
            <div className={'ms-2'} />
            <p>{trailName}</p>
          </div>
          <div>
            <Label
              labelSuccess={
                (selectedTab === TabSwitch.WEIGHT &&
                  weightCompleteStatus === 100) ||
                (selectedTab === TabSwitch.KC_LABEL_NP &&
                  keepCullCompleteStatus === 100) ||
                (selectedTab === TabSwitch.KC_LABEL_P &&
                  keepCullPrintedLabelCompleteStatus === 100)
                  ? true
                  : false
              }
              labelText={
                selectedTab === TabSwitch.WEIGHT
                  ? `${weightCompleteStatus}% COMPLETE`
                  : selectedTab === TabSwitch.KC_LABEL_NP
                  ? `${keepCullCompleteStatus}% COMPLETE`
                  : `${keepCullPrintedLabelCompleteStatus}% COMPLETE`
              }
            />
          </div>
        </div>
        <table className="table table-striped table-hover mt-4">
          <thead className="table-primary">
            <tr>
              <th scope="col">
                <TableHeading
                  field={MissingViewCol.RANGE}
                  sortable={true}
                  onSort={(sortDirection, title) =>
                    handleOnSortMissing(sortDirection, title)
                  }
                />
              </th>
              <th scope="col">
                <TableHeading
                  field={MissingViewCol.ROW}
                  sortable={true}
                  onSort={(sortDirection, title) =>
                    handleOnSortMissing(sortDirection, title)
                  }
                />
              </th>
              <th scope="col">
                <TableHeading
                  field={MissingViewCol.NAME}
                  sortable={true}
                  onSort={(sortDirection, title) =>
                    handleOnSortMissing(sortDirection, title)
                  }
                />
              </th>
              <th scope="col">
                <TableHeading
                  field={MissingViewCol.BARCODE}
                  sortable={true}
                  onSort={(sortDirection, title) =>
                    handleOnSortMissing(sortDirection, title)
                  }
                />
              </th>
              <th scope="col">
                <TableHeading
                  field={MissingViewCol.PLOT}
                  sortable={true}
                  onSort={(sortDirection, title) =>
                    handleOnSortMissing(sortDirection, title)
                  }
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {missingData?.map((item: GrainEntryResponse, index: number) => {
              return (
                <tr key={index}>
                  <td>
                    <p className={styles.rowText}>{item.range}</p>
                  </td>
                  <td>
                    <p className={styles.rowText}>{item.row}</p>
                  </td>
                  <td>
                    <p className={styles.rowText}>{item.name}</p>
                  </td>
                  <td>
                    <p className={styles.rowText}>{item.barcode}</p>
                  </td>
                  <td>
                    <p className={styles.rowText}>{item.plot}</p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {errorMessage && (
          <div>
            <h4 className={'mt-5 text-danger text-center'}>{errorMessage}</h4>
          </div>
        )}
        <div className={'mt-5'}>
          <div className={'d-flex justify-content-between'}>
            <div className={'d-flex align-items-center'}>
              <h6 className={`me-3 ${styles.rowTest}`}>Rows per page </h6>
              <select
                id="framework"
                className={` font-size-default ${styles.selectBox}`}
                value={pageSize}
                onChange={(e) => {
                  setPageSize(e.target.value as unknown as number);
                  setActivePage(1);
                }}
              >
                <option
                  value="05"
                  selected={true}
                  className={'font-size-default primary-color-text'}
                >
                  05
                </option>
                <option
                  value="10"
                  className={'font-size-default primary-color-text'}
                >
                  10
                </option>
                <option
                  value="15"
                  className={'font-size-default primary-color-text'}
                >
                  15
                </option>
                <option
                  value="20"
                  className={'font-size-default primary-color-text'}
                >
                  20
                </option>
                <option
                  value="100"
                  className={'font-size-default primary-color-text'}
                >
                  100
                </option>
                <option
                  value="200"
                  className={'font-size-default primary-color-text'}
                >
                  200
                </option>
              </select>
            </div>
            <PaginationPages
              hasPreviousPage={paginationData?.hasPreviousPage}
              hasNextPage={paginationData?.hasNextPage}
              clickPrevious={clickPrevious}
              clickNext={clickNext}
              setActivePage={(value) =>
                setActivePage(value as unknown as number)
              }
              currentPage={activePage}
              totalCount={paginationData?.itemCount ?? 10}
              pageSize={pageSize}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenMissingView;
