import React, { useState } from 'react';
import CustomButton from '../shared/customButton/customButton';
import { deleteCurrentUserProfile } from '../../services/profile.services';
import { deleteUser } from '../../services/user.service';
import styles from '../table/userList/userListTable.module.scss';
import { useNavigate } from 'react-router-dom';

type UserCreateProps = {
  onCloseModal: (value: boolean) => void;
  userId?: string;
  fullName?: string;
  fromPage?: string;
  setFullPageLoader?: (value: boolean) => void;
  activeStatus?: boolean;
};

const UserDeleteModal = ({
  onCloseModal,
  userId,
  fullName,
  fromPage,
  setFullPageLoader,
  activeStatus,
}: UserCreateProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleDeleteUserProfile = async () => {
    setLoading(true);
    try {
      await deleteCurrentUserProfile();
      setLoading(false);
      navigate('/');
    } catch {
      setLoading(false);
    }
  };
  const handleDeleteUser = async () => {
    setLoading(true);
    try {
      await deleteUser(userId);
      onCloseModal(false);
      setLoading(false);
      if (fromPage !== 'ViewUser' && setFullPageLoader) {
        setFullPageLoader(true);
      }
      if (fromPage === 'ViewUser') {
        navigate('/userManager');
      }
    } catch {
      setLoading(false);
    }
  };

  return (
    <div>
      <h6 className={'light-text text-normal'}>
        {activeStatus
          ? `Are you sure you want to delete ${fullName}?`
          : `Are you sure you want to permanently delete ${fullName}?`}
      </h6>
      <div className={'d-flex justify-content-between align-items-center mt-5'}>
        <CustomButton
          text={'Cancel'}
          onClick={() => onCloseModal(false)}
          className={styles.buttonWidth}
        />
        <CustomButton
          loading={loading}
          variant={'danger'}
          text={'Delete User'}
          className={`ms-4 ${styles.buttonWidth}`}
          type={'button'}
          onClick={
            fromPage === 'UserProfile'
              ? handleDeleteUserProfile
              : handleDeleteUser
          }
        />
      </div>
    </div>
  );
};
export default UserDeleteModal;
