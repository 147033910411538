import React from 'react';

interface Props {
  size?: number;
  color?: string;
}

const BrowserIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 14}
      height={size ?? 14}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#a)">
        <path
          fill={color ?? '#003359'}
          d="M11.083.583H2.917A2.92 2.92 0 0 0 0 3.5v7a2.92 2.92 0 0 0 2.917 2.917h8.166A2.92 2.92 0 0 0 14 10.5v-7A2.92 2.92 0 0 0 11.083.583ZM2.917 1.75h8.166a1.75 1.75 0 0 1 1.75 1.75v.583H1.167V3.5a1.75 1.75 0 0 1 1.75-1.75Zm8.166 10.5H2.917a1.75 1.75 0 0 1-1.75-1.75V5.25h11.666v5.25a1.75 1.75 0 0 1-1.75 1.75Zm0-4.667a.583.583 0 0 1-.583.584h-7A.583.583 0 0 1 3.5 7h7a.583.583 0 0 1 .583.583ZM8.75 9.918a.583.583 0 0 1-.583.583H3.5a.583.583 0 0 1 0-1.166h4.667a.583.583 0 0 1 .583.583Zm-7-7a.583.583 0 1 1 1.167 0 .583.583 0 0 1-1.167 0Zm1.75 0a.583.583 0 1 1 1.167 0 .583.583 0 0 1-1.167 0Zm1.75 0a.583.583 0 1 1 1.167 0 .583.583 0 0 1-1.167 0Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h14v14H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BrowserIcon;
