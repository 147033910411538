import { ErrorResponse, PaginationType } from '../../../typeDef/common.model';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TableHeading, {
  SortDirection,
} from '../../shared/tableHeading/tableHeading';
import {
  UserName,
  UserRequest,
  UserResponse,
  UserRole,
  UserRoleDisplayFormat,
  UserType,
} from '../../../typeDef/user.model';
import {
  convertDateFormat,
  convertDisableDateFormat,
} from '../../../common/users';
import {
  getAllUsers,
  reactivateDisableUser,
} from '../../../services/user.service';
import ActionIcon from '../../../icons/action.icon';
import CustomButton from '../../shared/customButton/customButton';
import CustomModal from '../../shared/customModal/customModal';
import EditIcon from '../../../icons/edit.icon';
import FileUploadModal from '../../modal/fileUploadModal';
import LoadingSpinner from '../../shared/loadingSpinner/loadingSpinner';
import MessageModal from '../../modal/messageModal';
import PaginationPages from '../../shared/pagination/paginationPages';
import Popup from 'reactjs-popup';
import ProfileImage from './profileImage';
import SearchIcon from '../../../icons/search.icon';
import SpinnerIcon from '../../../icons/spinner.icon';
import TableRow from './userListTableRow/userListTableRow';
import TrashIcon from '../../../icons/trash.icon';
import UserCreateModal from '../../modal/userCreateModal';
import UserDeleteModal from '../../modal/userDeleteModal';
import UserWithRightIcon from '../../../icons/userWithRight.icon';

import loadingStyles from '../../shared/loadingSpinner/loadingSpinner.module.scss';

import styles from './userListTable.module.scss';

import { useNavigate } from 'react-router-dom';

export default function UserListTable() {
  const [userData, setUserData] = useState<UserResponse[]>([]);
  const [paginationData, setPaginationData] = useState<PaginationType>();
  const [createUserModal, setCreateUserModal] = useState(false);
  const [uploadFileOpenModal, setUploadFileOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imageIsLoading, setImageIsLoading] = useState(false);
  const [isReactivationLoading, setIsReactivationLoading] = useState(false);
  const [isReactivationFor30DaysLoading, setIsReactivationFor30DaysLoading] =
    useState(false);
  const [userId, setUserId] = useState('');
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const [fullName, setFullName] = useState('');
  const [activeStatus, setActiveStatus] = useState<boolean>();
  const [searchField, setSearchField] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  // const [sortField, setSortField] = useState('firstName');
  const sortField = useRef(UserName.FIRSTNAME);
  const sortSecondaryField = useRef(UserName.LASTNAME);
  // const [sortOrder, setSortOrder] = useState(SortDirection.ASC);
  const sortOrder = useRef(SortDirection.ASC);
  const sortSecondaryOrder = useRef(SortDirection.ASC);
  const [pageSize, setPageSize] = useState(10);
  const [activePage, setActivePage] = useState<number>(1);
  const [fullPageLoader, setFullPageLoader] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, seIsError] = useState(false);
  const [messageText, setMessageText] = useState('');

  const navigate = useNavigate();

  /* click previous */
  const clickPrevious = () => {
    if (paginationData?.hasPreviousPage) {
      setActivePage(activePage - 1);
    }
  };

  /* click next */
  const clickNext = () => {
    if (paginationData?.hasNextPage) {
      setActivePage(activePage + 1);
    }
  };

  const getAllUserList = useCallback(async () => {
    setIsLoading(true);
    try {
      const query: Partial<UserRequest> = {
        pageNumber: activePage,
        pageSize: pageSize,
        sortOrder: sortOrder.current ?? SortDirection.ASC,
        secondarySortOrder: sortSecondaryOrder.current ?? SortDirection.ASC,
        secondarySortField: sortSecondaryField.current ?? UserName.LASTNAME,
        sortField: sortField.current ?? UserName.FIRSTNAME,
        ...(searchField && {
          firstName: searchField.toLowerCase().trim(),
          lastName: searchField.toLowerCase().trim(),
          // email: searchField.toLowerCase().trim(),
          username: searchField.toLowerCase().trim(),
          location: searchField.toLowerCase().trim(),
        }),
      };
      const response = await getAllUsers(query);
      setErrorMessage('');
      setUserData(response.data.data.items);
      setPaginationData(response.data.data.meta);
      setIsLoading(false);
    } catch (e) {
      const errorResponse = e as ErrorResponse;
      setIsLoading(false);
      if (errorResponse.response.data.message === 'No users found') {
        setErrorMessage('No matching results...');
        setUserData([]);
      }
    }
  }, [pageSize, activePage, searchField, sortOrder, sortField]);

  useEffect(() => {
    (async () => {
      await getAllUserList();
    })();
  }, [pageSize, activePage, fullPageLoader]);

  const handleChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearchField(e.target.value);
  };

  const handleSearch = async () => {
    try {
      if (searchField === '' || activePage === 1) {
        await getAllUserList();
      }
      setActivePage(1);
    } catch (e) {
      setErrorMessage('Something went wrong');
    }
  };
  const handleOnSort = async (sortDirection: SortDirection, title: string) => {
    const sortBy =
      title === 'FIRST NAME'
        ? UserName.FIRSTNAME
        : title === 'LAST NAME'
        ? UserName.LASTNAME
        : // : title === 'EMAIL ADDRESS'
        // ? UserName.EMAIL
        title === 'USERNAME'
        ? UserName.USERNAME
        : title === 'LOCATION'
        ? UserName.LOCATION
        : title === 'CREATED DATE'
        ? UserName.CREATEDATE
        : title === 'LAST LOGIN'
        ? UserName.LASTLOGIN
        : title === 'DISABLE DATE'
        ? UserName.DISABLEDATE
        : title === 'STATUS'
        ? UserName.STATUS
        : UserName.TYPE;
    //setSortField(sortBy);
    sortField.current = sortBy;
    //setSortOrder(sortDirection);
    sortOrder.current = sortDirection;
    if (title === 'LAST NAME') sortSecondaryOrder.current = sortDirection;
    await getAllUserList();
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const pressedKey = event.key;

    if (pressedKey === 'Enter') {
      event.preventDefault();

      await handleSearch();
    }
  };

  const handleReactivateUser = async (id: string, type?: string) => {
    if (type) {
      setIsReactivationFor30DaysLoading(true);
    } else {
      setIsReactivationLoading(true);
    }

    await reactivateDisableUser({ id: id, type: type });

    if (type) {
      setIsReactivationFor30DaysLoading(false);
    } else {
      setIsReactivationLoading(false);
    }

    setTimeout(() => {
      setFullPageLoader(true);
    }, 1000);
    setFullPageLoader(false);
  };

  return (
    <div
      className={`${
        (isLoading || imageIsLoading) && loadingStyles.app_while_loading
      }`}
    >
      {(isLoading || imageIsLoading) && <LoadingSpinner />}
      <div className={`${styles.mainContent}`}>
        <div className={styles.subTitle}>
          <h6 className={'primary-color-text text-bolder'}>User List</h6>
        </div>
        <div
          className={'d-flex justify-content-between align-items-center mt-5'}
        >
          <div className={'d-flex align-items-center'}>
            <div className={styles.searchBarContainer}>
              <input
                type="Search"
                className={`form-control ${styles.searchBar}`}
                placeholder="Search by First Name, Last Name, Username or Location"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
              <label className={styles.iconSeach}>
                <SearchIcon />
              </label>
            </div>

            <CustomButton
              text={'Search'}
              className={'ms-4 fit-content'}
              type={'button'}
              onClick={handleSearch}
            />
          </div>
          <div className={'d-flex justify-content-between'}>
            <CustomButton
              text={'Upload File'}
              className={'fit-content me-2'}
              onClick={() => setUploadFileOpenModal(true)}
            />
            <CustomButton
              text={'Create New User'}
              className={'fit-content'}
              onClick={() => setCreateUserModal(true)}
            />
          </div>
        </div>
        <div className={styles.tableContainer}>
          <table className="table table-striped table-hover mt-5">
            <thead className="table-primary">
              <tr>
                <th scope="col" className={styles.tableHeading}></th>
                <th scope="col" className={styles.tableHeading}>
                  <TableHeading
                    field={'FIRST NAME'}
                    sortable={true}
                    onSort={(sortDirection, title) =>
                      handleOnSort(sortDirection, title)
                    }
                  />
                </th>
                <th scope="col" className={styles.tableHeading}>
                  <TableHeading
                    field={'LAST NAME'}
                    sortable={true}
                    onSort={(sortDirection, title) =>
                      handleOnSort(sortDirection, title)
                    }
                  />
                </th>
                <th scope="col" className={styles.tableHeading}>
                  <TableHeading
                    field={'USERNAME'}
                    sortable={true}
                    onSort={(sortDirection, title) =>
                      handleOnSort(sortDirection, title)
                    }
                  />
                </th>
                <th scope="col" className={styles.tableHeading}>
                  <TableHeading
                    field={'LOCATION'}
                    sortable={true}
                    onSort={(sortDirection, title) =>
                      handleOnSort(sortDirection, title)
                    }
                  />
                </th>
                <th scope="col" className={styles.tableHeading}>
                  <TableHeading
                    field={'CREATED DATE'}
                    sortable={true}
                    onSort={(sortDirection, title) =>
                      handleOnSort(sortDirection, title)
                    }
                  />
                </th>
                <th scope="col" className={styles.tableHeading}>
                  <TableHeading
                    field={'LAST LOGIN'}
                    sortable={true}
                    columnType={'dateFromUser'}
                    onSort={(sortDirection, title) =>
                      handleOnSort(sortDirection, title)
                    }
                  />
                </th>
                <th scope="col" className={styles.tableHeading}>
                  <TableHeading
                    field={'DISABLE DATE'}
                    sortable={true}
                    onSort={(sortDirection, title) =>
                      handleOnSort(sortDirection, title)
                    }
                  />
                </th>
                <th scope="col" className={styles.tableHeading}>
                  <TableHeading
                    field={'USER TYPE'}
                    sortable={true}
                    onSort={(sortDirection, title) =>
                      handleOnSort(sortDirection, title)
                    }
                  />
                </th>
                <th scope="col" className={styles.tableHeading}>
                  <TableHeading
                    field={'STATUS'}
                    sortable={true}
                    onSort={(sortDirection, title) =>
                      handleOnSort(sortDirection, title)
                    }
                  />
                </th>
                <th scope="col" className={styles.tableHeading}>
                  <TableHeading action field={'ACTIONS'} sortable={false} />
                </th>
              </tr>
            </thead>
            <tbody>
              {userData?.map((item: UserResponse) => {
                return (
                  <tr key={item.id}>
                    <th scope="row" className={styles.rowHeight}>
                      <ProfileImage
                        userId={item.id}
                        firstName={item.firstName}
                        lastName={item.lastName}
                        profileImageKey={item.profileImageKey}
                        loading={(value) => setImageIsLoading(value)}
                      />
                    </th>
                    <td className={styles.widthCol}>
                      <TableRow text={item.firstName} />
                    </td>
                    <td className={styles.widthCol}>
                      <TableRow text={item.lastName} />
                    </td>
                    <td className={styles.widthCol}>
                      <TableRow text={item.username} addElipsis />
                    </td>
                    <td className={styles.widthCol}>
                      <TableRow
                        text={item.location ? item.location : 'N/A'}
                        addElipsis
                      />
                    </td>
                    <td className={styles.widthColDate}>
                      <TableRow
                        text={convertDateFormat(new Date(item.createdAt))}
                      />
                    </td>
                    <td
                      className={
                        item.lastLogin
                          ? styles.widthColDate
                          : styles.widthColDateWhenEmpty
                      }
                    >
                      <TableRow
                        text={
                          item.lastLogin
                            ? convertDateFormat(new Date(item.lastLogin))
                            : '-'
                        }
                      />
                    </td>
                    <td
                      className={
                        item.disableDate
                          ? styles.widthColDate
                          : styles.widthColDateWhenEmpty
                      }
                    >
                      <TableRow
                        text={
                          item.disableDate
                            ? convertDisableDateFormat(
                                new Date(item.disableDate)
                              )
                            : '-'
                        }
                      />
                    </td>

                    <td className={styles.widthColSmall}>
                      <div className={'d-flex justify-content-between'}>
                        <TableRow
                          text={
                            item.type === UserType.PERMANENT
                              ? UserRoleDisplayFormat.STAFF
                              : item.type === UserType.CASUAL &&
                                item.role === UserRole.USER
                              ? UserRoleDisplayFormat.GUEST
                              : `${item.role}`
                          }
                          type={item.type}
                        />
                      </div>
                    </td>

                    <td className={styles.widthColSmallNew}>
                      <TableRow text={item.isActive ? 'Active' : 'Inactive'} />
                    </td>

                    <td className={styles.widthActionCol}>
                      {!item.isActive && (
                        <Popup
                          trigger={
                            <a className={''}>
                              <ActionIcon />
                            </a>
                          }
                          position="left bottom"
                        >
                          <div className={styles.actionContainer}>
                            <a
                              className={'pointer text-decoration-none'}
                              onClick={() => handleReactivateUser(item.id)}
                            >
                              <ul
                                className={`d-flex justify-content-start align-items-center ${styles.popUpSelection}`}
                              >
                                {isReactivationLoading ? (
                                  <SpinnerIcon size={15} color={'#373737'} />
                                ) : (
                                  <UserWithRightIcon />
                                )}
                                <h6 className={styles.linkText}>
                                  Reactivate User
                                </h6>
                              </ul>
                            </a>
                            <a
                              className={'pointer text-decoration-none'}
                              onClick={() =>
                                handleReactivateUser(item.id, 'define')
                              }
                            >
                              <ul
                                className={`d-flex justify-content-start align-items-center ${styles.popUpSelection}`}
                              >
                                {isReactivationFor30DaysLoading ? (
                                  <SpinnerIcon size={15} color={'#373737'} />
                                ) : (
                                  <UserWithRightIcon />
                                )}
                                <h6 className={styles.linkText}>
                                  Reactivate User
                                  <div>(30 days)</div>
                                </h6>
                              </ul>
                            </a>
                            <a
                              onClick={() => {
                                setDeleteModal(true);
                                setUserId(item.id);
                                setFullName(
                                  `${item.firstName} ${item.lastName}`
                                );
                                setActiveStatus(item.isActive);
                              }}
                              className={'pointer text-decoration-none'}
                            >
                              <ul
                                className={`d-flex justify-content-start align-items-center ${styles.popUpSelection}`}
                              >
                                <TrashIcon />
                                <h6 className={styles.linkText}>Delete User</h6>
                              </ul>
                            </a>
                          </div>
                        </Popup>
                      )}
                      {item.isActive && (
                        <Popup
                          trigger={
                            <a className={''}>
                              <ActionIcon />
                            </a>
                          }
                          position="left bottom"
                        >
                          <div className={styles.actionContainer}>
                            {item.type != 'Permanent' && (
                              <a
                                className={'pointer text-decoration-none'}
                                onClick={() =>
                                  navigate('/viewUser', {
                                    state: {
                                      userId: item.id,
                                      firstName: item.firstName,
                                      lastName: item.lastName,
                                      username: item.username,
                                      email: item.email,
                                      role: item.role,
                                      type: item.type,
                                      location: item.location,
                                    },
                                  })
                                }
                              >
                                <ul
                                  className={`d-flex justify-content-start align-items-center ${styles.popUpSelection}`}
                                >
                                  <EditIcon />
                                  <h6 className={styles.linkText}>
                                    Edit Profile
                                  </h6>
                                </ul>
                              </a>
                            )}
                            <a
                              onClick={() => {
                                setDeleteModal(true);
                                setUserId(item.id);
                                setFullName(
                                  `${item.firstName} ${item.lastName}`
                                );
                                setActiveStatus(item.isActive);
                              }}
                              className={'pointer text-decoration-none'}
                            >
                              <ul
                                className={`d-flex justify-content-start align-items-center ${styles.popUpSelection}`}
                              >
                                <TrashIcon />
                                <h6 className={styles.linkText}>Delete User</h6>
                              </ul>
                            </a>
                          </div>
                        </Popup>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {errorMessage && (
          <div>
            <h4 className={'mt-5 text-danger text-center'}>{errorMessage}</h4>
          </div>
        )}
        <div className={'mt-5'}>
          {/*<Pagination*/}
          {/*  userData={userData}*/}
          {/*  setUserData={(value) => setUserData(value)}*/}
          {/*/>*/}

          <div className={'d-flex justify-content-between'}>
            <div className={'d-flex align-items-center'}>
              <h6 className={`me-3 ${styles.rowTest}`}>Rows per page </h6>
              <select
                id="framework"
                className={` font-size-default ${styles.selectBox}`}
                value={pageSize}
                onChange={(e) => {
                  setPageSize(e.target.value as unknown as number);
                  setActivePage(1);
                }}
              >
                <option
                  value="05"
                  className={'font-size-default primary-color-text'}
                >
                  05
                </option>
                <option
                  value="10"
                  selected={true}
                  className={'font-size-default primary-color-text'}
                >
                  10
                </option>
                <option
                  value="15"
                  className={'font-size-default primary-color-text'}
                >
                  15
                </option>
                <option
                  value="20"
                  className={'font-size-default primary-color-text'}
                >
                  20
                </option>
                <option
                  value="100"
                  className={'font-size-default primary-color-text'}
                >
                  100
                </option>
                <option
                  value="200"
                  className={'font-size-default primary-color-text'}
                >
                  200
                </option>
              </select>
            </div>
            <PaginationPages
              hasPreviousPage={paginationData?.hasPreviousPage}
              hasNextPage={paginationData?.hasNextPage}
              clickPrevious={clickPrevious}
              clickNext={clickNext}
              setActivePage={(value) =>
                setActivePage(value as unknown as number)
              }
              currentPage={activePage}
              totalCount={paginationData?.itemCount ?? 10}
              pageSize={pageSize}
            />
          </div>
        </div>
        {createUserModal && (
          <CustomModal
            title={'Create a New User'}
            open={createUserModal}
            onCloseModal={(val) => setCreateUserModal(val)}
          >
            <UserCreateModal
              onCloseModal={setCreateUserModal}
              status={setStatus}
              message={setMessage}
            />
          </CustomModal>
        )}
        {deleteModal && (
          <CustomModal
            title={'Delete User'}
            deleteTitle
            open={deleteModal}
            onCloseModal={(val) => setDeleteModal(val)}
          >
            <UserDeleteModal
              onCloseModal={setDeleteModal}
              userId={userId}
              fullName={fullName}
              setFullPageLoader={(value) => {
                setTimeout(() => {
                  setFullPageLoader(value);
                }, 1000);
                setFullPageLoader(false);
              }}
              activeStatus={activeStatus}
            />
          </CustomModal>
        )}
        {uploadFileOpenModal && (
          <CustomModal
            title={'Upload File'}
            open={uploadFileOpenModal}
            onCloseModal={(val) => setUploadFileOpenModal(val)}
          >
            <FileUploadModal
              onCloseModal={setUploadFileOpenModal}
              setOpenModal={(value) => setOpenModal(value)}
              setIsSuccess={(value) => setIsSuccess(value)}
              setIsError={(value) => seIsError(value)}
              setMessageText={(value) => setMessageText(value)}
            />
          </CustomModal>
        )}
        {status === 'success' && (
          <CustomModal
            title={'Success'}
            open={status === 'success'}
            success
            onCloseModal={() => setStatus('')}
          >
            <div className={styles.modalWidth}>
              <h6 className={'light-text text-normal'}>
                User successfully added.
              </h6>
              <div
                className={
                  'd-flex justify-content-between align-items-center mt-5'
                }
              >
                <CustomButton
                  text={'Ok'}
                  onClick={async () => {
                    setStatus('');
                    await getAllUserList();
                  }}
                  className={styles.buttonWidth}
                />
              </div>
            </div>
          </CustomModal>
        )}
        {status === 'error' && (
          <CustomModal
            title={'Error'}
            open={status === 'error'}
            deleteTitle
            onCloseModal={() => setStatus('')}
          >
            <div className={styles.modalWidth}>
              <h6 className={'light-text text-normal'}>{message}</h6>
              <div
                className={
                  'd-flex justify-content-between align-items-center mt-5'
                }
              >
                <CustomButton
                  text={'Ok'}
                  onClick={async () => {
                    setStatus('');
                    await getAllUserList();
                  }}
                  className={styles.buttonWidth}
                />
              </div>
            </div>
          </CustomModal>
        )}
        {openModal && (
          <MessageModal
            title={isSuccess ? 'File Imported' : 'File Error'}
            isSuccess={isSuccess}
            isError={isError}
            onCloseModal={(value) => {
              setOpenModal(value);
              isSuccess && setUploadFileOpenModal(false);
            }}
            openModal={openModal}
            messageText={messageText}
            from={'userManager'}
          />
        )}
      </div>
    </div>
  );
}
