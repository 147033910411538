import * as Yup from 'yup';

import AuthContext, { AgtUser } from '../../context/auth.context';
import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { SignInRequest, SignInResponse } from '../../typeDef/auth.model';
import { signIn, signInURL } from '../../services/auth.service';
import CustomButton from '../../components/shared/customButton/customButton';
import CustomInputField from '../../components/shared/customInputField/customInputFiled';
import CustomModal from '../../components/shared/customModal/customModal';
import LockIcon from '../../icons/lock.icon';
import Microsoft from '../../icons/microsoft.icon';
import SignInImage from '../../images/sign.webp';
import UploadIcon from '../../icons/upload.icon';
import UserIcon from '../../icons/user.icon';

import agtLeaf from '../../images/agtLeaf.webp';
import { clearData } from '../../services/database.service';
import { getCurrentUserProfile } from '../../services/profile.services';
import styles from './signIn.module.scss';
import { useNavigate } from 'react-router-dom';

type SignInProps = {
  username: string;
  password: string;
};

function SignIn() {
  const navigate = useNavigate();
  const { user, setUser } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [closeModal, setCloseModal] = useState(
    localStorage.getItem('tokenExpirationKey') === 'tokenExpirationValue'
  );
  const [userNameValue, setUserNameValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const initialVal: SignInProps = useMemo(() => {
    return {
      username: '',
      password: '',
    };
  }, []);

  useEffect(() => {
    if (userNameValue !== '' || passwordValue !== '') {
      setErrorMessage('');
    }
  }, [userNameValue, passwordValue]);

  /**
   * Handle successful login
   * @param authResponse The response from the server
   * @returns void
   */
  const loginSuccess = async (authResponse: SignInResponse): Promise<void> => {
    setIsLoading(false);

    localStorage.setItem('token', authResponse.token);
    localStorage.setItem('refreshToken', authResponse.refreshToken);
    localStorage.setItem(
      'tokenExpiration',
      String(new Date().getTime() + authResponse.expiresIn * 1000)
    );

    const userResponse = await getCurrentUserProfile();
    localStorage.setItem('userType', userResponse.data.type);
    setUser(
      new AgtUser({ jwtToken: authResponse.token, ...userResponse.data })
    );

    dispatchEvent(new Event('storage'));
    navigate('/dashboard');
  };

  /**
   * Handle failed login
   * @param error The error from the server
   * @returns void
   */
  const loginFailure = (error: unknown) => {
    setIsLoading(false);

    if (
      error instanceof Object &&
      'response' in error &&
      error.response instanceof Object &&
      'data' in error.response &&
      error.response.data instanceof Object &&
      'message' in error.response.data &&
      typeof error.response.data.message === 'string'
    ) {
      switch (error.response.data.message) {
        case 'Cannot sign in non-local user, use Microsoft sign in instead.':
          return setErrorMessage('Please sign in with your AGT account.');
        // return localStorage.setItem(
        //   'errorMessageKey',
        //   'Please sign in with your AGT account.'
        // );
        case 'User not found':
          return setErrorMessage('User not found.');
        //return localStorage.setItem('errorMessageKey', 'User not found.');
        case 'Incorrect username or password':
          return setErrorMessage('Incorrect username or password.');
        // return localStorage.setItem(
        //   'errorMessageKey',
        //   'Incorrect username or password.'
        // );
        case 'Your account is deactivated.':
          return setErrorMessage('Your account is deactivated.');
      }
    } else {
      //setErrorMessage('An unknown error occurred.');
      localStorage.setItem('errorMessageKey', 'An unknown error occurred.');
    }
  };

  useEffect(() => {
    if (user?.jwtToken || localStorage.getItem('token')) {
      // If there is a token, check if it is valid
      getCurrentUserProfile()
        .then((response) => {
          // If the token is valid, set the user and navigate to the dashboard
          setUser(
            new AgtUser({
              jwtToken: localStorage.getItem('token') ?? user?.jwtToken ?? '',
              email: response.data.email,
              firstName: response.data.firstName,
              lastName: response.data.lastName,
            })
          );
          navigate('/dashboard');
        })
        .catch(() => {
          // If the token is invalid, clear the data and remove the token
          (async () => {
            await clearData();
          })();
          setUser(undefined);
          localStorage.removeItem('token');
        });
    } else {
      // If there is no token, clear the data
      (async () => {
        await clearData();
      })();
    }
  }, [user]);

  const validationSchema = Yup.object().shape({
    username: Yup.string().required('Please enter your username.'),
    password: Yup.string().required('Please enter your password.'),
  });

  const handleSubmit = async (values: SignInProps) => {
    setIsLoading(true);
    try {
      const signInUser: Partial<SignInRequest> = {};
      signInUser.username = values.username.toLowerCase();
      signInUser.password = values.password;

      await signIn(signInUser)
        .then((r) => loginSuccess(r.data))
        .catch(loginFailure);
    } catch {
      setIsLoading(false);
    }
  };

  const handleSubmitWithAGTAccount = async () => {
    const url = await signInURL();
    location.assign(url.data.url);
  };

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const version = require('../../../package.json').version;

  return (
    <div>
      <Formik
        initialValues={initialVal}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        {({ setFieldValue }) => (
          <Form>
            <div className={'d-flex w-100'}>
              <div className={'w-50'}>
                <img
                  src={SignInImage}
                  alt="signin image"
                  className={styles.mainImage}
                />
              </div>
              <div className={'d-flex justify-content-center w-50'}>
                <div className={styles.formContainer}>
                  <div className={'d-flex justify-content-center'}>
                    <img src={agtLeaf} alt={'leaf'} className={styles.image} />
                  </div>
                  <h2
                    className={
                      'primary-color-text text-bolder mt-4 mt-xl-0 d-flex justify-content-center'
                    }
                  >
                    Welcome to AGT Weights and Keep/Cull
                  </h2>
                  <h6
                    className={
                      'mt-4 light-text  d-flex justify-content-center text-bolder'
                    }
                  >
                    Please login to continue
                  </h6>
                  <div className={'mt-5'}>
                    <CustomButton
                      className={styles.microsoftBtn}
                      text={'Sign in with your AGT email account'}
                      icon={<Microsoft />}
                      iconSide={'left'}
                      type={'button'}
                      onClick={handleSubmitWithAGTAccount}
                    />
                  </div>
                  <div
                    className={
                      'mt-5 d-flex justify-content-between align-items-center'
                    }
                  >
                    <div className={styles.devidedLine} />
                    <h6 className={'light-text text-normal'}>
                      Or Casual Login
                    </h6>
                    <div className={styles.devidedLine} />
                  </div>

                  <div className={'mt-4'}>
                    <p className={'primary-color-text mb-2'}>User Name</p>
                    <CustomInputField
                      name={'username'}
                      icon={<UserIcon />}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('username', e.target.value);
                        setUserNameValue(e.target.value);
                      }}
                      autoComplete="new-username"
                    />
                  </div>
                  <div className={'mt-4'}>
                    <p className={'primary-color-text mb-2'}>Password</p>
                    <CustomInputField
                      autoFocus={false}
                      type={'password'}
                      name={'password'}
                      icon={<LockIcon />}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('password', e.target.value);
                        setPasswordValue(e.target.value);
                      }}
                      autoComplete="new-password"
                    />
                  </div>
                  {errorMessage && (
                    <div>
                      <p className={'mt-4 text-danger text-center'}>
                        <small>{errorMessage}</small>
                      </p>
                    </div>
                  )}
                  <div className={'mt-5'}>
                    <CustomButton
                      text={'Login'}
                      className={styles.heightBtn}
                      type={'submit'}
                      loading={isLoading}
                    />
                  </div>
                  {/*<div className={'w-25'}>*/}
                  {/*  <p*/}
                  {/*    className={*/}
                  {/*      'primary-color-text text-bold underline-text pointer mt-3'*/}
                  {/*    }*/}
                  {/*    onClick={() =>*/}
                  {/*      navigate('/forgetPassword', {*/}
                  {/*        state: {*/}
                  {/*          email: emailValue,*/}
                  {/*        },*/}
                  {/*      })*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    Forgot Password*/}
                  {/*  </p>*/}
                  {/*</div>*/}
                  <div className={`mt-4 ${styles.yelloInfoBox}`}>
                    <UploadIcon />
                    <p className={'text-bold secondary-color-text ms-3'}>
                      Please check with administrator for new account
                    </p>
                  </div>
                  <p className={'d-flex justify-content-end w-100 mt-5'}>
                    v<span>{version}</span>
                  </p>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {localStorage.getItem('tokenExpirationKey') === 'tokenExpirationValue' &&
        localStorage.getItem('errorMessageKey') && (
          <CustomModal
            title={
              localStorage.getItem('errorMessageKey') ===
              'Invalid Session. Please log in again to regain access to your account'
                ? 'Warning'
                : 'Error'
            }
            open={closeModal}
            warning={
              localStorage.getItem('errorMessageKey') ===
              'Invalid Session. Please log in again to regain access to your account'
                ? true
                : false
            }
            deleteTitle={
              localStorage.getItem('errorMessageKey') ===
              'Invalid Session. Please log in again to regain access to your account'
                ? false
                : true
            }
            onCloseModal={() => {
              setCloseModal(false);
              localStorage.removeItem('tokenExpirationKey');
              localStorage.removeItem('errorMessageKey');
            }}
          >
            <div className={styles.modalWidth}>
              <h6 className={'light-text text-normal'}>
                {localStorage.getItem('errorMessageKey')}
              </h6>
              <div
                className={
                  'd-flex justify-content-between align-items-center mt-5'
                }
              >
                <CustomButton
                  text={'OK'}
                  onClick={() => {
                    setCloseModal(false);
                    localStorage.removeItem('tokenExpirationKey');
                    localStorage.removeItem('errorMessageKey');
                  }}
                  className={styles.buttonWidth}
                />
              </div>
            </div>
          </CustomModal>
        )}
    </div>
  );
}

export default SignIn;
