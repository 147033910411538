import './App.scss';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Callback from './pages/auth/callback/callback';
import { DashboardOverview } from './pages/dashboardOverview/dashboardOverview';
import { DashboardWeightProcessingSection } from './pages/dashboardWeightSection/dashboardWeightProcessingSection';
import DashboardWeightSection from './pages/dashboardWeightSection/dashboardWeightSection';
import ForgetPassword from './pages/signIn/forgetPassword';
import ImageList from './pages/imgeManager/imageList';
import ImageManager from './pages/imgeManager/imageManager';
import ImportFile from './pages/importFile/importFile';
import { KeepCullResult } from './pages/keepCull/keepcullResult';
import KeepCullSection from './pages/keepCull/keepCullSelection';
import Loader from './components/loader/loader';
import OfflineSync from './components/offlineSync/offline';
import React from 'react';
import ResetPassword from './pages/signIn/resetPassword';
import RootContext from './context';
import SignIn from './pages/signIn/signIn';
import TestDashboard from './pages/testDashboard/testDashboard';
import TokenRefreshProvider from './providers/token.refresh.provider';
import UserManager from './pages/userManager/userManager';
import UserProfilePage from './pages/userProfile/userProfile';
import ViewUser from './components/viewUser/viewUser';
import { useLoader } from './context/LoaderContext';
import withClearCache from './ClearCache';

function AppLoader({ loader }: { loader: boolean | undefined }) {
  return <>{loader && <Loader />}</>;
}

function App() {
  const { isLoading } = useLoader();

  return (
    <TokenRefreshProvider>
      <RootContext>
        <OfflineSync>
          <BrowserRouter>
            <AppLoader loader={isLoading} />
            <Routes>
              <Route path={'/'} element={<SignIn />} />
              <Route path={'/auth/callback'} element={<Callback />} />
              <Route path={'/forgetPassword'} element={<ForgetPassword />} />
              <Route path={'/resetPassword'} element={<ResetPassword />} />
              <Route path={'/testDashboard'} element={<TestDashboard />} />
              <Route path={'/userManager'} element={<UserManager />} />
              <Route path={'/imageManager'} element={<ImageManager />} />
              <Route path={'/imageList'} element={<ImageList />} />
              <Route path={'/dashboard'} element={<DashboardOverview />} />
              <Route path={'/weight'} element={<DashboardWeightSection />} />
              <Route
                path={'/weight/processing'}
                element={<DashboardWeightProcessingSection />}
              />
              <Route path={'/userProfile'} element={<UserProfilePage />} />
              <Route path={'/viewUser'} element={<ViewUser />} />
              <Route path={'/importFile'} element={<ImportFile />} />
              <Route path={'/keepCull'} element={<KeepCullSection />} />
              <Route path={'/keepCull/result'} element={<KeepCullResult />} />
            </Routes>
          </BrowserRouter>
        </OfflineSync>
      </RootContext>
    </TokenRefreshProvider>
  );
}

const ClearCacheApp =
  process.env.NODE_ENV === 'production' ? withClearCache(App) : App;

export default ClearCacheApp;
